import React from 'react';
import LabelInputList from '../../UIBuilder/CustomDrawer/components/LabelInputList';
import { activityLayoutStore } from '../../../pages/ActivityLayoutTab/ActivityLayoutStore';
import { observer } from "mobx-react-lite";

const EntitySettings = observer((selectedEntity, setSelectedEntity) => {
    // TODO, need to cahnge the defaults and callback to the actual selected entity values
    const labelInfoContents = [
        {
            labelName: "Position",
            inputInfo: [
                {
                    inputValue: activityLayoutStore.currentEntityContext === "" ? "x" : activityLayoutStore.json[activityLayoutStore.currentActivityContext].entities[activityLayoutStore.currentEntityContext].components.position[0],
                    inputCallback: (input) => { activityLayoutStore.setEntityPosition(activityLayoutStore.currentEntityContext, 
                        [
                            input, 
                            activityLayoutStore.json[activityLayoutStore.currentActivityContext].entities[activityLayoutStore.currentEntityContext].components.position[1],
                            activityLayoutStore.json[activityLayoutStore.currentActivityContext].entities[activityLayoutStore.currentEntityContext].components.position[2]
                        ] )},
                },
                {
                    inputValue: activityLayoutStore.currentEntityContext === "" ? "y" : activityLayoutStore.json[activityLayoutStore.currentActivityContext].entities[activityLayoutStore.currentEntityContext].components.position[1],
                    inputCallback: (input) => { activityLayoutStore.setEntityPosition(activityLayoutStore.currentEntityContext, 
                        [
                            activityLayoutStore.json[activityLayoutStore.currentActivityContext].entities[activityLayoutStore.currentEntityContext].components.position[0],
                            input, 
                            activityLayoutStore.json[activityLayoutStore.currentActivityContext].entities[activityLayoutStore.currentEntityContext].components.position[2]
                        ] )},
                },
                {
                    inputValue: activityLayoutStore.currentEntityContext === "" ? "z" : activityLayoutStore.json[activityLayoutStore.currentActivityContext].entities[activityLayoutStore.currentEntityContext].components.position[2],
                    inputCallback: (input) => { activityLayoutStore.setEntityPosition(activityLayoutStore.currentEntityContext, 
                        [
                            activityLayoutStore.json[activityLayoutStore.currentActivityContext].entities[activityLayoutStore.currentEntityContext].components.position[0],
                            activityLayoutStore.json[activityLayoutStore.currentActivityContext].entities[activityLayoutStore.currentEntityContext].components.position[1],
                            input
                        ] )},
                },
            ]
        },
        {
            labelName: "Rotation",
            inputInfo: [
                {
                    inputValue: activityLayoutStore.currentEntityContext === "" ? "x" : activityLayoutStore.json[activityLayoutStore.currentActivityContext].entities[activityLayoutStore.currentEntityContext].components.rotation[0],
                    inputCallback: (input) => { activityLayoutStore.setEntityRotation(activityLayoutStore.currentEntityContext, 
                        [
                            input, 
                            activityLayoutStore.json[activityLayoutStore.currentActivityContext].entities[activityLayoutStore.currentEntityContext].components.rotation[1],
                            activityLayoutStore.json[activityLayoutStore.currentActivityContext].entities[activityLayoutStore.currentEntityContext].components.rotation[2]
                        ] )},
                },
                {
                    inputValue: activityLayoutStore.currentEntityContext === "" ? "y" : activityLayoutStore.json[activityLayoutStore.currentActivityContext].entities[activityLayoutStore.currentEntityContext].components.rotation[1],
                    inputCallback: (input) => { activityLayoutStore.setEntityRotation(activityLayoutStore.currentEntityContext, 
                        [
                            activityLayoutStore.json[activityLayoutStore.currentActivityContext].entities[activityLayoutStore.currentEntityContext].components.rotation[0],
                            input, 
                            activityLayoutStore.json[activityLayoutStore.currentActivityContext].entities[activityLayoutStore.currentEntityContext].components.rotation[2]
                        ] )},
                },
                {
                    inputValue: activityLayoutStore.currentEntityContext === "" ? "z" : activityLayoutStore.json[activityLayoutStore.currentActivityContext].entities[activityLayoutStore.currentEntityContext].components.rotation[2],
                    inputCallback: (input) => { activityLayoutStore.setEntityRotation(activityLayoutStore.currentEntityContext, 
                        [
                            activityLayoutStore.json[activityLayoutStore.currentActivityContext].entities[activityLayoutStore.currentEntityContext].components.rotation[0],
                            activityLayoutStore.json[activityLayoutStore.currentActivityContext].entities[activityLayoutStore.currentEntityContext].components.rotation[1],
                            input
                        ] )},
                },
            ]
        },
        {
            labelName: "RigidBody Size",
            inputInfo: [
                {
                    inputValue: activityLayoutStore.currentEntityContext === "" ? "x" : activityLayoutStore.json[activityLayoutStore.currentActivityContext].entities[activityLayoutStore.currentEntityContext].components.size[0],
                    inputCallback: (input) => { activityLayoutStore.setEntityRigidBodySize(activityLayoutStore.currentEntityContext, 
                        [
                            input, 
                            activityLayoutStore.json[activityLayoutStore.currentActivityContext].entities[activityLayoutStore.currentEntityContext].components.size[1],
                            activityLayoutStore.json[activityLayoutStore.currentActivityContext].entities[activityLayoutStore.currentEntityContext].components.size[2]
                        ] )},
                },
                {
                    inputValue: activityLayoutStore.currentEntityContext === "" ? "y" : activityLayoutStore.json[activityLayoutStore.currentActivityContext].entities[activityLayoutStore.currentEntityContext].components.size[1],
                    inputCallback: (input) => { activityLayoutStore.setEntityRigidBodySize(activityLayoutStore.currentEntityContext, 
                        [
                            activityLayoutStore.json[activityLayoutStore.currentActivityContext].entities[activityLayoutStore.currentEntityContext].components.size[0],
                            input, 
                            activityLayoutStore.json[activityLayoutStore.currentActivityContext].entities[activityLayoutStore.currentEntityContext].components.size[2]
                        ] )},
                },
                {
                    inputValue: activityLayoutStore.currentEntityContext === "" ? "z" : activityLayoutStore.json[activityLayoutStore.currentActivityContext].entities[activityLayoutStore.currentEntityContext].components.size[2],
                    inputCallback: (input) => { activityLayoutStore.setEntityRigidBodySize(activityLayoutStore.currentEntityContext, 
                        [
                            activityLayoutStore.json[activityLayoutStore.currentActivityContext].entities[activityLayoutStore.currentEntityContext].components.size[0],
                            activityLayoutStore.json[activityLayoutStore.currentActivityContext].entities[activityLayoutStore.currentEntityContext].components.size[1],
                            input
                        ] )},
                },
            ]
        },
        
    ]
    return (
      <div>
        <LabelInputList labelInfoContents={labelInfoContents}/>
      </div>
    );
  });
  
export default EntitySettings;