import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import deepClone from "../../utils/deepClone";
import AudioSettingsTab from "./Components/AudioSettingsTab/AudioSettingsTab";
import ControlSettingsTab from "./Components/ControlSettingsTab/ControlSettingsTab";
import DisplaySettingsTab from "./Components/DisplaySettingsTab/DisplaySettingsTab";
import GeneralSettingsTab from "./Components/GeneralSettingsTab/GeneralSettingsTab";

export default function SettingsMenu({
	accessibilitySettings,
	setAccessibilityAttribute,
	overrideAccessibilitySettings,
	resetToDefaultAccessibilitySettings,
	toggleDisplayAccessibilityMenu,
	displayAccessibilityMenu,
	checkIfKeyBeingUsed,
	mutedSoundSettings,
	setMutedSettings,
}) {
	const [initalAccessibilitySettings, setInitalAccessibilitySettings] = useState(null);
	const blackAndWhite = accessibilitySettings?.display.blackAndWhite;

	const menuRef = useRef(null);

	useEffect(() => {
		const handleClickOutside = event => {
			if (menuRef.current && !menuRef.current.contains(event.target)) {
				handleCancelSettings();
			}
		};

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [displayAccessibilityMenu, toggleDisplayAccessibilityMenu]);

	useEffect(() => {
		if (displayAccessibilityMenu) {
			setInitalAccessibilitySettings(deepClone(accessibilitySettings));
		}
	}, [displayAccessibilityMenu]);

	const [activeTab, setActiveTab] = useState("GeneralSettingsTab");

	const handleTabClick = tab => {
		setActiveTab(tab);
	};

	/**
	 * Function to handle focusing the settings cog button
	 */
	function focusOnSettingsButton() {
		const settingsButton = document.getElementById("accessibility-settings-button");
		if (settingsButton) {
			settingsButton.focus();
		} else {
			console.warn("Settings button not found.");
		}
	}
	/**
	 * Function to handle settings save
	 */
	function handleConfirmSettings() {
		toggleDisplayAccessibilityMenu();
		focusOnSettingsButton();
		// eslint-disable-next-line no-undef
		if (window.engine) {
			// eslint-disable-next-line no-undef
			window.engine.toggleHighContrastMode(accessibilitySettings.display.highContrast);
		}
	}

	/**
	 * Function to handle settings menu reset
	 */
	function handleResetSettings() {
		toggleDisplayAccessibilityMenu();
		resetToDefaultAccessibilitySettings();
		focusOnSettingsButton();
		// eslint-disable-next-line no-undef
		if (window.engine) {
			// eslint-disable-next-line no-undef
			window.engine.toggleHighContrastMode(accessibilitySettings.display.highContrast);
		}
	}

	/**
	 * Function to handle reset updated settings
	 */
	function handleCancelSettings() {
		toggleDisplayAccessibilityMenu();

		// Reset the settings to the initial settings
		overrideAccessibilitySettings(initalAccessibilitySettings);

		focusOnSettingsButton();

		if (window.engine) {
			window.engine.toggleHighContrastMode(initalAccessibilitySettings.display.highContrast);
		}
	}

	return (
		displayAccessibilityMenu && (
			<div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-25 px-2">
				<div
					ref={menuRef}
					id="settings-pop-up__container"
					className="w-11/12 max-w-[700px] h-3/4 absolute bg-slate-100 rounded-lg shadow-2xl z-100"
				>
					<div className="w-full h-full flex justify-center items-center flex-col">
						<div className="w-[90%] flex flex-row justify-between">
							<button
								type="button"
								className={`
										text-bold ${
											activeTab === "GeneralSettingsTab" ? "bg-[#4622f8] text-white" : "bg-[#bac9ff] text-black"
										}
										rounded-lg px-2 sm:px-8 md:px-10 py-2 my-4 text-sm sm:text-base opactiy-100 hover:opacity-75 shadow-md shadow-slate-300
										focus:ring-offset-2 focus:ring-[#1FC3FF] focus:ring focus:outline-none
									`}
								aria-label="General Settings Tab"
								onClick={() => handleTabClick("GeneralSettingsTab")}
							>
								General
							</button>
							<button
								type="button"
								className={`
										text-bold ${
											activeTab === "AudioSettingsTab" ? "bg-[#4622f8] text-white" : "bg-[#bac9ff] text-black"
										}
										rounded-lg px-2 xs:px-4 sm:px-8 md:px-10 py-2 my-4 text-sm sm:text-base hover:opacity-75 shadow-md shadow-slate-300
										focus:ring-offset-2 focus:ring-[#1FC3FF] focus:ring focus:outline-none
									`}
								aria-label="Audio Settings Tab"
								onClick={() => handleTabClick("AudioSettingsTab")}
							>
								Audio
							</button>
							<button
								type="button"
								className={`
										text-bold ${
											activeTab === "DisplaySettingsTab" ? "bg-[#4622f8] text-white" : "bg-[#bac9ff] text-black"
										}
										rounded-lg px-2 xs:px-4 sm:px-8 md:px-10 py-2 my-4 text-sm sm:text-base hover:opacity-75
										shadow-md shadow-slate-300
										focus:ring-offset-2 focus:ring-[#1FC3FF] focus:ring focus:outline-none
									`}
								aria-label="Display Settings Tab"
								onClick={() => handleTabClick("DisplaySettingsTab")}
							>
								Display
							</button>
							<button
								type="button"
								className={`
										text-bold ${
											activeTab === "ControlsSettingsTab" ? "bg-[#4622f8] text-white" : "bg-[#bac9ff] text-black"
										}
										rounded-lg px-2 xs:px-4 sm:px-8 md:px-10 py-2 my-4 text-sm sm:text-base
										hover:opacity-75 shadow-md shadow-slate-300
										focus:ring-offset-2 focus:ring-[#1FC3FF] focus:ring focus:outline-none
									`}
								aria-label="Control Settings Tab"
								onClick={() => handleTabClick("ControlsSettingsTab")}
							>
								Controls
							</button>
						</div>
						<div className="w-[90%] h-full rounded-md border overflow-auto border-slate-500 flex flex-col items-center">
							{activeTab === "GeneralSettingsTab" && (
								<GeneralSettingsTab
									accessibilitySettings={accessibilitySettings}
									setAccessibilityAttribute={setAccessibilityAttribute}
								/>
							)}
							{activeTab === "AudioSettingsTab" && (
								<AudioSettingsTab
									accessibilitySettings={accessibilitySettings}
									setAccessibilityAttribute={setAccessibilityAttribute}
									mutedSoundSettings={mutedSoundSettings}
									setMutedSettings={setMutedSettings}
								/>
							)}
							{activeTab === "DisplaySettingsTab" && (
								<DisplaySettingsTab
									accessibilitySettings={accessibilitySettings}
									setAccessibilityAttribute={setAccessibilityAttribute}
								/>
							)}
							{activeTab === "ControlsSettingsTab" && (
								<ControlSettingsTab
									accessibilitySettings={accessibilitySettings}
									setAccessibilityAttribute={setAccessibilityAttribute}
									checkIfKeyBeingUsed={checkIfKeyBeingUsed}
								/>
							)}
						</div>
						<div className="flex justify-end xs:w-[90%]">
							<button
								type="button"
								className={`bg-gray-500 text-white px-2 text-sm sm:text-base sm:px-6 py-2 my-2 mr-auto rounded-md
										active:opacity-50 hover:opacity-75 
										shadow-md shadow-slate-300
										focus:ring-offset-2 focus:ring-[#1FC3FF] focus:ring focus:outline-none
										`}
								aria-label="Reset To Default"
								onClick={handleResetSettings}
							>
								Reset To Default
							</button>
							<button
								type="button"
								className={`
										bg-gray-500 text-white sm:px-6 text-sm sm:text-base px-2 py-2 xs:mr-2 my-2 rounded-md
										active:opacity-50 hover:opacity-75 
										focus:ring-offset-2 focus:ring-[#1FC3FF] focus:ring focus:outline-none

										shadow-md shadow-slate-300
									`}
								aria-label="Cancel"
								onClick={handleCancelSettings}
							>
								Cancel
							</button>
							<button
								type="button"
								className={`
										bg-color-bua-button-primary text-white text-sm sm:text-base px-2 sm:px-6 py-2 my-2 rounded-md
										active:opacity-50 hover:opacity-75 
										focus:ring-offset-2 focus:ring-[#1FC3FF] focus:ring focus:outline-none
										shadow-md shadow-slate-300
									`}
								aria-label="Confirm"
								onClick={handleConfirmSettings}
							>
								Confirm
							</button>
						</div>
					</div>
				</div>
			</div>
		)
	);
}
