import React, { useState, useEffect } from "react";
import CreateAxiosInstance from "../../AxiosInstance/AxiosInstance";
import CreateS3AxiosInstance from "../../AxiosInstance/s3AxiosInstance";
import "./ProducePackerActivityCreator.css";

export default function ProducePackerActivityCreator() {
	const axios = CreateAxiosInstance();
	const s3Axios = CreateS3AxiosInstance();
	// Default JSON state
	const initialJson = {
		1: {
			difficultyScore: 900,
			passThreshold: 0.7,
			layout: {},
			orderData: [
				{
					equationId: 1,
					produceA: "oranges",
					produceB: "apples",
					producePriceA: 1,
					producePriceB: 2,
					budget: 8,
				},
				{
					equationId: 2,
					produceA: "oranges",
					produceB: "apples",
					producePriceA: 1,
					producePriceB: 1,
					budget: 5,
				},
			],
			studentData: [
				{
					equationId: 1,
					producePriceAType: "static",
					producePriceA: 1,
					producePriceBType: "static",
					producePriceB: 2,
					budgetType: "static",
					budget: 8,
				},
				{
					equationId: 2,
					producePriceAType: "static",
					producePriceA: 1,
					producePriceBType: "static",
					producePriceB: 1,
					budgetType: "static",
					budget: 5,
				},
			],
			characterRoster: ["Tae", "Kai"],
		},
	};

	const [json, setJson] = useState(initialJson);
	const [result, setResult] = useState(null);
	const [activityId, setActivityId] = useState("1");

	useEffect(() => {
		const fetchContentJson = async () => {
			const preSignedUrl = await axios.get("/activityContent?projectName=Math Arcade&gameName=Produce Packer");
			const response = await s3Axios.get(preSignedUrl.data);
			setJson(response.data);
			setActivityId(Object.keys(response.data)[0]);
		};

		addNewCharacterDropdown();
		fetchContentJson();
	}, []);

	const fruitList = [
		"apples",
		"avocados",
		"broccoli",
		"canteloupes",
		"carrots",
		"cucumbers",
		"eggs",
		"honey",
		"jam",
		"lemons",
		"limes",
		"oranges",
		"peaches",
		"peppers",
		"plums",
		"radishes",
		"strawberries",
		"tomatoes",
		"zucchinis",
	];

	const characterList = [
		"Anthony",
		"Bill",
		"Chris",
		"Dani",
		"Emily",
		"Francisco",
		"Gabi",
		"Harold",
		"Justin",
		"Kai",
		"Luis",
		"Madison",
		"Natalie",
		"Olivia",
		"Rebecca",
		"Sophia",
		"Tae",
		"Zach",
	];

	/**
	 * Solve 2 simultaneous equations in 2D
	 * @param {Object} orderData | contains data of 2 simultaneous equations to be solved
	 * @returns { x: x_variable, y: y_variable, equation1, equation2 } | returns the solution to the 2 equations
	 */
	const solveSimultaneous2D = orderData => {
		// Extract equation attributes from orderData
		const equations = {
			Eq1_x: orderData[0].producePriceA,
			Eq1_y: orderData[0].producePriceB,
			Eq1_const: orderData[0].budget,
			Eq2_x: orderData[1].producePriceA,
			Eq2_y: orderData[1].producePriceB,
			Eq2_const: orderData[1].budget,
		};

		// Form the equations in ax + by + c = 0 format
		const equation1 = `${equations.Eq1_x}x + ${equations.Eq1_y}y = ${equations.Eq1_const}`;
		const equation2 = `${equations.Eq2_x}x + ${equations.Eq2_y}y = ${equations.Eq2_const}`;

		// STEP 1:
		const x_coeff = [equations.Eq1_x, equations.Eq2_x];
		const y_coeff = [equations.Eq1_y, equations.Eq2_y];
		const const_coeff = [equations.Eq1_const, equations.Eq2_const];

		const eliminator = [];
		eliminator[0] = [];
		eliminator[1] = [];
		let x_variable;
		let y_variable;

		// STEP 2:
		eliminator[0][0] = y_coeff[1] * x_coeff[0];
		eliminator[0][1] = y_coeff[1] * const_coeff[0];

		// STEP 3:
		eliminator[1][0] = y_coeff[0] * x_coeff[1];
		eliminator[1][1] = y_coeff[0] * const_coeff[1];

		try {
			// STEPS 4, 5:
			x_variable = (eliminator[0][1] - eliminator[1][1]) / (eliminator[0][0] - eliminator[1][0]);

			// STEP 6:
			y_variable = (const_coeff[0] - x_coeff[0] * x_variable) / y_coeff[0];

			return { x: x_variable, y: y_variable, equation1, equation2 };
		} catch (ex) {
			console.error("Error: ", ex);
		}
	};

	// Handle change in JSON state
	const handleChange = (path, value) => {
		const keys = path.split(".");
		const newJson = { ...json };
		let current = newJson[activityId];
		for (let i = 0; i < keys.length - 1; i++) {
			current = current[keys[i]];
		}
		current[keys[keys.length - 1]] = value;
		setJson(newJson);
	};

	// Handle change in character dropdown
	const handleCharacterChange = (index, value) => {
		const newRoster = [...json[activityId].characterRoster];
		newRoster[index] = value;
		handleChange("characterRoster", newRoster);
	};

	// Add new character dropdown
	const addNewCharacterDropdown = () => {
		if (
			json[activityId].characterRoster.length === 0 ||
			json[activityId].characterRoster[json[activityId].characterRoster.length - 1] !== ""
		) {
			handleCharacterChange(json[activityId].characterRoster.length, "");
		} else {
			console.log("Character dropdown already exists");
		}
	};

	// Add new order to orderData and studentData
	const addNewOrder = () => {
		// generate new equationId
		let newEquationId = 1;
		for (let index = 0; index < json[activityId].orderData.length; index++) {
			const element = json[activityId].orderData[index];
			if (element.equationId >= newEquationId) {
				newEquationId = element.equationId + 1;
			}
		}

		const newOrder = {
			equationId: newEquationId,
			produceA: "oranges",
			produceB: "apples",
			producePriceA: 1,
			producePriceB: 1,
			budget: 5,
		};
		const newJson = { ...json };
		newJson[activityId].orderData.push(newOrder);

		// create new student data
		const newStudent = {
			equationId: newEquationId,
			producePriceAType: "static",
			producePriceA: 1,
			producePriceBType: "static",
			producePriceB: 1,
			budgetType: "static",
			budget: 5,
		};
		newJson[activityId].studentData.push(newStudent);

		setJson(newJson);
	};

	// Queries checkboxes and returns selected order ids, used for deleting orders or solving equations
	const handleOrderSelect = () => {
		const orderIds = [];
		// get list of all checkboxes
		const checkboxes = document.getElementsByName("OrderSelect");
		// loop through all checkboxes
		for (let i = 0; i < checkboxes.length; i++) {
			if (checkboxes[i].checked) {
				orderIds.push(checkboxes[i].id.split("orderCheckbox")[1]); // get the id of the checkbox
			}
		}
		return orderIds;
	};

	// Delete selected orders
	const deleteSelectedOrders = () => {
		//get ids of selected orders
		const orderIds = handleOrderSelect();
		const newJson = { ...json };
		// loop through all selected orders
		for (let i = 0; i < orderIds.length; i++) {
			// remove order from orderData
			newJson[activityId].orderData = newJson[activityId].orderData.filter(
				order => order.equationId !== Number.parseInt(orderIds[i]),
			);
			// remove order from studentData
			newJson[activityId].studentData = newJson[activityId].studentData.filter(
				student => student.equationId !== Number.parseInt(orderIds[i]),
			);
		}
		setJson(newJson);
	};

	return (
		<div className="produce-packer-activity-creator-container">
			<h1>Produce Packer Activity Creator</h1>

			<div>
				<label>Activity Select (Difficulty Score, ActivityID):</label>
				<select
					className="produce-packer-activity-creator-input-dropdown"
					value={activityId}
					onChange={e => setActivityId(e.target.value)}
				>
					{Object.keys(json).map(activity => (
						<option key={activity} value={activity}>
							{`${json[activity].difficultyScore} , ${activity}`}
						</option>
					))}
				</select>
				<button
					type="button"
					onClick={() => {
						// Get the last key in the json object
						const keys = Object.keys(json);
						const lastKey = keys[keys.length - 1];
						const newKey = Number.parseInt(lastKey) + 1;

						const newJson = { ...json };
						newJson[newKey] = {
							difficultyScore: 0,
							passThreshold: 0,
							layout: {},
							orderData: [
								{
									equationId: 1,
									produceA: "oranges",
									produceB: "apples",
									producePriceA: 1,
									producePriceB: 2,
									budget: 8,
								},
								{
									equationId: 2,
									produceA: "oranges",
									produceB: "apples",
									producePriceA: 1,
									producePriceB: 1,
									budget: 5,
								},
							],
							studentData: [
								{
									equationId: 1,
									producePriceAType: "static",
									producePriceA: 1,
									producePriceBType: "static",
									producePriceB: 2,
									budgetType: "static",
									budget: 8,
								},
								{
									equationId: 2,
									producePriceAType: "static",
									producePriceA: 1,
									producePriceBType: "static",
									producePriceB: 1,
									budgetType: "static",
									budget: 5,
								},
							],
							characterRoster: [],
						};
						setJson(newJson);
						setActivityId(newKey.toString());
					}}
					style={{ color: "green" }}
				>
					New Activity
				</button>
			</div>

			<br />

			<div>
				<label>Activity ID: {activityId}</label>
			</div>

			<div>
				<label>Difficulty Score (Proficiency Score):</label>
				<input
					className="produce-packer-activity-creator-input-number"
					type="number"
					value={json[activityId].difficultyScore}
					onChange={e => handleChange("difficultyScore", Number.parseInt(e.target.value))}
				/>
			</div>

			<div>
				<label>Pass Threshold: </label>
				<input
					className="produce-packer-activity-creator-input-number"
					type="number"
					min={0}
					step={0.01}
					max={1}
					value={json[activityId].passThreshold}
					onChange={e => handleChange("passThreshold", Number.parseFloat(e.target.value))}
				/>
			</div>

			<br />

			<div className="orderContainer">
				{json[activityId].orderData.map((order, index) => (
					<div key={order.equationId}>
						<div>
							<h3>Order Data - Equation {order.equationId}</h3>
							<label htmlFor={`checkbox${order.equationId}`}>Selected</label>
							<input
								type="checkbox"
								id={`orderCheckbox${order.equationId}`}
								name="OrderSelect"
								value="OrderSelect"
								style={{ width: "10%" }}
							/>
						</div>
						<div>
							<label>Produce A: </label>
							<select
								className="produce-packer-activity-creator-input-dropdown"
								value={order.produceA}
								onChange={e => handleChange(`orderData.${index}.produceA`, e.target.value)}
							>
								{fruitList.map((fruit, index) => (
									<option key={index} value={fruit}>
										{fruit}
									</option>
								))}
							</select>
						</div>
						<div>
							<label>Produce B: </label>
							<select
								className="produce-packer-activity-creator-input-dropdown"
								value={order.produceB}
								onChange={e => handleChange(`orderData.${index}.produceB`, e.target.value)}
							>
								{fruitList.map((fruit, index) => (
									<option key={index} value={fruit}>
										{fruit}
									</option>
								))}
							</select>
							{order.produceA === order.produceB && (
								<span style={{ color: "red" }}>Produce A and Produce B cannot be the same</span>
							)}
						</div>
						<div>
							<label>Produce Price A: </label>
							<input
								className="produce-packer-activity-creator-input-number"
								type="number"
								value={order.producePriceA}
								onChange={e => handleChange(`orderData.${index}.producePriceA`, e.target.value)}
							/>
						</div>
						<div>
							<label>Produce Price B: </label>
							<input
								className="produce-packer-activity-creator-input-number"
								type="number"
								value={order.producePriceB}
								onChange={e => handleChange(`orderData.${index}.producePriceB`, e.target.value)}
							/>
						</div>
						<div>
							<label>Budget: </label>
							<input
								className="produce-packer-activity-creator-input-number"
								type="number"
								value={order.budget}
								onChange={e => handleChange(`orderData.${index}.budget`, e.target.value)}
							/>
						</div>
					</div>
				))}

				<button
					type="button"
					onClick={() => {
						addNewOrder();
					}}
					style={{ color: "green" }}
				>
					Add Order
				</button>

				<button
					type="button"
					onClick={() => {
						deleteSelectedOrders();
					}}
					style={{ color: "red" }}
				>
					Delete Selected Orders
				</button>
			</div>

			<div className="studentContainer">
				{json[activityId].studentData.map((student, index) => (
					<div key={student.equationId}>
						<h3>Student Data - Equation {student.equationId}</h3>
						<div>
							<label>Produce Price A Type: </label>
							<select
								className="produce-packer-activity-creator-input-dropdown"
								value={student.producePriceAType}
								onChange={e => handleChange(`studentData.${index}.producePriceAType`, e.target.value)}
							>
								<option value="static">Static</option>
								<option value="dynamic">Dynamic</option>
							</select>
						</div>
						<div>
							<label>Produce Price A: </label>
							<input
								type="number"
								className="produce-packer-activity-creator-input-number"
								value={student.producePriceA}
								onChange={e => handleChange(`studentData.${index}.producePriceA`, e.target.value)}
							/>
						</div>
						<div>
							<label>Produce Price B Type: </label>
							<select
								className="produce-packer-activity-creator-input-dropdown"
								value={student.producePriceBType}
								onChange={e => handleChange(`studentData.${index}.producePriceBType`, e.target.value)}
							>
								<option value="static">Static</option>
								<option value="dynamic">Dynamic</option>
							</select>
						</div>
						<div>
							<label>Produce Price B: </label>
							<input
								type="number"
								className="produce-packer-activity-creator-input-number"
								value={student.producePriceB}
								onChange={e => handleChange(`studentData.${index}.producePriceB`, e.target.value)}
							/>
						</div>
						<div>
							<label>Budget Type: </label>
							<select
								className="produce-packer-activity-creator-input-dropdown"
								value={student.budgetType}
								onChange={e => handleChange(`studentData.${index}.budgetType`, e.target.value)}
							>
								<option value="static">Static</option>
								<option value="dynamic">Dynamic</option>
							</select>
						</div>
						<div>
							<label>Budget: </label>
							<input
								type="number"
								className="produce-packer-activity-creator-input-number"
								value={student.budget}
								onChange={e => handleChange(`studentData.${index}.budget`, e.target.value)}
							/>
						</div>
					</div>
				))}
			</div>

			<br />

			<div style={{ justifyContent: "center" }}>
				<label>Character Roster:</label>
				<button
					type="button"
					onClick={() => {
						addNewCharacterDropdown();
					}}
					style={{ color: "green" }}
				>
					Add Character
				</button>
			</div>
			{json[activityId].characterRoster.map((character, index) => (
				<div key={index}>
					<label>Character {index + 1}: </label>
					<select
						value={character}
						className="produce-packer-activity-creator-input-dropdown"
						onChange={e => handleCharacterChange(index, e.target.value)}
					>
						<option value="">Select a character</option>
						{characterList.map((char, charIndex) => (
							<option key={charIndex} value={char}>
								{char}
							</option>
						))}
					</select>
				</div>
			))}

			<br />

			<div className="produce-packer-activity-creator-footer">
				<button type="button" onClick={() => console.log(json)}>
					Print JSON to Console
				</button>
				<button type="button" onClick={() => navigator.clipboard.writeText(JSON.stringify(json, null, 4))}>
					Copy JSON to clipboard
				</button>
				<button
					type="button"
					onClick={() => {
						setActivityId("1");
						setJson({
							1: {
								difficultyScore: 0,
								passThreshold: 0,
								layout: {},
								orderData: [
									{
										equationId: 1,
										produceA: "oranges",
										produceB: "apples",
										producePriceA: 1,
										producePriceB: 2,
										budget: 8,
									},
									{
										equationId: 2,
										produceA: "oranges",
										produceB: "apples",
										producePriceA: 1,
										producePriceB: 1,
										budget: 5,
									},
								],
								studentData: [
									{
										equationId: 1,
										producePriceAType: "static",
										producePriceA: 1,
										producePriceBType: "static",
										producePriceB: 2,
										budgetType: "static",
										budget: 8,
									},
									{
										equationId: 2,
										producePriceAType: "static",
										producePriceA: 1,
										producePriceBType: "static",
										producePriceB: 1,
										budgetType: "static",
										budget: 5,
									},
								],
								characterRoster: [],
							},
						});
					}}
				>
					Clear JSON State
				</button>
				<button
					type="button"
					onClick={() => {
						// check if selected orders first
						const orderIds = handleOrderSelect();
						if (orderIds.length === 0) {
							alert("No orders selected");
							return;
						}
						if (orderIds.length > 2 || orderIds.length < 2) {
							alert("Only 2 orders can be selected");
							return;
						}

						const orderData = {};
						orderData.equation1 = json[activityId].orderData.find(order => order.equationId.toString() === orderIds[0]);
						orderData.equation2 = json[activityId].orderData.find(order => order.equationId.toString() === orderIds[1]);

						const result = solveSimultaneous2D(orderData);
						setResult(result);
					}}
				>
					Solve Equations
				</button>
				<button
					type="button"
					onClick={() => {
						if (window.confirm("Are you sure you want to save? This will overwrite the existing data.")) {
							axios.put("/activityContent?projectName=Math Arcade&gameName=Produce Packer", json);
						}
						// axios.put("http://localhost:8080/activityContent?projectName=chedzTest&gameName=anotherTest", json);
					}}
					style={{ backgroundColor: "cyan" }}
				>
					Save JSON to S3
				</button>

				{result && (
					<div>
						<h3>Results</h3>
						<p> {result.equation1}</p>
						<p> {result.equation2}</p>
						<p>x: {result.x}</p>
						<p>y: {result.y}</p>
					</div>
				)}
			</div>
		</div>
	);
}
