import React, { useState } from 'react';

export default function Element({
    name,
    color,
    isSelected,
    setSelected
}) {

    const colors = {
        red: 'bg-red-400',
        blue: 'bg-blue-400',
    }

    const handleClick = (e) => {
        console.log('Element clicked');
        setSelected(name);

        const handleOutsideClick = (event) => {
            if (event.target !== e.target) {
                setSelected(null);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }

    return (
        <button
            type="button"
            className={`
                absolute ${colors[color]}
                left-5 top-5
                w-20 h-20
                rounded-md
                focus:outline-none focus:ring-0 focus:ring-offset-2
                ${isSelected ? 'ring-offset-blue-500' : 'focus:ring-offset-gray-100'}
            `}
            onClick={(e) => handleClick(e)}
        >
            Element
        </button>
    )
}