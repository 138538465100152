import React from "react";
import CollapseDiv from '../../UIBuilder/CollapseDiv/CollapseDiv';
import MeshSetup from "../MeshSetup/MeshSetup";

function MeshSettings(props) {
	const meshes = props.meshes;

	const meshModelCallback = props.meshModelCallback;
	const meshScaleCallback = props.meshScaleCallback;
	const meshPositionOffsetCallback = props.meshPositionOffsetCallback;
	const meshRotationOffsetCallback = props.meshRotationOffsetCallback;
	const newMeshCallback = props.newMeshCallback;

	const meshesElements = meshes.map((mesh) => {
		return (
			<MeshSetup
				key={mesh.id}
				id={mesh.id}
				meshModel={mesh.meshModel}
				meshModelCallback={meshModelCallback}
				meshScale={mesh.meshScale}
				meshScaleCallback={meshScaleCallback}
				meshPositionOffset={mesh.meshPositionOffset}
				meshPositionOffsetCallback={meshPositionOffsetCallback}
				meshRotationOffset={mesh.meshRotationOffset}
				meshRotationOffsetCallback={meshRotationOffsetCallback}
			/>
		);
	});

	return (
		<div className="mesh-settings__container">
			<CollapseDiv
				fillComponents={
					<>
						{meshesElements}
						<button
							type="button"
							className="mesh-settings__button--add-mesh"
							onClick={newMeshCallback}
						>
							Add another mesh
						</button>
					</>
				}
				title={"Mesh Settings"}
			/>
		</div >
	);
}

export default MeshSettings;
