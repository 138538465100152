import { useOktaAuth } from "@okta/okta-react";
import React from "react";
import buaLogo from "../../assets/images/bua/bua-logo-multicolor.png";

const Login = () => {
	// Get the oktaAuth instance from the okta context
	const { oktaAuth } = useOktaAuth();
	/**
	 * This function calls the signInWithRedirect method from the oktaAuth instance, which redirect to Okta for login
	 */

	const handleLogin = async () => {
		try {
			await oktaAuth.signInWithRedirect();
		} catch (err) {
			console.error("Login error:", err);
		}
	};
	/**
	 * This function is currently Redundant since the logout is handled on the projects page under profile but will leave here in case we may need in future
	 */
	const handleLogout = async () => {
		await oktaAuth.signOut();
	};

	return (
		<div className="w-full h-full flex flex-col items-center justify-center">
			<div className="rounded-xl border-2 flex flex-col justify-center items-center w-10/12 h-1/2 sm:w-3/4 sm:h-3/4">

				<div className="flex flex-col items-center justify-center mt-10">
					{/* <h1
						className="text-color-bua-text-light font-bold text-4xl sm:text-6xl"
					>
						Login
					</h1> */}
					<img
						alt="bua logo"
						src={buaLogo}
						className="w-3/4 h-auto"
					/>
				</div>
				<div className="flex flex-col justify-center items-center w-3/4 h-3/4">
					<button
						type="button"
						className="bg-color-bua-button-primary hover:opacity-75 active:opacity-50 text-color-bua-text-light font-bold rounded-md text-lg sm:text-2xl px-4 py-2 w-10/12 h-16 sm:h-20 max-w-[400px] transform transition hover:-translate-y-1"
						onClick={handleLogin}
					>
						Login with Okta
					</button>
				</div>
			</div>
		</div>
	);
};

export default Login;
