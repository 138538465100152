import React from "react";
import CollapseDiv from '../../UIBuilder/CollapseDiv/CollapseDiv';
import CheckboxList from "../../UIBuilder/CustomDrawer/components/CheckboxList";

import LabelInput from "../../UIBuilder/CustomDrawer/components/LabelInput/LabelInput";

function HitboxSettings(props) {
	const shape = props.hitboxShape;
	const hitboxShapeCallback = props.hitboxShapeCallback;

	const sizeX = props.hitboxSizeX;
	const sizeY = props.hitboxSizeY;
	const sizeZ = props.hitboxSizeZ;
	const hitboxSizeCallback = props.hitboxSizeCallback;

	const mass = props.hitboxMass;
	const hitboxMassCallback = props.hitboxMassCallback;

	const handleHitboxSizeChange = (index, value) => {
		const size = [sizeX, sizeY, sizeZ];

		size[index] = value;

		hitboxSizeCallback(size);
	};

	const hitboxTypes = ["cube", "sphere", "plane"];
	const hitboxCheckboxElements =
		<CheckboxList
			items={hitboxTypes}
			selectedItems={[shape]}
			handleCheckboxChange={(event, item) => {
				if (event.target.checked) {
					hitboxShapeCallback(item);
				}
			}}
		/>

	const sizeInputInfo = [
		{
			inputDefault: "1",
			inputValue: sizeX.toString(),
			inputType: "number",
			inputStep: 0.1,
			inputMinimum: 0.1,
			inputMaximum: 100,
			inputCallback: (newValue) => handleHitboxSizeChange(0, Number(newValue)),
		},
		{
			inputDefault: "1",
			inputValue: sizeY.toString(),
			inputType: "number",
			inputStep: 0.1,
			inputMinimum: 0.1,
			inputMaximum: 100,
			inputCallback: (newValue) => handleHitboxSizeChange(1, Number(newValue)),
		},
		{
			inputDefault: "1",
			inputValue: sizeZ.toString(),
			inputType: "number",
			inputStep: 0.1,
			inputMinimum: 0.1,
			inputMaximum: 100,
			inputCallback: (newValue) => handleHitboxSizeChange(2, Number(newValue)),
		}
	];
	const sizeInput = <LabelInput labelName={"Size"} inputInfo={sizeInputInfo} />

	const massInputInfo = [
		{
			inputDefault: "1",
			inputValue: mass.toString(),
			inputType: "number",
			inputStep: 0.1,
			inputMinimum: 0,
			inputMaximum: 100,
			inputCallback: (newValue) => hitboxMassCallback(Number(newValue)),
		}
	];
	const massInput = <LabelInput labelName={"Mass"} inputInfo={massInputInfo} />


	return (
		<div className="hitbox-settings__container">
			<CollapseDiv
				fillComponents={
					<>
						<div className="hitbox__container--checkbox">
							{hitboxCheckboxElements}
						</div>
						<div className="hitbox__container--size">
							{sizeInput}
						</div>
						<div className="hitbox__container--mass">
							{massInput}
						</div>
					</>
				}
				title={"Hitbox Settings"}
			/>
		</div>
	);
}

export default HitboxSettings;
