import CollapsibleTextBox from "../CollapsibleTextBox/CollapsibleTextBox";

/* Need to handke API request to S3 to get the Relevant score*/

const BlocklyGeneratorSettings = () => {
	return (
		<>
			<div className="blockly-generator-settings__container">
				<h1 className="blockly-generator-settings__title">Blockly Generator Settings</h1>
				<div className="test">
					<CollapsibleTextBox title="Description" body="test text body" />
					<CollapsibleTextBox title="JSON" body="test text body" />
					<CollapsibleTextBox title="JavaScript" body="test text body" />
					<CollapsibleTextBox title="Interpreter" body="test text body" />
					<CollapsibleTextBox title="JS Object" body="test text body" />
					<CollapsibleTextBox title="Description" body="test text body" />
					<CollapsibleTextBox title="JSON" body="test text body" />
					<CollapsibleTextBox title="JavaScript" body="test text body" />
					<CollapsibleTextBox title="Interpreter" body="test text body" />
					<CollapsibleTextBox title="JS Object" body="test text body" />
					<CollapsibleTextBox title="Description" body="test text body" />
					<CollapsibleTextBox title="JSON" body="test text body" />
					<CollapsibleTextBox title="JavaScript" body="test text body" />
					<CollapsibleTextBox title="Interpreter" body="test text body" />
					<CollapsibleTextBox title="JS Object" body="test text body" />
				</div>
			</div>
		</>
	);
};
export default BlocklyGeneratorSettings;
