import React from "react";
import CollapseDiv from '../../UIBuilder/CollapseDiv/CollapseDiv';
import SystemsCheckbox from "../SystemsCheckbox/SystemsCheckbox";

function SystemsSettings(props) {
	const systems = props.systems;
	const systemsSettingsCallback = props.systemsSettingsCallback;

	/* 
		the list of all systems available for selection. 
		to add another system to the list just add it at the bottom as a string
	*/
	const systemsList = [
		"forwardBackwardMovement",
		"leftRightMovement",
		"upDownMovement",
		"pickUp",
		"dropOff",
		"positionReached",
		"pitchXTurn",
		"yawYTurn",
		"rollZTurn",
	];

	/* 
		use this list to block out systems that cannot be selected while another system is already selected.
		e.g. pickUp cannot be selected if dropOff or positionReached has already been selected
	*/
	const mutuallyExclusiveSystemsBlockedList = {
		pickUp: ["dropOff", "positionReached"],
		dropOff: ["pickUp", "positionReached"],
		positionReached: ["pickUp", "dropOff"],
	};

	// for each element in the systemsList, create a SystemsCheckbox component with the id set to the system name
	const systemCheckboxElements = systemsList.map((system) => {
		let blocked = false;

		if (mutuallyExclusiveSystemsBlockedList[system]) {
			blocked = mutuallyExclusiveSystemsBlockedList[system].some((i) =>
				systems.includes(i),
			);
		}

		return (
			<SystemsCheckbox
				key={system}
				id={system}
				systemsSettingsCallback={systemsSettingsCallback}
				blocked={blocked}
				alreadyChecked={systems.includes(system)}
			/>
		);
	});

	// return the title text along with all of the SystemCheckbox components
	return (
		<div className="systems-settings">
			<CollapseDiv
				fillComponents={
					<>
						{systemCheckboxElements}

					</>
				}
				title={"Systems Settings"}
			/>
		</div>
	);
}

export default SystemsSettings;
