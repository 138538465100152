import { useEffect, useState } from "react";
import { GraphExpand, GraphExpandSelect } from "./SVGIcons";
import { blackAndWhite } from "./colors";
import { DesmosGraph, DesmosModal } from "./DesmosGraph";

/**
 * Component for the Graph Tab
 * @param {Object} props - the props object
 * @param {Function} props.setStudentActivityAttribute - the function to set the student activity attribute
 * @param {Object} props.studentActivityData - the student activity data
 * @param {String} props.colourPallete - the colour pallete
 * @returns
 */
function GraphTab({ setStudentActivityAttribute, studentActivityData, colourPallete }) {
	// State Variables
	const [modalIsOpen, setModalIsOpen] = useState(false); // Used for expanding the graph
	const [expandGraphSelected, setExpandGraphSelected] = useState(false); // If the expand graph button is selected
	const phoneContainer = document.getElementById("phone--body"); // Get the phone container
	const [aspectRatio, setAspectRatio] = useState(phoneContainer.offsetWidth / phoneContainer.offsetHeight); // Get the aspect ratio of the phone, for the graph aspect ratio

	// Callback for expanding the graph
	const expandGraphCallback = () => {
		setModalIsOpen(!modalIsOpen);
		setExpandGraphSelected(true);
	};

	// Adding Event listener to the update the aspect ratio of the phone, so the graph aspect ratio will remain consistent
	useEffect(() => {
		const resizeListener = () => {
			// Get the phone container
			const phoneContainer = document.getElementById("phone--body");
			// Get aspect ratio
			setAspectRatio(phoneContainer.offsetWidth / phoneContainer.offsetHeight);
		};
		window.addEventListener("resize", resizeListener);
	}, []);

	return (
		<div className="h-[81%] w-[100%]">
			{/* Modal - for expanding the graph*/}
			{modalIsOpen && (
				<DesmosModal
					modalOpen={modalIsOpen}
					setModalIsOpen={setModalIsOpen}
					colourPallete={colourPallete}
					studentActivityData={studentActivityData}
				/>
			)}
			{/* Button Container - for when modal is not shown */}
			{!modalIsOpen && (
				<button
					type="button"
					onClick={expandGraphCallback}
					id="graphing-container__button-container__button"
					className="relative w-[100%] border-none bg-none z-100"
				>
					<GraphExpand
						color={colourPallete === "blackAndWhite" ? blackAndWhite.graphExpandBackgroundSelected : undefined}
						style={{
							width: "3.375rem",
							height: "3.375rem",
							position: "absolute",
							zIndex: "100",
							top: "5",
							right: "35",
						}}
					/>
				</button>
			)}

			{/* Graph - within the phone - NOT MODAL */}
			<div
				id="graphing-container__graph-container"
				className="w-full h-[70%] lg:h-[81%] box-border overflow-y-auto relative"
			>
				<div
					id="graphing-container__graph-container__div"
					style={{ aspectRatio: `${aspectRatio}` }}
					className="absolute w-[95%] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 border-2 border-black"
				>
					{!modalIsOpen && <DesmosGraph studentActivityData={studentActivityData} colourPallete={colourPallete} />}
				</div>
			</div>
			{/* Bottom Container */}
			<div
				id="graphing-container__bottom-container"
				className=" h-[20%] lg:h-[15%] flex items-center width-[100%] gap-[2.5rem] p-[.625rem_1.5625rem] min-w-[30rem] lg:min-w-[20rem] border-t-2 border-black"
			>
				{/* X input */}
				<div id="graphing-container__bottom-container__div__left" className="flex items-center gap-[.625rem]">
					<div className="flex flex-col justify-center items-start gap-[0.625rem]">
						<p
							id="graphing-container__bottom-container__div__left__label"
							className="text-black text-[1.5rem] italic font-bold leading-normal"
						>
							x <span className="not-italic">=</span>
						</p>
					</div>
					<div className="w-[2.5rem]">
						<input
							id="graphing-container__bottom-container__div__left__input"
							className="flex w-[3.5rem] h-[2.5rem] p-[0.75rem] px-[0.625rem] lg:py-[0.75rem] flex-col justify-center items-center gap-[0.625rem] lg:gap-[2.5rem] flex-shrink-0 rounded-[0.4375rem] border-[3px] border-[#797979] bg-[#D9D9D9] text-black text-center text-[1.5rem] font-bold leading-normal"
							value={studentActivityData.graphCoordinateX}
							onChange={e => {
								setStudentActivityAttribute("graphCoordinateX", e.target.value);
							}}
						/>
					</div>
				</div>
				{/* Y input */}
				<div id="graphing-container__bottom-container__div__right" className="flex items-center gap-[.625rem]">
					<div className="flex flex-col justify-center items-start gap-[0.625rem]">
						<p
							id="graphing-container__bottom-container__div__left__label"
							className="text-black text-[1.5rem] italic font-bold leading-normal"
						>
							y <span className="not-italic">=</span>
						</p>
					</div>
					<div className="w-[2.5rem]">
						<input
							id="graphing-container__bottom-container__div__left__input"
							className="flex w-[3.5rem] h-[2.5rem] p-[0.75rem] px-[0.625rem] flex-col justify-center items-center gap-[0.625rem] flex-shrink-0 rounded-[0.4375rem] border-[3px] border-[#797979] bg-[#D9D9D9] text-black text-center text-[1.5rem] font-bold leading-normal"
							value={studentActivityData.graphCoordinateY}
							onChange={e => {
								setStudentActivityAttribute("graphCoordinateY", e.target.value);
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default GraphTab;
