import { useEffect } from "react";
import MenuIcon from "../../assets/images/bua/MenuIcon";
import QuestionMarkIcon from "../../assets/images/bua/QuestionMarkIcon";
import buaHomeButton from "../../assets/images/bua/bua-home-button.svg";
import CreateAxiosInstance from "../../AxiosInstance/AxiosInstance";

const StudentViewHeader = ({
	displayAccessibilityMenu,
	toggleDisplayAccessibilityMenu,
	toggleDisplayTutorialsMenu,
	kValue,
	setKValue,
}) => {
	const axiosInstance = CreateAxiosInstance();
	// Use setTimeout to ensure the rendering has occurred and the html elements exist
	useEffect(() => {
		let focusableElements = [];

		/**
		 * Function to trap focus within the settings menu
		 * @param {Object} e - event object
		 */
		function trapFocus(e) {
			console.log("Trapping focus");

			const firstFocusableElement = focusableElements[0];
			const lastFocusableElement = focusableElements[focusableElements.length - 1];

			if (e.key === "Tab") {
				// If the user is pressing shift + tab (backward)
				if (e.shiftKey) {
					if (document.activeElement === firstFocusableElement) {
						e.preventDefault(); // Prevent default tab behavior
						lastFocusableElement.focus(); // Focus the last element
					}
				}
				// If the user is tabbing forward
				else {
					if (document.activeElement === lastFocusableElement) {
						e.preventDefault(); // Prevent default tab behavior
						firstFocusableElement.focus(); // Focus the first element
					}
				}
			}
		}

		if (displayAccessibilityMenu) {
			// Get all the focusable elements in the settings menu
			focusableElements = document
				.getElementById("settings-pop-up__container")
				.querySelectorAll("button, input, a, canvas");

			// Add the event listener to trap focus
			document.addEventListener("keydown", trapFocus);

			// Focus on the first element in the settings menu
			if (focusableElements.length > 0) {
				focusableElements[0].focus();
			} else {
				console.warn("No focusable elements found in the settings menu.");
			}
		}

		return () => {
			// Cleanup the event listener
			document.removeEventListener("keydown", trapFocus);
		};
	}, [displayAccessibilityMenu]);

	/**
	 * Function to handle the deletion of the game save state
	 */
	async function handleDelete() {
		let user = "";
		// Get the user from the okta token storage
		const userString = window.localStorage.getItem("okta-token-storage");
		const userObject = JSON.parse(userString);
		user = userObject.accessToken?.claims?.sub.toString() || user;

		// Send request to S3 to delete the currentGameSaveState.json file
		try {
			await axiosInstance
				.delete(`gameSaveState?projectName=Math Arcade&gameName=Produce Packer&user=${user}`)
				.then(response => {
					console.log("Delete response:", response);
				});
		} catch (error) {
			console.error("Failed to delete game save state:", error);
		}
	}

	return (
		<div className="sticky top-0 flex justify-between w-full h-[0px] z-20">
			<div className="flex w-16 h-16 flex-col items-start justify-start bg-black">
				<button
					className="w-full h-full hover:bg-gray-500 hover:bg-opacity-25 rounded-lg"
					type="button"
					onClick={() => {
						window.location.href = "/";
					}}
				>
					<img src={buaHomeButton} alt="Home button" />
				</button>
			</div>
			<div className="flex w-20 h-64 flex-col pr-[1%] pt-[1%]">
				<div className="flex w-16 h-16 flex-col items-center justify-center">
					<button
						id="accessibility-settings-button"
						className="flex items-center justify-center w-full h-full hover:bg-gray-500 hover:bg-opacity-25 rounded-lg"
						type="button"
						onClick={toggleDisplayAccessibilityMenu}
					>
						<MenuIcon />
					</button>
				</div>
				<div className="flex w-16 h-16 flex-col items-center justify-center">
					<button
						id="tutorials-button"
						className="flex items-center justify-center w-full h-full hover:bg-gray-500 hover:bg-opacity-25 rounded-lg"
						type="button"
						onClick={() => {
							toggleDisplayTutorialsMenu();
						}}
					>
						<QuestionMarkIcon />
					</button>
				</div>
				{/* Dont display these toggle in production - K value toggle and button for delte the game save state */}
				{window.APP_ENV?.REACT_APP_ENV !== "" && (
					<>
						{/* Input for toggling the K value */}
						<div className="flex flex-col items-center justify-center pb-3">
							<label
								htmlFor="k-value-input"
								className="text-white text-center text-base font-bold leading-normal not-italic"
							>
								K Value
							</label>
							<input
								id="k-value-input"
								className="w-14 h-14 text-white text-center text-base font-bold leading-normal not-italic bg-[#00a7e4] border rounded-xl"
								type="number"
								value={kValue}
								onChange={e => {
									// Ensure the value is a number
									if (isNaN(e.target.value)) {
										console.warn("K value must be a number");
										return;
									}
									setKValue(e.target.value);
								}}
							/>
						</div>
						{/* Button for deleting a users gamesavestate */}
						<div className="flex flex-col items-center justify-center">
							<button
								className="w-14 h-14 hover:bg-white hover:text-[#00a7e4] text-white text-center text-base font-bold leading-normal not-italic bg-[#00a7e4] border rounded-xl"
								type="button"
								onClick={() => {
									if (
										window.confirm(
											"Are you sure you want to delete the Game Save State, all activity progress will be lost?",
										)
									) {
										handleDelete();
									}
								}}
							>
								Delete
							</button>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default StudentViewHeader;
