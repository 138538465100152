import axios from "axios";

// This creates an axios instamce with the base URL and the token from the oktaAuth instance
const CreateS3AxiosInstance = () => {
	// Create an axios instance
	const axiosInstance = axios.create({
		baseURL: process.env?.REACT_APP_API_URL || window.APP_ENV?.REACT_APP_API_URL, // "https://bua-api-dev.supplemental-nonprod.imaginelearning.com", // Corrected environment variable
	});

	return axiosInstance;
};

export default CreateS3AxiosInstance;
