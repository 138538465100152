
import React, { useState, useEffect } from "react";
import { Tabs } from '@mantine/core';

import burger from '../../../assets/images/bua/burger.png';

import './CustomDrawer.css';

/*
    Drawer component

    Drawer that has an aboslute position on the page, and overlays the rest of the elements and can be dragged to the left or right.
    
    - drawerTabInfo: Array of objects containing the tab information, see uiBuilder for example usage
    - workspaceDivRef: React reference to the workspace div, used to resize the workspace when the drawer is dragged, see uiBuilder for example usage
    - resizeableSim: True if you want to have a resize of sim. Engine undefined is throwing errors. If you want resizeable sim resizeableSim = true, 
        and set up drawer locally as a condition as so {isSimulationLoaded && <CustomDrawer drawerTabInfo={drawerTabInfo} workspaceDivRef={"simulationWindow"} resizeableSim={true}/>}
 */
const CustomDrawer = ({drawerTabInfo, workspaceDivRef = undefined, resizeableSim = false}) => {

    // If workspaceDivRef is not an object, it will be a string id, find the div with the id
    let idDiv = undefined;
    if (typeof workspaceDivRef === 'string') {
        idDiv = document.getElementById(workspaceDivRef);
        if (workspaceDivRef === "simulationWindow"){
            // idDiv = document.querySelector('canvas[data-engine="three.js r156"]');
            idDiv.style.position = "absolute";
        }
    }

    // Drawer states
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedTab, setSelectedTab] = useState(drawerTabInfo[0].key);

    /*
        Drawer drag

        Drawer drag functionality for the drawer in the UI builder
     */

    const [mouseDown, setMouseDown] = useState(false);

    useEffect(() => {
        const button = document.getElementById(drawerOpen ? "ui-builder__drawer-button--open" : "ui-builder__drawer-button--closed");
    
        const startDrag = (e) => {
            setMouseDown(true);
        };
    
        const doDrag = (e) => {
            if (!mouseDown) return;
    
            // New drawer width
            const newWidth = e.clientX;
    
            const drawer = document.getElementById("ui-builder__drawer");

            // If the new width is less than 10% of the window's width, set it to 10% of the window's width and stop the drag
            if (newWidth < window.innerWidth * 0.1) {
                
                drawer.style.width = `${window.innerWidth * 0.1}px`;
    
                const button = document.getElementById(drawerOpen ? "ui-builder__drawer-button--open" : "ui-builder__drawer-button--closed");
                button.style.left = `${window.innerWidth * 0.1}px`;

                if (workspaceDivRef !== undefined) {
                    if (idDiv !== undefined) {
                        idDiv.style.width = `${window.innerWidth - (window.innerWidth * 0.1)}px`;
                        idDiv.style.left = `${window.innerWidth * 0.1}px`;
                    } else {
                        workspaceDivRef.current.style.width = `${window.innerWidth - (window.innerWidth * 0.1)}px`;
                        workspaceDivRef.current.style.left = `${window.innerWidth * 0.1}px`;
                    }
                }

            // Can't resize bigger than the screen
            } else if (newWidth > window.innerWidth) {
                drawer.style.width = `${window.innerWidth}px`;
            
                const button = document.getElementById(drawerOpen ? "ui-builder__drawer-button--open" : "ui-builder__drawer-button--closed");
                button.style.left = `${window.innerWidth}px`;

                if (workspaceDivRef !== undefined) {
                    if (idDiv !== undefined) {
                        idDiv.style.width = `${window.innerWidth}px`;
                        idDiv.style.left = `${window.innerWidth}px`;
                    } else {
                        workspaceDivRef.current.style.width = `${window.innerWidth}px`;
                        workspaceDivRef.current.style.left = `${window.innerWidth}px`;
                    }
                }
                   
            } else {
                // Change the drawer width
                drawer.style.width = `${newWidth}px`;
    
                // Change the button left position
                const button = document.getElementById(drawerOpen ? "ui-builder__drawer-button--open" : "ui-builder__drawer-button--closed");
                button.style.left = `${newWidth-15}px`;

                // Resizing the worspace area if its defined
                if (workspaceDivRef !== undefined) {
                    if (idDiv !== undefined) {
                        idDiv.style.width = `${window.innerWidth - newWidth}px`;
                        idDiv.style.left = `${newWidth}px`;
                    } else {
                        workspaceDivRef.current.style.width = `${window.innerWidth - newWidth}px`;
                        workspaceDivRef.current.style.left = `${newWidth}px`;
                    }
                }
            }
        };
    
        const stopDrag = () => {
            setMouseDown(false);
        };

        if (!button) return;
    
        button.addEventListener('mousedown', startDrag);
        window.addEventListener('mousemove', doDrag);
        window.addEventListener('mouseup', stopDrag);
    
        return () => {
            button.removeEventListener('mousedown', startDrag);
            window.removeEventListener('mousemove', doDrag);
            window.removeEventListener('mouseup', stopDrag);
        };
    }, [mouseDown]); 

    useEffect(() => {
        if (workspaceDivRef?.current) {
            if (drawerOpen) {
                workspaceDivRef.current.style.width = `85vw`;
                workspaceDivRef.current.style.left = `15vw`;
            } else {
                workspaceDivRef.current.style.width = `100vw`;
                workspaceDivRef.current.style.left = `0vw`;
            }
        } else if (idDiv) {
            if (drawerOpen) {
                idDiv.style.width = `85vw`;
                idDiv.style.left = `15vw`;
            } else {
                idDiv.style.width = `100vw`;
                idDiv.style.left = `0vw`;
            }
        }

    }, [drawerOpen]);

    useEffect(() => {
        if (resizeableSim) {
            const intervalId = setInterval(() => {
                // eslint-disable-next-line no-undef
                engine.onWindowResize();
            }, 10);
    
            return () => clearInterval(intervalId);
        }
    }, [resizeableSim, mouseDown]);

    return(
        <>
            {!drawerOpen && (
                <button id="ui-builder__burger-button_image--closed" className="absolute top-4 left-4 w-auto h-[5vh] border-none z-50" onClick={() => setDrawerOpen(true)}>
                    <img className="h-full w-auto" id="ui-builder__burger-button_image--open" src={burger} alt="Close drawer burger button" /> 
                </button>
            )}

            {drawerOpen && (
                <div className="absolute h-lvh bg-color-bua-background z-50 w-2/12 overflow-scroll" id="ui-builder__drawer"> 
                    <Tabs value={selectedTab} onChange={setSelectedTab} style={{ width: '100%', height: '75px'}} >
                        <Tabs.List style={{ display: 'flex', justifyContent: 'space-between', width: '100%', height: '100%', padding: '19px 25px 19px 25px', boxSizing: 'border-box'}}>

                            <button 
                                className="border-none font-bold text-2xl text-color-bua-text-dark hover:bg-color-bua-background-light" 
                                onClick={() => setDrawerOpen(false)}
                            >
                                <img className="h-full w-auto" id="ui-builder__burger-button_image--open" src={burger} alt="Close drawer burger button" /> 
                            </button>

                            {Object.keys(drawerTabInfo).map((key) => {
                                const tabInfo = drawerTabInfo[key];
                                return (
                                    <Tabs.Tab 
                                        className="border-none font-bold text-2xl text-color-bua-text-dark hover:bg-color-bua-background-light"
                                        value={tabInfo.key} 
                                        style={{ 
                                            width: tabInfo.tabWidth,
                                            color: selectedTab === tabInfo.key ? '#FFFFFF' : '#000000', 
                                            backgroundColor: selectedTab === tabInfo.key ? '#3291ff' : '#FFFFFF',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap'
                                        }}
                                    > 
                                        {tabInfo.name}
                                    </Tabs.Tab>
                                );
                            })}
                        </Tabs.List>

                        {Object.keys(drawerTabInfo).map((key) => {
                                const tabInfo = drawerTabInfo[key];
                                return (
                                    <Tabs.Panel
                                        value={tabInfo.key} 
                                        style={{width: '100%', boxSizing: 'border-box', padding: "8px 0px"}}
                                    >
                                        {tabInfo.content}
                                    </Tabs.Panel>
                                );
                            })}
                    </Tabs>
                    <button 
                        id={drawerOpen ? "ui-builder__drawer-button--open" : "ui-builder__drawer-button--closed"} 
                        className={`absolute bg-color-bua-text-dark border-none h-full w-2 top-16 z-10 cursor-ew-resize ${drawerOpen ? "left-full" : "left-2"} -translate-x-full`}
                        onMouseDown={() => setMouseDown(true)}
                        onMouseUp={() => setMouseDown(false)}
                    ></button>
                </div>
            )}
        </>
    );
};

export default CustomDrawer;
