export default function FullVolumeIcon({ color = "black" }) {
    return (
        <svg width="100%" height="100%" viewBox="0 0 37 22" version="1.1" style={{ fillRule: "evenodd", clipRule: "evenodd" }}>
            <title>Full Volume Icon</title>
            <g>
                <g transform="matrix(1,0,0,1,7.25001,-0.218509)">
                    <path d="M8.012,7.786L8.012,14.429C8.012,14.705 7.788,14.929 7.512,14.929L1,14.929C0.724,14.929 0.5,14.705 0.5,14.429L0.5,7.786C0.5,7.51 0.724,7.286 1,7.286L7.512,7.286C7.788,7.286 8.012,7.51 8.012,7.786Z" style={{ stroke: color, fill: color, strokeWidth: "1px" }} />
                </g>
                <g transform="matrix(1,0,0,1,7.25001,-0.218509)">
                    <path d="M1.883,11.882C1.647,11.682 1.647,11.318 1.883,11.118L10.627,3.743C10.952,3.469 11.449,3.7 11.449,4.125L11.449,18.875C11.449,19.3 10.952,19.531 10.627,19.257L1.883,11.882Z" style={{ fillRule: "nonzero", stroke: color, fill: color, strokeWidth: "1px" }} />
                </g>
                <g transform="matrix(1,0,0,1,7.25001,-0.218509)">
                    <path d="M16.133,5.34C18.7,9.3 18.7,12.82 16.133,17.22" style={{ fill: "none", fillRule: "nonzero", stroke: color, strokeWidth: "2px", strokeLinecap: "round" }} />
                </g>
                <g transform="matrix(1,0,0,1,7.25001,-0.218509)">
                    <path d="M19.8,3.58C22.733,8.567 22.733,13.439 19.8,18.98" style={{ fill: "none", fillRule: "nonzero", stroke: color, strokeWidth: "2px", strokeLinecap: "round" }} />
                </g>
            </g>
        </svg>
    )
}