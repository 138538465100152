import React from "react";

import ButtonList from "../../UIBuilder/CustomDrawer/components/ButtonList";

import cog from "../../../assets/images/bua/cog.png";

function EntityLibrary({ entityList, selectedEntity, setSelectedEntity, setModalState, setSelectedEntityCog }) {
    const handleEntityCogClick = (entity) => {
        setModalState(true);
        setSelectedEntityCog(entity);
    }

    return (
        <div className="entity-library-container">
            <ButtonList
                items={(entityList ?? []).sort()}
                selectedButton={selectedEntity}
                setSelectedButton={setSelectedEntity}
                additionalFillComponent={item => (
                    <img
                        id="ui-buider__template-library__template__cog"
                        src={cog}
                        alt="Cog icon for template library rename"
                        onClick={() => handleEntityCogClick(item)}
                    />
                )}
            />
        </div>
    );
}

export default EntityLibrary;