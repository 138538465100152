import React from "react";

function SystemsCheckbox(props) {
	const systemName = props.id;
	const systemSettingsCallback = props.systemsSettingsCallback;
	const alreadyChecked = props.alreadyChecked;

	const [checked, setChecked] = React.useState(alreadyChecked);

	const handleSystemsCheckboxChange = () => {
		setChecked(!checked);

		systemSettingsCallback(systemName, !checked);
	};

	return (
		<div className="systems-checkbox__container">
			<input
				type="checkbox"
				id={`${systemName}SystemCheckbox`}
				checked={alreadyChecked}
				onChange={handleSystemsCheckboxChange}
				disabled={props.blocked}
			/>
			<label htmlFor={`${systemName}SystemCheckbox`}>{systemName}</label>
		</div>
	);
}

export default SystemsCheckbox;
