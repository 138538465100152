import React from "react";

function FileTree(props) {
	return (
		<>
			<SubTree allFiles={props.files} {...props} />
		</>
	);
}

function SubTree({ files, allFiles, selectedFile, onSelect, ...props }) {
	return (
		<div {...props}>
			{files.sort(sortingFunction).map(child => (
				<React.Fragment key={child.id}>
					<File selectedFile={selectedFile} allFiles={allFiles} onClick={() => onSelect(child)} {...child} />
				</React.Fragment>
			))}
		</div>
	);
}

function File({ selectedFile, allFiles, ...props }) {
	const isSelected = selectedFile && selectedFile.id === props.id;

	return (
		<div
			{...props}
			className={`${isSelected
				? "bg-color-bua-background border-b border-color-bua-background-light"
				: "bg-color-bua-background-light border-b border-color-bua-background"
				} p-2 text-color-bua-text-light  `}
		>
			<span className="flex">
				{props.name}
				{props.modified.includes("true") && <div className="w-3 h-3 rounded-lg bg-white ml-2 my-auto" />}
			</span>
		</div>
	);
}

function sortingFunction(a, b) {
	// directories come first, sorted alphabetically
	// then files, also sorted alphabetically
	let first;

	if (a.type === b.type) {
		if (a.name < b.name) first = a;
		else first = b;
	}

	// js be weird
	if (first === a) return -1;
	return 1;
}

export default FileTree;
