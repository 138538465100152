import React, { useState, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import xIcon from '../../assets/images/bua/close-x.png'

interface PostModalProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: (content: string) => void;
    title: string;
}

export default function PostModal({
    isOpen,
    onClose,
    onConfirm,
    title
}: PostModalProps) {

    const [isBrowser, setIsBrowser] = useState<boolean>(false);
    const [editContent, setEditContent] = useState<string>("");

    const modalRef = useRef<HTMLDivElement>(null);

    const handleClose = () => {
        onClose();

        setEditContent("");
    }

    useEffect(() => {
        setIsBrowser(true)
    }, [])

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                handleClose();
            }
        }

        const handleEscapeKey = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                handleClose();
            }
        }

        if (isOpen) {
            if (modalRef.current) {
                modalRef.current.focus();
            }

            setEditContent(editContent);

            document.addEventListener('mousedown', handleOutsideClick)
            document.addEventListener('keydown', handleEscapeKey)
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick)
            document.removeEventListener('keydown', handleEscapeKey)
        }
    }, [isOpen, onClose])

    useEffect(() => {
        if (isOpen && modalRef.current) {
            modalRef.current.focus();
        }
    }, [isOpen]);

    const modalContent = (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75 px-2">
            <div ref={modalRef} tabIndex={-1} className="w-full max-w-md rounded-lg bg-[#3A3A3A] shadow-lg border-2">
                <div className="flex items-center justify-between border-b p-4">
                    <h2 className="text-2xl font-bold text-white">{title}</h2>
                    <button
                        type="button"
                        onClick={handleClose} className="text-color-bua-text-light">
                        <img
                            className="w-4 h-4"
                            src={xIcon}
                            alt="close"
                        />
                    </button>
                </div>
                <div className="p-4">
                    <input
                        onChange={(e) => { setEditContent(e.target.value) }}
                        value={editContent}
                        className="text-color-bua-background w-full pl-2"
                    />
                </div>
                <div className="flex justify-between space-x-2 border-t p-4">
                    <button
                        type="button"
                        onClick={() => {
                            handleClose();
                        }}
                        className="rounded-md disabled:opacity-50 disabled:cursor-not-allowed bg-gray-300 px-4 py-2 text-sm font-md text-color-bua-text-dark text-bold hover:opacity-75"
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        onClick={() => { onConfirm(editContent || "") }}
                        className="rounded-md bg-color-bua-button-primary px-4 py-2 text-sm font-lg font-bold text-white text-bold hover:opacity-75"
                    >
                        Create
                    </button>
                </div>
            </div>
        </div>
    )

    if (isBrowser && isOpen) {
        return createPortal(modalContent, document.body)
    }

    return null
}