import { action, makeAutoObservable, observable } from "mobx";
import { toJS } from "mobx";
import deepClone from "../../utils/deepClone";
import updateProficiency from "../Scoring/updateProficiency";

class SVS {
	// default accessibility settings
	defaultAccessibilitySettings = {
		general: {
			language: "english",
			tutorials: true,
		},
		audio: {
			musicVolume: 50,
			masterVolume: 50,
			sfxVolume: 50,
			uiVolume: 50,
			stereo: true,
			mute: false,
		},
		display: {
			font: "Arial",
			fontSize: 100,
			displayTheme: "light",
			highlightColor: "#FF0000",
			tooltipHighlightColor: "#FF0000",
			visibleNonInteractableEntities: true,
			outOfFocusDimOpacity: 0.4,
			blackAndWhite: false,
			highContrast: false,
		},
		controls: {
			Enter: "Enter",
			Escape: "Escape",
			ArrowUp: "W",
			ArrowDown: "S",
			ArrowLeft: "A",
			ArrowRight: "D",
		},
	};

	mutedSoundSettings = {
		musicVolume: 50,
		masterVolume: 50,
		sfxVolume: 50,
		uiVolume: 50,
		stereo: true,
	};

	sounds = {
		click: new Audio(require("../../assets/sounds/click.mp3")), // Preload audio
	};

	accessibilitySettings = {};
	displayAccessibilityMenu = false;
	displayTutorialsMenu = false;
	kValue = 50;

	simulatorLoaded = false;

	gameSaveState = {};
	activityData = {};
	activityAttempts = {};

	activityDataFromDatabase = {};
	activityDialogueFromDatabase = {};

	constructor() {
		makeAutoObservable(this, {
			accessibilitySettings: observable,
			sounds: observable,
			displayAccessibilityMenu: observable,
			gameSaveState: observable,
			activityData: observable,
			mutedSoundSettings: observable,
			activityDataFromDatabase: observable,
			activityDialogueFromDatabase: observable,
			setAccessibilityAttribute: action,
			setActivityContent: action,
			setActivityDialogue: action,
			setMutedSettings: action,
			setAccessibilitySettings: action,
			resetToDefaultAccessibilitySettings: action,
			startGame: action,
			toggleDisplayAccessibilityMenu: action,
			overrideAccessibilitySettings: action,
			checkIfKeyBeingUsed: action,
			playSound: action,
			simulatorLoaded: observable,
			setSimulatorLoaded: action,
			addActivityAttempt: action,
			activityAttempts: observable,
			kValue: observable,
			setKValue: action,
		});

		this.loadAccessibilitySettings();
		this.loadKValue();
	}

	setSimulatorLoaded = () => {
		this.simulatorLoaded = true;
	};

	// Setter function for the k value
	setKValue = value => {
		this.kValue = value;
		// Update in local storage
		localStorage.setItem("kValue", value);
	};

	// Loader function for the k value - called on initial load
	loadKValue = () => {
		const kValue = localStorage.getItem("kValue");
		if (kValue) {
			this.kValue = kValue;
		}
	};

	toggleDisplayAccessibilityMenu = () => {
		this.displayAccessibilityMenu = !this.displayAccessibilityMenu;

		if (this.displayAccessibilityMenu) {
			// if menu is open
			const simulationWindow = document.getElementById("simulationWindow");
			const phoneUIOverlay = document.getElementById("phone-ui__overlay");

			if (simulationWindow) {
				simulationWindow.style.opacity = 0.2; // grey out the simulation window
			}

			if (phoneUIOverlay) {
				phoneUIOverlay.style.opacity = 0.2;
			}
		} else {
			// if menu is closed
			const simulationWindow = document.getElementById("simulationWindow");
			const phoneUIOverlay = document.getElementById("phone-ui__overlay");

			if (simulationWindow) {
				simulationWindow.style.opacity = 1; // make the simulation window visible again
			}

			if (phoneUIOverlay) {
				phoneUIOverlay.style.opacity = 1;
			}
		}
		return this.displayAccessibilityMenu;
	};

	toggleDisplayTutorialsMenu = () => {
		this.displayTutorialsMenu = !this.displayTutorialsMenu;

		if (this.displayTutorialsMenu) {
			// if menu is open
			const simulationWindow = document.getElementById("simulationWindow");
			const phoneUIOverlay = document.getElementById("phone-ui__overlay");

			if (simulationWindow) {
				simulationWindow.style.opacity = 0.2; // grey out the simulation window
			}

			if (phoneUIOverlay) {
				phoneUIOverlay.style.opacity = 0.2;
			}
		} else {
			// if menu is closed
			const simulationWindow = document.getElementById("simulationWindow");
			const phoneUIOverlay = document.getElementById("phone-ui__overlay");

			if (simulationWindow) {
				simulationWindow.style.opacity = 1; // make the simulation window visible again
			}

			if (phoneUIOverlay) {
				phoneUIOverlay.style.opacity = 1;
			}
		}
		return this.displayTutorialsMenu;
	};

	startGame = async (gameSaveState, activityDataJson, activityAttempts) => {
		// Set the activity attempts
		this.setActivityAttempts(activityAttempts);
		// Load the game save state and set accordingly
		this.gameSaveState = this.loadGameSaveState(gameSaveState);
		// Save the game save state to local storage
		this.saveGameSaveStateToLocalStorage();
		// Load the activity data
		this.loadActivity(false, activityDataJson, true);
	};

	loadGameSaveState = gameSaveState => {
		console.log("loading game save state", gameSaveState);

		if (gameSaveState) {
			if (Object.keys(gameSaveState).length !== 0) return gameSaveState;
		}

		return this.constructNewSaveState();
	};

	saveGameSaveStateToLocalStorage = () => {
		// TODO: use student's profile rather than saving to local storage
		localStorage.setItem("gameSaveState", JSON.stringify(this.gameSaveState));
	};

	restartCurrentActivity = () => {
		console.log("restarting activity");

		const newActivityData = deepClone(this.activityData);

		// reset current score
		newActivityData.currentScore = 0;

		// remove algebra data
		newActivityData.algebraData = [];

		// reset studentData attributes:
		newActivityData.studentData.equation1.orderAccepted = false;

		// only reset equations app if they require user input
		if (newActivityData.studentData.equation1.budgetType === "dynamic")
			newActivityData.studentData.equation1.budget = "";
		if (newActivityData.studentData.equation1.producePriceAType === "dynamic")
			newActivityData.studentData.equation1.producePriceA = "";
		if (newActivityData.studentData.equation1.producePriceBType === "dynamic")
			newActivityData.studentData.equation1.producePriceB = "";

		newActivityData.studentData.equation2.orderAccepted = false;

		// only reset equations app if they require user input
		if (newActivityData.studentData.equation2.budgetType === "dynamic")
			newActivityData.studentData.equation2.budget = "";
		if (newActivityData.studentData.equation2.producePriceAType === "dynamic")
			newActivityData.studentData.equation2.producePriceA = "";
		if (newActivityData.studentData.equation2.producePriceBType === "dynamic")
			newActivityData.studentData.equation2.producePriceB = "";

		newActivityData.studentData.graphCoordinateX = -1;
		newActivityData.studentData.graphCoordinateY = -1;
		newActivityData.studentData.simulationCoordinateX = -1;
		newActivityData.studentData.simulationCoordinateY = -1;

		this.setActivityData(newActivityData);

		// try to call the reset function in the engine files
		try {
			window.restartActivityInEngine();
		} catch (error) {
			console.warn(error);
		}
	};

	moveOnToNextActivity = () => {
		console.log("moving on to next activity");

		const newGameSaveState = deepClone(this.gameSaveState);

		const userPassed = this.activityData.bestScore >= this.activityData.passThreshold;

		/* 
            update friendship scores for the orders that were accepted:
            - if the friendship score is less than 5, increase it by 1, only if the user passed the activity
            - if the friendship score is 5, do not increase it any more
            - if the friendship score is 1, always increase it, even if the user failed the activity
        */
		if (
			(userPassed && newGameSaveState.friendshipScores[this.activityData.orderData[0].character] < 5) ||
			newGameSaveState.friendshipScores[this.activityData.orderData[0].character] === 1
		)
			newGameSaveState.friendshipScores[this.activityData.orderData[0].character]++;

		if (
			(userPassed && newGameSaveState.friendshipScores[this.activityData.orderData[1].character] < 5) ||
			newGameSaveState.friendshipScores[this.activityData.orderData[1].character] === 1
		)
			newGameSaveState.friendshipScores[this.activityData.orderData[1].character]++;

		// update proficiency score
		newGameSaveState.proficiencyScore = updateProficiency(
			this.gameSaveState.proficiencyScore,
			this.activityData.difficultyScore,
			userPassed ? 1 : 0,
			this.kValue,
		);

		// Updating the users passes and failed activities
		// If user passed activity
		if (userPassed) {
			// Remove from failed activities if it exists
			const failedIndex = newGameSaveState.failedActivities.indexOf(this.activityData.id);
			if (failedIndex > -1) newGameSaveState.failedActivities.splice(failedIndex, 1);
			// Add to passed activities
			newGameSaveState.passedActivities.push(this.activityData.id);
		}
		else { // If failed activity
			// Ensure the failed activities array exists
			if (!newGameSaveState.failedActivities) {
				newGameSaveState.failedActivities = [];
			  }
			  // Remove from passed activities if it exists
			  if (!newGameSaveState.failedActivities.includes(this.activityData.id)) {
				newGameSaveState.failedActivities.push(this.activityData.id);
			  }
		}

		newGameSaveState.seenDialogueIDs.push(
			this.activityData.orderData[0].dialogueID,
			this.activityData.orderData[1].dialogueID,
		);

		// update the game save state
		this.setGameSaveState(newGameSaveState);

		// restart the game with the new game save state - if user passes will look for next activity upwards and vice versa if fails
		if (userPassed){
			// Load activity data
			this.loadActivity(true, null, true);
		}
		else{
			// Load activity data
			this.loadActivity(true, null, false);
		}

		// try to call the reset function in the engine files
		try {
			window.restartActivityInEngine();
		} catch (error) {
			console.warn(error);
		}
	};

	setGameSaveState = gameSaveStateData => {
		this.gameSaveState = {
			...JSON.parse(JSON.stringify(gameSaveStateData)),
		};

		this.saveGameSaveStateToLocalStorage(this.gameSaveState);
	};

	constructNewSaveState = () => {
		// for now, we will hard code the characters
		const defaultGameSaveState = {
			friendshipScores: {
				Kai: 1,
				Francisco: 1,
				Harold: 1,
				Zach: 1,
				Sophia: 1,
				Natalie: 1,
				Chris: 1,
				Luis: 1,
				Rebecca: 1,
				Tae: 1,
				Madison: 1,
				Olivia: 1,
				Emily: 1,
				Anthony: 1,
				Bill: 1,
				Dani: 1,
				Gabi: 1,
				Justin: 1,
			},
			seenDialogueIDs: [],
			passedActivities: [],
			failedActivities: [],
			proficiencyScore: 1000,
		};

		const characterRoster = Object.keys(this.activityDialogueFromDatabase);

		if (!this.activityDialogueFromDatabase || characterRoster.length === 0) return defaultGameSaveState;

		// this will only work if the activityDialogueFromDatabase pulls all characters from the database
		for (const character of characterRoster) {
			defaultGameSaveState.friendshipScores[character] = 1;
		}

		return defaultGameSaveState;
	};

	loadActivity(loadingNewActivity = false, activityDataJson = null, pass=true, ) {
		// If not loading a new activity and a non-empty activityDataJson is passed, set the activity data, otherwise load a new activity

		if (activityDataJson) {
			if (activityDataJson && !loadingNewActivity) {
				this.setActivityData(activityDataJson);
				return;
			}
		}

		// figure out what Elo (proficiency) score the student is at
		const prof = this.gameSaveState.proficiencyScore;

		const rawActivityData = this.getActivityByProficiency(prof, pass);

		const activityData = this.constructActivity(rawActivityData);

		this.setActivityData(activityData);

		return;
	}

	setActivityAttempts(attempts) {
		this.activityAttempts = attempts;
	}

	addActivityAttempt = (activityID, attemptData) => {
		if (!this.activityAttempts[activityID]) {
			this.activityAttempts[activityID] = {};
		}

		const attemptCount = Object.keys(this.activityAttempts[activityID]).length;

		this.activityAttempts = {
			...this.activityAttempts,
			[activityID]: {
				...this.activityAttempts[activityID],
				[attemptCount + 1]: attemptData,
			},
		};
		return toJS(this.activityAttempts);
	};

	constructActivity = rawActivityData => {
		const activityData = { ...rawActivityData };
		/* 
            solve the simultaenous equations given in orderData and save the answers to the 
            activityData object as answerX and answerY
        */
		const equation1 = {
			a: activityData.orderData[0].producePriceA,
			b: activityData.orderData[0].producePriceB,
			c: activityData.orderData[0].budget,
		};

		const equation2 = {
			a: activityData.orderData[1].producePriceA,
			b: activityData.orderData[1].producePriceB,
			c: activityData.orderData[1].budget,
		};

		const { x, y } = this.solveSimultaneousEquations(equation1, equation2);

		activityData.answerX = x;
		activityData.answerY = y;

		const [character1, character2] = this.selectCharacters(activityData.characterRoster);
		activityData.orderData[0].character = character1;
		activityData.orderData[1].character = character2;

		[activityData.orderData[0].dialogueID, activityData.orderData[0].characterDialogue] = this.getDialogue(
			activityData.orderData[0],
		);
		[activityData.orderData[1].dialogueID, activityData.orderData[1].characterDialogue] = this.getDialogue(
			activityData.orderData[1],
		);

		activityData.orderData[0].friendshipScore = this.gameSaveState.friendshipScores[character1];
		activityData.orderData[1].friendshipScore = this.gameSaveState.friendshipScores[character2];

		// convert student data arrays to more complex objects

		const studentDataEq1 = deepClone(activityData.studentData[0]);
		const studentDataEq2 = deepClone(activityData.studentData[1]);

		activityData.studentData = {
			equation1: studentDataEq1,
			equation2: studentDataEq2,
		};

		// student data set up
		activityData.studentData.equation1.orderAccepted = false;
		activityData.studentData.equation2.orderAccepted = false;

		activityData.studentData.equation1.produceA = activityData.orderData[0].produceA;
		activityData.studentData.equation1.produceB = activityData.orderData[0].produceB;

		activityData.studentData.equation2.produceA = activityData.orderData[1].produceA;
		activityData.studentData.equation2.produceB = activityData.orderData[1].produceB;

		activityData.studentData.graphCoordinateX = -1;
		activityData.studentData.graphCoordinateY = -1;
		// Equation tab variables
		activityData.studentData.simulationCoordinateX = -1;
		activityData.studentData.simulationCoordinateY = -1;
		activityData.studentData.varX = null;
		activityData.studentData.varY = null;
		activityData.studentData.eq1VarX = null;
		activityData.studentData.eq1VarY = null;
		activityData.studentData.eq1Operator = null;
		activityData.studentData.eq1Result = null;
		activityData.studentData.eq2VarX = null;
		activityData.studentData.eq2VarY = null;
		activityData.studentData.eq2Operator = null;
		activityData.studentData.eq2Result = null;

	

		activityData.retries = 3;
		activityData.currentScore = 0;
		activityData.bestScore = 0;

		return activityData;
	};

	setActivityData = activityData => {
		this.activityData = {
			...JSON.parse(JSON.stringify(activityData)),
		};

		this.saveActivityDataToLocalStorage();
	};

	setStudentEquation1Attribute = (attribute, value) => {
		if (Object.prototype.hasOwnProperty.call(this.activityData.studentData.equation1, attribute)) {
			this.activityData = {
				...this.activityData,
				studentData: {
					...this.activityData.studentData,
					equation1: {
						...this.activityData.studentData.equation1,
						[attribute]: value,
					},
				},
			};
			this.saveActivityDataToLocalStorage();
		}
	};

	setStudentEquation2Attribute = (attribute, value) => {
		if (Object.prototype.hasOwnProperty.call(this.activityData.studentData.equation2, attribute)) {
			this.activityData = {
				...this.activityData,
				studentData: {
					...this.activityData.studentData,
					equation2: {
						...this.activityData.studentData.equation2,
						[attribute]: value,
					},
				},
			};
			this.saveActivityDataToLocalStorage();
		}
	};

	setAlgebraData = algebraData => {
		this.activityData = {
			...this.activityData,
			algebraData: algebraData,
		};

		this.saveActivityDataToLocalStorage();
	};

	setStudentActivityAttribute = (attribute, value, equation = null) => {
		if (equation === 1) {
			this.setStudentEquation1Attribute(attribute, value);
			return;
		}

		if (equation === 2) {
			this.setStudentEquation2Attribute(attribute, value);
			return;
		}
		if (Object.prototype.hasOwnProperty.call(this.activityData.studentData, attribute)) {
			this.activityData = {
				...this.activityData,
				studentData: {
					...this.activityData.studentData,
					[attribute]: value,
				},
			};
			this.saveActivityDataToLocalStorage();
		}
	};

	saveActivityDataToLocalStorage = () => {
		localStorage.setItem("activityData", JSON.stringify(this.activityData));
	};

	setActivityDataAttribute = (attribute, value) => {
		console.log("setting attribute", attribute, value);

		if (Object.prototype.hasOwnProperty.call(this.activityData, attribute)) {
			this.activityData = {
				...this.activityData,
				[attribute]: value,
			};
		}

		this.saveActivityDataToLocalStorage();
	};

	/**
	 * gets the activity based on the passed activity ID
	 *
	 * @param {Int} activityID - The ID of the activity to get
	 * @returns the activity object
	 */
	getActivityByID = activityID => {
		// pull the activity data from the database
		return this.activityDataFromDatabase[activityID];
	};

	/**
	 * gets a random activity based on the passed proficiency score
	 *
	 * @param {Int} proficiencyScore - The proficiency score of the student
	 * @returns {Object} - The randomly selected activity object
	 */
	getActivityByProficiency = (proficiencyScore, pass=true )=> {
		// organize the activities in this.activityDataFromDatabase by proficiency score
		const sortedActivities = Object.entries(this.activityDataFromDatabase)
			.map(([key, activity]) => ({
				...activity,
				id: Number(key), // save the id inside the sorted object to use as a reference later
			}))
			.sort((a, b) => a.difficultyScore - b.difficultyScore);
		
		let selectedActivity = null;
		let filteredActivities = [];
		// If user passed the previous activity
		if (pass){
			// remove all activities from the list that appear in gameSaveState.passedActivities
			filteredActivities = sortedActivities.filter(
				activity => !this.gameSaveState.passedActivities.includes(activity.id),
			);

			// choose the next highest activity based on the passed proficiency score
			for (let i = 0; i < filteredActivities.length; i++) {
				if (filteredActivities[i].difficultyScore >= proficiencyScore) {
					selectedActivity = filteredActivities[i];
					break;
				}
			}
			// if no activities are left, choose the closest lower score activity
			if (!selectedActivity) selectedActivity = filteredActivities[filteredActivities.length - 1];
			if (!selectedActivity) throw new Error("No activities left for user to do");
		}
		else{
			// Filter out the failed activitiies and get the next lowest activity
			filteredActivities = sortedActivities.filter(
				activity => !this.gameSaveState.failedActivities.includes(activity.id),
			);
			// Filter out all activities with a higher difficulty score than the proficiency score
			filteredActivities = filteredActivities.filter(
				activity => activity.difficultyScore <= proficiencyScore,
			);
			// Choose the next lowest activity based on the proficiency score
			for (let i = filteredActivities.length - 1; i >= 0; i--) {
				if (filteredActivities[i].difficultyScore <= proficiencyScore) {
					selectedActivity = filteredActivities[i];
					break;
				}
			}
			// If no activities are left, choose the closest higher score activity
			if (!selectedActivity) selectedActivity = filteredActivities[0];
			if (!selectedActivity) throw new Error("No Activities below this for user to do");
		}
		return selectedActivity;
	};

	getStudentProficiency = () => {
		// TODO: pull the students proficiency from whatever data base we are using and return it
		return 900;
	};

	loadAccessibilitySettings = () => {
		// Load the accessibility JSON from local storage
		const localAccessibilitySettings = localStorage.getItem("accessibilitySettings");

		if (localAccessibilitySettings) {
			// Set it to the json from local storage if it exists
			this.accessibilitySettings = JSON.parse(localAccessibilitySettings);

			// redundant code?
			this.setAccessibilitySettings();
		} else {
			// Otherwise, set it to the default accessibility settings
			this.resetToDefaultAccessibilitySettings();
		}
	};

	setAccessibilitySettings = () => {
		// Update local storage for persistency
		localStorage.setItem("accessibilitySettings", JSON.stringify(this.accessibilitySettings));
	};

	setMutedSettings = () => {
		this.mutedSoundSettings = deepClone(this.accessibilitySettings.audio);
	};

	setAccessibilityAttribute = (heading, attribute, value) => {
		if (Object.prototype.hasOwnProperty.call(this.accessibilitySettings, heading)) {
			if (Object.prototype.hasOwnProperty.call(this.accessibilitySettings[heading], attribute)) {
				// Create a new object for the heading
				const updatedHeading = {
					...this.accessibilitySettings[heading],
					[attribute]: value,
				};

				// Create a new object for the accessibility settings
				this.accessibilitySettings = {
					...this.accessibilitySettings,
					[heading]: updatedHeading,
				};

				console.log("successfully set accessibility attribute");
			} else {
				console.warn(`Attribute '${attribute}' does not exist in heading '${heading}'.`);
				return;
			}
		} else {
			console.warn(`Heading '${heading}' does not exist in accessibility settings.`);
			return;
		}

		// Update local storage for persistency
		this.setAccessibilitySettings();
	};

	resetToDefaultAccessibilitySettings = () => {
		this.accessibilitySettings = deepClone(this.defaultAccessibilitySettings);

		// Update local storage for persistency
		this.setAccessibilitySettings();

		console.log("reset to default accessibility settings");
	};

	overrideAccessibilitySettings = newAccessibilitySettings => {
		this.accessibilitySettings = deepClone(newAccessibilitySettings);

		this.setAccessibilitySettings();
	};

	checkIfKeyBeingUsed = key => {
		const { controls } = this.accessibilitySettings;
		const keys = Object.values(controls);
		return keys.includes(key);
	};

	/**
	 * Plays a sound
	 * @param {String} sound - the name of the string of the sound you want to play
	 */
	playSound = sound => {
		console.log(sound);

		// Get the volume from the accessibility store
		if (this.sounds[sound] === undefined) {
			console.error("Sound not found");
			return;
		}
		const volume = this.accessibilitySettings.audio.uiVolume / 100;
		this.sounds[sound].volume = volume;
		// Set the time to be zero so that the sound can be played multiple times at once
		this.sounds[sound].currentTime = 0;
		this.sounds[sound].play();
	};

	/**
	 * Solves a pair of simultaneous equations
	 * @param {Object} equation1 - the first equation to solve
	 * @param {Object} equation2 - the second equation to solve
	 * @returns {Object} - the values of x and y that solve the equations
	 */
	solveSimultaneousEquations = (equation1, equation2) => {
		const { a: a1, b: b1, c: c1 } = equation1;
		const { a: a2, b: b2, c: c2 } = equation2;

		// Calculate the determinant
		const determinant = a1 * b2 - a2 * b1;

		// Check if the equations are parallel or coincident
		if (determinant === 0) {
			if (c1 === c2) return "The equations are coincident, infinite solutions exist.";

			return "The equations are parallel, no solution exists.";
		}

		// Calculate the values of x and y
		const x = (b2 * c1 - b1 * c2) / determinant;
		const y = (a1 * c2 - a2 * c1) / determinant;

		return { x, y };
	};

	selectCharacters = roster => {
		const weightedRoster = [];

		/* 
            loop over all characters in the roster and add them to the weightedRoster array. 
            The number of times a character appears in the weightedRoster array is determined 
            by their friendship score. e.g if Kai's friendship is 3 then he will appear in the
            weightedRoster array 3 times which makes him 3 times more likely to be selected.
        */
		for (const character of roster) {
			const score = this.gameSaveState.friendshipScores[character];

			if (score === undefined) {
				console.error("Character not found in friendship scores");
				return;
			}

			for (let i = 0; i < score; i++) {
				weightedRoster.push(character);
			}
		}

		const selectedCharacters = [];

		// randomly select two characters from the weightedRoster array ensuring that the same name isn't picked twice
		while (selectedCharacters.length < 2 && weightedRoster.length > 0) {
			const randomIndex = Math.floor(Math.random() * weightedRoster.length);
			const character = weightedRoster.splice(randomIndex, 1)[0];
			if (!selectedCharacters.includes(character)) {
				selectedCharacters.push(character);
			}
		}

		return selectedCharacters;
	};

	getDialogue = equationData => {
		const { character, produceA, producePriceA, produceB, producePriceB, budget } = equationData;
		
		const dialogueForCharacter = this.activityDialogueFromDatabase[character];

		const characterFriendshipScore = this.gameSaveState.friendshipScores[character];
		if (!dialogueForCharacter[characterFriendshipScore])
			console.error(`No dialogue found for character ${character} with friendship score ${characterFriendshipScore}`);

		const potentialDialogues = Object.keys(
			this.activityDialogueFromDatabase[character][this.gameSaveState.friendshipScores[character]],
		);

		const randomDialogueID = potentialDialogues[Math.floor(Math.random() * potentialDialogues.length)];

		let chosenDialogue = dialogueForCharacter[characterFriendshipScore][randomDialogueID];

		chosenDialogue = chosenDialogue.replace(/\$orderProduceA\$/g, `<span class='font-bold'>${produceA}</span>`);

		// handle the case where dollar sign was prefixed
		chosenDialogue = chosenDialogue.replace(/\$\$orderPriceA\$/g, `<span class='font-bold'>$${producePriceA}</span>`);
		// if double dollar sign string wasn't found then just handle the single dollar sign case
		chosenDialogue = chosenDialogue.replace(/\$orderPriceA\$/g, `<span class='font-bold'>$${producePriceA}</span>`);

		chosenDialogue = chosenDialogue.replace(/\$orderProduceB\$/g, `<span class='font-bold'>${produceB}</span>`);

		// handle the case where double dollar sign was prefixed
		chosenDialogue = chosenDialogue.replace(/\$\$orderPriceB\$/g, `<span class='font-bold'>$${producePriceB}</span>`);
		// if double dollar sign string wasn't found then just handle the single dollar sign case
		chosenDialogue = chosenDialogue.replace(/\$orderPriceB\$/g, `<span class='font-bold'>$${producePriceB}</span>`);

		chosenDialogue = chosenDialogue.replace(/\$budget\$/g, `<span class='font-bold'>$${budget}</span>`);

		return [randomDialogueID, chosenDialogue];
	};

	setActivityContent = data => {
		this.activityDataFromDatabase = data;
	};

	setActivityDialogue = data => {
		this.activityDialogueFromDatabase = data;
	};
}

export const svs = new SVS();
