import React from 'react';
import "./components.css";

/*
    Checkbox List

    This is a custom component for the custom drawer, that is used to display a list of divs that are as wide as the drawer, that
    have a label and a checkbox component. The checkbox component is used to select the item, and the label is used to display the name.
    Example the component opetions for the UIBuilder.
*/
const CheckboxList = ({ items, selectedItems, handleCheckboxChange, blockedItems = []}) => {
    return (
        items.map((item, index) => (
            <div id="checkbox-list__div" key={index}>
                <input id="checkbox-list__input" type="checkbox"
                    checked={selectedItems.includes(item)}
                    disabled={blockedItems.includes(item)}
                    onChange={(event) => handleCheckboxChange(event, item)}
                />
                <label id="checkbox-list__label">
                    {item}
                </label>
            </div>
        ))
    );
};

export default CheckboxList;