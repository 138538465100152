import React, { useContext, useEffect, useRef, useState } from "react";
import CreateAxiosInstance from "../../AxiosInstance/AxiosInstance";
import AppContext from "../../components/AppContext/AppContext";

import DesignToolsHeader from "../../components/DesignToolsHeader/DesignToolsHeader";
import EntityBuilderSettings from "../../components/EntityBuilder/EntityBuilderSettings/EntityBuilderSettings";
import EntityLibrary from "../../components/EntityBuilder/EntityLibrary/EntityLibrary";
import SimulationWindow from "../../components/SimulationWindow/SimulationWindow";

import CustomDrawer from "../../components/UIBuilder/CustomDrawer/CustomDrawer";
import ButtonItem from "../../components/UIBuilder/CustomDrawer/components/ButtonItem";

import "./EntityBuilder.css";

const EntityBuilder = () => {
	const { projectName, gameName, entityName } = useContext(AppContext);
	const axios = CreateAxiosInstance();
	const [selectedEntity, setSelectedEntity] = useState(entityName ?? null);
	const [entityList, setEntityList] = useState([]);

	useEffect(() => {
		async function fetchEntityList() {
			await axios.get(`/entities/all/${projectName}/${gameName}/`).then(response => {
				setEntityList(response.data.data);
			});
		}
		fetchEntityList();
	}, []);

	const saveEntityToDatabase = async entitySettings => {
		const entityExists = entityList.includes(entitySettings.name);
		const url = entityExists ? `/entities/${projectName}/${gameName}/${entitySettings.name}/` : "/entities/";

		const data = entityExists
			? { entity: JSON.stringify(entitySettings) }
			: {
				entityName: entitySettings.name,
				projectName: projectName,
				gameName: gameName,
				entity: JSON.stringify(entitySettings),
			};

		const axiosMethod = entityExists ? axios.put : axios.post;

		await axiosMethod(url, data).then(response => {
			if (!entityExists && response.status === 201) {
				setEntityList([...entityList, entitySettings.name]);
			}
			setSelectedEntity(entitySettings.name);
		});
	};

	const [projectModalState, setProjectModalState] = useState(false);
	const [settingsModalState, setSettingsModalState] = useState(false);
	const [selectedEntityCog, setSelectedEntityCog] = useState(null);

	const handleAddEntity = () => {
		setProjectModalState(true);
	}

	/* INIT DRAWER INFO */
	const drawerTabInfo = [
		{
			name: "Entity Library",
			key: "entity-library",
			tabWidth: "60%",
			content: (
				<>
					<EntityLibrary entityList={entityList} selectedEntity={selectedEntity} setSelectedEntity={setSelectedEntity} setModalState={setSettingsModalState} setSelectedEntityCog={setSelectedEntityCog} />
					<ButtonItem onClick={handleAddEntity} name="Add Entity" />
				</>
			),
		},
		{
			name: "Edit",
			key: "edit",
			tabWidth: "30%",
			content: (
				<EntityBuilderSettings
					selectedEntity={selectedEntity}
					projectName={projectName} // needed for the API call
					gameName={gameName} // needed for the API call
					entityName={entityName} // needed for the API call
					saveEntityToDatabase={saveEntityToDatabase}
				/>
			),
		},
	];

	const entityBuilderWorkspaceDiv = useRef(null);

	return (
		<>
			<DesignToolsHeader tab={"entity-builder"} />
			<div className="entity-builder__body">

				<CustomDrawer drawerTabInfo={drawerTabInfo} workspaceDivRef={entityBuilderWorkspaceDiv} />
				<div className="entity-builder-container" ref={entityBuilderWorkspaceDiv}>
					<SimulationWindow simulationMode="EntityBuilder" />
				</div>
			</div>
		</>
	);
};

export default EntityBuilder;
