import React, { useState } from 'react'

interface ToggleSwitchProps {
    label?: string
    label2?: string
    initialState?: boolean
    colorMode?: string
    onChange?: (isChecked: boolean) => void
}

export default function ToggleSwitch({
    label,
    label2,
    initialState = false,
    colorMode = "dark",
    onChange
}: ToggleSwitchProps) {

    const [isChecked, setIsChecked] = useState(initialState)

    const handleToggle = () => {
        const newState = !isChecked
        setIsChecked(newState)
        if (onChange) {
            onChange(newState)
        }
    }

    return (
        <div className="flex items-center justify-between">
            <div className="flex-grow mr-4 justify-center items-center">
                <div className={`${colorMode === "dark" ? "text-color-bua-text-light" : "text-color-bua-text-dark"} text-sm sm:text-base`}>
                    {label}
                </div>
            </div>
            <div className="flex-shrink-0">
                <label className="flex items-center cursor-pointer">
                    <div className="relative">
                        <input
                            id="toggle-switch"
                            type="checkbox"
                            className="sr-only peer"
                            checked={isChecked}
                            onChange={handleToggle}
                        />
                        <div
                            className={`
                                block w-14 h-8 rounded-full transition-colors duration-300 ease-in-out 
                                ${isChecked ? 'bg-[#4622f8]' : 'bg-gray-300'} 
                                peer-focus:outline-none peer-focus:ring peer-focus:ring-[#1FC3FF] peer-focus:ring-offset-2
                            `}
                        />
                        <div
                            className={`
                                absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition-transform duration-300 ease-in-out ${isChecked ? 'transform translate-x-full' : ''}
                            `}
                        />
                    </div>
                </label>
            </div>
        </div>
    )
}