import React, { useEffect, useState } from "react";
import CreateAxiosInstance from "../../AxiosInstance/AxiosInstance";
import "./AssetLibrary.css";

function AssetLibrary({ setAssetLibraryModalIsOpen, assetLibraryCallback }) {
	const [thumbnails, setThumbnails] = useState(null);
	const axios = CreateAxiosInstance();
	useEffect(() => {
		const fetchThumbnails = async () => {
			const response = await axios.get("/assets/getAllThumbnails");

			const base64Thumbnails = [];
			for (let index = 0; index < response.data.length; index++) {
				const base64 = response.data[index].data;
				const name = response.data[index].name;
				const contentType = response.data[index].contentType;
				
				base64Thumbnails.push({ name: name, data: `data:${contentType};base64,${base64}` });
			}

			setThumbnails(base64Thumbnails);
		};

		fetchThumbnails();
	}, []);

	if (!thumbnails) {
		return <div>Loading...</div>; // You can return a loading spinner here
	}

	return (
		<div className="asset-library-container">
			<h1>Asset Library</h1>
			<div className="asset-library-grid">
				{thumbnails.map((thumbnail, index) => (
					// biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
					<img
						className={"asset-library-grid-image"}
						key={thumbnail.name}
						id={thumbnail.name}
						src={thumbnail.data}
						alt={`Thumbnail ${index}`}
						onClick={id => {
							assetLibraryCallback(thumbnail.name);
							setAssetLibraryModalIsOpen(false);
						}}
					/>
				))}
			</div>
		</div>
	);
}

export default AssetLibrary;
