import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import CreateAxiosInstance from "../../AxiosInstance/AxiosInstance";
import Dropdown from "../../components/Dropdown/Dropdown";
import ToggleSwitch from "../../components/ToggleSwitch/ToggleSwitch";

import buaLogo from "../../assets/images/bua/bua-logo-multicolor.png";

const Layout = () => {
	const [engineVersionOptions, setEngineVersionOptions] = useState<{ key: string, value: string }[]>([]);

	const [selectedEngineBuild, setSelectedEngineBuild] = useState<string>(
		window.localStorage.getItem("engineBuildVersion") ?? "Latest",
	);

	const [useLocalEngineRepo, setUseLocalEngineRepo] = useState<boolean>(
		window.localStorage.getItem("useLocalEngineRepo") === "true",
	)

	// TODO: Hook up semantic versioning for bua engine
	const version = "0.0.2";

	let user: string | undefined;

	// Get the user from the local storage
	const userString = window.localStorage.getItem("okta-token-storage");
	if (userString) {
		const userObject = JSON.parse(userString);
		user = userObject.accessToken?.claims?.sub.toString();
	}

	const axios = CreateAxiosInstance();

	const handleEngineBuildVersionChange = (version: string) => {
		window.localStorage.setItem("engineBuildVersion", version);
		setSelectedEngineBuild(version);
	};

	const handleLocalSimulationRepoChange = (toggle: boolean) => {
		const toggleString = toggle.toString();
		window.localStorage.setItem("useLocalEngineRepo", toggleString);
		setUseLocalEngineRepo(toggle);
	}

	useEffect(() => {
		async function fetchData() {
			// Fetch data
			const { data } = await axios.get("/engineBuilds/getEngineBuildList");
			const results = [];
			// Store results in the results array
			for (const value of data) {
				results.push({
					key: value,
					value: value,
				});
			}
			// Update the options state
			setEngineVersionOptions([...results]);
		}

		// Trigger the fetch
		fetchData();
	}, []);

	return (
		<>
			{user && <h2 className="text-color-bua-text-light right-0 absolute m-4 text-sm sm:text-lg">{user}</h2>}
			<div className="bg-color-bua-background flex flex-col h-full w-full items-center justify-center space-y-24 sm:space-y-0 sm:justify-between text-sm">
				<img src={buaLogo} alt="Bua Logo" className="w-1/2 min-w-[250px] max-w-max h-auto mt-10 sm:mt-16" />

				<div className="flex w-full justify-around gap-4 items-center flex-col sm:flex-row sm:text-2xl">
					<button
						type="button"
						className="bg-color-bua-button-primary hover:opacity-75 active:opacity-50 text-color-bua-text-light font-medium rounded-md px-4 py-2 w-1/3 h-16 sm:w-1/5 sm:h-20 max-w-[200px] transform transition hover:scale-105 focus:scale-105"
						onClick={() => {
							window.location.href = "/projects";
						}}
					>
						Designer
					</button>
					<button
						type="button"
						className="bg-color-bua-button-primary hover:opacity-75 active:opacity-50 text-color-bua-text-light font-medium rounded-md px-4 py-2 w-1/3 h-16 sm:w-1/5 sm:h-20 max-w-[200px] transform transition hover:scale-105 focus:scale-105"
						onClick={() => {
							window.location.href = "/games";
						}}
					>
						Author
					</button>
					<button
						type="button"
						className="bg-color-bua-button-primary hover:opacity-75 active:opacity-50 text-color-bua-text-light font-medium rounded-md px-4 py-2 w-1/3 h-16 sm:w-1/5 sm:h-20 max-w-[200px] transform transition hover:scale-105 focus:scale-105"
						onClick={() => {
							window.location.href = "/student-view";
						}}
					>
						Student View
					</button>
				</div>

				<div className="flex flex-col items-center">
					<h1 className="text-color-bua-text-light font-bold text-lg sm:text-2xl"> Dev Settings </h1>
					<div className="flex flex-col items-center gap-4 mb-2">

						<ToggleSwitch
							label="Use Local Simulation Repo Build Files"
							initialState={useLocalEngineRepo}
							onChange={handleLocalSimulationRepoChange}
						/>
						<Dropdown
							label="Engine Build Version"
							options={engineVersionOptions}
							defaultValue={selectedEngineBuild}
							onChange={handleEngineBuildVersionChange}
						/>
						<Outlet />
						<div className="text-color-bua-text-light mb-4">Engine version {version} using React version 18.2.0</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Layout;
