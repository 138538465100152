import React from "react";

interface ColorInputProps {
	label: string;
	onChange: (value: string) => void;
	defaultValue: string;
}

const ColorInput = ({
	label,
	onChange,
	defaultValue

}: ColorInputProps) => {
	return (
		<div className="w-full flex items-center justify-between">
			<div
				className="text-sm sm:text-base"
			>
				{label}
			</div>
			<input
				className="
					flex-shrink-0 w-32 max-w-48 shadow-sm hover:cursor-pointer
					rounded-lg
					focus:ring-offset-2 focus:ring-[#1FC3FF] focus:ring focus:outline-none
				"
				type="color"
				onChange={(e) => onChange(e.target.value)}
				defaultValue={defaultValue}
				id={`colorInput${label}`}
				aria-labelledby={`label${label}`}
			/>
		</div>
	);
};
export default ColorInput;
