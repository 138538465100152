import React, { useContext } from "react";
import AppContext from "../../components/AppContext/AppContext";

import DesignToolsHeader from "../../components/DesignToolsHeader/DesignToolsHeader";

const AvailableBlocks = () => {

    const localContext = useContext(AppContext);
    console.log("AvailableBlocks " + localContext.projectName, localContext.gameName, localContext.entityName)

    return (
        <div>
            <DesignToolsHeader tab={"available-blocks"} />
            <div className="available-blocks-container">Available Blocks will go here</div>
        </div>
    );
};

export default AvailableBlocks;
