import React from "react";

export default function QuestionMarkIcon() {
    return (
        <svg width="100%" height="100%" viewBox="0 0 1080 1080" version="1.1" style={{ fillRule: 'evenodd', clipRule: 'evenodd' }}>
            <title>Question Mark Icon</title>
            <g transform="matrix(11.2997,0,0,11.2997,42.8151,88.0137)">
                <g>
                    <circle cx="44" cy="40" r="38.5" style={{ fill: 'rgb(0,167,228)', stroke: 'white', strokeWidth: '3px' }} />
                    <path d="M33.465,24.803C34.742,19.776 39.302,16.052 44.723,16.052C51.134,16.052 56.34,21.258 56.34,27.669C56.34,34.081 51.134,39.286 44.723,39.286C43.909,39.286 43.114,39.202 42.348,39.043" style={{ fill: 'none', fillRule: 'nonzero', stroke: 'white', strokeWidth: '8.14px', strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: '1.5' }} />
                    <path d="M42.103,64.502C43.634,64.502 44.875,63.261 44.875,61.73C44.875,60.198 43.634,58.957 42.103,58.957C40.571,58.957 39.33,60.198 39.33,61.73C39.33,63.261 40.571,64.502 42.103,64.502Z" style={{ fill: 'white', fillRule: 'nonzero', stroke: 'white', strokeWidth: '4.25px', strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: '1.5' }} />
                    <path d="M41.795,39.055L42.425,49.764" style={{ fill: 'none', fillRule: 'nonzero', stroke: 'white', strokeWidth: '8.14px', strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: '1.5' }} />
                </g>
            </g>
        </svg>
    )
}