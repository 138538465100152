import ProducePackerSubmitButton from "../ProducePacker/ProducePackerSubmitButton";

export default function Scoring({
	accessibilitySettings,
	activityData,
	setActivityDataAttribute,
	setShowResultModal,
	showResultModal,
	setFeedback,
}) {
	// students data
	const {
		producePriceA: studentEquation1ProducePriceA,
		producePriceB: studentEquation1ProducePriceB,
		budget: studentEquation1Budget,
	} = activityData.studentData.equation1;

	// students data
	const {
		producePriceA: studentEquation2ProducePriceA,
		producePriceB: studentEquation2ProducePriceB,
		budget: studentEquation2Budget,
	} = activityData.studentData.equation2;

	// data set by activity
	const {
		producePriceA: orderEquation1ProducePriceA,
		producePriceB: orderEquation1ProducePriceB,
		budget: orderEquation1Budget,
		produceA,
		produceB,
	} = activityData.orderData[0];

	// data set by activity
	const {
		producePriceA: orderEquation2ProducePriceA,
		producePriceB: orderEquation2ProducePriceB,
		budget: orderEquation2Budget,
	} = activityData.orderData[1];

	// students data
	const {
		simulationCoordinateX, // simulationCoordinateX - the x answer value that comes from the simulation (number of produceA crate placed in the truck)
		simulationCoordinateY, // simulationCoordinateY - the y answer value that comes from the simulation (number of produceB crate placed in the truck)
		graphCoordinateX, // graphCoordinateX - the x answer value that comes from the input boxes on the graph app
		graphCoordinateY, // graphCoordinateY - the y answer value that comes from the input boxes on the graph app
	} = activityData.studentData;

	// data set by activity
	const { answerX, answerY } = activityData;

	const bestScore = activityData.bestScore;

	const potentialFeedback = {};

	// Solve activity feedback given depends on activity type set by author
	// Allowable values: 'genericSolve', 'algebraSolve', 'graphSolve'
	const solve_activity_type = "genericSolve";

	// Tracking the type and number of mistakes
	const mistakes = {};
	let totalMistakes = 0;

	/* Feedback Dialogue.
	I'm sure this will be saved somewhere differently and possibly in a different format, like in a seperate JSON file.
	Currently no feedback for:
	- Positive feedback when the answer exceeds the treshold score, but there are still mistakes.
	- No distinction between x and y for the 'orders; feedback'
	*/

	// Feedback for a problem with the equation
	const equationFeedbackStart =
		"Pay attention to the equations you create. The equations should match the dollar amounts given for the fruits in the order.";
	const equationFeedbackType = {
		mixup: "It looks like you mixed up the numbers in your equations.",
		orders: "You should reread the orders to help you create the equations.",
		budget: "Check the customers' budgets to fill out the final part of your equation.",
	};

	// Feedback for a problem with the solving
	const genericSolveFeedbackStart =
		"You can solve for x and y using graphs or algebra. On the graph, the point at the intersection of the two lines will give you the answer. When solving using algebra, you can use elimination or substitution.";
	const genericSolveFeedbackType = {
		xValue: "Pay closer attention to how you solved for the x value.",
		yValue: "Pay closer attention to how you solved for the y value.",
		bothValues: "Pay closer attention to how you solved for the x and y values.",
	};

	const algebraSolveFeedbackStart = "Pay attention when solving for x and y.";
	const algebraSolveFeedbackType = {
		xValue: "It looks like something went wrong when solving for x.",
		yValue: "It looks like something went wrong when solving for y.",
		bothValues: "It looks like something went wrong when solving for x and y.",
	};

	const graphSolveFeedbackStart =
		"Check the graph when solving for x and y. The point at the intersection of the two lines will give you the answer.";
	const graphSolveFeedbackType = {
		xValue: "Pay closer attention to the x value, which can be read from the horizontal axis.",
		yValue: "Pay closer attention to the y value, which can be read from the vertical axis.",
		bothValues: "Pay closer attention to both the x and y values.",
	};

	// Feedback for a problem with packing
	const packingFeedbackType = {
		produceX: `The amount of ${produceA} you pack for each customer should match the x value you solved for.`,
		produceY: `The amount of ${produceB} you pack for each customer should match the y value you solved for.`,
		bothProduce: `The amount of ${produceA} and ${produceB} you pack for each customer should match the x and y values you solved for.`,
	};

	//This counts the number and type of mistakes the student has made.
	//Currently, if the student has made the same mistake more than three times
	//all it will do make a note of it.
	function countMistakes(mistake, mistakeType) {
		if (mistake in mistakes) {
			if (mistakeType in mistakes[mistake]) {
				if (mistakes[mistake][mistakeType] < 3) {
					mistakes[mistake][mistakeType] += 1;
				} else if (mistakes[mistake][mistakeType] >= 3) {
					// Action needed here
					console.log(`The player has made the same mistake: '${mistake}: ${mistakeType}' 3 or more times.`);
				}
			} else {
				mistakes[mistake][mistakeType] = 1;
			}
		} else {
			mistakes[mistake] = {};
			mistakes[mistake][mistakeType] = 1;
		}

		//Action needed if a certain number of total mistakes are accrued.
		totalMistakes += 1;
	}

	// This constructs the appropriate feedback from the feedback dialogues
	// and inserts it into Chedzeys code
	function giveFeedback(feedback, feedbackType) {
		let feedbackMessage = "";

		switch (feedback) {
			case "equation":
				feedbackMessage = `${equationFeedbackStart} ${equationFeedbackType[feedbackType]}`;

				break;

			case "genericSolve":
				feedbackMessage = `${genericSolveFeedbackStart} ${genericSolveFeedbackType[feedbackType]}`;

				break;

			case "algebraSolve":
				feedbackMessage = algebraSolveFeedbackStart + algebraSolveFeedbackType[feedbackType];

				break;

			case "graphSolve":
				feedbackMessage = graphSolveFeedbackStart + graphSolveFeedbackType[feedbackType];

				break;

			case "packing":
				feedbackMessage = packingFeedbackType[feedbackType];

				break;

			default:
				feedbackMessage = "Looks like that wasn't quite right. Take another look at your solution and try again.";
		}

		countMistakes(feedback, feedbackType);
		return feedbackMessage;
	}

	const calculateScore = () => {
		// Calculate the score for building the equations -> 2 marks max
		const BuiltEquations = [
			{
				Student_x: Number(studentEquation1ProducePriceA),
				Student_y: Number(studentEquation1ProducePriceB),
				Student_const: Number(studentEquation1Budget),
				Correct_x: Number(orderEquation1ProducePriceA),
				Correct_y: Number(orderEquation1ProducePriceB),
				Correct_const: Number(orderEquation1Budget),
			},
			{
				Student_x: Number(studentEquation2ProducePriceA),
				Student_y: Number(studentEquation2ProducePriceB),
				Student_const: Number(studentEquation2Budget),
				Correct_x: Number(orderEquation2ProducePriceA),
				Correct_y: Number(orderEquation2ProducePriceB),
				Correct_const: Number(orderEquation2Budget),
			},
		];

		const [buildScore1, buildScore2] = calculateEquationBuildingScore(BuiltEquations);
		const { equation_build_score: equationBuildScore1, message: buildMessage1 } = buildScore1;
		const { equation_build_score: equationBuildScore2, message: buildMessage2 } = buildScore2;

		if (buildMessage1 !== "") {
			potentialFeedback.buildFeedback1 = buildMessage1;
		}
		if (buildMessage2 !== "") {
			potentialFeedback.buildFeedback2 = buildMessage2;
		}

		// Calculate the score for solving the equations -> 2 marks max
		const solvedEquations = {
			Eq1_x: BuiltEquations[0].Student_x,
			Eq1_y: BuiltEquations[0].Student_y,
			Eq1_const: BuiltEquations[0].Student_const,

			Eq2_x: BuiltEquations[1].Student_x,
			Eq2_y: BuiltEquations[1].Student_y,
			Eq2_const: BuiltEquations[1].Student_const,

			Student_x: Number(graphCoordinateX), // Student's answer for x (inputted on graph app)
			Student_y: Number(graphCoordinateY), // Student's answer for y (inputted on graph app)
		};

		const { score: solveScore, message: solveMessage } = calculateEquationSolvingScore(solvedEquations); // change to use BuiltEquations as input
		console.log("solveScore:", solveScore);
		console.log(solveMessage);

		if (solveMessage !== "") {
			potentialFeedback.solveFeedback = solveMessage;
		}

		// Calculate the score for packing the truck -> 1 mark max
		const packingData = {
			Student_x: simulationCoordinateX,
			Student_y: simulationCoordinateY,
			Answer_x: answerX,
			Answer_y: answerY,
		};
		const { score: packingScore, message: packingMessage } = calculatePackingScore(packingData);
		console.log("packingScore:");
		console.log(packingScore);

		if (packingMessage !== "") {
			potentialFeedback.packingFeedback = packingMessage;
		}

		const randomFeedback = obj => {
			const keys = Object.keys(obj);
			return obj[keys[(keys.length * Math.random()) << 0]];
		};

		setFeedback(randomFeedback(potentialFeedback));

		const finalScore = ((equationBuildScore1 + equationBuildScore2 + solveScore + packingScore) / 5).toFixed(2);

		setActivityDataAttribute("currentScore", finalScore);
		if (activityData.retries !== 0) setActivityDataAttribute("retries", activityData.retries - 1);

		if (finalScore > bestScore) setActivityDataAttribute("bestScore", finalScore);

		setShowResultModal(true);
	};

	/**
	 *
	 * @param {Object} BuiltEquations | Array of objects containing the student's built equations and the correct equations
	 * @returns score matrix
	 */
	function calculateEquationBuildingScore(BuiltEquations) {
		const overall_student_score = [];

		for (let i = 0; i < BuiltEquations.length; i++) {
			let currentMessage = "";
			const x_score = [];
			const y_score = [];
			const const_score = [];

			const correct_slope = -BuiltEquations[i].Correct_x / BuiltEquations[i].Correct_y;
			const correct_intercept = BuiltEquations[i].Correct_const / BuiltEquations[i].Correct_y;
			const student_slope = [];
			const student_intercept = [];

			student_slope[i] = -BuiltEquations[i].Student_x / BuiltEquations[i].Student_y;
			student_intercept[i] = BuiltEquations[i].Student_const / BuiltEquations[i].Student_y;

			if (student_slope[i] === correct_slope && student_intercept[i] === correct_intercept) {
				// Correct Solution
				overall_student_score[i] = 1;
			} else {
				if (BuiltEquations[i].Student_x === BuiltEquations[i].Correct_x) {
					// Correct X
					x_score[i] = 0.33;
				} else {
					// Incorrect X
					x_score[i] = 0;
					currentMessage = "orders"; // Changed feedback here
				}

				if (BuiltEquations[i].Student_y === BuiltEquations[i].Correct_y) {
					// Correct Y
					y_score[i] = 0.33;
				} else {
					// Incorrect Y
					y_score[i] = 0;
					currentMessage = "orders"; // Changed feedback here
				}

				if (BuiltEquations[i].Student_const === BuiltEquations[i].Correct_const) {
					// Correct Constant
					const_score[i] = 0.33;
				} else {
					// Incorrect Constant
					const_score[i] = 0;
					currentMessage = "budget"; // Changed feedback here
				}

				overall_student_score[i] = x_score[i] + y_score[i] + const_score[i];

				if (
					BuiltEquations[i].Student_x === BuiltEquations[i].Correct_y &&
					BuiltEquations[i].Student_y === BuiltEquations[i].Correct_x
				) {
					// X and Y components are swapped but correct
					overall_student_score[i] = 0.5;
					currentMessage = "mixup"; // Changed feedback here
				}
			}
			if (currentMessage !== "") {
				BuiltEquations[i].message = giveFeedback("equation", currentMessage);
			} else {
				BuiltEquations[i].message = "";
			}
		}

		const overall_student_score_matrix = [overall_student_score];
		const full_matrix = BuiltEquations.map((obj, i) => ({
			...obj,
			equation_build_score: overall_student_score_matrix[0][i],
		}));

		return full_matrix;
	}

	/**
	 * @param {Object} equations | Object containing the equations to be solved
	 * @returns {Object} | correct x and y values for the equation
	 */
	function solveSimultaneous2D(equations) {
		// STEP 1:
		const x_coeff = [equations.Eq1_x, equations.Eq2_x];
		const y_coeff = [equations.Eq1_y, equations.Eq2_y];
		const const_coeff = [equations.Eq1_const, equations.Eq2_const];

		const eliminator = [];
		eliminator[0] = [];
		eliminator[1] = [];
		let x_variable;
		let y_variable;
		// STEP 2:
		eliminator[0][0] = y_coeff[1] * x_coeff[0];
		eliminator[0][1] = y_coeff[1] * const_coeff[0];
		// STEP 3:
		eliminator[1][0] = y_coeff[0] * x_coeff[1];
		eliminator[1][1] = y_coeff[0] * const_coeff[1];

		try {
			// STEPS 4, 5:
			x_variable = (eliminator[0][1] - eliminator[1][1]) / (eliminator[0][0] - eliminator[1][0]);
			// STEP 6:
			y_variable = (const_coeff[0] - x_coeff[0] * x_variable) / y_coeff[0];

			return { x: x_variable, y: y_variable };
		} catch (ex) {
			console.error("Error: ", ex);
		}
	}

	function calculateEquationSolvingScore(equations) {
		// "6x + y = 18, 4x + y = 14"
		// Correct Solution: x = 2, y = 6

		let message = "";

		const equationsResult = solveSimultaneous2D(equations); // correct x and y values for the equation

		console.log("equationsResult:", equationsResult);
		console.log("equations:", equations);

		let score = 0;

		// Changed here
		// I've wrapped the existing code in this if statement to
		// give feedback when both values are wrong.
		if (equationsResult.x !== equations.Student_x && equationsResult.y !== equations.Student_y) {
			message += giveFeedback(solve_activity_type, "bothValues");
		} else {
			if (equationsResult.x === equations.Student_x) score++; // increment score if x is correct
			else message += giveFeedback(solve_activity_type, "xValue");
			if (equationsResult.y === equations.Student_y) score++; // increment score if y is correct
			else message += giveFeedback(solve_activity_type, "yValue");
		}

		return { score: score, message: message };
	}

	function calculatePackingScore(Data) {
		let message = "";
		const x_student = Data.Student_x;
		const x_answer = Data.Answer_x;
		const x_score = 1 / (1 + Math.abs(x_student - x_answer));

		const y_student = Data.Student_y;
		const y_answer = Data.Answer_y;
		const y_score = 1 / (1 + Math.abs(y_student - y_answer));

		// Changed here
		// I've wrapped the existing code in this if statement to
		// give feedback when both values are wrong.
		if (y_score < 1 && x_score < 1) {
			message = giveFeedback("packing", "bothProduce");
		} else {
			if (x_score < 1) message += giveFeedback("packing", "produceX");
			if (y_score < 1) message += giveFeedback("packing", "produceY");
		}

		const score_packing = 0.5 * x_score + 0.5 * y_score;

		const scorePackingObject = { score: score_packing, message: message };

		return scorePackingObject;
	}

	return (
		<ProducePackerSubmitButton
			accessibilitySettings={accessibilitySettings}
			showResultModal={showResultModal}
			onClickCallback={calculateScore}
		/>
	);
}
