import { useEffect, useState } from "react";

export default function ({ showResultModal, onClickCallback, accessibilitySettings, screensize }) {
	const blackAndWhite = accessibilitySettings?.display.blackAndWhite;

	const time = 1000;
	const [timeoutId, setTimeoutId] = useState(null);
	const [isHeld, setIsHeld] = useState(false);

	// Get the size of the screen and set the height accordingly
	let height;
	if (window.innerWidth >= 1024) {
		height = "2%";
	} else {
		height = "42%";
	}
	const [top, setTopPosition] = useState(height);

	// Add event listener for updating the screen size when the window is resized
	useEffect(() => {
		window.addEventListener("resize", () => {
			// get the simulationwindow element
			const simulationWindow = document.getElementById("simulationWindow");
			// Get the bottom position of the element
			const buttonBottomPosition = simulationWindow.getBoundingClientRect().bottom;
			// Set the bottom position of the element
			const position = window.innerHeight - buttonBottomPosition * 0.98;
			setTopPosition(`${position}px`);
		});
	}, []);

	const handleMouseDown = () => {
		setIsHeld(true);
		const id = setTimeout(() => {
			onClickCallback();
		}, time);
		setTimeoutId(id);
	};

	const handleMouseUp = () => {
		clearTimeout(timeoutId);
		setTimeoutId(null);
		setIsHeld(false);
	};

	const handleMouseLeave = () => {
		clearTimeout(timeoutId);
		setTimeoutId(null);
		setIsHeld(false);
	};

	useEffect(() => {
		const button = document.querySelector(".calculate_score_button");

		const handleKeyDown = event => {
			if ((event.key === "Enter" || event.key === " ") && !isHeld) {
				setIsHeld(true);
				const id = setTimeout(() => {
					onClickCallback();
				}, time);
				setTimeoutId(id);
			}
		};

		const handleKeyUp = event => {
			if (event.key === "Enter" || event.key === " ") {
				clearTimeout(timeoutId);
				setTimeoutId(null);
				setIsHeld(false);
			}
		};

		button.addEventListener("keydown", handleKeyDown);
		button.addEventListener("keyup", handleKeyUp);

		return () => {
			button.removeEventListener("keydown", handleKeyDown);
			button.removeEventListener("keyup", handleKeyUp);
		};
	}, [isHeld, timeoutId]);

	return (
		<button
			type="button"
			className={`calculate_score_button absolute right-[1.5%] m-0 w-[15%] h-[10%] min-w-fit p-2 min-h-[5vw] text-white font-bold overflow-hidden border border-3 border-white text-base cursor-pointer transition-transform duration-1000 ease-linear select-none ${
				blackAndWhite ? "bg-black" : "bg-[#E6650F]"
			} ${isHeld ? "hold" : ""}`}
			style={{ bottom: top }}
			disabled={showResultModal}
			onMouseDown={handleMouseDown}
			onMouseUp={handleMouseUp}
			onMouseLeave={handleMouseLeave}
		>
			Deliver
		</button>
	);
}
