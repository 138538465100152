import React from "react";


// Define props interface to specify the expected type of 'gif'
interface LoadingScreenProps {
  gif: string;  // gif is expected to be a string
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({ gif }) => {
  return (
    <div className="absolute w-full h-full flex justify-center items-center z-30 bg-[#fbb3a4]" aria-label="Simulation is loading">
      <img className="w-1/2 h-auto max-w-full max-h-full" src={gif} alt="loading..." />
    </div>
  );
};

export default LoadingScreen;
