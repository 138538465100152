import React from 'react';

/*
    Label Dropdown

    This is a custom component for the custom drawer, is a label component next to a dropdown component. This can be used to 
    display a label next to a dropdown field, where the dropdown field can be used to select data. Example of usage is in the 
    EntitySettings component where the user can select the ui template of the game.
*/

import "./LabelDropdown.css";
const LabelDropdown = ({ label, options, value, onChange }) => {
    return (
        <div id="label-dropdown__container">
            <label id="label-dropdown__container__label">
                {label}
            </label>

            <div id="label-dropdown__container__right">
                <div id="label-dropddown__dropdown">
                    <button id="label-dropddown__button" type="button">{value}</button>
                    <div id="label-dropddown__dropdown-content">
                        {options.map((option, index) => (
                            <button 
                                key={index} 
                                className="label-dropddown__dropdown-content__button"
                                onClick={() => onChange(option)}
                                type="button"
                            >
                                {option}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LabelDropdown;