import { useOktaAuth } from "@okta/okta-react";
import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ component: Component }) => {
	const { authState } = useOktaAuth();
	if (!authState) return <div>Loading...</div>;

	return authState.isAuthenticated ||
		process.env?.REACT_APP_AUTHENTICATION ||
		window.APP_ENV?.REACT_APP_AUTHENTICATION === "false" ? (
		<Component />
	) : (
		<Navigate to="/login" />
	);
};

export default ProtectedRoute;
