import AuthorToolsHeader from "../../components/AuthorToolsHeader/AuthorToolsHeader";

import { observer } from "mobx-react-lite";
import React, { useRef, useState, useEffect } from "react";
import CreateAxiosInstance from "../../AxiosInstance/AxiosInstance.jsx";
import CreateS3AxiosInstance from "../../AxiosInstance/s3AxiosInstance.jsx";
import ActivitySelector from "../../components/ActivityLayoutTab/ActivitySelector/ActivitySelector";
import ActivitySettings from "../../components/ActivityLayoutTab/ActivitySettings/ActivitySettings";
import EntityLibrary from "../../components/EntityBuilder/EntityLibrary/EntityLibrary";
import EntitySettings from "../../components/ActivityLayoutTab/EntitySettings/EntitySettings";
import GridSettings from "../../components/ActivityLayoutTab/GridSettings/GridSettings";
import SceneEntities from "../../components/ActivityLayoutTab/SceneEntities/SceneEntities";
import UiTemplate from "../../components/ActivityLayoutTab/UiTemplate/UiTemplate";
import SimulationWindow from "../../components/SimulationWindow/SimulationWindow.jsx";
import CollapseDiv from "../../components/UIBuilder/CollapseDiv/CollapseDiv";
import CustomDrawer from "../../components/UIBuilder/CustomDrawer/CustomDrawer";
import { activityLayoutStore } from "./ActivityLayoutStore.js";

// import "./ActivityLayoutTab.css";
// import "./ActivityLayoutTab.css";

global.activityLayoutStore = activityLayoutStore; // make the store global so it can be accessed from the console and simulation windows

const ActivityLayoutTab = observer(() => {
	/* ACTIVITY SELECTOR INFO */
	const activities = activityLayoutStore.json.length ? [1] : Object.keys(activityLayoutStore.json); //if activity layout store is defined, get the keys, else return an array with 1 element
	const [selectedActivity, setSelectedActivity] = useState(activities[0]);
	const axios = CreateAxiosInstance();
	const s3Axios = CreateS3AxiosInstance();
	const addActivity = () => {
		// Adds a new activity to the activity layout store json
		activityLayoutStore.addActivity();
		activityLayoutStore.setJsonUnsavedChanges(true);
	};

	const deleteActivity = async () => {
		// Deletes an activity from the activity layout store json, changes the current activity context to the previous one
		const activityToDelete = activityLayoutStore.currentActivityContext;
		const activityToDeleteIndex = Object.keys(activityLayoutStore.json).indexOf(activityToDelete.toString());

		let newSelectedActivity;

		if (activityToDeleteIndex === 0) {
			// if the first activity is being deleted
			if (Object.keys(activityLayoutStore.json).length === 1) {
				// if there's only one activity
				console.warn("Cannot delete the first activity, when there's only one activity");
				return;
			}
			newSelectedActivity = Number(Object.keys(activityLayoutStore.json)[1]); // select the next activity
		} else {
			newSelectedActivity = Number(Object.keys(activityLayoutStore.json)[activityToDeleteIndex - 1]); // select the previous activity, if not the first activity being deleted
		}

		await activityLayoutStore.switchActivity(newSelectedActivity); // switch to the new selected activity
		activityLayoutStore.deleteActivity(activityToDelete); // delete the activity
		activityLayoutStore.setJsonUnsavedChanges(true);
	};

	const saveActivity = input => {
		// Save the activity layout store json to the S3 bucket
		// Disable the save button while saving
		// const currentColor = input.target.style.backgroundColor;
		input.target.style.backgroundColor = "grey";
		input.target.disabled = true;

		axios
			.put(
				`/activityLayout?projectName=${activityLayoutStore.currentProjectContext}&gameName=${activityLayoutStore.currentGameContext}`,
				activityLayoutStore.json,
			)
			.then(response => {
				console.log(response);
				// Re-enable the save button
				// input.target.style.backgroundColor = currentColor; #3291ff
				input.target.style.backgroundColor = "#3291ff";
				input.target.disabled = false;
				activityLayoutStore.setJsonUnsavedChanges(false);
			})
			.catch(error => {
				console.error(error);
				// Re-enable the save button
				input.target.style.backgroundColor = "red"; //set the button to red if there's an error
				input.target.disabled = false;
			});
	};

	/* ACTIVITY SETTINGS INFO */
	const activitySettings = (
		<ActivitySettings selectedActivity={selectedActivity} setSelectedActivity={setSelectedActivity} />
	);

	/* UI TEMPLATES INFO */
	const [templates, setTemplates] = useState([]);
	const [selectedUITemplate, setSelectedUITemplate] = useState(null); 

	useEffect(() => {
        async function fetchFiles() {
            try {
                // get the secure s3 link from the API server
                const uiLayoutLink = await axios.get(`http://localhost:8080/ui/getUILayout/${activityLayoutStore.currentProjectContext}/${activityLayoutStore.currentGameContext}`);

                // get the ui layout file from s3
                const uiLayoutFile = await axios.get(uiLayoutLink.data);

				// make keys into array of strings
				const keys = Object.keys(uiLayoutFile.data).map(key => key.toString());

                setTemplates(keys);
            } catch (error) {
                console.error("Error fetching UI Builder Template Library file:", error);
            }
        };
        fetchFiles();
    }, []);

	useEffect(() => {
		if (templates[0]) setSelectedUITemplate(templates[0]);
		else setSelectedUITemplate("");
    }, [templates]);

	useEffect(() => {
		activityLayoutStore.setUiTemplate(selectedUITemplate);
    }, [selectedUITemplate]);

	const uiTemplateSettings = (
		<UiTemplate
			templates={templates}
			selectedUITemplate={selectedUITemplate}
			setSelectedUITemplate={setSelectedUITemplate}
		/>
	);

	/* GRID SETTINGS INFO */
	const gridSettings = <GridSettings selectedActivity={selectedActivity} setSelectedActivity={setSelectedActivity} />;

	/* ENTITY SETTINGS INFO */
	const [selectedEntity, setSelectedEntity] = useState(null); // TODO: This is the selected entity in the scene
	const entitySettings = <EntitySettings selectedEntity={selectedEntity} setSelectedEntity={setSelectedEntity} />;

	/* ENTITY LIBRARY INFO */
	const [entities, setEntities] = useState([]); // TODO: This is the list of entities in the scene
	useEffect(() => {
		// fetch entities
		axios
			.get(`/entities/all/${activityLayoutStore.currentProjectContext}/${activityLayoutStore.currentGameContext}/`)
			.then(response => {
				setEntities(response.data.data);
				axios.get(`/entities/jsons/${activityLayoutStore.currentProjectContext}/${activityLayoutStore.currentGameContext}/`).then(response => {
					//console.log(response);
					// Cycle through the response data and get the json for each entity from s3
					const entityJsons = response.data.data;
					entityJsons.forEach(entityJson => {
						// get the json from s3
						s3Axios
							.get(entityJson)
							.then(response => {
								const jsonObject = JSON.parse(response.data);
								activityLayoutStore.addEntityToEntityLibrary(jsonObject);
							})
							.catch(error => {
								console.error(error);
							});
					});
				});
			})
			.catch(error => {
				console.error(error);
			});
	}, []);
	const [selectedEntityEntityLibrary, setSelectedEntityEntityLibrary] = useState(null);
	// arrow for for event handler
	const handleClick = item => {
		setSelectedEntityEntityLibrary(item);
		// Set the selected entity
		activityLayoutStore.setEntityToBeAdded(item);
	};
	const entityLibrary = (
		<EntityLibrary entities={entities} selectedEntity={selectedEntityEntityLibrary} setSelectedEntity={handleClick} />
	);

	/* SCENE INVENTORY INFO */
	// using activity layout store data to populate scene entities
	const sceneEntities = (
		<SceneEntities
			entities={Object.keys(activityLayoutStore.json[activityLayoutStore.currentActivityContext].entities)}
			selectedEntity={activityLayoutStore.currentEntityContext}
			setSelectedEntity={entitySelected => activityLayoutStore.selectEntity(entitySelected)}
		/>
	);

	/* INIT DRAWER INFO */

	const drawerTabInfo = [
		{
			name: "Activity Settings",
			key: "activity-settings",
			tabWidth: "50%",
			content: (
				<>
					<CollapseDiv fillComponents={activitySettings} title={"Activity Settings"} />
					<CollapseDiv fillComponents={uiTemplateSettings} title={"UI Template"} />
					<CollapseDiv fillComponents={gridSettings} title={"Grid Settings"} />
				</>
			),
		},
		{
			name: "Entities",
			key: "entities",
			tabWidth: "30%",
			content: (
				<>
					<CollapseDiv fillComponents={entitySettings} title={"Entity Settings"} />
					<CollapseDiv fillComponents={entityLibrary} title={"Entity Library"} />
					<CollapseDiv fillComponents={sceneEntities} title={"Scene Entities"} />
				</>
			),
		},
	];

	// const activityLayoutWorspaceDiv = useRef(null);
	const [isSimulationLoaded, setIsSimulationLoaded] = useState(false);
	useEffect(() => {
	  const timer = setTimeout(() => setIsSimulationLoaded(true), 1000); // Simulate async load
  
	  return () => clearTimeout(timer);
	}, []);

	return (
		<div className="h-full">
			<AuthorToolsHeader tab={"activity-layout"} />
			<div className="flex">
				<div id="activity-layout-tab__activity-selecter" className="absolute w-16 h-[95vh] bg-color-bua-background-light">
					<ActivitySelector
						buttons={activities}
						selectedActivity={activityLayoutStore.currentActivityContext.toString()}
						setSelectedActivity={input => {
							activityLayoutStore.switchActivity(Number(input));
						}}
						addActivity={addActivity}
						deleteActivity={deleteActivity}
					/>
				</div>
			</div>
			<div className="absolute h-full w-11/12 left-16" id="activity-layout-tab__drawer__work-space__container">
				<SimulationWindow 
					simulationMode="enabledV2"
					useLocalRepo={window.localStorage.getItem("useLocalEngineRepo")}
					engineBuildVersion={window.localStorage.getItem("engineBuildVersion")}
					className="h-full w-full"
				/>
				{isSimulationLoaded && <CustomDrawer drawerTabInfo={drawerTabInfo} workspaceDivRef={"simulationWindow"} resizeableSim={true}/>}
				<button className="absolute h-12 w-24 bottom-24 right-12 border-0 rounded-lg bg-color-bua-button-primary text-color-bua-text-light text-base hover:shadow-2xl border-color-bua-text-light hover:border-2" id="activity-layout-tab__save-button">Save</button>

			</div>
		</div>
	  );
});

export default ActivityLayoutTab;
