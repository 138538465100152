import React from "react";
import { activityLayoutStore } from "../../../pages/ActivityLayoutTab/ActivityLayoutStore";
import "./ActivitySettings.css";
function DirectionalLightInput({ labelName, positionInputInfo, colorInputInfo, id }) {
	return (
		<div className="bg-color-bua-background-light p-4" id="directional-light-input">
			<div id="directional-light-input__container-title">
				<label id="directional-light-input__container-title__label">
					{labelName}
				</label>
				<button className="bg-color-bua-button-delete rounded-lg w-10 h-8 text-lg" id="directional-light-input__container-title__button"
					type="button"
					onClick={() => activityLayoutStore.deleteDirectionalLight(id)}
				>
					x
				</button>
			</div>

			<div id="directional-light-input__container-body-position">
				<h3>Position</h3>
				{positionInputInfo.map((input, index) => (
					<input
						key={index}
						id={`inputField${index}`}
						type={input.type || "text"}
						defaultValue={input.inputDefault}
						onChange={e => input.inputCallback(e.target.value)}
						// className="directional-light-input__container-body-position__input"
						className='bg-color-bua-background-light text-sm text-color-bua-text-light border-2 border-color-bua-background rounded-lg w-1/2 h-7 px-2 text-center'
					/>
				))}
			</div>
			<div id="directional-light-input__container-body-color">
				<h3>Color</h3>
				<input
					type={colorInputInfo[0].type || "color"}
					defaultValue={colorInputInfo[0].inputDefault}
					onChange={e => activityLayoutStore.setDirectionLightColor(id, e.target.value)}
					className='bg-color-bua-background-light text-sm text-color-bua-text-light border-2 border-color-bua-background rounded-lg w-1/2 h-16 px-2 text-center cursor-pointer'
					id="directional-light-input__container-body-color__input"
				/>
			</div>
		</div>
	);
}

export default DirectionalLightInput;
