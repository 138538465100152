import { useEffect, useState } from 'react'
import folderIcon from '../../../assets/images/bua/FolderIcon'
import FolderIcon from '../../../assets/images/bua/FolderIcon';
import arrowRightIcon from '../../../assets/images/bua/arrow-right.png';
import cogIcon from '../../../assets/images/bua/cog.png';
import entityIcon from '../../../assets/images/bua/entity_icon.png';
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";

const FileTreeNode = ({ item, level, addGameCallback, addEntityCallback, project, game }) => {
    const [isExpanded, setIsExpanded] = useState(false)

    const toggleExpand = () => {
        if (item.type === 'project' || item.type === 'game') {
            setIsExpanded(!isExpanded)
        }
    }

    const indentLevels = {
        0: "pl-[0px]",
        1: "pl-[16px]",
        2: "pl-[32px]",
        3: "pl-[48px]",
    }

    const indent = indentLevels[level] || 0

    return (
        <div className='hover:cursor-pointer'>
            <div
                type="button"
                className={`group flex items-center px-2 text-color-bua-text-light ${indent}`}
                onClick={(e) => { toggleExpand(); item.onClickCallback?.(e) }}
                onKeyDown={(e) => { toggleExpand(); item.onClickCallback?.(e) }}
            >
                {(item.type === 'project' || item.type === "game") && (
                    <span className="mr-1">
                        <img src={arrowRightIcon} alt="arrow toggle" className={`w-4 h-4 transform duration-300 ${isExpanded ? "rotate-90" : ""}`} />
                    </span>
                )}
                {
                    item.type === 'project' ? <div className="w-4 h-auto"><FolderIcon startColor={'#A387FF'} endColor={'#7750F9'} /></div>
                        : item.type === 'game' ? <div className="w-4 h-auto"><FolderIcon /></div>
                            : item.type === 'entity' ? <img src={entityIcon} alt="entity icon" className="w-6 h-auto" />
                                : null
                }
                <span className="ml-2 text-lg sm:text-xl">{item.name}</span>
                {item.settingsCallback && (
                    <button
                        className='invisible group-hover:visible w-6 h-6 sm:w-8 sm:h-8'
                        type="button"
                        onClick={(e) => {
                            e.stopPropagation();
                            item.settingsCallback(item.name);
                        }}
                    >
                        <img
                            src={cogIcon}
                            alt="settings icon"
                            className="w-auto h-auto"
                        />
                    </button>
                )}
            </div>
            {(item.type === 'project' || item.type === "game") && isExpanded && item.children && (
                <div
                    className={`${indentLevels[level + 1]}`}
                >
                    {item.children.map((child, index) => (
                        <FileTreeNode key={index} item={child} level={level + 1} project={item.type === 'project' ? item.name : project ? project : null} game={item.type === 'game' ? item.name : null} addEntityCallback={addEntityCallback} />
                    ))}
                </div>
            )}
            {item.type === "project" && isExpanded && (
                <div className={`${indentLevels[level + 1]}`}>
                    <button
                        type="button"
                        className={"hover:opacity-75 hover:cursor-pointer rounded-md text-color-bua-text-light text-bold bg-color-bua-button-primary w-32 m-2 text-md sm:text-lg"}
                        onClick={() => {
                            addGameCallback(item.name);
                        }}
                    >
                        Add Game
                    </button>
                </div>
            )}
            {item.type === "game" && isExpanded && (
                <div className={`${indent}`}>
                    <button
                        type="button"
                        className={"hover:opacity-75 hover:cursor-pointer rounded-md text-color-bua-text-light text-bold bg-color-bua-button-primary w-32 m-2 text-md sm:text-lg"}
                        onClick={() => {
                            addEntityCallback(project, item.name);
                        }}
                    >
                        Add Entity
                    </button>
                </div>
            )}
        </div>
    )
}

export default function FileTree({ data, dataLoading = false, addProjectCallback, addGameCallback, addEntityCallback }) {

    const [filteredData, setFilteredData] = useState(data);

    const handleSearch = (search) => {
        const filtered = data.filter((item) => {
            if (item.name.toLowerCase().includes(search.toLowerCase())) {
                return true;
            }
            if (item.children) {
                const childFiltered = item.children.filter((child) => child.name.toLowerCase().includes(search.toLowerCase()));
                return childFiltered.length > 0;
            }
            return false;
        });
        setFilteredData(filtered);
    }

    useEffect(() => {
        setFilteredData(data);
    }, [data]);

    return (
        <>
            <div className="border-2 border-gray-200 rounded-md flex flex-col min-h-32 max-h-full">
                <input
                    placeholder='Search...'
                    className='w-full bg-color-bua-background border-b-2 border-gray-200 px-2 py-1 text-color-bua-text-light text-md sm:text-lg'
                    onChange={(e => { handleSearch(e.target.value) })}
                />
                {dataLoading &&
                    <div className='self-center justify-self-center w-10 h-10 py-6'>
                        <LoadingSpinner />
                    </div>
                }
                {!dataLoading && filteredData.map((item, index) => (
                    <FileTreeNode key={index} item={item} level={0} addGameCallback={addGameCallback} addEntityCallback={addEntityCallback} />
                ))}
                {!dataLoading && (
                    <button
                        type="button"
                        className="hover:opacity-75 rounded-md hover:cursor-pointer text-color-bua-text-light text-bold bg-color-bua-button-primary w-36 m-2 px-2 text-md sm:text-lg"
                        onClick={() => {
                            addProjectCallback();
                        }}
                    >
                        Create Project
                    </button>
                )}
            </div>
        </>
    )
}