import React from 'react';
import LabelInput from './LabelInput/LabelInput';

/*
    Label Input

    This is a custom component for the custom drawer, that groups multiple LabelInput components together. This is used in the
    Activuty Layout Tab for the Entity Settings component, where the user can see 3 label input components, posiiton rottaion and scale.
*/
const LabelInputList = ({ labelInfoContents }) => {
  return (
    <div>
      {Array.isArray(labelInfoContents) && labelInfoContents.map((inputItem, index) => (
        <LabelInput key={index} labelName={inputItem.labelName} inputInfo={inputItem.inputInfo} />
      ))}
    </div>
  );
};

export default LabelInputList;