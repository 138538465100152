import React from 'react';
import "./components.css";

/*
    Checkbox Label

    This is a custom component for the custom drawer, checkbox component next to a label component. Example of usage is in the 
    Activity Layout Tab where the user can toggle the grid on and off.
*/

function CheckboxLabel({ labelName, checkboxDefault, checkboxCallback}) {
    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', height: '26px', boxSizing: 'border-box', gap: '12px', margin: '16px 8px'}}>
            <input 
                id="checkboxField" 
                type="checkbox" 
                defaultChecked={checkboxDefault} 
                onChange={e => checkboxCallback(e.target.checked)} 
            />
            <label id="labelField">
                {labelName}
            </label>
        </div>
    );
}

export default CheckboxLabel;