import { useEffect, useState } from "react";
import { OrderAcceptTick } from "./SVGIcons";

/**
 * The order message tab is a functional component that displays the order message in full
 * @param {Object} props - the props passed down to this component
 * @param {String} props.text - the message text to be displayed
 * @param {Integer} props.orderID - the order ID
 * @param {Function} props.setStudentActivityAttribute - the function to set the student activity attribute
 * @param {Integer} props.acceptedOrders - the number of accepted orders
 * @param {String} props.colourPallete - the colour pallete to be used - normal or blackAndwhite
 * @param {Object} props.activityData - the activity data
 * @param {Function} props.playSound - the function to play a sound - used for playing the 'click' sound
 * @param {Object} props.accessibilitySettings - the accessibility settings
 * @param {String} props.selectedCharacter - the selected character
 * @returns
 */
function OrdersMessage({
	text,
	orderID,
	setStudentActivityAttribute,
	acceptedOrders,
	colourPallete,
	activityData,
	playSound,
	accessibilitySettings,
	selectedCharacter,
}) {
	// Equation is order ID + 1
	const equationNumber = orderID + 1;
	const otherEquationNumber = equationNumber === 1 ? 2 : 1;

	// State variable for tracking the state of the order - accepted or not
	const [orderState, setOrderState] = useState(activityData.studentData[`equation${equationNumber}`].orderAccepted);

	// Plain text version of the text without html elements for screen readers (ARIA announcement)
	let plainText = text.replace(/<[^>]*>/g, "");
	plainText = `Message: ${plainText}`;

	let disabled = false; // Variable for disabling the accept button if the produce is not the same in the the current order as in the acceted order

	const [styledHtml, setStyledHtml] = useState(""); // State variable to store the styled html which is displayed

	/**
	 * This function parses through the order text and adds inline style to the spans to highlight the relevant words
	 * @param {String} inputText - the html input text
	 * @param {String} color - the highlight color for the text in HEX - (#xxxxxx)
	 * @returns
	 */
	const addSpanClasses = (inputText, color) => {
		// Regular expression to find existing <span> tags or targeted words/phrases
		const regex = /<span[^>]*>.*?<\/span>|oranges|apples|\$\d+/g;

		// Replace matching parts with styled <span> tags
		return inputText.replace(regex, match => {
			// If match already contains a <span>, check for the 'style' attribute
			if (match.startsWith("<span")) {
				// If the <span> already has a style attribute, replace it
				if (match.match(/style=['"][^'"]*['"]/)) {
					return match.replace(/style=['"][^'"]*['"]/, `style="color:${color};"`);
				} else {
					// If the <span> doesn't have a style, add one
					return match.replace(/<span/, `<span style="color:${color};"`);
				}
			}
			// Wrap other matches with <span> and apply dynamic inline style
			return `<span style="color:${color};">${match}</span>`;
		});
	};

	// Use effect which updated the styling of the message when the highlight color changes in the accessibility menu
	useEffect(() => {
		setStyledHtml(addSpanClasses(text, accessibilitySettings.display.highlightColor)); // fixed property name here
	}, [accessibilitySettings.display.highlightColor, text]);

	// If the produce values are not the same, disable the button - this is to ensure valid simulataneous equations
	if (
		activityData.studentData[`equation${otherEquationNumber}`].produceA !==
			activityData.studentData[`equation${equationNumber}`].produceA ||
		activityData.studentData[`equation${otherEquationNumber}`].produceB !==
			activityData.studentData[`equation${equationNumber}`].produceB
	) {
		disabled = true;
	}

	/**
	 * Function to handle when the order button is clicked
	 */
	const handleOrderClick = () => {
		playSound("click"); // Play the click sound
		setStudentActivityAttribute("orderAccepted", true, equationNumber);
		setOrderState(true);
	};

	/**
	 * Function to handle when the order is cancelled
	 */
	const handleCancelOrder = () => {
		playSound("click"); // Play the click sound
		setStudentActivityAttribute("orderAccepted", false, equationNumber);
		setOrderState(false);
	};

	return (
		<div id="order-message" className="flex pt-[7%] flex-col h-[81%] lg:pt-[15%] flex-shrink-0 ">
			{/* Order Content */}
			<div className="px-[.625rem] py-[1.25rem] max-h-[60%]">
				<div
					style={{
						backgroundColor: colourPallete === "blackAndWhite" ? "#9199A2" : "#0179CA",
					}}
					className={`flex  text-white p-[0.625rem] px-4 flex-col justify-center overflow-auto items-start rounded-[0.9375rem] ${
						colourPallete === "blackAndWhite" ? "bg-[#9199A2]" : "bg-[#1877D4]"
					}`}
				>
					<div className="text-[1.375rem] font-bold leading-normal">{selectedCharacter}</div>
					<div
						id="order-message-container__top__contents"
						// biome-ignore lint/a11y/noNoninteractiveTabindex: <explanation>
						tabIndex={0}
						aria-label={plainText}
						// biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
						dangerouslySetInnerHTML={{ __html: styledHtml }}
					/>
				</div>
			</div>
			{/* Order Buttons */}
			<div
				id="order-message-container__bottom"
				className="flex w-full flex-row flex-shrink-0 px-[1.25rem] py-[0.75rem] min-h-[3rem] lg:h-[19%] lg:flex-wrap lg:p-[0.625rem] mt-auto lg:px-[0.9375rem] justify-between items-center content-center gap-y-[0.375rem] border-t-2 border-[#ACACAC] bg-white"
			>
				<div className="h-[1.5rem] w-[50%] lg:w-[100%] min-w-[16rem] ">
					<h3
						className="text-black/90 text-[1.25rem] font-bold leading-normal "
						// biome-ignore lint/a11y/noNoninteractiveTabindex: <explanation>
						tabIndex={0}
						aria-label={`Orders Accepted ${acceptedOrders} out of two`}
					>
						Orders accepted: {acceptedOrders} / 2
					</h3>
				</div>

				<div className="flex w-full text-center justify-around">
					{/* Cancel Button */}
					{orderState ? (
						<button
							type="button"
							className="flex text-white p-[0.375rem] px-[0.625rem] items-center gap-[0.8125rem] max-h-[2.2rem] min-w-[4rem] rounded-[0.3125rem] bg-[#6C6C6C] shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] cursor-pointer"
							onClick={handleCancelOrder}
							aria-label="Cancel Order"
						>
							<p className="text-[1.125rem] font-bold leading-normal">Redo</p>
						</button>
					) : (
						// Accept Button
						<button
							className={`${
								colourPallete === "blackAndWhite" ? "bg-black" : "bg-[#1877D4]"
							} flex p-[0.375rem] px-[0.625rem] text-white items-center gap-[0.8125rem] max-h-[2.2rem] min-w-[7rem] rounded-[0.3125rem] shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] cursor-pointer ${
								disabled ? "opacity-50" : "opacity-100"
							}`}
							type="button"
							onClick={handleOrderClick}
							aria-label={"Accept Order"}
							disabled={disabled}
						>
							{/* Tick SVG -- mOVE INTO SVG */}
							<p className="text-[1.125rem] font-bold leading-normal">Accept Order</p>
							<OrderAcceptTick />
						</button>
					)}
				</div>
			</div>
		</div>
	);
}

export default OrdersMessage;
