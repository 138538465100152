import React from "react";

export default function MenuIcon() {
    return (
        <svg width="100%" height="100%" viewBox="0 0 1080 1080" version="1.1" style={{ fillRule: 'evenodd', clipRule: 'evenodd' }}>
            <title>Menu Icon</title>
            <g transform="matrix(11.3058,0,0,11.3058,42.5427,87.7661)">
                <circle cx="44" cy="40" r="38.5" style={{ fill: 'rgb(0,167,228)', stroke: 'white', strokeWidth: '3px' }} />
            </g>
            <g transform="matrix(11.3058,0,0,11.3058,34.0286,87.7661)">
                <path d="M37.101,22.371C35.21,22.371 33.675,23.891 33.675,25.778C33.675,27.666 35.21,29.186 37.101,29.186L63.436,29.186C65.328,29.186 66.862,27.666 66.862,25.778C66.862,23.891 65.328,22.371 63.436,22.371L37.101,22.371ZM37.101,36.908C35.21,36.908 33.675,38.428 33.675,40.315C33.675,42.202 35.21,43.722 37.101,43.722L63.436,43.722C65.328,43.722 66.862,42.202 66.862,40.315C66.862,38.428 65.328,36.908 63.436,36.908L37.101,36.908ZM37.101,51.444C35.21,51.444 33.675,52.964 33.675,54.852C33.675,56.739 35.21,58.259 37.101,58.259L63.436,58.259C65.328,58.259 66.862,56.739 66.862,54.852C66.862,52.964 65.328,51.444 63.436,51.444L37.101,51.444Z" style={{ fill: 'white', fillRule: 'nonzero', stroke: 'white', strokeWidth: '1px' }} />
            </g>
            <g transform="matrix(1,0,0,1,-36.9338,40.6836)">
                <circle cx="352.523" cy="334.839" r="50.733" style={{ fill: 'white' }} />
            </g>
            <g transform="matrix(1,0,0,1,-36.9338,205.161)">
                <circle cx="352.523" cy="334.839" r="50.733" style={{ fill: 'white' }} />
            </g>
            <g transform="matrix(1,0,0,1,-36.9338,369.638)">
                <circle cx="352.523" cy="334.839" r="50.733" style={{ fill: 'white' }} />
            </g>
        </svg>
    );
}
