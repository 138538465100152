import FolderIcon from '../../assets/images/bua/FolderIcon';
import cogIcon from '../../assets/images/bua/cog.png';

export default function FolderItem({
    folderName,
    gradientStartColor = "#A387FF",
    gradientEndColor = "#7750F9",
    onClickCallback
}) {
    return (
        <div className="group flex flex-col w-full h-full relative">
            <button
                type="button"
                className="w-full h-full hover:opacity-75 active:opacity-50"
                onClick={onClickCallback ?? null}
            >
                <FolderIcon startColor={gradientStartColor} endColor={gradientEndColor} />
                <h3 className="text-color-bua-text-light text-2xl sm:text-lg md:text-base lg:text-xl xl:text-2xl text-center">{folderName}</h3>
            </button>
        </div>
    )
}