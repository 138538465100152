import CharacterContainer from "./CharacterContainer";

/**
 * EquationsTab component
 * @param {Object} props - the props passed down to this component
 * @param {Function} props.setStudentActivityAttribute - the function to set the student activity attribute
 * @param {Object} props.studentActivityData - the student activity data
 * @param {Array} props.orderActivityData - the order activity data
 * @param {String} props.colourPallete - the colour pallete to be used - normal or blackAndwhite
 * @returns
 * */
function EquationsTab({ setStudentActivityAttribute, studentActivityData, orderActivityData, colourPallete }) {
	// Get the orders from the student activity data
	const order1 = orderActivityData[0];
	const order2 = orderActivityData[1];

	// Get the fruit options from the student activity data based on what orders the student accepted - assumes the orders has the same fruit
	const fruitOptions = [];
	fruitOptions.push(order1.produceA);
	fruitOptions.push(order1.produceB);

	// Function to set the fruit assignment variable
	function handleVarAssignment(variable, e) {
		setStudentActivityAttribute(variable, e.target.value);
	}

	return (
		<div className="flex p-[0.75rem] h-[81%] px-[0.625rem] flex-col justify-between items-center flex-shrink-0 rounded-br-[1.25rem] bg-white">
			{/* Variable assignment */}
			<div className="flex lg:flex-col shrink-0 h-[20%] min-h-[3.2rem] w-full justify-center">
				{/* Variable X */}
				<div className="flex w-[40%] lg:w-[100%] justify-center lg:justify-start py-[0.375rem] px-0 flex-row gap-[0.625rem] shrink-0">
					<label
						htmlFor="options"
						className="text-black text-center text-[1.25rem] font-bold leading-none flex items-center justify-center"
					>
						X :
					</label>
					<select
						id="options"
						className="text-black text-center text-[1.25rem] font-bold leading-none inline-flex p-[0.375rem_0.625rem] items-center gap-[0.75rem] rounded-[0.3125rem] border-[3px] border-[#797979] bg-[#D9D9D9]"
						onChange={e => handleVarAssignment("varX", e)}
						value={studentActivityData.varX}
					>
						<option value={""}>Select</option>
						{fruitOptions.map(option => (
							<option key={option} value={option}>
								{option}
							</option>
						))}
					</select>
				</div>

				{/* Variable Y */}
				<div className="flex w-[40%] lg:w-[100%] justify-center lg:justify-start py-[0.375rem] px-0 flex-row gap-[0.625rem] shrink-0">
					<label
						htmlFor="options"
						className="text-black text-center text-[1.25rem] font-bold leading-none flex items-center justify-center"
					>
						Y :
					</label>
					<select
						id="options"
						className="text-black text-center text-[1.25rem] font-bold leading-none inline-flex p-[0.375rem_0.625rem] items-center gap-[0.75rem] rounded-[0.3125rem] border-[3px] border-[#797979] bg-[#D9D9D9]"
						onChange={e => handleVarAssignment("varY", e)}
					>
						<option value={null}>Select</option>
						{fruitOptions.map(option => (
							<option key={option} value={option}>
								{option}
							</option>
						))}
					</select>
				</div>
			</div>

			{/* Character containers */}
			<CharacterContainer
				character={order1.character}
				border={"4px dashed #F9A366"}
				borderDark={"4px dashed #232121"}
				colourPallete={colourPallete}
				studentActivityData={studentActivityData}
				equation={1}
				setStudentActivityAttribute={setStudentActivityAttribute}
			/>
			<CharacterContainer
				character={order2.character}
				border={"4px solid #00A7E4"}
				borderDark={"4px solid #D9D9D9"}
				colourPallete={colourPallete}
				studentActivityData={studentActivityData}
				equation={2}
				setStudentActivityAttribute={setStudentActivityAttribute}
			/>
		</div>
	);
}
export default EquationsTab;
