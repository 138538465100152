import React, { useState, useContext } from "react";

import AppContext from "../../components/AppContext/AppContext";
import BlocklyGeneratorSettings from "../../components/BlocklyGenerator/BlocklyGeneratorSettings/BlocklyGeneratorSettings";
import CollapsibleTextBox from "../../components/BlocklyGenerator/CollapsibleTextBox/CollapsibleTextBox";
import FooterButtons from "../../components/BlocklyGenerator/FooterButtons/FooterButtons";
import Slider from "../../components/BlocklyGenerator/Slider/Slider";
import DesignToolsHeader from "../../components/DesignToolsHeader/DesignToolsHeader";
import "./BlocklyGenerator.css";

const BlocklyGenerator = () => {

	const localContext = useContext(AppContext);
	console.log("BlocklyGenerator " + localContext.projectName, localContext.gameName, localContext.entityName)

	const [dragging, setDragging] = useState(false);
	const [width, setWidth] = useState(50);

	const handleMouseDown = () => {
		setDragging(true);
	};

	const handleMouseUp = () => {
		setDragging(false);
	};

	const handleMouseMove = event => {
		if (dragging) {
			const containerWidth = event.currentTarget.parentElement.offsetWidth;
			const mouseX = event.clientX;
			const newWidth = (mouseX / containerWidth) * 100;
			setWidth(newWidth);
		}
	};
	return (
		<>
			<DesignToolsHeader tab={"blockly-generator"} />
			<div className="blockly-generator" onMouseMove={handleMouseMove} onMouseUp={handleMouseUp}>
				<div className="blockly-generator__container--left-side" style={{ width: `${width}%`, minWidth: "28%" }}>
					<BlocklyGeneratorSettings />
				</div>
				<div className="divider" onMouseDown={handleMouseDown} style={{ width: "5px" }} />
				<div className="blockly-generator__container--right-side" style={{ width: `${100 - width}%`, minWidth: "28%" }}>
					<div className="blockly-generator__container--simulation-window">Simulation Window</div>
					<div className="blockly-generator__container--blank">Blank Space</div>
					<div className="blockly-generator__container--footer-buttons">
						<FooterButtons />
					</div>
				</div>
			</div>
		</>
	);
};

export default BlocklyGenerator;
