import React, { useEffect } from "react";
import { useState } from "react";
import ColorInput from "../../../ColorInput/ColorInput";
import Dropdown from "../../../Dropdown/Dropdown";
import Slider from "../../../Slider/Slider";
import ToggleSwitch from "../../../ToggleSwitch/ToggleSwitch";

const DisplaySettingsTab = ({ accessibilitySettings, setAccessibilityAttribute }) => {
	const fontFamily = ["Inter", "Comic Neue"];
	const visibilityToggle = ["Visible", "Hide"];

	const [BlackAndWhiteVal, setBlackAndWhiteVal] = useState(accessibilitySettings.display.blackAndWhite);
	const [HighContrastVal, setHighContrastVal] = useState(accessibilitySettings.display.highContrast);
	const [OutOfFocusWindowDimmingVal, setOutOfFocusWindowDimmingVal] = useState(
		accessibilitySettings.display.outOfFocusDimOpacity,
	);
	const blackAndWhite = accessibilitySettings.display.blackAndWhite;

	/**
	 * Function to handle font change
	 * @param {Object} e - event object
	 * @returns {void}
	 */
	function handleFontChange(newValue) {
		setAccessibilityAttribute("display", "font", newValue);
	}

	/**
	 * Function to handle highlight color change
	 * @param {Object} e - event object
	 * @returns {void}
	 */
	function handleHighlightColorChange(newValue) {
		setAccessibilityAttribute("display", "highlightColor", newValue);
	}

	/**
	 * Function to handle tooltip highlight color change
	 * @param {Object} e - event object
	 */
	function handleTooltipHighlightColorChange(newValue) {
		setAccessibilityAttribute("display", "tooltipHighlightColor", newValue);
	}

	/**
	 * Function to handle highlight color change
	 * @param {Object} e - event object
	 * @returns {void}
	 */
	function handleToggleNonInteractableEntitiesVisibility(newValue) {
		//eslint-disable-next-line
		jsonInterpreter.toggleIsInteractable(newValue === "Visible");
		setAccessibilityAttribute("display", "visibleNonInteractableEntities", newValue);
	}

	/**
	 * Function to handle highlight color change
	 * @param {Object} e - event object
	 * @returns {void}
	 */
	function handleOutOfFocusWindowDimming(newValue) {
		//eslint-disable-next-line
		const sliderValue = newValue / 100; // convert to decimal
		setAccessibilityAttribute("display", "outOfFocusDimOpacity", 1 - sliderValue);
		setOutOfFocusWindowDimmingVal(1 - sliderValue);
	}

	/**
	 * Toggles the black and white display mode for accessibility.
	 *
	 * @param {Event} e The event triggered by the toggle action.
	 */
	function handleBlackAndWhiteToggle(newValue) {
		setAccessibilityAttribute("display", "blackAndWhite", newValue);
		setBlackAndWhiteVal(newValue);
	}

	/**
	 *
	 * @param {*} e
	 */
	function handleHighContrastToggle(newValue) {
		setAccessibilityAttribute("display", "highContrast", newValue);
		setHighContrastVal(newValue);
		// eslint-disable-next-line no-undef
		if (engine) {
			// eslint-disable-next-line no-undef
			engine.toggleHighContrastMode(newValue);
		}
	}

	useEffect(() => {
		setBlackAndWhiteVal(accessibilitySettings.display.blackAndWhite);
		setHighContrastVal(accessibilitySettings.display.highContrast);
		setOutOfFocusWindowDimmingVal(accessibilitySettings.display.outOfFocusDimOpacity);
	}, [
		accessibilitySettings.display.blackAndWhite,
		accessibilitySettings.display.highContrast,
		accessibilitySettings.display.outOfFocusDimOpacity,
	]);

	const fontOptions = fontFamily.map(font => {
		return {
			key: font,
			value: font,
		};
	});

	const visibilityOptions = visibilityToggle.map(op => {
		return {
			key: op,
			value: op,
		};
	});

	return (
		<div className="w-full max-w-[580px] h-full p-2 flex flex-col gap-4 overflow-x-hidden">
			<Dropdown
				label="Font"
				options={fontOptions}
				defaultValue={accessibilitySettings.display.font}
				onChange={handleFontChange}
				colorMode="light"
			/>
			<ColorInput
				label="Highlight Color"
				defaultValue={accessibilitySettings.display.highlightColor}
				onChange={handleHighlightColorChange}
			/>
			<ColorInput
				label="Simulation Highlight Color"
				defaultValue={accessibilitySettings.display.tooltipHighlightColor}
				onChange={handleTooltipHighlightColorChange}
			/>
			<Dropdown
				label="Toggle Non-Interactable Entities Visibility"
				options={visibilityOptions}
				defaultValue={accessibilitySettings.display.visibleNonInteractableEntities}
				onChange={handleToggleNonInteractableEntitiesVisibility}
				colorMode="light"
			/>
			<Slider
				label="Out-of-focus Window Dimming"
				onChange={handleOutOfFocusWindowDimming}
				value={100 - OutOfFocusWindowDimmingVal * 100}
				min={0}
				max={100}
				blackAndWhite={blackAndWhite}
			/>
			<ToggleSwitch
				label="Black and White"
				onChange={handleBlackAndWhiteToggle}
				initialState={BlackAndWhiteVal}
				colorMode="light"
			/>
			<ToggleSwitch
				label="High Contrast"
				onChange={handleHighContrastToggle}
				initialState={HighContrastVal}
				colorMode="light"
			/>
		</div>
	);
};
export default DisplaySettingsTab;
