import { useEffect, useState } from "react";
import { MessageProfilePictureRelative } from "./MessageProfilePicture";

/**
 * The Order component is a functional component that displays a single order on the phone screen
 * @param {Object} props - the props passed down to this component
 * @param {String} props.name - the name of the character
 * @param {Integer} props.friendshipScore - the friendship score of the character
 * @param {String} props.text - the text of the order
 * @param {Boolean} props.isAlternate - boolean to determine if the order is an alternate order -for styling purposes
 * @param {Function} props.orderCallback - the function to call when the order is clicked
 * @param {String} props.colourPallete - the colour pallete to be used - normal or blackAndwhite
 * @param {Object} props.accessibilitySettings - the accessibility settings
 * @returns
 */
function Order({ name, friendshipScore, text, isAlternate, orderCallback, colourPallete, accessibilitySettings }) {
	const [styledHtml, setStyledHtml] = useState(""); // State variable to store the styled html which is displayed

	/**
	 * This function parses through the order text and adds inline style to the spans to highlight the relevant words
	 * @param {String} inputText - the html input text
	 * @param {String} color - the highlight color for the text in HEX - (#xxxxxx)
	 * @returns
	 */
	const addSpanClasses = (inputText, color) => {
		// Regular expression to find existing <span> tags or targeted words/phrases
		const regex = /<span[^>]*>.*?<\/span>|oranges|apples|\$\d+/g;

		// Replace matching parts with styled <span> tags
		return inputText.replace(regex, match => {
			// If match already contains a <span>, check for the 'style' attribute
			if (match.startsWith("<span")) {
				// If the <span> already has a style attribute, replace it
				if (match.match(/style=['"][^'"]*['"]/)) {
					return match.replace(/style=['"][^'"]*['"]/, `style="color:${color};"`);
				} else {
					// If the <span> doesn't have a style, add one
					return match.replace(/<span/, `<span style="color:${color};"`);
				}
			}
			// Wrap other matches with <span> and apply dynamic inline style
			return `<span style="color:${color};">${match}</span>`;
		});
	};

	// Use effect which updated the styling of the message when the highlight color changes in the accessibility menu
	useEffect(() => {
		setStyledHtml(addSpanClasses(text, accessibilitySettings.display.highlightColor)); // fixed property name here
	}, [accessibilitySettings.display.highlightColor, text]);

	return (
		<button
			type="button"
			onClick={orderCallback}
			id="single-order__container"
			className={`w-full h-auto border-none box-border flex items-center p-[0.625rem] px-[0.8125rem] gap-[0.625rem] self-stretch cursor-pointer ${
				isAlternate ? "bg-[#DDDFE2]" : "bg-[#FFFFFFFF]"
			}`}
			aria-label={`Order from ${name} with a friendship score of ${friendshipScore}`}
		>
			{/* Profile picture with relative positioning */}
			<div className="w-[15%] lg:w-[20%] min-w-[4.2rem] flex justify-center items-center h-full">
				<MessageProfilePictureRelative
					name={name}
					friendshipScore={friendshipScore}
					friendshipStarOrientation={"left"}
					colourPallete={colourPallete}
				/>
			</div>

			<div
				id="phone-ui__order__text-box"
				className="w-[85%] lg:80% h-[5.125rem]  overflow-hidden text-ellipsis text-left"
			>
				{/* User name */}
				<h3
					id="phone-ui__order__title"
					className="text-[1.125rem] font-bold leading-none text-black justify-center  h-[1.5rem] flex-shrink-0"
				>
					{name}
				</h3>
				{/* The order text - uses Tailwind plugin called lineclamp for clamping the number of lines display */}
				<p
					id="phone-ui__order__text"
					className="text-black overflow-hidden text-ellipsis text-[1rem] font-medium leading-[150%] h-[3rem] line-clamp-2"
					dangerouslySetInnerHTML={{ __html: styledHtml }}
				/>
			</div>
		</button>
	);
}

/**
 * The OrdersTab component is a functional component that displays the orders on the phone screen
 * @param {Object} props - the props passed down to this component
 * @param {Array} props.orders - the orders to be displayed
 * @param {Function} props.setCurrentScreen - the function to set the current screen which is displayed
 * @param {Function} props.setTitleText - the function to set the title text for the header
 * @param {Function} props.setTitleImageSrc - the function to set the title image source for the header
 * @param {Function} props.setFriendshipScore - the function to set the friendship score of the character for the order
 * @param {Function} props.setOrderTextID - the function to set the order text ID
 * @param {String} props.colourPallete - the colour pallete to be used - normal or blackAndwhite
 * @param {Function} props.playSound - the function to play a sound - used for 'click' sound when an order is clicked
 * @param {Object} props.accessibilitySettings - the accessibility settings
 * @param {Function} props.setSelectedCharacter - the function to set the selected character
 * @returns
 */
function OrdersTab({
	orders,
	setCurrentScreen,
	setTitleText,
	setTitleImageSrc,
	setFriendshipScore,
	setOrderTextID,
	colourPallete,
	playSound,
	accessibilitySettings,
	setSelectedCharacter,
}) {
	const appStyle = {}; // Appstyle - REDUNDANT

	/**
	 * Function to handle when an order is clicked on
	 * @param {String} name  - the name of the character whos order has been clicked on
	 * @param {Integer} friendshipScore - the level of friendship with the character
	 * @param {Integer} id - the id of the order
	 */
	const handleOrderClick = (name, friendshipScore, id) => {
		setCurrentScreen("orders-message");
		setSelectedCharacter(name);
		setTitleText(null);
		setTitleImageSrc(name);
		setFriendshipScore(friendshipScore);
		setOrderTextID(id);
		playSound("click");
	};

	return (
		<div style={appStyle}>
			{orders.map((order, index) => (
				<Order
					key={order.name}
					name={order.name}
					friendshipScore={order.friendshipScore}
					text={order.text}
					isAlternate={index % 2 !== 0} // For alternate styling
					orderCallback={() => handleOrderClick(order.name, order.friendshipScore, index)}
					colourPallete={colourPallete} // black and white or colour
					accessibilitySettings={accessibilitySettings}
				/>
			))}
		</div>
	);
}

export default OrdersTab;
