import { OktaAuth } from "@okta/okta-auth-js";

// Load environment variables before initializing OktaAuth

async function loadEnvVariables() {
	try {
		const host = `${window.location.protocol}//${window.location.host}`;
		const response = await fetch(`${host}/settings/env.json`);
		const appEnv = await response.json();
		window.APP_ENV = appEnv; // Attach fetched environment variables to window
	} catch (error) {
		console.error("Failed to load environment variables:", error);
	}
}

// Initialize OktaAuth instance
async function initializeOktaAuth() {
	let oktaAuthInstance;
	if (!oktaAuthInstance) {
		await loadEnvVariables();

		const oktaConfig = {
			clientId: process.env?.REACT_APP_CLIENT_ID || window.APP_ENV?.REACT_APP_CLIENT_ID,
			issuer: process.env?.REACT_APP_ISSUER || window.APP_ENV?.REACT_APP_ISSUER,
			redirectUri: `${window.location.origin}/login/callback`,
			pkce: true,
			scopes: ["openid", "profile", "email"],
		};

		oktaAuthInstance = new OktaAuth(oktaConfig);
	}
	return oktaAuthInstance;
}

// This makes sure we return the initialized instance immediately when imported
const oktaAuthInstance = await initializeOktaAuth();
export default oktaAuthInstance;
