const SkulptSettings = () => {

    return (
        <>
            <div className="skulpt-settings-container">
                skulpt-settings-container
            </div>
        </>
    );
};
export default SkulptSettings;