export const blackAndWhite = {

    // App Dash Icon
    appDashBackground: "#D9D9D9",
    appDashBackgroundSelected: "#9199A2",
    appDashStroke: "#232121",

    // Graph Expand Icon
    graphExpandBackground: "#D9D9D9",
    graphExpandBackgroundSelected:  "#9199A2",

    graphLine1: "#D9D9D9",
    graphPoint1: "#D9D9D9",

    graphLine2: "#232121",
    graphPoint2: "#232121",

    // Alebra Dialogie Colours 
    algebraBackground1: "#D9D9D9",
    algebraBackground2: "#9199A2",

    // Friendship Star
    friendshipCircle: "#9199A2", 
    friendshipStar: "#232121",

}