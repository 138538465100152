import React, { forwardRef } from 'react';

const Container = forwardRef(({ x, y, w, h, name, color }, ref) => {

    const colors = {
        green: 'bg-green-400',
        red: 'bg-red-400',
        blue: 'bg-blue-400',
    }

    const bgColor = colors[color];

    return (
        <div
            ref={ref}
            name={`${name}`}
            className={`absolute ${bgColor}`}
            style={{
                left: `${x}px`,
                top: `${y}px`,
                width: `${w}px`,
                height: `${h}px`,
            }}
        />
    );
});

export default Container;