import React from "react";
import "./components.css";

/*
    Label Input

    This is a custom component for the custom drawer, is a label component next to an input component. This can be used to 
    display a label next to an input field, where the input field can be used to input data. Example of usage is in the 
    EntitySettings component where the user can input the positionan entity. To create a list of elements of this type, use
    the LabelInputList component.
*/

function InputColour({ labelName, inputInfo }) {
	return (
		<div id="input-colour__container">
			<label id="input-colour__container__label">
				{labelName}
			</label>
			{inputInfo.map((input, index) => (
				<input className = "input-colour__container__input"
					key={index}
					id={`inputField${index}`}
					type="color"
					defaultValue={input.inputDefault}
					onChange={e => input.inputCallback(e.target.value)}
				/>
			))}
		</div>
	);
}

export default InputColour;
