import React from "react";
import { useEffect, useState } from "react";
import AudioSlider from "../../../AudioSlider/AudioSlider";
import ToggleSwitch from "../../../ToggleSwitch/ToggleSwitch";

const AudioSettingsTab = ({
	accessibilitySettings,
	setAccessibilityAttribute,
	mutedSoundSettings,
	setMutedSettings,
}) => {
	// State variable for value of sliders
	const [masterVolumeVal, setMasterValVolume] = useState(accessibilitySettings.audio.masterVolume);
	const [musicVolumeVal, setMusicVolumeVal] = useState(accessibilitySettings.audio.musicVolume);
	const [sfxVolumeVal, setSFXVolumeVal] = useState(accessibilitySettings.audio.sfxVolume);
	const [uiVolumeVal, setUIVolumeVal] = useState(accessibilitySettings.audio.uiVolume);
	const [mute, setMute] = useState(accessibilitySettings.audio.mute);
	const blackAndWhite = accessibilitySettings.display.blackAndWhite;

	/**
	 * Function to set music volume
	 * @param {Object} e - event object
	 * @returns {void}
	 */
	function setMusicVolume(value) {
		setAccessibilityAttribute("audio", "musicVolume", value);
		setMusicVolumeVal(value);
	}

	/**
	 * Function to set master volume
	 * @param {Object} e - event object
	 * @returns {void}
	 */
	function setMasterVolume(value) {
		setAccessibilityAttribute("audio", "masterVolume", value);
		setMasterValVolume(value);
	}

	/**
	 * Function to set SFX volume
	 * @param {Object} e - event object
	 * @returns {void}
	 */
	function setSFXVolume(value) {
		setAccessibilityAttribute("audio", "sfxVolume", value);
		setSFXVolumeVal(value);
	}

	/**
	 * Function to set UI volume
	 * @param {Object} e - event object
	 * @returns {void}
	 */
	function setUIVolume(value) {
		setAccessibilityAttribute("audio", "uiVolume", value);
		setUIVolumeVal(value);
	}

	/**
	 * Function to handle mute
	 * @param {Object} e - event object
	 */
	function handleMute(newValue) {
		setAccessibilityAttribute("audio", "mute", newValue);
		setMute(newValue);
		setMutedSettings();
		if (newValue) {
			setMutedSettings();
			setAccessibilityAttribute("audio", "masterVolume", 0);
			setAccessibilityAttribute("audio", "musicVolume", 0);
			setAccessibilityAttribute("audio", "sfxVolume", 0);
			setAccessibilityAttribute("audio", "uiVolume", 0);
		} else {
			// Get the muted settings from the local
			setAccessibilityAttribute("audio", "masterVolume", mutedSoundSettings.masterVolume);
			setAccessibilityAttribute("audio", "musicVolume", mutedSoundSettings.musicVolume);
			setAccessibilityAttribute("audio", "sfxVolume", mutedSoundSettings.sfxVolume);
			setAccessibilityAttribute("audio", "uiVolume", mutedSoundSettings.uiVolume);
		}
	}

	function handleStereoSound(newValue) {
		const stereoSound = newValue;

		console.log("Use Stereo Sound: ", stereoSound);
	}

	// Want to use useEffect to update the volume values when they change
	useEffect(() => {
		setMasterValVolume(accessibilitySettings.audio.masterVolume);
		setMusicVolumeVal(accessibilitySettings.audio.musicVolume);
		setSFXVolumeVal(accessibilitySettings.audio.sfxVolume);
		setUIVolumeVal(accessibilitySettings.audio.uiVolume);
		setMute(accessibilitySettings.audio.mute);
	}, [
		accessibilitySettings.audio.masterVolume,
		accessibilitySettings.audio.musicVolume,
		accessibilitySettings.audio.sfxVolume,
		accessibilitySettings.audio.uiVolume,
		accessibilitySettings.audio.mute,
	]);

	return (
		<div className="w-full  h-full p-2 flex flex-col gap-4">
			<AudioSlider
				label={"Master Volume"}
				min={0}
				max={100}
				value={masterVolumeVal}
				disabled={mute}
				onChange={setMasterVolume}
			/>
			<AudioSlider
				label="Music Volume"
				disabled={mute}
				min={0}
				max={100}
				onChange={setMusicVolume}
				value={musicVolumeVal}
			/>
			<AudioSlider label="SFX Volume" disabled={mute} min={0} max={100} onChange={setSFXVolume} value={sfxVolumeVal} />
			<AudioSlider label="UI Volume" disabled={mute} min={0} max={100} onChange={setUIVolume} value={uiVolumeVal} />

			<div className="p-2">
				<div className="w-1/4">
					<ToggleSwitch label="Mute" onChange={handleMute} initialState={mute} colorMode="light" />
				</div>
			</div>
		</div>
	);
};

export default AudioSettingsTab;
