import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import CreateAxiosInstance from "../../AxiosInstance/AxiosInstance";
import CreateS3AxiosInstance from "../../AxiosInstance/s3AxiosInstance";

// Component to display the simulation window
export default function SimulationWindow({
	simulationMode,
	width = "100%",
	height = "100%",
	style,
	useLocalRepo = false,
	engineBuildVersion = "Latest",
	displayAccessibilityMenu,
	accessibilitySettings,
}) {
	// Two state variables for storing the main export script and the content file script
	const engineFileRef = useRef("");
	const contentFileRef = useRef("");
	const [scriptsLoaded, setScriptsLoaded] = useState(false);
	const APIaxios = CreateAxiosInstance();
	global.APIaxios = APIaxios;
	const S3Axios = CreateS3AxiosInstance();
	if (useLocalRepo === null) {
		useLocalRepo = true;
	}

	useEffect(() => {
		// Fetch script content from the API endpoint
		const fetchFiles = async () => {
			if (useLocalRepo.toString() === "true") {
				try {
					// get the engine script from local bua-engine repo running on port 5173
					const engineScript = await axios.get("http://localhost:5173/export/buaEngine.js");
					console.log(engineScript);
					// set the engine file state to the returned script
					engineFileRef.current = engineScript.data;
				} catch (error) {
					console.error("Error fetching engine build file:", error);
				}
				try {
					// get the content script from local bua-engine repo running on port 5173
					const contentScript = await axios.get(`http://localhost:5173/content/${simulationMode}/${simulationMode}.js`);
					// set the content file state to the returned script
					contentFileRef.current = contentScript.data;
				} catch (error) {
					console.error("Error fetching content file:", error);
				}
			} else {
				try {
					let engineFileLink;
					if (engineBuildVersion !== "Latest") {
						// get the secure s3 link from the API server
						engineFileLink = await APIaxios.get(`/engineBuilds/getSpecificEngineBuild/${engineBuildVersion}`);
					} else {
						// get the secure s3 link from the API servers
						engineFileLink = await APIaxios.get("/engineBuilds/getLatest");
					}
					// get the engine script from s3
					const engineScript = await S3Axios.get(engineFileLink.data);
					// set the engine file state to the returned script
					engineFileRef.current = engineScript.data;
				} catch (error) {
					console.error("Error fetching engine build file:", error);
				}
				try {
					// get the secure s3 link from the API server
					const contentFileLink = await APIaxios.get(`/simulationModes/get${simulationMode}`);
					// get the content script from s3
					const contentScript = await S3Axios.get(contentFileLink.data);
					// set the content file state to the returned script
					contentFileRef.current = contentScript.data;
				} catch (error) {
					console.error("Error fetching content file:", error);
				}
			}
			// Set scriptsLoaded to true after both engineFile and contentFile have been loaded
			setScriptsLoaded(true);
		};
		fetchFiles(simulationMode, useLocalRepo, engineBuildVersion);
	}, [simulationMode, useLocalRepo, engineBuildVersion]); // call this function again if simulation mode is updated
	useEffect(() => {
		// Don't do anything until both engineFile and contentFile have been loaded
		if (!scriptsLoaded) return;
		const appendScript = async () => {
			// Create a script HTML element and put it in the simulationWindow div
			if (engineFileRef.current) {
				const scriptElement = document.createElement("script");
				scriptElement.type = "module";
				scriptElement.textContent = engineFileRef.current;
				scriptElement.id = "engineScript";
				document.getElementById("simulationWindow").appendChild(scriptElement);
			}
			// Delay to allow for the engine to load
			setTimeout(() => {
				if (contentFileRef.current) {
					// Creating script
					const scriptElement = document.createElement("script");
					// Set the script content to the content file
					scriptElement.textContent = contentFileRef.current;
					// Set the language to JavaScript
					scriptElement.language = "javascript";
					// Set the type of script to JavaScript
					scriptElement.type = "text/javascript";
					// Set async to true
					scriptElement.async = "async";
					document.getElementById("simulationWindow").appendChild(scriptElement); // Add the script to the simulationWindow div
					try {
						// eslint-disable-next-line no-undef
						main(APIaxios);
					} catch (error) {
						console.log(error);
					}
				}
			}, 750); // 750 milliseconds delay
		};
		appendScript(); // Call the function

		// Add mouseover event listener to the simulation window
		document.getElementById("simulationWindow").addEventListener("mouseover", event => {
			// eslint-disable-next-line no-undef
			if (displayAccessibilityMenu) return;
			if (!document.getElementById("phone--body")) return;
			// Set phone overlay grey div opacity to make it visible (greys out phone component)
			// eslint-disable-next-line no-undef
			document.getElementById("phone--body").style.opacity = accessibilitySettings.display.outOfFocusDimOpacity ?? 0.4;
			// Set simulation window opacity to make it visible
			document.getElementById("simulationWindow").style.opacity = 1;
		});

		return () => {
			// Clean up function to remove the script elements
			console.log("Cleaning up");
			// eslint-disable-next-line no-undef
			try {
				// eslint-disable-next-line no-undef
				tearDown();
			} catch (error) {
				console.log("errror timre");
				console.log(error);
			}
			const engineScript = document.getElementById("engineScript");
			const contentScript = document.getElementById("contentScript");
			if (engineScript) {
				engineScript.remove();
			}
			if (contentScript) {
				contentScript.remove();
			}
		};
	}, [scriptsLoaded]);

	return <div tabIndex="-1" style={style} className="simulationWindow" id="simulationWindow" />;
}
