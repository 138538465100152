import emptyStar from "../../assets/images/ProducePacker/emptyStar.svg";
import fullStar from "../../assets/images/ProducePacker/fullStar.svg";
import halfFilledStar from "../../assets/images/ProducePacker/halfFilledStar.svg";

export default function StarRating({
    score
}) {

    // Map the score to a 5-star rating system, rounding to the nearest 0.5
    const mappedScore = Math.round(score * 10) / 2;

    // array to hold the image elements
    const starElements = [];

    for (let i = 1; i <= 5; i++) {
        // Determine which image to use based on the score
        const starImg = mappedScore >= i ? fullStar : mappedScore >= i - 0.5 ? halfFilledStar : emptyStar;

        starElements.push(
            <img
                key={i}
                className="w-1/6"
                src={starImg}
                alt="star"
            />
        )
    }

    return (
        <div className="flex flex-row sm:gap-2">
            {starElements}
        </div>
    )
}