import React from 'react';

export default function MuteIcon({ color = "black" }) {
    return (
        <svg width="100%" height="100%" viewBox="0 0 37 22" version="1.1" style={{ fillRule: "evenodd", clipRule: "evenodd" }}>
            <title>Mute Icon</title>
            <g transform="matrix(1,0,0,1,-28.4326,-36.9633)">
                <g transform="matrix(1,0,0,1,35.6826,36.4634)">
                    <path d="M8.432,7.786L8.432,14.429C8.432,14.705 8.208,14.929 7.932,14.929L1,14.929C0.724,14.929 0.5,14.705 0.5,14.429L0.5,7.786C0.5,7.51 0.724,7.286 1,7.286L7.932,7.286C8.208,7.286 8.432,7.51 8.432,7.786Z" style={{ fill: color, stroke: color, strokeWidth: "1px" }} />
                </g>
                <g transform="matrix(1,0,0,1,35.6826,36.4634)">
                    <path d="M1.97,11.89C1.721,11.69 1.721,11.31 1.97,11.11L11.226,3.671C11.553,3.408 12.039,3.641 12.039,4.06L12.039,18.94C12.039,19.359 11.553,19.592 11.226,19.329L1.97,11.89Z" style={{ fill: color, fillRule: "nonzero", stroke: color, strokeWidth: "1px" }} />
                </g>
                <g transform="matrix(1,0,0,1,35.6826,36.4634)">
                    <path d="M22,8.42L16.16,15.099" style={{ fill: color, fillRule: "evenodd", stroke: color, strokeWidth: "2px", strokeLinecap: "round" }} />
                </g>
                <g transform="matrix(1,0,0,1,35.6826,36.4634)">
                    <path d="M22,15.099L16.16,8.42" style={{ fill: color, fillRule: "nonzero", stroke: color, strokeWidth: "2px", strokeLinecap: "round" }} />
                </g>
            </g>
        </svg>
    );
}