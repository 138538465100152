import React from 'react';
import LabelDropdown from "../../UIBuilder/CustomDrawer/components/LabelDropdown/LabelDropdown";

const UITemplate = ({templates, selectedUITemplate, setSelectedUITemplate}) => {
  
    return (
      <LabelDropdown
        label="UI Template"
        options={templates}
        value={selectedUITemplate}
        onChange={setSelectedUITemplate}
      />
    );
}

export default UITemplate;