import ButtonList from "../../UIBuilder/CustomDrawer/components/ButtonList";

const SceneEntities = ({ entities, selectedEntity, setSelectedEntity }) => {
    return (
        <ButtonList 
            items={entities} 
            selectedButton={selectedEntity} 
            setSelectedButton={setSelectedEntity} 
        />
    );
};

export default SceneEntities;