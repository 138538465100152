import React from "react";
import useFetch from "../../hooks/useFetch";

import { FriendShip } from "./SVGIcons";
import { blackAndWhite } from "./colors";

const MessageProfilePictureRelative = ({
	name,
	friendshipScore,
	colourPallete,
	friendshipStarOrientation = "right",
	width = "25%",
}) => {
	// fetch the character image
	const {
		data: characterImage,
		loading,
		error,
	} = useFetch(
		`assets/images/Math Arcade/Produce Packer/character${name}${colourPallete === "blackAndWhite" ? "BW" : ""}.webp`,
	);

	if (loading) {
		return <div>Loading...</div>;
	}

	return (
		<div
			className="w-[5.2rem] h-[4.4rem] box-border bg-contain bg-no-repeat bg-center relative"
			style={{ backgroundImage: `url(${characterImage})` }}
		>
			{friendshipStarOrientation !== "none" &&
				(friendshipStarOrientation === "right" ? (
					<FriendShip
						color={colourPallete === "blackAndWhite" ? blackAndWhite.friendshipCircle : undefined}
						strokeColor={colourPallete === "blackAndWhite" ? blackAndWhite.friendshipStar : undefined}
						score={friendshipScore}
						style={{ width: "50%", position: "absolute", top: "35%", left: "50%" }}
					/>
				) : (
					<FriendShip
						color={colourPallete === "blackAndWhite" ? blackAndWhite.friendshipCircle : undefined}
						strokeColor={colourPallete === "blackAndWhite" ? blackAndWhite.friendshipStar : undefined}
						score={friendshipScore}
						style={{ width: "50%", position: "absolute", top: "35%", left: "-5%" }}
					/>
				))}
		</div>
	);
};

const MessageProfilePictureAbsolute = ({
	imageSrc,
	friendshipScore,
	colourPallete,
	profilePicWidth = "25%",
	profilePicHeight = "auto",
	profilePicTop = "0%",
	profilePicLeft = "auto",
	friendshipStarWidth = "10%",
	friendshipStarHeight = "auto",
	friendshipStarTop = "55%",
	friendshipStarLeft = "55%",
}) => {
	const {
		data: characterImage,
		loading,
		error,
	} = useFetch(
		`assets/images/Math Arcade/Produce Packer/character${imageSrc}${
			colourPallete === "blackAndWhite" ? "BW" : ""
		}.webp`,
	);

	if (loading) {
		return <div>Loading...</div>;
	}

	return (
		<div id="friendship-container__absolute">
			<img
				src={characterImage}
				alt="Profile icon"
				style={{
					position: "absolute",
					width: profilePicWidth, // You can also use these props to customize
					height: profilePicHeight,
					top: profilePicTop,
					left: profilePicLeft,
					transform: "translate(0%, -50%)",
				}}
			/>
			<FriendShip
				score={friendshipScore}
				color={colourPallete === "blackAndWhite" ? blackAndWhite.friendshipCircle : undefined}
				strokeColor={colourPallete === "blackAndWhite" ? blackAndWhite.friendshipStar : undefined}
				style={{
					position: "absolute",
					width: friendshipStarWidth,
					height: friendshipStarHeight,
					top: friendshipStarTop,
					left: friendshipStarLeft,
					transform: "translate(-50%, -50%)",
				}}
			/>
		</div>
	);
};

export { MessageProfilePictureRelative, MessageProfilePictureAbsolute };
