import AuthorToolsHeader from "../../components/AuthorToolsHeader/AuthorToolsHeader";

const CodeTab = () => {
    return (
        <div>
            <AuthorToolsHeader tab={"code"} />
            <div className="code-tab-container">Code tab will go here</div>
        </div>
    );
};

export default CodeTab;
