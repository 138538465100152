/**
 * Deep clones a value, including objects, arrays, dates, regular expressions, maps, and sets.
 *
 * @param {*} value - The value to be deep cloned.
 * @param {Map} [seen] - A map to keep track of already cloned values to avoid circular references.
 * @returns {*} - The deep cloned value.
 */
function deepClone(value, seen = new Map()) {
	if (value === null || typeof value !== "object") {
		return value;
	}

	if (seen.has(value)) {
		return seen.get(value);
	}

	if (Array.isArray(value)) {
		const arr = [];
		seen.set(value, arr);
		for (let i = 0; i < value.length; i++) {
			arr[i] = deepClone(value[i], seen);
		}
		return arr;
	}

	if (value instanceof Date) {
		return new Date(value);
	}

	if (value instanceof RegExp) {
		return new RegExp(value);
	}

	if (value instanceof Map) {
		const map = new Map();
		seen.set(value, map);
		value.forEach((v, k) => {
			map.set(k, deepClone(v, seen));
		});
		return map;
	}

	if (value instanceof Set) {
		const set = new Set();
		seen.set(value, set);
		for (const v of value) {
			set.add(deepClone(v, seen));
		}
		return set;
	}

	const obj = {};
	seen.set(value, obj);
	for (const key in value) {
		if (Object.prototype.hasOwnProperty.call(value, key)) {
			obj[key] = deepClone(value[key], seen);
		}
	}
	return obj;
}

export default deepClone;
