import { Button } from "@mantine/core";
import React, { useState, useEffect, useRef } from "react";
import CreateAxiosInstance from "../../AxiosInstance/AxiosInstance";
import CreateS3AxiosInstance from "../../AxiosInstance/s3AxiosInstance";

import DesignToolsHeader from "../../components/DesignToolsHeader/DesignToolsHeader";
import CollapseDiv from "../../components/UIBuilder/CollapseDiv/CollapseDiv";
import CustomDrawer from "../../components/UIBuilder/CustomDrawer/CustomDrawer";
import ButtonItem from "../../components/UIBuilder/CustomDrawer/components/ButtonItem";
import ButtonList from "../../components/UIBuilder/CustomDrawer/components/ButtonList";
import CheckboxList from "../../components/UIBuilder/CustomDrawer/components/CheckboxList";
import TemplateModal from "../../components/UIBuilder/TemplateModal/TemplateModal";

import cog from "../../assets/images/bua/cog.png";

const UIBuilder = () => {
	const axios = CreateAxiosInstance();
	const s3Axios = CreateS3AxiosInstance();
	// Project name, game name for the UI Buidler
	const projectName = "RuthTest"; // TEMP
	const gameName = "RuthTestGame"; // TEMP

	// for holding the names of the templates
	const [templateLibrary, setTemplateLibrary] = useState({});

	// for holding the names of the components
	const [uiComponentsLibrary, setUiComponentsLibrary] = useState([]);

	// Get the template library from the backend
	useEffect(() => {
		async function fetchFiles() {
			try {
				// get the secure s3 link from the API server
				const uiLayoutLink = await axios.get(`/ui/getUILayout/${projectName}/${gameName}`);

				// get the ui layout file from s3
				const uiLayoutFile = await s3Axios.get(uiLayoutLink.data);

				setTemplateLibrary(uiLayoutFile.data);
			} catch (error) {
				console.error("Error fetching UI Builder Template Library file:", error);
			}
		}
		fetchFiles();
	}, []);

	// Get the types of ui components from the backend
	useEffect(() => {
		async function fetchFiles() {
			try {
				// get the secure s3 link from the API server
				const componentLibraryLink = await axios.get("/ui/getComponentLibrary");

				// get the component library file from s3
				const componentLibraryFile = await s3Axios.get(componentLibraryLink.data);

				// set the template library state to an array of the parent object names
				setUiComponentsLibrary(componentLibraryFile.data.components);
			} catch (error) {
				console.error("Error fetching UI Builder Component Library file:", error);
			}
		}
		fetchFiles();
	}, []);

	// Slected template in the template select tab of the drawer
	const [selectedTemplate, setSelectedTemplate] = useState(undefined);

	/* MODAL SETUP FOLLOW */

	const [modalTemplateOpen, setTemplateModalOpen] = useState(false);

	// For changing the modal type between rename modal and template (add a new template) modal
	const [modalType, setModalType] = useState("");

	// For renaming a template, store the state of the original template name
	const [templateSettingsSelect, setTemplateSettingsSelect] = useState("");

	const openModal = (type, templateName) => {
		setModalType(type);
		if (type !== "template") setTemplateSettingsSelect(templateName);
		else setTemplateSettingsSelect(undefined);

		setTemplateModalOpen(true);
	};

	/* DRAWER CONTENTS FOLLOW */

	/*
		Template library.

		Populating the template library with all of the templates in the template library tab in the drawer.
	 */

	const templateLibraryTemplateNamesComponents = (
		<ButtonList
			items={Object.keys(templateLibrary)}
			selectedButton={selectedTemplate}
			setSelectedButton={setSelectedTemplate}
			additionalFillComponent={item => (
				<img
					id="ui-buider__template-library__template__cog"
					src={cog}
					alt="Cog icon for template library rename"
					onClick={() => openModal("settings", item)}
				/>
			)}
		/>
	);

	/*
		UI components library that holds all components that is coming from s3. Example, simulation window, blockly build etc. 

		A button is created for each and then added tothe scene and visibility is set to none
	 */

	// Create a button for each of the components in the componentsLibrary when a new template is selected
	const [buttons, setButtons] = useState([]);
	const [buttonsInitCss, setButtonsInitCss] = useState([]); // Using this to mainatin the percentage css of the button

	useEffect(() => {
		if (!uiComponentsLibrary) return;

		const tempButtonsInitCss = {};
		uiComponentsLibrary.map((component, index) => {
			tempButtonsInitCss[component] = {
				position: "absolute",
				boxSizing: "border-box",
				display: "none",
				cursor: "pointer",
				width: "10%",
				height: "10%",
				left: `${45 + index * 5}%`,
				top: `${45 + index * 5}%`,
				zIndex: "1",
				borderColor: "#000000",
				borderWidth: "1px",
				borderStyle: "solid",
			};
		});

		setButtonsInitCss(tempButtonsInitCss);
	}, [uiComponentsLibrary]);

	/*
		UI components for the specific template that is chosen. The chosen template is held in selectedTemplate (above).

		A button is created for each and then added tothe scene and visibility is set to none
	 */

	// This holds the UI components for a template that has been selected. The chosen template is held in selectedTemplate (above).
	const [uiComponents, setUiComponents] = useState({});

	/*
		UI component checkboxs

		The checkboxes laying beside all of the UI components in the edit tab in the drawer.
	 */

	// Selected checkboxes for the ui components,a nd the buttons holds the ui components that are entered onto the screen
	const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

	const handleCheckboxChange = (event, type) => {
		if (event.target.checked) {
			setSelectedCheckboxes([...selectedCheckboxes, type]);
		} else {
			setSelectedCheckboxes(selectedCheckboxes.filter(item => item !== type));
		}
	};

	const uiComponentsComponents = (
		<CheckboxList
			items={uiComponentsLibrary}
			selectedItems={selectedCheckboxes}
			handleCheckboxChange={handleCheckboxChange}
		/>
	);

	/*
		UI component settings

		The settings for the currently selected UI component in the edit tab in the drawer.
	 */

	// For the cuurently selected UI component, and the css comonets of that component
	const [selectedButton, setSelectedButton] = useState(null);
	const [selectedButtonProperties, setSelectedButtonProperties] = useState({});

	const uiComponentSettings = [
		{ label: "Width: %", key: "width", min: 0, max: 100 },
		{ label: "Height: %", key: "height", min: 0, max: 100 },
		{ label: "Left: %", key: "left", min: 0, max: 99 },
		{ label: "Top: %", key: "top", min: 0, max: 99 },
		{ label: "Z-Index:", key: "zIndex", min: 0, max: 100 },
		{ label: "Border Colour:", key: "borderColor" },
		{ label: "Border Width:", key: "borderWidth", min: 0, max: 100 },
	];

	const uiComponentsSettingsComponents = uiComponentSettings.map((setting, index) => (
		<div
			key={index}
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "space-between",
				height: "26px",
				boxSizing: "border-box",
				gap: "12px",
				margin: "16px 8px",
			}}
		>
			<label
				style={{
					fontSize: "16px",
					fontWeight: 500,
					textAlign: "left",
					color: "#FFFFFF",
					overflow: "hidden",
					textOverflow: "ellipsis",
					whiteSpace: "nowrap",
					width: "100%",
				}}
			>
				{setting.label}
			</label>
			<input
				type="text"
				style={{ height: "70%", width: "40%", cursor: "pointer" }}
				value={
					setting.key === "borderColor"
						? selectedButtonProperties[setting.key]
						: isNaN(Number.parseFloat(selectedButtonProperties[setting.key]))
							? ""
							: Number.parseFloat(selectedButtonProperties[setting.key])
				}
				onChange={e => {
					let targetValue = Number.parseFloat(e.target.value);

					// Maintain range
					if (targetValue > setting.max) {
						targetValue = setting.max;
					} else if (targetValue < setting.min || isNaN(targetValue)) {
						targetValue = setting.min;
					}

					if (["width", "height", "top", "left"].includes(setting.key)) {
						targetValue = `${targetValue}%`; // Use targetValue instead of e.target.value
					}

					if (["borderWidth"].includes(setting.key)) {
						targetValue = `${targetValue}px`;
					}

					const newProperties = { ...selectedButtonProperties, [setting.key]: targetValue };
					setSelectedButtonProperties(newProperties);

					const buttonElement = document.getElementById(`ui-builder__component__${selectedButton}`);
					buttonElement.style[setting.key] = targetValue;
				}}
			/>
		</div>
	));

	useEffect(() => {
		// Set Ui Components with this new info
		if (selectedButton === null || Object.keys(selectedButtonProperties).length === 0) return;

		setUiComponents(prev => {
			return {
				...prev,
				[selectedButton]: selectedButtonProperties,
			};
		});
	}, [selectedButtonProperties]);

	useEffect(() => {
		// Changing colour of selected button
		const unSelectedButton = document.getElementsByClassName("ui-builder__component");
		for (let i = 0; i < unSelectedButton.length; i++) {
			unSelectedButton[i].style.backgroundColor = "#FFFFFF";
		}

		const button = document.getElementById(`ui-builder__component__${selectedButton}`);
		if (button) button.style.backgroundColor = "#3291FF";

		// Set the selected button properties from template library not corresponding button css
		if (
			templateLibrary === undefined ||
			templateLibrary[selectedTemplate] === undefined ||
			templateLibrary[selectedTemplate][selectedButton] === undefined
		)
			return;

		setSelectedButtonProperties({
			width: `${Number.parseFloat(templateLibrary[selectedTemplate][selectedButton].width)}%`,
			height: `${Number.parseFloat(templateLibrary[selectedTemplate][selectedButton].height)}%`,
			left: `${Number.parseFloat(templateLibrary[selectedTemplate][selectedButton].left)}%`,
			top: `${Number.parseFloat(templateLibrary[selectedTemplate][selectedButton].top)}%`,
			zIndex: templateLibrary[selectedTemplate][selectedButton].zIndex,
			borderColor: templateLibrary[selectedTemplate][selectedButton].borderColor,
			borderWidth: `${Number.parseFloat(templateLibrary[selectedTemplate][selectedButton].borderWidth)}px`,
		});
	}, [selectedButton]);

	/*
		Select template and populate the layout and all of the other drawer elements with the info from s3

		The settings for the currently selected UI component in the edit tab in the drawer.
	 */

	useEffect(() => {
		if (selectedTemplate === undefined && buttons.length !== 0) {
			// When template is unselected ie. renamed or deleted
			// Set all of the buttons to display none

			for (const button of buttons)
				if (button.key !== undefined) {
					const buttonElement = document.getElementById(`ui-builder__component__${button.key}`);
					buttonElement.style.display = "none";
				}
			return;
		}

		if (Object.keys(templateLibrary).length === 0) return; // No templates yet
		setSelectedButton(null);
		setSelectedButtonProperties({});

		// if (selectedTemplate) setSelectedTab('edit');

		// New template chosen, check the appropriate checkboxes
		if (selectedTemplate) setSelectedCheckboxes(Object.keys(templateLibrary[selectedTemplate]));

		const newButtons = uiComponentsLibrary.map((type, index) => {
			return (
				<div key={type}>
					<Button
						id={`ui-builder__component__${type}`}
						className="ui-builder__component"
						style={buttonsInitCss[type]}
						onClick={() => setSelectedButton(type)}
					>
						{type}
					</Button>
				</div>
			);
		});

		setButtons(newButtons);
	}, [selectedTemplate]);

	useEffect(() => {
		if (Object.keys(templateLibrary).length === 0) return; // No templates yet

		if (!uiComponentsLibrary) return; // The actual components to choose from haven't loaded in

		if (!selectedTemplate) return; // No template selected yet

		// Elements in selectedCheckboxes but not in Object.keys(uiComponents), ADD
		const inSelectedCheckboxesNotInUiComponents = selectedCheckboxes.filter(
			x => !Object.keys(uiComponents).includes(x),
		);

		// Elements in Object.keys(uiComponents) but not in selectedCheckboxes, DELETE
		const inUiComponentsNotInSelectedCheckboxes = Object.keys(uiComponents).filter(
			x => !selectedCheckboxes.includes(x),
		);

		// Elements in both selectedCheckboxes and Object.keys(uiComponents), UPDATE
		const inBoth = selectedCheckboxes.filter(x => Object.keys(uiComponents).includes(x));

		let tempUIComponents = templateLibrary[selectedTemplate]; // Cannot set during a for loop so init here and set at the end

		// Traverse the uiComponents to be added
		for (const component of [...inSelectedCheckboxesNotInUiComponents, ...inBoth]) {
			// Get the button corresponding to that checkbox
			const buttonElement = document.getElementById(`ui-builder__component__${component}`);

			if (!buttonElement) {
				console.log("ERROR: Button element: " + { buttonElement } + " not found");
				continue;
			}

			buttonElement.style.display = "block";

			// Get the css from the template and apply it to the button
			let css = tempUIComponents[component];

			// Get the css from the button init props if it is added and not in the template library
			if (!css) css = buttonsInitCss[component];

			applyCssToButton(buttonElement, css);
			// Add the new component and css
			tempUIComponents = {
				...tempUIComponents,
				[component]: {
					width: `${Number.parseFloat(css.width)}%`,
					height: `${Number.parseFloat(css.height)}%`,
					left: `${Number.parseFloat(css.left)}%`,
					top: `${Number.parseFloat(css.top)}%`,
					zIndex: css.zIndex,
					borderColor: css.borderColor,
					borderWidth: css.borderWidth,
				},
			};
		}

		// Traverse the uiComponents to be removed
		for (const component of inUiComponentsNotInSelectedCheckboxes) {
			const buttonElement = document.getElementById(`ui-builder__component__${component}`);

			if (!buttonElement) {
				console.log("ERROR: Button element: " + { buttonElement } + " not found");
				continue;
			}

			buttonElement.style.display = "none";

			// If this component is in the uiComponents, remove it
			delete tempUIComponents[component];
		}

		setUiComponents(tempUIComponents);
	}, [selectedCheckboxes]);

	useEffect(() => {
		if (!templateLibrary || Object.keys(templateLibrary).length === 0) return; // No templates yet
		const newTemplateLibrary = { ...templateLibrary, [selectedTemplate]: uiComponents };

		setTemplateLibrary(newTemplateLibrary);
	}, [uiComponents]);

	const applyCssToButton = (button, css) => {
		button.style.width = `${Number.parseFloat(css.width)}%`;
		button.style.height = `${Number.parseFloat(css.height)}%`;
		button.style.left = `${Number.parseFloat(css.left)}%`;
		button.style.top = `${Number.parseFloat(css.top)}%`;
		button.style.zIndex = css.zIndex;
		button.style.borderColor = css.borderColor;
		button.style.borderWidth = `${Number.parseFloat(css.borderWidth)}px`;
	};

	/*
		Save template 

		Using the current selected template, save the template to the s3
	 */

	const saveTemplate = () => {
		axios
			.put("/ui/updateUILayout", {
				projectName: projectName,
				gameName: gameName,
				updatedJSON: templateLibrary,
			})
			.then(response => {
				if (response.status === 201) {
					console.log(`Successfully saved UI Layout data for ${projectName}/${gameName}!`);
				}
			});
	};

	/* INIT DRAWER INFO */

	const drawerTabInfo = [
		{
			name: "Template Library",
			key: "template-library",
			tabWidth: "50%",
			content: (
				<>
					{templateLibraryTemplateNamesComponents}
					<ButtonItem onClick={() => openModal("template")} name="Add Template" />
				</>
			),
		},
		{
			name: "Edit",
			key: "edit",
			tabWidth: "30%",
			content: (
				<>
					<CollapseDiv fillComponents={uiComponentsComponents} title={"Toggle Content"} />
					<CollapseDiv
						fillComponents={uiComponentsSettingsComponents}
						title={"Component Settings"}
						conditionToShowFillComponents={selectedButton}
						alternativeFillComponentsIfConditionNotMet={"Select a template from the template library"}
					/>
					<ButtonItem onClick={saveTemplate} name="Save Template" />
				</>
			),
		},
	];

	const uiBuilderWorkspaceDiv = useRef(null);

	return (
		<>
			<TemplateModal
				modalOpen={modalTemplateOpen}
				setModalOpen={setTemplateModalOpen}
				templateLibrary={templateLibrary}
				setTemplateLibrary={setTemplateLibrary}
				setSelectedTemplate={setSelectedTemplate}
				type={modalType}
				templateSettingsSelect={templateSettingsSelect}
				projectName={projectName}
				gameName={gameName}
			/>
			<DesignToolsHeader tab={"ui-builder"} />
			<div className="ui-builder__body">
				<CustomDrawer drawerTabInfo={drawerTabInfo} workspaceDivRef={uiBuilderWorkspaceDiv} />
				<div id="ui-builder__work-space" ref={uiBuilderWorkspaceDiv}>
					{buttons}
				</div>
			</div>
		</>
	);
};

export default UIBuilder;
