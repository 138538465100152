import "codemirror/lib/codemirror.css";
import "codemirror/mode/javascript/javascript";
import "codemirror/theme/material-darker.css";
import React, { useContext } from "react";
import { Controlled as CodeMirror } from "react-codemirror2";
import CreateAxiosInstance from "../../../AxiosInstance/AxiosInstance";
import AppContext from "../../AppContext/AppContext";

export default function CodeMirrorContainer({ file, show, onUpdate, handleAddScript }) {
	const localContext = useContext(AppContext);
	const [state, setState] = React.useState(file.code);
	const axios = CreateAxiosInstance();
	const handleSaveButtonClick = async () => {
		let params;
		// if(localContext.entityName.includes("Games/") || localContext.entityName.includes("Projects/") || localContext.entityName.includes("Scripts/")) {
		// 	params = localContext.entityName.replace('Projects/','').replace('Games/','').replace('Scripts/','');
		// } else {
		// 	params = `${localContext.projectName}/${localContext.gameName}/${localContext.entityName}.js`;
		// }

		params = `${localContext.projectName}/${localContext.gameName}/${localContext.entityName}.js`;
		console.log(state);
		// update entity script contents with PUT
		await axios
			.put(`scripts/${params}`, state, {
				headers: { "Content-Type": "text/plain" },
			})
			.then(response => {
				if (response.status === 200) {
					onUpdate(localContext.entityName, state, true); // remove asterisks stating file was saved and no longer modified
					localContext.updateModifiedEntities(localContext.entityName, false); // set modifiedEntities to false as file has now been saved to s3
				}
			});
	};

	const DefaultOptions = {
		theme: "material-darker",
		autoCloseBrackets: true,
		matchBrackets: true,
		styleActiveLine: true,
		cursorScrollMargin: 48,
		mode: "javascript",
		lineNumbers: true,
		indentUnit: 2,
		tabSize: 2,
		viewportMargin: 99,
		dragDrop: true,
	};

	React.useEffect(() => {
		setState(file.code);
	}, [file]);

	return (
		<div className="flex flex-col h-full max-h-screen bg-color-bua-background">
			{show && (
				<CodeMirror
					value={state}
					options={DefaultOptions}
					onBeforeChange={(editor, data, value) => {
						localContext.updateEntityName(file.id);
						setState(value);
						onUpdate(file.id, value, false); // false states file has not been saved since being modified
					}}
					onChange={(editor, data, value) => { }}
					className="flex-grow overflow-y-auto"
				/>
			)}
			<div className="bg-color-bua-background mt-auto p-6 flex justify-between border-t border-color-bua-background-light">
				<button className="bg-color-bua-text-light px-4 py-1 hover:opacity-75 " type="button" onClick={handleAddScript}>
					Add New Script
				</button>
				<button
					className="bg-color-bua-button-primary px-4 py-1 hover:opacity-75 "
					type="button"
					onClick={handleSaveButtonClick}
				>
					Save
				</button>
			</div>
		</div>
	);
}
