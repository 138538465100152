import DesignToolsHeader from "../../components/DesignToolsHeader/DesignToolsHeader";
import SkulptFunctionSettings from "../../components/SkulptGenerator/SkulptFunctionSettings/SkulptFunctionSettings";
import SkulptOutput from "../../components/SkulptGenerator/SkulptOutput/SkulptOutput";
import SkulptSettings from "../../components/SkulptGenerator/SkulptSettings/SkulptSettings";

import React, { useContext } from "react";
import AppContext from "../../components/AppContext/AppContext";

const SkulptGenerator = () => {

    const localContext = useContext(AppContext);
    console.log("SkulptGenerator " + localContext.projectName, localContext.gameName, localContext.entityName)

    return (
        <>
            <DesignToolsHeader tab={"skulpt-generator"} />
            <div className="skulpt-generator-container">
                <SkulptSettings />
                <SkulptFunctionSettings />
                <SkulptOutput />
            </div>
        </>
    );
}

export default SkulptGenerator;