import { Tabs } from "@mantine/core";
import React, { useState } from "react";
import Modal from "react-modal";
import CreateAxiosInstance from "../../../AxiosInstance/AxiosInstance";

import "./TemplateModal.css";

const TemplateModal = ({
	modalOpen,
	setModalOpen,
	templateLibrary,
	setTemplateLibrary,
	setSelectedTemplate,
	type,
	templateSettingsSelect,
	projectName,
	gameName,
}) => {
	const [searchText, setSearchText] = useState("");
	const [selectedTab, setSelectedTab] = useState("rename");
	const handleSearchChange = event => {
		setSearchText(event.target.value);
	};

	const modalCancel = () => {
		setModalOpen(false);

		// clear text on cancel
		setSearchText("");
	};

	const modalConfirm = (setTemplateLibrary, type, templateSettingsSelect, projectName, gameName, selectedTab) => {
		switch (type) {
			case "template":
				createTemplate(searchText, templateLibrary, setTemplateLibrary, projectName, gameName);
				break;
			case "settings":
				if (selectedTab === "rename") {
					renameTemplate(
						searchText,
						templateLibrary,
						setTemplateLibrary,
						templateSettingsSelect,
						projectName,
						gameName,
					);
				} else if (selectedTab === "delete") {
					deleteTemplate(
						searchText,
						templateLibrary,
						setTemplateLibrary,
						templateSettingsSelect,
						projectName,
						gameName,
					);
				}

				// Clear the workspace
				setSelectedTemplate(undefined);
				break;
			default:
				console.log("Invalid type: " + type);
		}

		setModalOpen(false);

		// clear text on create
		setSearchText("");
	};

	return (
		<Modal
			className="ui-builder__template-modal"
			isOpen={modalOpen}
			ariaHideApp={false} // To suppress warning
			style={{
				overlay: {
					zIndex: 1001, // This should be higher than the z-index of other elements
					backgroundColor: "rgba(0, 0, 0, 0.75)", // This sets the opacity
				},
			}}
		>
			<div id="ui-builder__template-modal__title">
				<h2>{type === "template" ? "Add Template" : `${templateSettingsSelect} Settings`}</h2>
			</div>

			<Tabs defaultValue={selectedTab} onChange={setSelectedTab} style={{ width: "100%" }}>
				{type === "template" ? null : (
					<Tabs.List style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
						<Tabs.Tab
							className={`ui-builder__template-modal__tab ${
								selectedTab === "rename"
									? "ui-builder__template-modal__tab--active"
									: "ui-builder__template-modal__tab--not-active"
							}`}
							value="rename"
						>
							Rename
						</Tabs.Tab>
						<Tabs.Tab
							className={`ui-builder__template-modal__tab ${
								selectedTab === "delete"
									? "ui-builder__template-modal__tab--active"
									: "ui-builder__template-modal__tab--not-active"
							}`}
							value="delete"
						>
							Delete
						</Tabs.Tab>
					</Tabs.List>
				)}
				{selectedTab === "delete" ? (
					<label id="search">
						Type <em>{`${templateSettingsSelect}`}</em> in the text input field
					</label>
				) : null}
				{type === "template" ? (
					<input
						id="ui-builder__template-modal__search"
						type="text"
						value={searchText}
						onChange={handleSearchChange}
						placeholder="Enter template title"
					/>
				) : selectedTab === "rename" ? (
					<input
						id="ui-builder__template-modal__search"
						type="text"
						value={searchText}
						onChange={handleSearchChange}
						placeholder="Enter new template title"
					/>
				) : (
					<input
						id="ui-builder__template-modal__search"
						type="text"
						value={searchText}
						onChange={handleSearchChange}
						placeholder={`Enter ${templateSettingsSelect}`}
					/>
				)}
			</Tabs>

			<div id="ui-builder__template-modal__end">
				<button id="ui-builder__template-modal__end__cancel" type="button" onClick={modalCancel}>
					Cancel
				</button>
				<button
					id="ui-builder__template-modal__end__confirm"
					type="button"
					onClick={() =>
						modalConfirm(setTemplateLibrary, type, templateSettingsSelect, projectName, gameName, selectedTab)
					}
				>
					{type === "template" ? "Create" : "Confirm"}
				</button>
			</div>
		</Modal>
	);
};

function createTemplate(searchText, templateLibrary, setTemplateLibrary, projectName, gameName) {
	// Verify no existing template with the same name
	if (Object.keys(templateLibrary).includes(searchText)) {
		console.error(
			`Duplicate name error: ${searchText} already exists in the template library for ${projectName}/${gameName}!`,
		);
		return;
	}

	const newTemplateLibrary = {
		...templateLibrary,
		[searchText]: {},
	};

	setTemplateLibrary(newTemplateLibrary);

	axiosPut(newTemplateLibrary, projectName, gameName);
}

function renameTemplate(
	searchText,
	templateLibrary,
	setTemplateLibrary,
	templateSettingsSelect,
	projectName,
	gameName,
) {
	// Verify no existing template with the same name
	if (Object.keys(templateLibrary).includes(searchText)) {
		console.error(
			`Duplicate name error: ${searchText} already exists in the template library for ${projectName}/${gameName}!`,
		);
		return;
	}

	const { [templateSettingsSelect]: oldAttributeValue, ...restOfTemplateLibrary } = templateLibrary;

	const newTemplateLibrary = {
		...restOfTemplateLibrary,
		[searchText]: oldAttributeValue,
	};

	setTemplateLibrary(newTemplateLibrary);

	axiosPut(newTemplateLibrary, projectName, gameName);
}

function deleteTemplate(
	searchText,
	templateLibrary,
	setTemplateLibrary,
	templateSettingsSelect,
	projectName,
	gameName,
) {
	// verifiy the search text is the same as the templateSettingsSelect
	if (searchText !== templateSettingsSelect) {
		console.error(`Error: search text ${searchText} does not match templateSettingsSelect ${templateSettingsSelect}`);
		return;
	}

	const newTemplateLibrary = { ...templateLibrary };
	delete newTemplateLibrary[templateSettingsSelect];

	setTemplateLibrary(newTemplateLibrary);

	axiosPut(newTemplateLibrary, projectName, gameName);
}

function axiosPut(newTemplateLibrary, projectName, gameName) {
	const axios = CreateAxiosInstance();
	axios
		.put("/ui/updateUILayout", {
			projectName: projectName,
			gameName: gameName,
			updatedJSON: newTemplateLibrary,
		})
		.then(response => {
			if (response.status === 201) {
				console.log(`Successfully saved UI Layout data for ${projectName}/${gameName}!`);
			}
		});
}

export default TemplateModal;
