import React from "react";
import "./components.css";

/*
    Button Item

    This is a custom component for the custom drawer, that is one singular button in the drawer. This button can be clicked
    and will trigger a function. Example of usage is in the UIBuilder the saveTemplate and addTemplate buttons.
*/

const ButtonItem = ({ onClick, name }) => {
	return (
		<button
			type="button"
			className="ui-builder__drawer__tab-button"
			id="ui-builder__drawer__add-template-button"
			onClick={onClick}
		>
			{name}
		</button>
	);
};

export default ButtonItem;
