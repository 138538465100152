import React from "react";
import CollapseDiv from '../../UIBuilder/CollapseDiv/CollapseDiv';
import LabelInput from "../../UIBuilder/CustomDrawer/components/LabelInput/LabelInput";

function FilterSettings(props) {
	const filterGroupCallback = props.filterGroupCallback;
	const filterGroup = props.filterGroup;

	const filterMaskCallback = props.filterMaskCallback;
	const filterMask = props.filterMask;

	const filterGroupInputInfo = [
		{
			inputDefault: "-1",
			inputValue: filterGroup.toString(),
			inputType: "number",
			inputStep: 1,
			inputMinimum: -1,
			inputMaximum: 100,
			inputCallback: (newValue) => filterGroupCallback(Number(newValue)),
		}
	];
	const filterGroupInput = <LabelInput labelName={"Filter Group"} inputInfo={filterGroupInputInfo} />

	const filterMaskInputInfo = [
		{
			inputDefault: "-1",
			inputValue: filterGroup.toString(),
			inputType: "number",
			inputStep: 1,
			inputMinimum: -1,
			inputMaximum: 100,
			inputCallback: (newValue) => filterMaskCallback(Number(newValue)),
		}
	];
	const filterMaskInput = <LabelInput labelName={"Filter Mask"} inputInfo={filterMaskInputInfo} />

	return (
		<div className="filter-settings">

			<CollapseDiv
				fillComponents={
					<>
						<div className="entity-setting__container--filter-group">
							{filterGroupInput}
						</div>
						<div className="entity-setting__container--filter-mask">
							{filterMaskInput}
						</div>
						{
							/* only display this warning if filterMask or filterGroup are not a power of 2 */
							((Math.log(filterGroup) / Math.log(2)) % 1 !== 0 ||
								(Math.log(filterMask) / Math.log(2)) % 1 !== 0) && (
								<p className="entity-settings__text--filter-warning">
									Warning: filter numbers must be powers of two (2, 4, 8 etc.)
								</p>
							)
						}
					</>
				}
				title={"Filter Settings"}
			/>

		</div>
	);
}

export default FilterSettings;
