import React from "react";
import KeyboardShortcutInput from "../../../KeyboardShortcutInput/KeyboardShortcutInput";

const ControlSettingsTab = ({ accessibilitySettings, setAccessibilityAttribute, checkIfKeyBeingUsed }) => {
	const blackAndWhite = accessibilitySettings.display.blackAndWhite;
	return (
		<div className="w-full max-w-[580px] h-full p-2 flex flex-col gap-4 overflow-x-hidden">
			<KeyboardShortcutInput
				label="Enter"
				targetKey={"Enter"}
				defaultKey={accessibilitySettings.controls.Enter}
				setAccessibilityAttribute={setAccessibilityAttribute}
				checkIfKeyBeingUsed={checkIfKeyBeingUsed}
			/>
			<KeyboardShortcutInput
				label="Escape"
				targetKey={"Escape"}
				defaultKey={accessibilitySettings.controls.Escape}
				setAccessibilityAttribute={setAccessibilityAttribute}
				checkIfKeyBeingUsed={checkIfKeyBeingUsed}
			/>
			<KeyboardShortcutInput
				label="Arrow Up"
				targetKey={"ArrowUp"}
				defaultKey={accessibilitySettings.controls.ArrowUp}
				setAccessibilityAttribute={setAccessibilityAttribute}
				checkIfKeyBeingUsed={checkIfKeyBeingUsed}
			/>
			<KeyboardShortcutInput
				label="Arrow Down"
				targetKey={"ArrowDown"}
				defaultKey={accessibilitySettings.controls.ArrowDown}
				setAccessibilityAttribute={setAccessibilityAttribute}
				checkIfKeyBeingUsed={checkIfKeyBeingUsed}
			/>
			<KeyboardShortcutInput
				label="Arrow Left"
				targetKey={"ArrowLeft"}
				defaultKey={accessibilitySettings.controls.ArrowLeft}
				setAccessibilityAttribute={setAccessibilityAttribute}
				checkIfKeyBeingUsed={checkIfKeyBeingUsed}
			/>
			<KeyboardShortcutInput
				label="Arrow Right"
				targetKey={"ArrowRight"}
				defaultKey={accessibilitySettings.controls.ArrowRight}
				setAccessibilityAttribute={setAccessibilityAttribute}
				checkIfKeyBeingUsed={checkIfKeyBeingUsed}
			/>
		</div>
	);
};
export default ControlSettingsTab;
