import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./pages/Layout/Layout";
import { useEffect } from "react";

import AvailableBlocks from "./pages/AvailableBlocks/AvailableBlocks.jsx";
import BlocklyGenerator from "./pages/BlocklyGenerator/BlocklyGenerator.jsx";
import EntityBuilder from "./pages/EntityBuilder/EntityBuilder.jsx";
import GamesPage from "./pages/GamesPage/GamesPage.jsx";
import ProjectsPage from "./pages/ProjectsPage/ProjectsPage.jsx";
import ScriptEditor from "./pages/ScriptEditor/ScriptEditor.jsx";
import SkulptGenerator from "./pages/SkulptGenerator/SkulptGenerator.jsx";
import UIBuilder from "./pages/UIBuilder/UIBuilder.jsx";

import ActivityLayoutTab from "./pages/ActivityLayoutTab/ActivityLayoutTab";
import CodeTab from "./pages/CodeTab/CodeTab.jsx";
import NarrationTab from "./pages/NarrationTab/NarrationTab";

import StudentView from "./components/StudentView/StudentView";
import Compression from "./pages/Compression/Compression";
import PageNotFound from "./pages/PageNotFound/PageNotFound";

// Global variable context
import React, { useState } from "react";
import AppContext from "./components/AppContext/AppContext.js";

import { Security } from "@okta/okta-react";
// Global variable context
import CallbackComponent from "./components/CallBackComponent/CallbackComponent.jsx";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute.jsx";
import oktaAuthInstance from "./oktaAuthInstance.jsx";
import ClickDragDemo from "./pages/ClickDragDemo/ClickDragDemo";
import Login from "./pages/Login/Login.jsx";
import ProducePackerActivityCreator from "./pages/ProducePackerActivityCreator/ProducePackerActivityCreator.jsx";

function App() {
	const [projectName, setProjectName] = useState("sample-project");
	const [gameName, setGameName] = useState("sample-game");
	const [entityName, setEntityName] = useState("sample-entity");
	const [modifiedEntities, setModifiedEntities] = useState([]);

	const restoreOriginalUri = (_oktaAuth, originalUri) => {
		//navigate(toRelativeUrl(originalUri || "/", window.location.origin));
	};

	const updateProjectName = projectName => {
		setProjectName(projectName);
	};

	const updateGameName = projectName => {
		setGameName(projectName);
	};

	const updateEntityName = entityName => {
		setEntityName(entityName);
	};

	const updateModifiedEntities = (name, value) => {
		const modifiedEntity = modifiedEntities.find(entity => entity.name === name);
		if (modifiedEntity) {
			modifiedEntity.value = value;
		} else {
			modifiedEntities.push({ name, value });
		}
		setModifiedEntities(modifiedEntities);
	};

	const clearModifiedEntities = () => {
		setModifiedEntities([]);
	};

	const projectGameSettings = {
		projectName: projectName,
		gameName: gameName,
		entityName: entityName,
		modifiedEntities: modifiedEntities,
		updateProjectName,
		updateGameName,
		updateEntityName,
		updateModifiedEntities,
		clearModifiedEntities,
	};

	return (
		<div className="bg-[#252526] w-full h-full">
			<Security oktaAuth={oktaAuthInstance} restoreOriginalUri={restoreOriginalUri}>
				<AppContext.Provider value={projectGameSettings}>
					<BrowserRouter>
						<Routes>
							{/* Default to Layout page to decide which page to visit */}
							<Route path="/" element={<ProtectedRoute component={Layout} />} />
							<Route path="click-drag-demo" element={<ProtectedRoute component={ClickDragDemo} />} />
							<Route path="projects" element={<ProtectedRoute component={ProjectsPage} />} />
							<Route path="design-tools/entity-builder" element={<ProtectedRoute component={EntityBuilder} />} />
							<Route path="design-tools/script-editor" element={<ProtectedRoute component={ScriptEditor} />} />
							<Route path="design-tools/ui-builder" element={<ProtectedRoute component={UIBuilder} />} />
							<Route path="design-tools/blockly-generator" element={<ProtectedRoute component={BlocklyGenerator} />} />
							<Route path="design-tools/skulpt-generator" element={<ProtectedRoute component={SkulptGenerator} />} />
							<Route path="design-tools/available-blocks" element={<ProtectedRoute component={AvailableBlocks} />} />
							<Route path="games" element={<ProtectedRoute component={GamesPage} />} />
							<Route path="author-tools/code" element={<ProtectedRoute component={CodeTab} />} />
							<Route path="author-tools/activity-layout" element={<ProtectedRoute component={ActivityLayoutTab} />} />
							<Route path="author-tools/narration" element={<ProtectedRoute component={NarrationTab} />} />
							<Route
								path="author-tools/produce-packer-activity-creator"
								element={<ProtectedRoute component={ProducePackerActivityCreator} />}
							/>
							<Route path="student-view" element={<ProtectedRoute component={StudentView} />} />
							<Route path="login" element={<Login />} />
							<Route path="login/callback" element={<CallbackComponent />} />
							<Route path="*" element={<ProtectedRoute component={PageNotFound} />} />
						</Routes>
					</BrowserRouter>
				</AppContext.Provider>
			</Security>
		</div>
	);
}

export default App;
