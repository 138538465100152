import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";

// This creates an axios instamce with the base URL and the token from the oktaAuth instance
const CreateAxiosInstance = () => {
	// Get the oktaAuth instance from the okta context
	console.log("CreateAxiosInstance", process.env?.REACT_APP_API_URL, window.APP_ENV?.REACT_APP_API_URL);
	const { oktaAuth } = useOktaAuth();
	// Create an axios instance
	const axiosInstance = axios.create({
		baseURL: process.env?.REACT_APP_API_URL || window.APP_ENV?.REACT_APP_API_URL, // Corrected environment variable
	});
	// Add an interceptor to the axios instance to add the token to the headers
	axiosInstance.interceptors.request.use(
		async config => {
			const token = await oktaAuth.getAccessToken();
			if (token) {
				config.headers.Authorization = `Bearer ${token}`;
			}
			return config;
		},
		error => Promise.reject(error),
	);

	return axiosInstance;
};

export default CreateAxiosInstance;
