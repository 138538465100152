import React, { useRef, useState } from 'react';
import Container from "./Container";
import DragElement from "./DragElement";
import Element from "./Element";

const ClickDragDemo = () => {
    const containerRef1 = useRef(null);
    const containerRef2 = useRef(null);

    const [selectedEntity, setSelectedEntity] = useState(null);

    const handleSelected = (name) => {
        console.log('Element clicked:', name);
        setSelectedEntity(name);
    }

    return (
        <div className="w-full h-full bg-slate-400 relative">
            {/* <DragElement
                name={"element1"}
                x={"25"}
                y={"25"}
                w={"10"}
                h={"10"}
                color={"red"}
                containerRefs={[containerRef1, containerRef2]}
            /> */}

            <Element
                name={"element1"}
                color={"red"}
                isSelected={selectedEntity === "element1"}
                setSelected={handleSelected}
            />

            <Container
                x={"25"}
                y={"200"}
                w={"200"}
                h={"100"}
                color={"green"}
                name={"container1"}
                ref={containerRef1}
            />

            <Container
                x={"25"}
                y={"400"}
                w={"200"}
                h={"100"}
                color={"green"}
                name={"container2"}
                ref={containerRef2}
            />
        </div>
    );
};

export default ClickDragDemo;