import { useEffect, useRef } from "react";
import CreateAxiosInstance from "../../AxiosInstance/AxiosInstance";
import StarRating from "../StarRating/StarRating";

export default function ResultModal({
	currentScore,
	bestScore,
	retries,
	feedback,
	setShowResultModal,
	showResultModal,
	restartCurrentActivity,
	moveOnToNextActivity,
	activityData,
	gameSaveState,
	addActivityAttempt,
}) {
	const axios = CreateAxiosInstance();

	const resultModalRef = useRef(null);

	useEffect(() => {
		const handleKeyDown = (e) => {
			const focusableElements = resultModalRef.current.querySelectorAll(
				'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
			);
			const firstElement = focusableElements[0];
			const lastElement = focusableElements[focusableElements.length - 1];

			if (e.key === 'Tab') {
				if (e.shiftKey) {
					if (document.activeElement === firstElement) {
						e.preventDefault();
						lastElement.focus();
					}
				} else {
					if (document.activeElement === lastElement) {
						e.preventDefault();
						firstElement.focus();
					}
				}
			}
		};

		if (showResultModal) {
			document.addEventListener('keydown', handleKeyDown);
		}

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [showResultModal]);

	/**
	 * Function to update the attempt data when the modal is displayed
	 */
	async function updateAttemptData() {
		// Your code here to run the function when the modal is displayed
		const userString = window.localStorage.getItem("okta-token-storage");
		const userObject = JSON.parse(userString);
		const user = userObject?.accessToken?.claims?.sub;
		//const user = "stephen.byrne@imaginelearning.com";
		// if the user doesn't exist, return, can toggle these two lines for development
		if (!user) return;
		// Get the attempt data
		const newAttemptData = activityData.studentData;
		// Get the current time asnd date
		const date = new Date();
		const time = date.getTime();
		// Store the time and date in the attempt data in iso format
		newAttemptData.attemptTime = date.toISOString();
		// Add the attempt data to the activity data
		const attemptData = addActivityAttempt(activityData.id, newAttemptData);
		// Update the activity data
		const url = `/activityAttempts?projectName=Math Arcade&gameName=Produce Packer&user=${user}`;
		console.log("updating attemptData", attemptData);
		try {
			// Send request to update the data
			await axios.put(url, attemptData);
		} catch (error) {
			console.error("Error saving game data:", error);
			// If the error is a 404, create a new gaveSaveJson for the user
			if (error.response?.status === 404) {
				await axios.post(url, attemptData);
			}
		}
	}

	useEffect(() => {
		console.log("Updating Attempt data");
		updateAttemptData();
	}, []);

	return (
		<div ref={resultModalRef} className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75 px-2">
			<div className="w-[90%] h-[90%] max-h-[600px] max-w-lg rounded-xl border-[#58BCBE] bg-slate-50 shadow-lg border-4 grid grid-cols-1 grid-rows-12">
				<div className="row-start-1 row-end-2 w-full flex items-center justify-center">
					<h1 className="text-3xl md:text-4xl font-semibold lg:text-4xl">Results</h1>
				</div>
				<div className="row-start-2 row-end-5 border-gray-400 border-y-2 mx-2 flex flex-col items-center justify-center">
					<div className=" w-full flex items-center justify-center my-2 mt-2">
						<div className="w-3/12 text-lg xs:text-xl sm:text-3xl">Current</div>
						<div className="w-9/12 max-w-72 flex">
							<StarRating score={currentScore} />
						</div>
					</div>
					<div className="w-[90%] flex items-center justify-center my-2 mb-2">
						<div className="w-3/12 text-lg xs:text-xl sm:text-3xl">Best</div>
						<div className="w-7/12 max-w-72 flex">
							<StarRating score={bestScore} />
						</div>
					</div>
				</div>
				<div className="row-start-5 row-end-11 overflow-y-auto w-full flex items-center justify-center mt-4">
					<h3 className="h-full w-[90%] flex justify-center text-center text-balance mx-4 text-lg sm:text-xl">
						{feedback ?? "teststststst teststststst teststststst teststststst teststststst teststststst teststststst teststststst teststststst teststststst teststststst teststststst teststststst stststststtestststsover flow-y-autooverflow-y-a utooverflow-y-a utotstststst ststtestststststs tststststteststst ststststst ststteststststs tstststststtes tstststststststst stteststststststs tstststtests tstststst ststststteststst ststststs tststtes stststs tststst stst"}
					</h3>
				</div>
				<div className="row-start-11 row-end-13 flex flex-row mx-4 h-full justify-between items-center">
					<button
						type="button"
						className={`
										bg-gray-500 text-white text-sm sm:text-base md:text-xl lg:text-2xl font-bold px-6 sm:px-8 py-4 rounded-md
										active:opacity-50 hover:opacity-75 
										focus:ring-offset-2 focus:ring-[#1FC3FF] focus:ring focus:outline-none

										shadow-md shadow-slate-300
									`}
						aria-label="Confirm"
						onClick={async () => {
							setShowResultModal(false);
							restartCurrentActivity();
						}}
					>
						Retry {retries ? `(${retries})` : ""}
					</button>

					<button
						type="button"
						className={`
										bg-color-bua-button-primary text-white font-bold text-sm sm:text-base md:text-xl lg:text-2xl px-6 sm:px-8 py-4 rounded-md
										active:opacity-50 hover:opacity-75 
										focus:ring-offset-2 focus:ring-[#1FC3FF] focus:ring focus:outline-none

										shadow-md shadow-slate-300
									`}
						aria-label="Confirm"
						onClick={async () => {
							setShowResultModal(false);
							moveOnToNextActivity();
						}}
					>
						Continue
					</button>
				</div>
			</div>
		</div >
	);
}
