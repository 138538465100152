import { useContext } from "react";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import buaHomeButton from "../../assets/images/bua/bua-home-button.svg";
import AppContext from "../AppContext/AppContext";

const DesignToolsHeader = props => {
	const localContext = useContext(AppContext);

	const navigate = useNavigate();

	const selectedTab = props.tab;

	const designTabs = [
		{
			text: "Entity Builder",
			path: "entity-builder",
		},
		{
			text: "Script Editor",
			path: "script-editor",
		},
		{
			text: "UI Builder",
			path: "ui-builder",
		},
		{
			text: "Blockly Generator",
			path: "blockly-generator",
		},
		{
			text: "Skulpt Generator",
			path: "skulpt-generator",
		},
		{
			text: "Available Blocks",
			path: "available-blocks",
		},
	];

	const designTabElements = designTabs.map(tab => {
		const button = (
			<button
				type="button"
				className={`bg-color-bua-background text-color-bua-text-light text-[10px] p-1
                    sm:text-sm
                    md:text-base md:p-2
                    lg:text-lg 
                    hover:opacity-75 
                    h-full
                    ${tab.path === selectedTab ? "bg-color-bua-background-light" : ""} 
                    border-x border-color-bua-text-light`}
				//{`design-tools-header__button ${tab.path === selectedTab ? "selected" : ""}`}
				key={tab.path}
				onClick={() => {
					if (localContext.modifiedEntities.some(entity => entity.value === true)) {
						if (
							window.confirm(
								`You have unsaved files! Clicking ok will leave the page and you will lose the changes made to the following entities: ${localContext.modifiedEntities
									.filter(entity => entity.value === true)
									.map(entity => entity.name)
									.join(", ")}`,
							)
						) {
							// Lose Changes and navigate to new page anyway
							console.log("Navigating to new page and losing changes");
							localContext.clearModifiedEntities(); // Reset the modified entities
							navigate(`/design-tools/${tab.path}`);
						} else {
							// Do nothing!
							console.log("Navigation cancelled, now save the modified files");
							return;
						}
					} else {
						navigate(`/design-tools/${tab.path}`);
					}
				}}
			>
				{tab.text}
			</button>
		);

		return button;
	});

	return (
		<div className="flex h-10 sm:h-14">
			<img
				className="h-full my-auto w-10 px-1 py-2 hover:opacity-75"
				src={buaHomeButton}
				alt="Home button"
				onClick={() => {
					if (localContext.modifiedEntities.some(entity => entity.value === true)) {
						if (
							window.confirm(
								`You have unsaved files! Clicking ok will leave the page and you will lose the changes made to the following entities: ${localContext.modifiedEntities
									.filter(entity => entity.value === true)
									.map(entity => entity.name)
									.join(", ")}`,
							)
						) {
							// Lose Changes and navigate to new page anyway
							console.log("Navigating to home page and losing changes");
							window.location.href = "/";
						} else {
							// Do nothing!
							console.log("Navigation cancelled, now save the modified files");
							return;
						}
					} else {
						window.location.href = "/";
					}
				}}
				onKeyUp={e => {
					if (e.key === "Enter") {
						// Trigger the same action as onClick
						if (localContext.modifiedEntities.some(entity => entity.value === true)) {
							if (
								window.confirm(
									`You have unsaved files! Clicking ok will leave the page and you will lose the changes made to the following entities: ${localContext.modifiedEntities
										.filter(entity => entity.value === true)
										.map(entity => entity.name)
										.join(", ")}`,
								)
							) {
								// Lose Changes and navigate to new page anyway
								console.log("Navigating to home page and losing changes");
								window.location.href = "/";
							} else {
								// Do nothing!
								console.log("Navigation cancelled, now save the modified files");
								return;
							}
						} else {
							window.location.href = "/";
						}
					}
				}}
				onKeyDown={e => {
					if (e.key === "Enter") {
						// Prevent the default behavior of the Enter key
						e.preventDefault();
					}
				}}
			/>

			{designTabElements}

			<Outlet />
		</div>
	);
};

export default DesignToolsHeader;
