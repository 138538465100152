import { useOktaAuth } from "@okta/okta-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

const CallbackComponent = () => {
	const navigate = useNavigate();
	const { oktaAuth } = useOktaAuth();
	const [error, setError] = useState(null);
	const [showLoginButton, setShowLoginButton] = useState(false);


	// show login button if loading takes too long
	useEffect(() => {
		const timer = setTimeout(() => {
			setShowLoginButton(true);
		}, 5000);

		return () => clearTimeout(timer);
	}, []);

	useEffect(() => {
		const handleAuthentication = async () => {
			try {
				let tokens = await oktaAuth.token.parseFromUrl();
				tokens = tokens.tokens;
				// Check if the tokens object contains both accessToken and idToken
				if (tokens.accessToken && tokens.idToken) {
					await oktaAuth.tokenManager.setTokens(tokens);
					navigate("/");
				} else {
					console.error("Parsed tokens do not contain both accessToken and idToken", tokens);
					setError("Tokens not found");
				}
			} catch (error) {
				console.error("Error handling authentication", error);
				setError(`Error handling authentication${error}`);
			}
		};

		handleAuthentication();
	}, [navigate]);

	return (
		<div className="flex flex-col w-full h-full items-center justify-center space-y-16">
			{error && (
				<>
					<h1 className="callback__text">Login Failed</h1>
					<h2 className="callback__text">{error}</h2>
				</>
			)}

			{!showLoginButton && (
				<div
					className="w-20 h-20"
				>
					<LoadingSpinner />
				</div>

			)}

			{showLoginButton && (
				<button type="button" className="" onClick={() => navigate("/login")}>
					Go to Login
				</button>
			)}
		</div>
	);
};

export default CallbackComponent;
