import React, { useState, useEffect } from "react";
import CreateAxiosInstance from "../../AxiosInstance/AxiosInstance";
import CreateS3AxiosInstance from "../../AxiosInstance/s3AxiosInstance";
import deepClone from "../../utils/deepClone";


export default function NarrationTab() {
	const axios = CreateAxiosInstance();
	const s3Axios = CreateS3AxiosInstance();
	const [json, setJson] = useState({});

	const [maxID, setMaxID] = useState(0);

	// Fetches the JSON from the S3 bucket and sets it to the state
	useEffect(() => {
		const fetchJSON = async () => {
			const presignedURL = await axios.get("activityDialogue?projectName=Math Arcade&gameName=Produce Packer");
			const json = await s3Axios.get(presignedURL.data);
			setJson(json.data);
			// Get the max ID
			let maximumID = 0;
			const newJson = deepClone(json.data);
			// Get the max ID in the JSON
			for (const character in newJson) {
				for (const score in newJson[character]) {
					for (const id in newJson[character][score]) {
						// added maximumID to the Math.max function to ensure that the maximumID is always the highest
						maximumID = Math.max(maxID, maximumID, Number.parseInt(id));
					}
				}
			}

			setMaxID(maximumID);
		};

		fetchJSON();
	}, []);

	const handleTextChange = event => {
		const text = event.target.value;
		const textId = event.target.id;

		const newJson = deepClone(json);
		for (const characterName in newJson) {
			for (const friendshipScore in newJson[characterName]) {
				for (const dialogueText in newJson[characterName][friendshipScore]) {
					if (dialogueText === textId) {
						newJson[characterName][friendshipScore][dialogueText] = text;
					}
				}
			}
		}

		setJson(newJson);
	};

	const handleDeleteDialogue = (characterName, friendshipScore, dialogueText) => {
		// Assuming your data is stored in some state
		const updatedData = { ...json };

		// Remove the specific dialogue
		delete updatedData[characterName][friendshipScore][dialogueText];

		// Update the state with the modified data
		setJson(updatedData);
	};

	const addCharacter = (characterName = "NewCharacter") => {
		const newJson = deepClone(json);

		if (newJson[characterName] !== undefined) characterName = characterName + "1";
		console.log(maxID);
		// Generate the new JSON with dynamic IDs
		newJson[characterName] = {
			1: {
				[maxID + 1]: "New dialogue Level 1",
			},
			2: {
				[maxID + 2]: "New dialogue Level 2",
			},
			3: {
				[maxID + 3]: "New dialogue Level 3",
			},
			4: {
				[maxID + 4]: "New dialogue level 4",
			},
			5: {
				[maxID + 5]: "New dialogue level 5",
			},
		};

		setJson(newJson);
		setMaxID(maxID + 5); // Increment maxID for the next character
	};

	const deleteCharacter = characterName => {
		console.log(characterName);
		const newJson = deepClone(json);
		delete newJson[characterName];
		setJson(newJson);
	};

	const addDialogue = characterNameAndId => {
		// console.log(characterName);
		const characterName = characterNameAndId.split("_")[0];
		const friendshipScore = characterNameAndId.split("_")[1];
		const newJson = deepClone(json);

		console.log("maxID", maxID);
		newJson[characterName][friendshipScore][maxID + 1] = "New dialogue " + (maxID + 1);

		setJson(newJson);
		setMaxID(maxID + 1); // Increment maxID for the next dialogue
	};

	return (
		<div className="h-full">
			<div className={`flex space-x-4 
				bg-color-bua-background 
				text-color-bua-text-light 
				text-[10px] p-1
				sm:text-sm
				md:text-base md:p-2
				lg:text-lg 
			...`}>
				<button
					type="button"
					className=" bg-color-bua-button-primary px-4 py-1 hover:opacity-75 rounded-md"
					onClick={() => {
						if (window.confirm("Are you sure you want to save? This will overwrite the existing data.")) {
							axios.put("activityDialogue?projectName=Math Arcade&gameName=Produce Packer", json);
						}
					}}
				>
					Save JSON to S3
				</button>

				<button type="button" onClick={() => console.log(json)} className="bg-color-bua-background-light  px-4 py-1 hover:opacity-75 rounded-md">
					Print JSON to Console
				</button>

				<button type="button" onClick={() => navigator.clipboard.writeText(JSON.stringify(json, null, 4))} className="bg-color-bua-background-light  px-4 py-1 hover:opacity-75 rounded-md">
					Copy JSON to clipboard
				</button>

				<button type="button" onClick={() => setJson({})} className="bg-color-bua-background-light  px-4 py-1 hover:opacity-75 rounded-md">
					Clear JSON
				</button>

				<div className="flex flex-col space-y-4 ... bg-color-bua-background-light px-4 py-1 rounded-md"> 	
					<input
						className="w-full text-color-bua-text-dark"
						type="text"
						defaultValue="Character Name"
						id="characterNameInput"
						onChange={e => console.log(e)}
					/>
					<button type="button" onClick={() => addCharacter(document.getElementById("characterNameInput").value)} className="hover:opacity-75">
						Add New Character
					</button>
				</div>
			</div>
			<div className="overflow-y-scroll h-full bg-neutral-600" > 
			{Object.keys(json).map((characterNames, index) => (
				 <div key={index} className="bg-color-bua-background px-4 py-4 m-12 text-color-bua-text-light rounded-lg">
					<div className="flex justify-between bg-color-bua-background-light border-2 px-4 py-4 rounded-xl my-2">
					<p className="font-extrabold text-xl">{characterNames}</p>
					<button
						type="button"
						className="bg-color-bua-button-delete px-4 py-1 hover:opacity-75 rounded-md"
						id={"deleteCharacterButton" + characterNames}
						onClick={e => deleteCharacter(e.target.id.substring(21))}
					>
						Delete Character
					</button>
					</div>
					<div>
						{Object.keys(json[characterNames]).map((friendshipScore, friendshipIndex) => (
							<div key={friendshipIndex}>
								<div className="bg-color-bua-background-light my-4 py-2 px-2 rounded-none">
									<p className="font-bold text-lg" key={friendshipIndex}>friendshipScore: {friendshipScore}</p>
									{Object.keys(json[characterNames][friendshipScore]).map((dialogueText, textIndex) => (
										<h5 key={textIndex} style={{ padding: "20px", display: "flex", alignItems: "center", gap: "10px" }}>
											{dialogueText}
											<textarea
												defaultValue={json[characterNames][friendshipScore][dialogueText]}
												onChange={e => handleTextChange(e)}
												key={dialogueText}
												id={dialogueText}
												className="text-color-bua-text-dark"
												style={{ minWidth: "80%", width: "95%", minHeight: "60px", height: "80px", }}
											/>
											<button
												type="button"
												className="bg-color-bua-button-delete px-2 py-1 hover:opacity-75 rounded-md"
												onClick={() => handleDeleteDialogue(characterNames, friendshipScore, dialogueText)}
											>
												x
											</button>
										</h5>
									))}
									<div className="flex justify-center space-y-4 px-4 py-1 rounded-md">
										<button
											type="button"
											className="bg-color-bua-logo-green px-4 py-1 hover:opacity-75 rounded-md"
											id={"addDialogueButton" + characterNames + "_" + friendshipScore}
											onClick={e => addDialogue(e.target.id.substring(17))}
										>
											Add Dialogue
										</button>
									</div>
								</div>
							</div>
						))}
					</div>
					<br />
				</div>
			))}
			</div>
		</div>
	);
}