import React from "react";
import "./components.css";

/*
    Button List

    This is a custom component for the custom drawer, that is used to display a list of divs that are as wide as the drawer,
    where one is sleected at once. For example the templates in the tempalte library.
*/
const ButtonList = ({ items, selectedButton, setSelectedButton, additionalFillComponent = null }) =>
	items.map((item, index) => (
		<div
			key={index}
			className="button-list__item"
			id="ui-buider__template-library__template"
		>
			<button
				type="button"
				className={`button-list__button ${item === selectedButton ? 'button-list__button--selected' : 'button-list__button--unselected'}`}
				onClick={() => {
					setSelectedButton(item);
				}}
			>
				{item}
			</button>
			{additionalFillComponent ? additionalFillComponent(item) : null}
		</div>
	));

export default ButtonList;
