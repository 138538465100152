const FooterButtons = () => {
	return (
		<div className="footer-buttons">
			<button className="footer-buttons__button">Load Workspace</button>
			<button className="footer-buttons__button">Load Several Blocks</button>
			<button className="footer-buttons__button footer-buttons__button--save">Save</button>
		</div>
	);
};
export default FooterButtons;
