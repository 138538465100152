import React, { useState } from "react";
const CollapsibleTextBox = ({ title, body }) => {
	const [isCollapsed, setIsCollapsed] = useState(true);
	const [inputValue, setInputValue] = useState(body);

	const toggleCollapse = () => {
		setIsCollapsed(!isCollapsed);
	};
	const handleInputChange = event => {
		setInputValue(event.target.value);
	};
	const handleCopy = () => {
		navigator.clipboard.writeText(inputValue);
	};

	return (
		<div className="collapsible-text-box__container">
			<div className="collapsible-text-box__header">
				<button className="collapsible-text-box__button--collapsible-button" onClick={toggleCollapse}>
					{isCollapsed ? "+" : "-"}
				</button>
				<h3 className="collapsible-text-box__title">{title}</h3>
				<button className="collapsible-text-box__button--copy-button" onClick={handleCopy}>
					Copy Text
				</button>
			</div>
			{!isCollapsed && (
				<div className="collapsible-text-box__container--text-box">
					<textarea
						rows={5}
						className="collapsible-text-box__input--text-box"
						value={inputValue}
						onChange={handleInputChange}
						handplaceholder="Type here..."
					/>
				</div>
			)}
		</div>
	);
};

export default CollapsibleTextBox;
