import { Link, Outlet, useNavigate } from "react-router-dom";
import buaHomeButton from "../../assets/images/bua/bua-home-button.svg";
import { activityLayoutStore } from "../../pages/ActivityLayoutTab/ActivityLayoutStore";

const AuthorToolsHeader = props => {
	const navigate = useNavigate();

	const selectedTab = props.tab;

	const authorTabs = [
		{
			text: "Code",
			path: "code",
		},
		{
			text: "Layout",
			path: "activity-layout",
		},
		{
			text: "Narration",
			path: "narration",
		},
	];

	const authorTabElements = authorTabs.map(tab => {
		const button = (
			<Link
				to={`/author-tools/${tab.path}`}
				className={`bg-color-bua-background text-color-bua-text-light text-[10px] p-1
				sm:text-sm
				md:text-base md:p-2
				lg:text-lg 
				hover:opacity-75 
				h-full
				${tab.path === selectedTab ? "bg-color-bua-background-light" : ""} 
				border-x border-color-bua-text-light`}
				key={tab.path}
				onClick={e => {
					if (activityLayoutStore.jsonUnsavedChanges) {
						e.preventDefault(); // prevent navigation
						if (window.confirm("You have unsaved changes. Please save your changes before navigating away.")) {
							// Lose Changes and navigate to new page anyway
							console.log(`Navigating to /author-tools/${tab.path} and losing changes`);
							activityLayoutStore.setJsonUnsavedChanges(false);
							navigate(`/author-tools/${tab.path}`);
						} else {
							// Do nothing!
							console.log("Navigation cancelled, now save the modified files");
							return;
						}
					}
				}}
			>
				{tab.text}
			</Link>
		);

		return button;
	});

	return (
		<div className="author-tools-header__container">
			<img
				className="h-full my-auto w-10 px-1 py-2 hover:opacity-75"
				src={buaHomeButton}
				alt="Home button"
				onClick={() => {
					if (window.confirm("You have unsaved changes. Please save your changes before navigating away.")) {
						// Lose Changes and navigate to new page anyway
						console.log("Navigating to home page and losing changes");
						window.location.href = "/";
					} else {
						// Do nothing!
						console.log("Navigation cancelled, now save the modified files");
						return;
					}
				}}
				onKeyUp={() => { }}
				onKeyDown={() => { }}
			/>

			{authorTabElements}

			<Outlet />
		</div>
	);
};

export default AuthorToolsHeader;
