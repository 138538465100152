import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "./index.css";

import { MantineProvider } from "@mantine/core";

async function loadEnvVariables() {
	try {
		const host = `${window.location.protocol}//${window.location.host}`;
		console.log("Host:", `${host}/settings/env.json`);
		const response = await fetch(`${host}/settings/env.json`);
		console.log("Response:", response);
		const appEnv = await response.json();
		window.APP_ENV = appEnv; // Attach fetched environment variables to window
		console.log("Environment settings:", window.APP_ENV);
	} catch (error) {
		console.error("Failed to load environment variables:", error);
	}
}

loadEnvVariables().then(() => {
	ReactDOM.createRoot(document.getElementById("root")).render(
		<MantineProvider>
			<App />
		</MantineProvider>,
	);
});
