import React from "react";
import { useEffect, useState } from "react";
import Dropdown from "../../../Dropdown/Dropdown";
import ToggleSwitch from "../../../ToggleSwitch/ToggleSwitch";

const GeneralSettingsTab = ({ accessibilitySettings, setAccessibilityAttribute }) => {
	const languages = ["English", "Spanish", "French", "German"];
	const blackAndWhite = accessibilitySettings.display.blackAndWhite;

	/**
	 * This function is used to set the language
	 * @param {Event} e - Event object
	 */
	function setLanguage(e) {
		setAccessibilityAttribute("general", "language", e);
	}

	const options = languages.map(language => {
		return {
			key: language,
			value: language,
		};
	});

	return (
		<div className="w-full h-full p-2 max-w-sm flex flex-col gap-4">
			<Dropdown
				label="Language"
				defaultValue={accessibilitySettings.general.language}
				options={options}
				onChange={setLanguage}
				colorMode={"light"}
			/>
		</div>
	);
};

export default GeneralSettingsTab;
