import React from 'react';
import FullVolumeIcon from '../../assets/images/bua/FullVolumeIcon';
import MuteIcon from '../../assets/images/bua/MuteIcon';

interface AudioSliderProps {
    label: string;
    min: number;
    max: number;
    value: number;
    disabled: boolean;
    onChange: (value: number) => void;
}

export default function AudioSlider({
    label,
    min,
    max,
    value,
    disabled,
    onChange
}: AudioSliderProps) {
    return (
        <div className="w-full flex items-center justify-evenly px-2">
            <div className="w-3/12 text-sm sm:text-base">{label}</div>
            <div className="w-2/12 max-w-20">
                <MuteIcon color={"DimGray"} />
            </div>
            <div className="w-1/2 max-w-72">
                <input
                    className="
                        w-full active:cursor-grabbing cursor-grab
                        rounded-lg
                        focus:ring-offset-2 focus:ring-[#1FC3FF] focus:ring focus:outline-none
                    "
                    type="range"
                    min={min}
                    max={max}
                    value={value}
                    disabled={disabled}
                    onChange={(e) => onChange(Number(e.target.value))}
                />
            </div>
            <div className="w-2/12 max-w-20">
                <FullVolumeIcon color={"DimGray"} />
            </div>
        </div >
    )
}