import React from "react";
const OrdersIcon = ({ color = "#28C178", strokeColor = "#FFFFFF", size = 60, border = "none" }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 25 25"
		xmlns="http://www.w3.org/2000/svg"
		style={{
			fillRule: "evenodd",
			clipRule: "evenodd",
			strokeLinejoin: "round",
			strokeMiterlimit: 1.5,
			border: border,
		}}
	>
		<defs>
			<linearGradient
				id="_Linear1"
				x1="0"
				y1="0"
				x2="1"
				y2="0"
				gradientUnits="userSpaceOnUse"
				gradientTransform="matrix(3.58438e-15,58.5374,-58.5374,3.58438e-15,29.5406,0)"
			>
				<stop offset="0" style={{ stopColor: "rgb(40,193,120)", stopOpacity: 1 }} />
				<stop offset="0.48" style={{ stopColor: "rgb(29,179,107)", stopOpacity: 1 }} />
				<stop offset="1" style={{ stopColor: "rgb(0,140,70)", stopOpacity: 1 }} />
			</linearGradient>
		</defs>
		<g transform="matrix(0.423941,0,0,0.427545,-0.0227702,0)">
			<path
				d="M59.024,8.78L59.024,49.755C59.024,54.603 55.09,58.535 50.246,58.535L8.832,58.535C3.988,58.535 0.054,54.603 0.054,49.755L0.054,8.78C0.054,3.932 3.988,0 8.832,0L50.246,0C55.09,0 59.024,3.932 59.024,8.78Z"
				style={{ fill: color }}
			/>
		</g>
		<g transform="matrix(0.423941,0,0,0.427545,-0.0227702,0)">
			<path
				d="M48.939,17.794L48.939,34.102C48.939,37.792 45.943,40.789 42.253,40.789L29.056,40.789L16.413,50.238L18.345,40.789L16.825,40.789C13.134,40.789 10.138,37.792 10.138,34.102L10.138,17.794C10.138,14.104 13.134,11.108 16.825,11.108L42.253,11.108C45.943,11.108 48.939,14.104 48.939,17.794Z"
				style={{ fill: strokeColor, stroke: strokeColor, strokeWidth: "2px" }}
			/>
		</g>
	</svg>
);

const EquationsIcon = ({ color = "#29AAFD", strokeColor = "#FFFFFF", size = 60, border = "none" }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 25 25"
		xmlns="http://www.w3.org/2000/svg"
		style={{
			fillRule: "evenodd",
			clipRule: "evenodd",
			strokeLinejoin: "round",
			strokeMiterlimit: 1.5,
			border: border,
		}}
	>
		<defs>
			<linearGradient
				id="_Linear1"
				x1="0"
				y1="0"
				x2="1"
				y2="0"
				gradientUnits="userSpaceOnUse"
				gradientTransform="matrix(3.58438e-15,58.5374,-58.5374,3.58438e-15,30.3247,0)"
			>
				<stop offset="0" style={{ stopColor: "rgb(41,170,253)", stopOpacity: 1 }} />
				<stop offset="0.47" style={{ stopColor: "rgb(30,156,238)", stopOpacity: 1 }} />
				<stop offset="1" style={{ stopColor: "rgb(0,117,197)", stopOpacity: 1 }} />
			</linearGradient>
		</defs>
		<g transform="matrix(0.42394,0,0,0.42394,-0.355164,0.0923169)">
			<path
				d="M59.808,8.78L59.808,49.755C59.808,54.603 55.874,58.535 51.03,58.535L9.616,58.535C4.772,58.535 0.838,54.603 0.838,49.755L0.838,8.78C0.838,3.932 4.772,0 9.616,0L51.03,0C55.874,0 59.808,3.932 59.808,8.78Z"
				style={{ fill: color }}
			/>
		</g>
		<g transform="matrix(0.42394,0,0,0.42394,-0.355164,0.0923169)">
			<path d="M17.41,23.448L43.237,23.448" style={{ fill: "none", stroke: strokeColor, strokeWidth: "8px" }} />
		</g>
		<g transform="matrix(0.42394,0,0,0.42394,-0.355164,0.0923169)">
			<path d="M17.41,35.082L43.237,35.082" style={{ fill: "none", stroke: strokeColor, strokeWidth: "8px" }} />
		</g>
	</svg>
);

const NotesIcon = ({ color = "#917CEB", strokeColor = "#FFFFFF", size = 60, border = "none" }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 25 25"
		xmlns="http://www.w3.org/2000/svg"
		style={{
			fillRule: "evenodd",
			clipRule: "evenodd",
			strokeLinejoin: "round",
			strokeMiterlimit: 2,
			border: border,
		}}
	>
		<g transform="matrix(0.423941,0,0,0.423941,-0.25808,-0.218212)">
			<path
				d="M59.579,9.513L59.579,50.487C59.579,55.336 55.645,59.268 50.801,59.268L9.387,59.268C4.543,59.268 0.609,55.336 0.609,50.487L0.609,9.513C0.609,4.664 4.543,0.732 9.387,0.732L50.801,0.732C55.645,0.732 59.579,4.664 59.579,9.513Z"
				style={{ fill: color, fillRule: "nonzero" }}
			/>
		</g>
		<g transform="matrix(0.423941,0,0,0.423941,-0.25808,-0.218212)">
			<path
				d="M33.57,15.857C34.238,14.977 35.466,14.839 36.312,15.549L42.468,20.709C43.314,21.419 43.459,22.707 42.791,23.587L29.007,41.744C28.34,42.623 27.112,42.761 26.265,42.052L20.11,36.891C19.263,36.182 19.119,34.893 19.787,34.014L33.57,15.857Z"
				style={{ fill: strokeColor, fillRule: "nonzero" }}
			/>
		</g>
		<g transform="matrix(0.423941,0,0,0.423941,-0.25808,-0.218212)">
			<path
				d="M16.514,46.942C16.499,47.623 17.11,48.136 17.739,47.969L24.413,46.198C25.191,45.991 25.4,44.939 24.768,44.409L18.255,38.949C17.623,38.419 16.694,38.867 16.675,39.711L16.514,46.942Z"
				style={{ fill: strokeColor, fillRule: "nonzero" }}
			/>
		</g>
		<g transform="matrix(0.423941,0,0,0.423941,-0.25808,-0.218212)">
			<path
				d="M45.815,20.465C45.484,20.906 44.87,20.979 44.445,20.626L36.721,14.224C36.295,13.872 36.219,13.228 36.551,12.786L38.084,10.743C38.747,9.86 39.974,9.715 40.824,10.42L47.009,15.545C47.859,16.25 48.011,17.538 47.348,18.421L45.815,20.465Z"
				style={{ fill: strokeColor, fillRule: "nonzero" }}
			/>
		</g>
		<defs>
			<linearGradient
				id="_Linear1"
				x1="0"
				y1="0"
				x2="1"
				y2="0"
				gradientUnits="userSpaceOnUse"
				gradientTransform="matrix(3.58438e-15,58.5374,-58.5374,3.58438e-15,30.0957,0.732483)"
			>
				<stop offset="0" style={{ stopColor: "rgb(145,124,235)", stopOpacity: 1 }} />
				<stop offset="0.49" style={{ stopColor: "rgb(122,99,231)", stopOpacity: 1 }} />
				<stop offset="1" style={{ stopColor: "rgb(56,29,221)", stopOpacity: 1 }} />
			</linearGradient>
		</defs>
	</svg>
);

const GraphIcon = ({ color = "#FB6F12", strokeColor = "#FFFFFF", size = 60, border = "none" }) => (
	<div
		style={{
			width: size,
			height: size,
			overflow: "hidden",
			border: border,
			borderRadius: "8px",
		}}
	>
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 25 25"
			xmlns="http://www.w3.org/2000/svg"
			style={{
				fillRule: "evenodd",
				clipRule: "evenodd",
				strokeLinecap: "round",
				strokeLinejoin: "round",
				strokeMiterlimit: 1.5,
			}}
		>
			<g id="graph" transform="matrix(0.996294,0,0,0.996294,-5.3533e-17,0.0923169)">
				<g transform="matrix(0.425517,0,0,0.425517,-0.0431125,0)">
					<path
						d="M59.072,8.78L59.072,49.755C59.072,54.603 55.138,58.535 50.294,58.535L8.88,58.535C4.036,58.535 0.101,54.603 0.101,49.755L0.101,8.78C0.101,3.932 4.036,0 8.88,0L50.294,0C55.138,0 59.072,3.932 59.072,8.78Z"
						style={{ fill: color }}
					/>
				</g>
				<g transform="matrix(0.423941,0,0,0.423941,-0.0429528,0.184576)">
					<path
						d="M12.226,13.507L12.226,46.951L49.003,46.951"
						style={{ fill: "none", stroke: strokeColor, strokeWidth: "8px" }}
					/>
				</g>
				<g transform="matrix(0.423941,0,0,0.423941,-0.0429528,0.184576)">
					<path
						d="M21.619,38.059C21.619,38.059 45.866,38.868 45.866,14.588"
						style={{ fill: "none", stroke: strokeColor, strokeWidth: "5px" }}
					/>
				</g>
			</g>
			<defs>
				<linearGradient
					id="_Linear1"
					x1="0"
					y1="0"
					x2="1"
					y2="0"
					gradientUnits="userSpaceOnUse"
					gradientTransform="matrix(3.58438e-15,58.5374,-58.5374,3.58438e-15,29.5883,0)"
				>
					<stop offset="0" style={{ stopColor: "rgb(251,111,18)", stopOpacity: 1 }} />
					<stop offset="0.51" style={{ stopColor: "rgb(230,101,15)", stopOpacity: 1 }} />
					<stop offset="1" style={{ stopColor: "rgb(164,70,7)", stopOpacity: 1 }} />
				</linearGradient>
			</defs>
		</svg>
	</div>
);

export default GraphIcon;

const GraphExpand = ({ color = "#00A7E4", strokeColor = "#FFFFFF", style }) => (
	<svg viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ ...style }}>
		<title>{"Graph expand icon"}</title>
		<circle cx="32.5" cy="32.5" r="32.5" fill={color} />
		<path
			d="M34.3174 49.3179L49.317 49.3178M49.3173 34.3173L49.317 49.3178M49.317 49.3178L36.4997 36.5005"
			stroke={strokeColor}
			strokeWidth="7"
			strokeMiterlimit="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M30.5 15.4998L15.5004 15.4998M15.5001 30.5003L15.5004 15.4998M15.5004 15.4998L28.3177 28.3171"
			stroke={strokeColor}
			strokeWidth="7"
			strokeMiterlimit="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

const GraphExpandSelect = ({ color = "#00FFFF", strokeColor = "#FFFFFF", style }) => (
	<svg width="69" height="69" viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ ...style }}>
		<title>{"Graph expand icon selected"}</title>
		<circle cx="34.5" cy="34.5" r="33.5" fill={color} />
		<path
			d="M36.3174 51.3179L51.317 51.3178M51.3173 36.3173L51.317 51.3178M51.317 51.3178L38.4997 38.5005"
			stroke={strokeColor}
			strokeWidth="7"
			strokeMiterlimit="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M32.5 17.4998L17.5004 17.4998M17.5001 32.5003L17.5004 17.4998M17.5004 17.4998L30.3177 30.3171"
			stroke={strokeColor}
			strokeWidth="7"
			strokeMiterlimit="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

const FriendShip = ({ color = "#58BCBE", strokeColor = "#FFC425", style, score }) => (
	<svg
		viewBox="0 0 57 57"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		style={{ width: "100%", height: "100%", ...style }}
	>
		<title>{`Friendship score icon, ${score} segments`}</title>
		<path
			d="M28.0537 56.3762C43.5474 56.3762 56.1074 43.8162 56.1074 28.3225C56.1074 12.8289 43.5474 0.268799 28.0537 0.268799C12.5601 0.268799 0 12.8289 0 28.3225C0 43.8162 12.5601 56.3762 28.0537 56.3762Z"
			fill={color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M26.5277 9.64352C26.8242 9.07594 27.4131 8.72021 28.0534 8.72021C28.6937 8.72021 29.2787 9.07594 29.5751 9.64352C31.251 12.8514 33.836 17.8007 34.8162 19.6777C35.0652 20.1548 35.5236 20.4876 36.0572 20.5769C38.1441 20.9295 43.65 21.8583 47.2191 22.4607C47.8476 22.567 48.3693 23.0156 48.5669 23.6243C48.7645 24.233 48.6064 24.9014 48.1598 25.3583C45.6262 27.9432 41.7172 31.9309 40.235 33.4435C39.8596 33.8277 39.6856 34.3664 39.7647 34.8985C40.073 36.9937 40.8912 42.5169 41.4208 46.0971C41.5157 46.7303 41.2508 47.3631 40.7331 47.7394C40.2153 48.1156 39.5315 48.1729 38.9544 47.888C35.7134 46.2777 30.7135 43.7928 28.8202 42.8505C28.338 42.611 27.7688 42.611 27.2866 42.8505C25.3934 43.7928 20.3895 46.2777 17.1524 47.888C16.5753 48.1729 15.8915 48.1156 15.3738 47.7394C14.856 47.3631 14.5912 46.7303 14.686 46.0971C15.2157 42.5169 16.0338 36.9937 16.3421 34.8985C16.4212 34.3664 16.2473 33.8277 15.8718 33.4435C14.3896 31.9309 10.4806 27.9432 7.94702 25.3583C7.50038 24.9014 7.34227 24.233 7.5399 23.6243C7.73752 23.0156 8.25927 22.567 8.88771 22.4607C12.4568 21.8583 17.9626 20.9295 20.0496 20.5769C20.5832 20.4876 21.0416 20.1548 21.2867 19.6777C22.2709 17.8007 24.8558 12.8514 26.5277 9.64352Z"
			fill="white"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M25.0695 8.88112C25.6505 7.77007 26.8007 7.07324 28.0536 7.07324C29.3066 7.07324 30.4568 7.77007 31.0378 8.88112L36.2788 18.9157C36.2867 18.9355 36.3065 18.9493 36.3302 18.9533L47.4921 20.8367C48.7292 21.045 49.745 21.9232 50.1324 23.1153C50.5197 24.3074 50.2114 25.616 49.3379 26.5113L41.4131 34.5965C41.3973 34.6123 41.3894 34.6349 41.3933 34.6574L43.0495 45.856C43.2352 47.0959 42.7135 48.335 41.7017 49.0718C40.6859 49.8085 39.346 49.9208 38.2235 49.3631L28.0853 44.3252C28.0655 44.3153 28.0418 44.3153 28.022 44.3252L17.8838 49.3631C16.7613 49.9208 15.4215 49.8085 14.4057 49.0718C13.3938 48.335 12.8721 47.0959 13.0578 45.856L14.7139 34.6574C14.7179 34.6349 14.71 34.6123 14.6942 34.5965L6.76939 26.5113C5.89194 25.616 5.58762 24.3074 5.97496 23.1153C6.36231 21.9232 7.3781 21.045 8.61523 20.8367L19.7771 18.9533C19.8008 18.9493 19.8206 18.9355 19.8285 18.9157L25.0695 8.88112ZM26.528 9.64357L21.2869 19.6778C21.0419 20.1549 20.5834 20.4877 20.0498 20.577L8.88796 22.4607C8.25951 22.5671 7.73776 23.0157 7.54014 23.6244C7.34251 24.2331 7.50062 24.9014 7.94726 25.3583L15.872 33.4436C16.2475 33.8278 16.4214 34.3665 16.3424 34.8985L14.6863 46.0971C14.5914 46.7303 14.8562 47.3631 15.374 47.7394C15.8918 48.1157 16.5756 48.173 17.1526 47.888L27.2869 42.8505C27.7691 42.611 28.3382 42.611 28.8204 42.8505L38.9547 47.888C39.5317 48.173 40.2155 48.1157 40.7333 47.7394C41.2511 47.3631 41.5159 46.7303 41.421 46.0971L39.7649 34.8985C39.6859 34.3665 39.8598 33.8278 40.2353 33.4436L48.16 25.3583C48.6067 24.9014 48.7648 24.2331 48.5672 23.6244C48.3695 23.0157 47.8478 22.5671 47.2194 22.4607L36.0575 20.577C35.5239 20.4877 35.0654 20.1549 34.8164 19.6778L29.5754 9.64357C29.279 9.07599 28.6939 8.72026 28.0536 8.72026C27.4133 8.72026 26.8244 9.07599 26.528 9.64357Z"
			fill="#FFFFFF"
		/>
		{score >= 1 && score <= 5 && (
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20.8008 20.3036C20.9707 20.1806 21.1091 20.0194 21.2119 19.8297L26.8363 9.05985C27.0734 8.60611 27.5398 8.32153 28.0536 8.32153C28.5674 8.32153 29.0339 8.60611 29.271 9.05985L34.8954 19.8297C34.9942 20.0202 35.1366 20.1818 35.3065 20.3051L28.0536 30.6172L20.8008 20.3036Z"
				fill={strokeColor}
			/>
		)}
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M20.8008 20.3036C20.9707 20.1806 21.1091 20.0194 21.2119 19.8297L26.8363 9.05985C27.0734 8.60611 27.5398 8.32153 28.0536 8.32153C28.5674 8.32153 29.0339 8.60611 29.271 9.05985L34.8954 19.8297C34.9942 20.0202 35.1366 20.1818 35.3065 20.3051L28.0536 30.6172L20.8008 20.3036ZM22.2158 20.3474L28.0536 28.6489L33.8915 20.3498L28.2671 9.58396C28.2275 9.50412 28.1445 9.45392 28.0536 9.45392C27.9627 9.45392 27.8797 9.50412 27.8402 9.58396L22.2158 20.3474Z"
			fill="white"
		/>
		{score >= 2 && score <= 5 && (
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M35.3066 20.3052C35.4766 20.4285 35.6742 20.5131 35.8876 20.5486L47.8676 22.5704C48.3736 22.6553 48.7886 23.0142 48.9467 23.5012C49.1048 23.9881 48.9783 24.5229 48.6226 24.8885L40.1168 33.5658C39.9705 33.7156 39.8599 33.8951 39.7966 34.0891L28.0537 30.6173L35.3066 20.3052Z"
				fill={strokeColor}
			/>
		)}
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M35.3066 20.3052C35.4766 20.4285 35.6742 20.5131 35.8876 20.5486L47.8676 22.5704C48.3736 22.6553 48.7886 23.0142 48.9467 23.5012C49.1048 23.9881 48.9783 24.5229 48.6226 24.8885L40.1168 33.5658C39.9705 33.7156 39.8599 33.8951 39.7966 34.0891L28.0537 30.6173L35.3066 20.3052ZM39.3144 32.7662L47.8123 24.096C47.8756 24.0312 47.8993 23.9371 47.8676 23.851C47.84 23.7652 47.7688 23.7016 47.6779 23.6865L35.7295 21.6708L29.8877 29.9785L39.3144 32.7662Z"
			fill="white"
		/>
		{score >= 3 && score <= 5 && (
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M39.7966 34.0891C39.7255 34.2922 39.7057 34.5116 39.7373 34.7294L41.516 46.7485C41.595 47.2549 41.3816 47.7612 40.9666 48.0624C40.5516 48.3631 40.0061 48.409 39.5476 48.1813L28.6664 42.7743C28.4727 42.6787 28.2632 42.6308 28.0537 42.6308V30.6172L39.7966 34.0891Z"
				fill={strokeColor}
			/>
		)}
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M39.7966 34.0891C39.7255 34.2922 39.7057 34.5116 39.7373 34.7294L41.516 46.7485C41.595 47.2549 41.3816 47.7612 40.9666 48.0624C40.5516 48.3631 40.0061 48.409 39.5476 48.1813L28.6664 42.7743C28.4727 42.6787 28.2632 42.6308 28.0537 42.6308V30.6172L39.7966 34.0891ZM29.1842 41.7688L40.0496 47.1671C40.1326 47.2074 40.2275 47.1991 40.3026 47.1462C40.3737 47.0932 40.4132 47.0039 40.3974 46.9142L38.6228 34.923L29.1842 32.1326V41.7688Z"
			fill="white"
		/>
		{score >= 4 && score <= 5 && (
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M28.0538 42.6308C27.8443 42.6308 27.6348 42.6787 27.4412 42.7743L16.5599 48.1813C16.1014 48.409 15.556 48.3631 15.141 48.0624C14.726 47.7612 14.5125 47.2549 14.5876 46.7485L16.3702 34.7294C16.4018 34.5104 16.382 34.2903 16.3109 34.0859L28.0538 30.6172V42.6308Z"
				fill={strokeColor}
			/>
		)}
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M28.0538 42.6308C27.8443 42.6308 27.6348 42.6787 27.4412 42.7743L16.5599 48.1813C16.1014 48.409 15.556 48.3631 15.141 48.0624C14.726 47.7612 14.5125 47.2549 14.5876 46.7485L16.3702 34.7294C16.4018 34.5104 16.382 34.2903 16.3109 34.0859L28.0538 30.6172V42.6308ZM26.9194 41.7688V32.1322L17.4848 34.9195L15.7101 46.9142C15.6943 47.0039 15.7338 47.0932 15.805 47.1462C15.8801 47.1991 15.9749 47.2074 16.0579 47.1671L26.9194 41.7688Z"
			fill="white"
		/>
		{score === 5 && (
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.3109 34.0859C16.2437 33.893 16.137 33.7147 15.9908 33.5657L7.48497 24.8884C7.12924 24.5228 7.00276 23.988 7.16086 23.501C7.31896 23.0141 7.73397 22.6552 8.23989 22.5702L20.2199 20.5485C20.4334 20.5126 20.631 20.4276 20.8009 20.3035L28.0538 30.6171L16.3109 34.0859Z"
				fill={strokeColor}
			/>
		)}
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16.3109 34.0859C16.2437 33.893 16.137 33.7147 15.9908 33.5657L7.48497 24.8884C7.12924 24.5228 7.00276 23.988 7.16086 23.501C7.31896 23.0141 7.73397 22.6552 8.23989 22.5702L20.2199 20.5485C20.4334 20.5126 20.631 20.4276 20.8009 20.3035L28.0538 30.6171L16.3109 34.0859ZM16.7891 32.7637L26.2199 29.978L20.378 21.6702L8.4296 23.6864C8.33869 23.7014 8.2636 23.7651 8.23593 23.8508C8.20826 23.937 8.23199 24.0311 8.29523 24.0959L16.7891 32.7637Z"
			fill="white"
		/>
	</svg>
);

const OrderAcceptTick = ({ strokeColor = "#FFFFFF", style, width = 21, height = 18 }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={width}
		height={height}
		viewBox="0 0 21 18"
		fill="none"
		style={{ ...style }}
		altern
	>
		<path
			d="M3 10.5823L7.76198 15L18 3"
			stroke={strokeColor}
			stroke-width="5"
			stroke-miterlimit="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);

export { OrdersIcon, EquationsIcon, NotesIcon, GraphIcon, GraphExpand, FriendShip, OrderAcceptTick };
