/* eslint-disable no-undef */
import React, { useEffect, useRef } from "react";
import Modal from "react-modal";
import { GraphExpand } from "./SVGIcons";
import { blackAndWhite } from "./colors";

/**
 * Functional componwnt for the Desmos Graph
 * @param {Object} studentActivityData - student activity data - contains the equations and coordinates
 * @param {String} colourPallete - the colour pallete for the graph - color, blackAndWhite
 * @returns
 */
function DesmosGraph({ studentActivityData, colourPallete }) {
	// Create a ref for the graph
	const graph = useRef(null);
	// Get the equations for the graph
	const equation1 = studentActivityData.equation1;
	const equation2 = studentActivityData.equation2;
	// Get the coordinates for the graph
	const { graphCoordinateX, graphCoordinateY, simulationCoordinateX, simulationCoordinateY } = studentActivityData;
	// Create the equation strings for ....
	const equation1String = `${equation1.producePriceA > 1 ? equation1.producePriceA : ""}x + ${
		equation1.producePriceB > 1 ? equation1.producePriceB : ""
	}y = ${equation1.budget}`;
	const equation2String = `${equation2.producePriceA > 1 ? equation2.producePriceA : ""}x + ${
		equation2.producePriceB > 1 ? equation2.producePriceB : ""
	}y = ${equation2.budget}`;

	// Load the Desmos script, if the graph is not loaded
	useEffect(() => {
		if (!graph.current) {
			loadDesmosScript();
		}
	}, []);

	// Set the expressions for the graph and update them when they are updated in the student activity data
	useEffect(() => {
		if (graph.current) {
			graph.current.setExpression({
				id: "coordinate1",
				latex: `(${graphCoordinateX},${graphCoordinateY})`,
				color: Desmos.Colors.GREEN,
			});
			graph.current.setExpression({
				id: "coordinate2",
				latex: `(${simulationCoordinateX},${simulationCoordinateY})`,
				color: Desmos.Colors.PURPLE,
			});
		}
	}, [graphCoordinateX, graphCoordinateY, simulationCoordinateX, simulationCoordinateY]);

	/**
	 * Initialize the graph
	 * @returns
	 */
	const initializeGraph = () => {
		// Get the graph element
		const graphElement = document.getElementById("calculator");
		// If the graph element does not exist or has no children, return
		if (!graphElement || graphElement.children.length !== 0) return;

		// Create the graph
		const desmosGraph = Desmos.GraphingCalculator(graphElement, {
			expressions: false,
			settingsMenu: false,
			zoomButtons: false,
			lockViewport: true,
		});
		// Update the settings for the calculator - axis labels and math bounds
		desmosGraph.updateSettings({ xAxisLabel: "X", yAxisLabel: "Y" });
		desmosGraph.setMathBounds({ left: -1, right: 10, bottom: -1, top: 10 });

		// Define expressions
		const expressions = [
			{
				id: "equation1",
				latex: equation1String,
				lineStyle: Desmos.Styles.SOLID,
				color: colourPallete === "blackAndWhite" ? blackAndWhite.graphLine1 : Desmos.Colors.BLUE,
			},
			{
				id: "equation2",
				latex: equation2String,
				lineStyle: Desmos.Styles.DASHED,
				color: colourPallete === "blackAndWhite" ? blackAndWhite.graphLine2 : Desmos.Colors.ORANGE,
			},
			{
				id: "coordinate2",
				latex: `(${simulationCoordinateX},${simulationCoordinateY})`,
				color: colourPallete === "blackAndWhite" ? blackAndWhite.graphPoint2 : Desmos.Colors.PURPLE,
				pointStyle: Desmos.Styles.OPEN,
				pointSize: 20,
			},
			{
				id: "coordinate1",
				latex: `(${graphCoordinateX},${graphCoordinateY})`,
				color: colourPallete === "blackAndWhite" ? blackAndWhite.graphPoint1 : "#000000",
				pointSize: 8,
			},
		];

		// Set the expressions for the graph
		for (const expr of expressions) {
			desmosGraph.setExpression(expr);
		}

		// Set the graph reference
		graph.current = desmosGraph;
	};

	/**
	 *  Load the Desmos script
	 * @returns {void}
	 */
	const loadDesmosScript = () => {
		// Check if the script is already loaded, return if so
		if (document.getElementById("scriptDesmos")) {
			initializeGraph();
			return;
		}

		// Load the Desmos script
		const script = document.createElement("script");
		script.id = "scriptDesmos";
		script.src = `https://www.desmos.com/api/v1.8/calculator.js?apiKey=${
			process.env?.REACT_APP_DESMOS_API_KEY || window.APP_ENV?.REACT_APP_DESMOS_API_KEY
		}`;
		script.async = true;

		// When the script is loaded, initialize the graph
		script.onload = () => {
			initializeGraph();
			return;
		};

		document.body.appendChild(script);
	};

	return <div className="w-full h-full flex flex-col justify-between bg-white z-10" id="calculator" />;
}

/**
 * Functional component for the Desmos Modal
 * @param {*} param0
 * @returns
 */
function DesmosModal({ modalOpen, setModalIsOpen, studentActivityData, colourPallete }) {
	return (
		<Modal isOpen={modalOpen} ariaHideApp={false} id="graph-tab__modal">
			{/* Expand/contract button */}
			<button
				type="button"
				onClick={() => setModalIsOpen(!modalOpen)}
				id="graph-tab__modal__button"
				className="relative w-[100%] border-none bg-none z-100"
			>
				<GraphExpand
					color={colourPallete === "blackAndWhite" ? blackAndWhite.graphExpandBackgroundSelected : undefined}
					style={{ width: "3.375rem", height: "3.375rem", position: "absolute", zIndex: "100", top: "5", right: "0" }}
				/>
			</button>
			{/* The Desmos graph */}
			<DesmosGraph studentActivityData={studentActivityData} colourPallete={colourPallete} />
		</Modal>
	);
}

export { DesmosGraph, DesmosModal };
