import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { act, useEffect, useRef, useState } from "react";
import CreateAxiosInstance from "../../AxiosInstance/AxiosInstance";
import CreateS3AxiosInstance from "../../AxiosInstance/s3AxiosInstance";
import truckLoadingGif from "../../assets/loadingGifs/producePackerLoading.gif";
import Phone from "../Phone/Phone";
import DevInfo from "../DevInfo/DevInfo.jsx";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import ResultModal from "../ResultModal/ResultModal.jsx";
import Scoring from "../Scoring/Scoring";
import SettingsMenu from "../SettingsMenu/SettingsMenu";
import SimulationWindow from "../SimulationWindow/SimulationWindow";
import StudentViewHeader from "../StudentViewHeader/StudentViewHeader";
import TutorialsMenu from "../TutorialsMenu/TutorialsMenu";
// new student view store class
import { svs } from "./SVS.js";

global.svs = svs;

// Variable for denoting when the tab event listener has been added
let tabEventListenerAdded = false;

const StudentView = observer(() => {
	const axios = CreateAxiosInstance();
	const s3Axios = CreateS3AxiosInstance();
	const renderCount = useRef(0); // Use to prevent thing happening on the initial renders
	const renderCount2 = useRef(0); // Use to prevent thing happening on the initial renders

	let screen = "large";
	let simHeight = "100%";
	if (window.innerWidth < 900) {
		screen = "small";
		simHeight = "50%";
	}

	const [screensize, setScreensize] = useState(screen);
	const [simulationHeight, setSimulationHeight] = useState(simHeight);

	const {
		accessibilitySettings,
		setAccessibilityAttribute,
		resetToDefaultAccessibilitySettings,
		toggleDisplayAccessibilityMenu,
		toggleDisplayTutorialsMenu,
		displayTutorialsMenu,
		displayAccessibilityMenu,
		overrideAccessibilitySettings,
		checkIfKeyBeingUsed,
		playSound,
		activityData,
		gameSaveState,
		startGame,
		setStudentActivityAttribute,
		setAlgebraData,
		restartCurrentActivity,
		moveOnToNextActivity,
		simulatorLoaded,
		setActivityDataAttribute,
		setActivityContent,
		setActivityDialogue,
		setMutedSettings,
		mutedSoundSettings,
		addActivityAttempt,
		kValue,
		setKValue,
	} = svs;

	useEffect(() => {
		const fetchActivityContentJson = async () => {
			const preSignedUrl = await axios.get("/activityContent?projectName=Math Arcade&gameName=Produce Packer");
			const response = await s3Axios.get(preSignedUrl.data);

			if (response.status === 200) {
				setActivityContent(response.data);
				return;
			}

			console.error("Error fetching content JSON");
		};

		const fetchActivityDialogue = async () => {
			const presignedURL = await axios.get("activityDialogue?projectName=Math Arcade&gameName=Produce Packer");
			const response = await s3Axios.get(presignedURL.data);

			if (response.status === 200) {
				setActivityDialogue(response.data);
				return;
			}

			console.error("Error fetching dialogue JSON");
		};

		// Function to start the game
		async function beginGame() {
			// Get the user from local storage
			const userString = window.localStorage.getItem("okta-token-storage");
			const userObject = JSON.parse(userString);
			let user = userObject.accessToken?.claims?.sub;
			// user = "stephen.byrne@imaginelearning.com";
			// If no user, set user to be "default", which is the starting point if the user hasnt played the game before
			if (!user) {
				user = "default";
			}
			let gameSaveStateS3Data;
			let attemptsS3Data;

			try {
				// Send get request to get the game save state from the server
				const gameSaveStateS3URL = await axios.get(
					`/gameSaveState?projectName=Math Arcade&gameName=Produce Packer&user=${user}`,
				);
				const { data } = await s3Axios.get(gameSaveStateS3URL?.data);
				gameSaveStateS3Data = data;
			} catch (error) {
				console.error("Error fetching game save state", error);
			}

			// Send request to get the attempts data from the server
			try {
				const attemptsS3URL = await axios.get(
					`/activityAttempts?projectName=Math Arcade&gameName=Produce Packer&user=${user}`,
				);
				const { data } = await s3Axios.get(attemptsS3URL?.data);
				attemptsS3Data = data;
			} catch (error) {
				console.error("Error fetching attempts", error);
			}

			const activityData = gameSaveStateS3Data ? gameSaveStateS3Data.activityData : null;
			const gameSaveData = gameSaveStateS3Data ? gameSaveStateS3Data.gameSaveData : null;
			const attemptData = attemptsS3Data || {};

			console.log("Start Game Data", attemptsS3Data, gameSaveData, activityData);
			startGame(gameSaveData, activityData, attemptData);
		}

		const fetchDataAndStartGame = async () => {
			try {
				console.log("fetching activity content and dialogue");
				await Promise.all([fetchActivityContentJson(), fetchActivityDialogue()]).then(() => {
					console.log("both fetches completed");
					beginGame();
				});
			} catch (error) {
				console.error("Error in fetchDataAndStartGame:", error);
			}
		};

		fetchDataAndStartGame();
	}, []);

	const [showResultModal, setShowResultModal] = useState(false);

	const aspectRatio = 0.5;
	const simulationWindow = document.getElementById("simulationWindow");
	const [height, setHeight] = useState(simulationWindow?.offsetHeight);
	const [width, setWidth] = useState(simulationWindow?.offsetHeight * aspectRatio);

	useEffect(() => {
		if (simulationWindow) {
			// Set the height of the phoneto the height of the simulation window
			setHeight(simulationWindow.offsetHeight);
			// Set the width of the phone based on the height and aspect ratio
			setWidth(simulationWindow.offsetHeight * aspectRatio);
		}
	}, [simulationWindow]);
	window.onresize = () => {
		console.log("resize");
		const simulationWindow = document.getElementById("simulationWindow");
		if (simulationWindow) {
			// Set the height of the phoneto the height of the simulation window
			setHeight(simulationWindow.offsetHeight);
			// Set the width of the phone based on the height and aspect ratio
			setWidth(simulationWindow.offsetHeight * aspectRatio);
		}
	};

	useEffect(() => {
		if (activityData.retries === 0) {
			setShowResultModal(true);
		}
	}, [activityData.retries]);

	const [feedback, setFeedback] = useState("");

	// Variable for denoting when the simulation window is in focus
	let simulationWindowFocus = false;

	// local varibales to pass down the student data from the data store
	const studentActivityData = activityData.studentData;

	// local varibales to pass down the order data from the data store
	const orderActivityData = activityData.orderData;

	const algebraData = activityData.algebraData;

	const devInfo = {
		activityID: activityData.id,
		difficultyScore: activityData.difficultyScore,
		passThreshold: activityData.passThreshold,
		bestScore: activityData.bestScore,
		currentScore: activityData.currentScore,
		proficiencyScore: gameSaveState.proficiencyScore,
		retriesLeft: activityData.retries,
	};

	const userSettings = accessibilitySettings;

	/**
	 * This function applies the user settings to the page
	 * @param {Object} settings
	 */
	const applyUserSettings = settings => {
		// Apply font and font size
		const toolTip = document.getElementById("toolTip");
		if (toolTip) {
			toolTip.style.fontFamily = settings.display.font;
			toolTip.style.fontSize = `${(1 * settings.display.fontSize) / 100}rem`;
		}
		// Need to apply to the root component of the student view
		const root = document.getElementById("student-view__container");
		if (root) {
			root.style.fontFamily = settings.display.font;
		}
	};

	const replayTutorial = tutorialTitle => {
		console.log(`replay tutorial ${tutorialTitle}`);
	};

	// Use effect for applying user settings when they change
	useEffect(() => {
		console.log("userSettings have changed");

		applyUserSettings(userSettings);
	}, [userSettings]);

	/**
	 * Function to save the updated gameSaveState for a given user to S3
	 */
	async function updateGameSave(activityData) {
		// Get the current user from local storage
		const userString = window.localStorage.getItem("okta-token-storage");
		const userObject = JSON.parse(userString);
		const user = userObject?.accessToken?.claims?.sub;
		//const user = "stephen.byrne@imaginelearning.com";
		// if the user doesn't exist, return, can toggle these two lines for development
		if (!user) return;
		// Prepare the data to be sent to the server
		const gameSaveStateJSON = toJS(gameSaveState);
		const combinedData = { activityData: activityData, gameSaveData: gameSaveStateJSON };
		// Define the common URL and user
		const url = `/gameSaveState?projectName=Math Arcade&gameName=Produce Packer&user=${user}`;
		try {
			// Send request to update the data
			await axios.put(url, combinedData);
		} catch (error) {
			console.error("Error saving game data:", error);
			// If the error is a 404, create a new gaveSaveJson for the user
			if (error.response?.status === 404) {
				await axios.post(url, combinedData);
			}
		}
	}

	// Updating the game save state after the proficiency score has been updated
	useEffect(() => {
		// Ignore initial renders
		renderCount.current += 1;
		if (renderCount.current > 2) {
			// Update the game save state after a new activity has been loaded and the proficiency score has been updated
			updateGameSave(null);
		}
	}, [gameSaveState.proficiencyScore]);

	// Updating the game save state after a user retries an activity
	useEffect(() => {
		renderCount2.current += 1;
		if (renderCount2.current > 2) {
			// Update the game save state after a user retries an activity
			const data = toJS(activityData);
			updateGameSave(data);
		}
	}, [activityData.retries]);

	// Event listener for resizing the simulation window when it window is below 900 px

	window.addEventListener("resize", () => {
		if (window.innerWidth < 900) {
			setSimulationHeight("50%");
			setScreensize("small");
		} else {
			setSimulationHeight("100%");
			setScreensize("large");
		}
	});

	useEffect(() => {
		const focusableElementsNames = "button, input, a, canvas"; // Array for storing focusable elements
		const simulationWindow = document.getElementById("simulationWindow");
		let simulationCanvas = document.getElementById("simulationCanvas");
		if (simulationWindow) {
			const children = simulationWindow.children;
			for (let i = 0; i < children.length; i++) {
				if (children[i].id === "simulationCanvas") {
					simulationCanvas = children[i];
					children[i].tabIndex = 0;

					if (!tabEventListenerAdded) {
						simulationCanvas.addEventListener("keydown", handleKeyDown);
						simulationCanvas.addEventListener("keyup", handleKeyUp);
						tabEventListenerAdded = true;
						// Removing the tooltip keydown event listener until it is entered the simulation window
						// eslint-disable-next-line no-undef
						engine.tooltipSystem.removeEventListeners();
					}
				}
			}

			// Use this to load in the
			const observer = new MutationObserver(mutationsList => {
				console.log("Mutation Observer", mutationsList);
				for (const mutation of mutationsList) {
					if (mutation.addedNodes.length) {
						const children = simulationWindow.children;
						for (let i = 0; i < children.length; i++) {
							if (children[i].id === "simulationCanvas") {
								simulationCanvas = children[i];
								console.log("Simulation Canvas Found");
								children[i].tabIndex = 0;

								if (!tabEventListenerAdded) {
									simulationCanvas.addEventListener("keydown", handleKeyDown);
									simulationCanvas.addEventListener("keyup", handleKeyUp);
									tabEventListenerAdded = true;
									// Removing the tooltip keydown event listener until it is entered the simulation window
									// eslint-disable-next-line no-undef
									engine.tooltipSystem.removeEventListeners();
								}
							}
						}
					}
				}
			});

			observer.observe(simulationWindow, { childList: true });
			setTimeout(() => observer.disconnect(), 10000);

			function handleKeyDown(e) {
				// Convert the key to a capital letter if it is a letter.
				// Dont want different mapping for capital and lowercase of the same letter
				let key = e.key;
				if (key.length === 1) {
					key = key.toUpperCase();
				}
				if (key === accessibilitySettings.controls.Enter && !simulationWindowFocus) {
					console.log("Entering the simulation window");
					// Set phone overlay grey div opacity to make it visible (greys out phone component)
					document.getElementById("phone--body").style.opacity = 0.4;
					// Set simulation window opacity to make it visible
					document.getElementById("simulationWindow").style.opacity = 1;
					// eslint-disable-next-line no-undef
					if (engine) {
						// eslint-disable-next-line no-undef
						engine.tooltipSystem.addEventListeners();
					}
					simulationWindowFocus = true;
					const children = Array.from(simulationCanvas.querySelectorAll(focusableElementsNames));

					for (const child of children) {
						child.tabIndex = 0;
					}

					if (children.length > 0) {
						children[0].focus();
					}
				} else if (key === "Tab" && simulationWindowFocus) {
					e.preventDefault();
				}
			}

			function handleKeyUp(e) {
				let key = e.key;
				if (key.length === 1) {
					key = key.toUpperCase();
				}
				if (key === userSettings.controls.Escape) {
					console.log("Exiting the simulation window");
					// Set phone overlay grey div opacity to make it not visible
					document.getElementById("phone--body").style.opacity = 1;
					// Set simulation window opacity to make it greyed out
					document.getElementById("simulationWindow").style.opacity = 0.4;
					simulationWindowFocus = false;

					const children = Array.from(simulationCanvas.querySelectorAll(focusableElementsNames));

					for (const child of children) {
						child.tabIndex = -1;
					}

					simulationCanvas.nextElementSibling?.focus();
					// check if engine is defined and then remove the tooltip event listeners
					// eslint-disable-next-line no-undef
					if (engine) {
						// eslint-disable-next-line no-undef
						engine.tooltipSystem.removeEventListeners();
					}
				}
			}
		}
	}, [userSettings, simulatorLoaded]);

	if (!activityData.orderData) return <LoadingScreen gif={truckLoadingGif} />;

	return (
		<div className="w-full h-full max-h-[100%]" id="student-view__container">
			{!simulatorLoaded && <LoadingScreen gif={truckLoadingGif} />}
			{/* if the user has no retries left for the current activity, instantly show the result modal */}
			{showResultModal && (
				<ResultModal
					restartCurrentActivity={restartCurrentActivity}
					moveOnToNextActivity={moveOnToNextActivity}
					currentScore={activityData.currentScore}
					bestScore={activityData.bestScore}
					retries={activityData.retries}
					setShowResultModal={setShowResultModal}
					showResultModal={showResultModal}
					activityData={activityData}
					gameSaveState={gameSaveState}
					addActivityAttempt={addActivityAttempt}
					feedback={feedback}
				/>
			)}
			<StudentViewHeader
				displayAccessibilityMenu={displayAccessibilityMenu}
				toggleDisplayAccessibilityMenu={toggleDisplayAccessibilityMenu}
				toggleDisplayTutorialsMenu={toggleDisplayTutorialsMenu}
				kValue={kValue}
				setKValue={setKValue}
			/>
			{/* <DevInfo data={devInfo} /> */}
			<div className="w-[100%] h-[100%] bg-gray-400 lg:flex">
				<div className="w-[100%] h-[60%] sm:h-[60%] bg-slate-400 lg:h-[100%]">
					<SimulationWindow
						simulationMode="pickAndPlaceDemo"
						useLocalRepo={window.localStorage.getItem("useLocalEngineRepo")}
						engineBuildVersion={window.localStorage.getItem("engineBuildVersion")}
						style={{ width: "100%", height: "100%" }}
						displayAccessibilityMenu={displayAccessibilityMenu}
						accessibilitySettings={accessibilitySettings}
					/>
				</div>
				<div
					style={
						window.innerWidth >= 1024
							? window.innerHeight > window.innerWidth // Check if in portrait mode
								? {
										width: window.innerWidth / 3,
										height: window.innerWidth / 1.5,
										minWidth: "400px",
										minHeight: "800px",
									}
								: { width: `${width}px`, height: `${height}px`, minHeight: "800px", minWidth: "400px" } // Landscape mode for large screens
							: undefined
					}
					className={
						"h-[40%]  sm:w-[100%]  min-w-[600px] min-h-[300px] lg:min-w-[200px] lg:absolute border-solid border-black border-[15px] rounded-3xl"
					}
				>
					<Phone
						setStudentActivityAttribute={setStudentActivityAttribute}
						studentActivityData={studentActivityData}
						orderActivityData={orderActivityData}
						algebraData={algebraData}
						setAlgebraData={setAlgebraData}
						accessibilitySettings={accessibilitySettings}
						displayAccessibilityMenu={displayAccessibilityMenu}
						playSound={playSound}
						activityData={activityData}
					/>
				</div>
			</div>
			<SettingsMenu
				accessibilitySettings={accessibilitySettings}
				setAccessibilityAttribute={setAccessibilityAttribute}
				resetToDefaultAccessibilitySettings={resetToDefaultAccessibilitySettings}
				toggleDisplayAccessibilityMenu={toggleDisplayAccessibilityMenu}
				displayAccessibilityMenu={displayAccessibilityMenu}
				overrideAccessibilitySettings={overrideAccessibilitySettings}
				checkIfKeyBeingUsed={checkIfKeyBeingUsed}
				mutedSoundSettings={mutedSoundSettings}
				setMutedSettings={setMutedSettings}
			/>
			<TutorialsMenu
				displayTutorialsMenu={displayTutorialsMenu}
				toggleDisplayTutorialsMenu={toggleDisplayTutorialsMenu}
				replayTutorial={replayTutorial}
			/>
			<Scoring
				accessibilitySettings={accessibilitySettings}
				setFeedback={setFeedback}
				activityData={activityData}
				setActivityDataAttribute={setActivityDataAttribute}
				setShowResultModal={setShowResultModal}
				showResultModal={showResultModal}
				gameSaveState={gameSaveState}
			/>
		</div>
	);
});

export default StudentView;
