import AppDashboard from "./AppDashboard";
import { useState, useEffect, useRef } from "react";
import OrdersTab from "./OrdersTab";
import OrdersMessage from "./OrderMessage";
import { MessageProfilePictureAbsolute } from "./MessageProfilePicture";
import NotesTab from "./NotesTab";
import GraphTab from "./GraphTab";
import EquationsTab from "./EquationsTab";

const Phone = ({
	setStudentActivityAttribute,
	studentActivityData,
	orderActivityData,
	activityData,
	algebraData,
	setAlgebraData,
	displayAccessibilityMenu,
	accessibilitySettings,
	playSound,
}) => {
	// State Variables
	const [currentScreen, setCurrentScreen] = useState("orders"); // Current screen of the phone
	const [resetActiveApp, setResetActiveApp] = useState(false); // State Variable for  triggering resetting acitve app
	const [titleText, setTitleText] = useState(null); // Text for the title of the screen
	const [titleImageSrc, setTitleImageSrc] = useState(null); // Source data for the title image - used in the order message tab
	const [friendshipScore, setFriendshipScore] = useState(0); // Friendship score of the character
	const [orderTextID, setOrderTextID] = useState(0); // ID of the order text
	const colourPallete = accessibilitySettings.display?.blackAndWhite ? "blackAndWhite" : null; // Toggle for triggering black and white mode in the phone
	const acceptedOrders =
		(studentActivityData.equation1.orderAccepted ? 1 : 0) + (studentActivityData.equation2.orderAccepted ? 1 : 0); // Number of accepted orders - used for unlocking the apps
	const [orientation, setOrientation] = useState(window.innerWidth < 1024 ? "small" : "large"); // Used for styling the the orders message tab frienship in header
	const [selectedCharacter, setSelectedCharacter] = useState(null); // Selected character for the order message tab

	// Updating the title text when the screen changes
	useEffect(() => {
		setTitleText(currentScreen.charAt(0).toUpperCase() + currentScreen.slice(1));
	}, [currentScreen]);

	// Event listener for updating the orientation of the phone -  this state variable is used for styling purposes
	window.addEventListener("resize", () => {
		const screenSize = window.innerWidth;
		if (screenSize < 1024) {
			setOrientation("small");
		} else {
			setOrientation("large");
		}
	});

	// Ref to store the previous value of orderActivityData
	const prevOrderActivityData = useRef(orderActivityData);
	useEffect(() => {
		// Check if orderActivityData has changed
		if (prevOrderActivityData.current !== orderActivityData) {
			// Update the value if orderActivityData has changed
			setResetActiveApp(true);
			// Update the ref to the current value
			prevOrderActivityData.current = orderActivityData;
		}
	}, [orderActivityData]);

	// Add mouseover event listener to the simulation window - used for dimmining the simulation when user is hovering on the phone
	document.getElementById("phone--body")?.addEventListener("mouseover", event => {
		// eslint-disable-next-line no-undef
		if (displayAccessibilityMenu) return;
		// Set phone overlay grey div opacity to make it visible (greys out phone component)
		document.getElementById("phone--body").style.opacity = 1;
		// Set simulation window opacity to make it visible
		// eslint-disable-next-line no-undef
		document.getElementById("simulationWindow").style.opacity =
			accessibilitySettings.display.outOfFocusDimOpacity ?? 0.4;
	});

	// Getting the order data from the activity data -- NEED TO SCALE TO A VARIABLE NUMBER OF ORDERS
	const {
		character: order1Character,
		characterDialogue: order1Dialogue,
		friendshipScore: order1FriendshipScore,
	} = orderActivityData[0];

	const {
		character: order2Character,
		characterDialogue: order2Dialogue,
		friendshipScore: order2FriendshipScore,
	} = orderActivityData[1];

	const orders = [
		{
			name: order1Character,
			friendshipScore: order1FriendshipScore,
			text: order1Dialogue,
		},
		{
			name: order2Character,
			friendshipScore: order2FriendshipScore,
			text: order2Dialogue,
		},
	];

	return (
		<div className="h-[100%] bg-black flex flex-row lg:flex-col">
			<div id="phone--body" className="h-[100%] w-[100%] bg-black flex flex-row lg:flex-col">
				<div
					id="phone--app--container"
					className="order-2 rounded-r-[1.25rem] lg:rounded-b-none lg:order-1 h-[100%] w-[86%] lg:h-[86%] lg:w-[100%] bg-white lg:rounded-t-[1.25rem]"
				>
					{/* App header */}
					{currentScreen !== "orders-message" && (
						<div className="w-full text-center bg-[#4B4B4B] text-white rounded-tr-[1.25rem] lg:py-[.9375rem] self-stretch lg:gap-[.625rem] lg:rounded-b-none lg:rounded-t-[1.25rem] h-[19%] flex flex-col items-center justify-center">
							<h1 className="w-[7rem] h-auto text-center text-[2.0625rem] font-extrabold leading-none">{titleText}</h1>
						</div>
					)}
					{/* Additional Logic for the order message tab for inserting the Profile picture, friendship score and the back button */}
					{currentScreen === "orders-message" && (
						<div className="relative w-full text-center bg-[#4B4B4B] text-white rounded-tr-[1.25rem] lg:py-[.9375rem] self-stretch lg:gap-[.625rem] lg:rounded-b-none lg:rounded-t-[1.25rem] h-[19%] flex flex-col items-center justify-center">
							{/* Back Button */}
							<div className="w-full flex justify-between items-center gap-[.625rem]">
								<button
									type="button"
									className="w-[17%] min-w-[5.2rem] lg:w-[25%] z-10 text-white cursor-pointer flex items-center justify-end"
									onClick={e => {
										e.stopPropagation();
										setCurrentScreen("orders");
										playSound("click");
									}}
									aria-label="back to orders tab"
								>
									<svg xmlns="http://www.w3.org/2000/svg" width="17" height="26" viewBox="0 0 17 26" fill="none">
										<path
											d="M13.4803 3.75L2.99998 12.96L13.5714 22.25"
											stroke="white"
											stroke-width="6"
											stroke-miterlimit="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>
									<p className="flex w-[3.875rem] h-[1.875rem] flex-col justify-center text-[rgba(255,255,255,0.90)] text-[1.375rem] font-bold">
										{" "}
										Back
									</p>
								</button>
							</div>
							<MessageProfilePictureAbsolute
								imageSrc={titleImageSrc}
								friendshipScore={friendshipScore}
								friendshipStarOrientation={"right"}
								colourPallete={colourPallete}
								profilePicHeight={orientation === "small" ? "150%" : "100%"}
								profilePicWidth="auto"
								profilePicTop="100%"
								profilePicLeft="34%"
								friendshipStarTop={orientation === "small" ? "135%" : "120%"}
								friendshipStarLeft={orientation === "small" ? "calc(50% + 60px)" : "calc(50% + 85px)"}
								friendshipStarHeight={orientation === "small" ? "85%" : "50%"}
								friendshipStarWidth="auto"
							/>
						</div>
					)}
					{/* Main Content Area */}
					{currentScreen === "orders" && (
						<div className="flex flex-col h-[81%] flex-shrink-0 items-start">
							<OrdersTab
								orders={orders}
								setCurrentScreen={setCurrentScreen}
								setTitleText={setTitleText}
								setTitleImageSrc={setTitleImageSrc}
								setOrderTextID={setOrderTextID}
								setFriendshipScore={setFriendshipScore}
								colourPallete={colourPallete}
								playSound={playSound}
								accessibilitySettings={accessibilitySettings}
								setSelectedCharacter={setSelectedCharacter}
							/>
						</div>
					)}
					{currentScreen === "orders-message" && (
						<OrdersMessage
							text={orders[orderTextID].text}
							orderID={orderTextID}
							activityData={activityData}
							studentActivityData={studentActivityData}
							setStudentActivityAttribute={setStudentActivityAttribute}
							acceptedOrders={acceptedOrders}
							colourPallete={colourPallete}
							playSound={playSound}
							accessibilitySettings={accessibilitySettings}
							selectedCharacter={selectedCharacter}
						/>
					)}
					{currentScreen === "notes" && (
						<NotesTab
							studentActivityData={studentActivityData}
							algebraData={algebraData}
							setAlgebraData={setAlgebraData}
							displayAccessibilityMenu={displayAccessibilityMenu}
							accessibilitySettings={accessibilitySettings}
							playSound={playSound}
							colourPallete={colourPallete}
						/>
					)}
					{currentScreen === "graph" && (
						<GraphTab
							graphCoordinateX={studentActivityData.graphCoordinateX}
							graphCoordinateY={studentActivityData.graphCoordinateY}
							setStudentActivityAttribute={setStudentActivityAttribute}
							studentActivityData={studentActivityData}
							colourPallete={colourPallete}
						/>
					)}
					{currentScreen === "equations" && (
						<EquationsTab
							setStudentActivityAttribute={setStudentActivityAttribute}
							studentActivityData={studentActivityData}
							orderActivityData={orderActivityData}
							colourPallete={colourPallete}
						/>
					)}
				</div>
				{/* App Dashboard */}
				<div
					id="phone--app-dashboard"
					className="order-1 lg:order-2 bg-[#232121] h-[100%] w-[14%] min-w-[90px] rounded-l-[1.25rem] justify-center items-center gap-[.75rem] pt-2.5 pb-2.5 shrink-0
               lg:w-[100%] lg:rounded-l-none lg:h-[14%] flex lg:flex-col lg:py-[.4375rem] lg:px-[0.9375rem] lg:gap-[.625rem] lg:rounded-bl-[1.25rem] lg:rounded-br-[1.25rem]"
				>
					<AppDashboard
						setCurrentScreen={setCurrentScreen}
						acceptedOrders={acceptedOrders}
						accessibilitySettings={accessibilitySettings}
						playSound={playSound}
						resetActiveApp={resetActiveApp}
						setResetActiveApp={setResetActiveApp}
					/>
				</div>
			</div>
		</div>
	);
};
export default Phone;
