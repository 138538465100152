import React from 'react';

const FolderIcon = ({ startColor, endColor }) => {
    return (
        <svg width="100%" height="80%" viewBox="0 0 100 79" version="1.1" xmlns="http://www.w3.org/2000/svg" style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}>
            <title>Folder Icon</title>
            <g transform="matrix(0.689655,0,0,0.689655,0,1.62621)">
                <path d="M96.347,0C95.21,0 94.107,0.388 93.219,1.099L83.014,9.282L10,9.282C4.477,9.282 0,13.759 0,19.282L0,99.834C0,105.357 4.477,109.834 10,109.834L135,109.834C140.523,109.834 145,105.357 145,99.834L145,5C145,2.239 142.761,0 140,0L96.347,0Z" style={{ fill: 'url(#_Linear1)' }} />
            </g>
            <defs>
                <linearGradient id="_Linear1" x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse" gradientTransform="matrix(6.72539e-15,109.834,-109.834,6.72539e-15,72.5,0)">
                    <stop offset="0" style={{ stopColor: startColor, stopOpacity: 1 }} />
                    <stop offset="1" style={{ stopColor: endColor, stopOpacity: 1 }} />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default FolderIcon;