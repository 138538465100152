import { useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

/**
 * Component to display the character container
 * @param {Object} props - the props object
 * @param {String} props.character - the character name
 * @param {String} props.border - the border colour
 * @param {String} props.borderDark - the dark border colour
 * @param {String} props.colourPallete - the colour pallete
 * @param {Object} props.studentActivityData - the student activity data
 * @param {Integer} props.equation - the equation number
 * @param {Function} props.setStudentActivityAttribute - the function to set the student activity attribute
 * @returns
 * */
function CharacterContainer({
	character,
	border,
	borderDark,
	colourPallete,
	studentActivityData,
	equation,
	setStudentActivityAttribute,
}) {
	// State Variables used for loading in the character image
	const [characterImage, setCharacterImage] = useState(null); // Character image data
	const [loading, setLoading] = useState(true); // Loading state for loading the character image from s3
	const [error, setError] = useState(null); // Error state for loading the character image from s3

	// Fetch the character profile picture from s3
	const { data, error: fetchError } = useFetch(
		`assets/images/Math Arcade/Produce Packer/character${character}${
			colourPallete === "blackAndWhite" ? "BW" : ""
		}.webp`,
	);

	useEffect(() => {
		if (fetchError) {
			setError(fetchError);
			console.error("Error fetching character image: ", fetchError);
			setLoading(false);
			return;
		}

		if (data) {
			setCharacterImage(data);
			setLoading(false);
		}
	}, [data, fetchError]);

	// If the image is loading return the loading spinner
	if (loading) {
		return <LoadingSpinner />;
	}

	// If there is an error fetching the image, log the error
	if (error) {
		console.error("Error fetching character image: ", error);
	}

	// Operator input options
	const operatorOptions = ["", "+", "-"];

	/**
	 * Updates the student activity attribute data with the new value
	 * @param {Integer} equation - the id of the equation
	 * @param {String} variable - the variable to update
	 * @param {Event} e - the event object
	 */
	function handleEquationChange(equation, variable, e) {
		const attribute = `eq${equation}${variable}`;
		setStudentActivityAttribute(attribute, e.target.value);
	}

	return (
		<div
			className="w-[100%] flex lg:h-[29%] lg:min-h-[8rem] lg:flex-col items-center lg:items-start justify-center p-[0.625rem_0.9375rem]  gap-[0.9375rem] rounded-[0.4375rem]"
			style={{ border: colourPallete === "blackAndWhite" ? borderDark : border }}
		>
			<div className="flex items-center gap-[0.625rem] w-[33%] min-w-[9rem]">
				{/* Profile Picture */}
				<div className="flex items-center justify-center  min-w-[3.3rem] min-h-[3.3rem] w-[50%] ">
					<img src={characterImage} alt="Character" className="w-[3.3rem] h-[3.3rem] object-cover rounded-full" />
				</div>
				{/* Name */}
				<div className="flex items-center  min-w-[5rem] min-h-[1.7rem]">
					<p className="text-[#1A171B] text-[1.375rem] font-bold leading-none">{character}</p>
				</div>
			</div>

			<div className="flex items-center gap-[.25rem] w-[66%] lg:w-[100%] min-w-[18rem] justify-center">
				{/* Input X */}
				<div className="w-[2.5rem]">
					<input
						id="graphing-container__bottom-container__div__left__input"
						className="flex w-[2.7rem] h-[2.7rem] p-[0.75rem] px-[0.625rem] lg:py-[0.75rem] flex-col justify-center items-center gap-[0.625rem] lg:gap-[2.5rem] flex-shrink-0 rounded-[0.4375rem] border-[3px] border-[#797979] bg-[#D9D9D9] text-black text-center text-[1.5rem] font-bold leading-normal"
						//value={studentActivityData.graphCoordinateX}
						onChange={e => {
							handleEquationChange(equation, "VarX", e);
						}}
						value={studentActivityData[`eq${equation}VarX`]}
					/>
				</div>
				{/* X */}
				<p className="text-black text-center text-[1.375rem] italic font-bold leading-none flex w-[1.875rem] h-[1.875rem] p-[0.75rem] px-[0.625rem] flex-col justify-center items-center gap-[0.625rem] flex-shrink-0">
					X
				</p>
				{/* Select - Operator */}
				<select
					id="options"
					className="text-black  w-[3.5rem] h-[2.7rem] text-center text-[1.25rem] font-bold leading-none inline-flex items-center gap-[0.75rem] rounded-[0.3125rem] border-[3px] border-[#797979] bg-[#D9D9D9]"
					onChange={e => handleEquationChange(equation, "Operator", e)}
					value={studentActivityData[`eq${equation}Operator`]}
				>
					{operatorOptions.map(option => (
						<option key={option} value={option}>
							{option}
						</option>
					))}
				</select>
				{/* Input Y*/}
				<div className="w-[2.5rem]">
					<input
						id="graphing-container__bottom-container__div__left__input"
						className="flex w-[2.7rem] h-[2.7rem] p-[0.75rem] px-[0.625rem] lg:py-[0.75rem] flex-col justify-center items-center gap-[0.625rem] lg:gap-[2.5rem] flex-shrink-0 rounded-[0.4375rem] border-[3px] border-[#797979] bg-[#D9D9D9] text-black text-center text-[1.5rem] font-bold leading-normal"
						onChange={e => {
							handleEquationChange(equation, "VarY", e);
						}}
						value={studentActivityData[`eq${equation}VarY`]}
					/>
				</div>
				{/* Y */}
				<p className="text-black text-center text-[1.375rem] italic font-bold leading-none flex w-[1.875rem] h-[1.875rem] p-[0.75rem] px-[0.625rem] flex-col justify-center items-center gap-[0.625rem] flex-shrink-0">
					Y
				</p>
				{/* = */}
				<p className="text-black text-center text-[1.375rem] font-bold leading-none flex w-[1.875rem] h-[1.875rem] p-[0.75rem] px-[0.625rem] flex-col justify-center items-center gap-[0.625rem] flex-shrink-0">
					=
				</p>
				{/* Input result*/}
				<div className="w-[2.5rem]">
					<input
						id="graphing-container__bottom-container__div__left__input"
						className="flex w-[2.7rem] h-[2.7rem] p-[0.75rem] px-[0.625rem] lg:py-[0.75rem] flex-col justify-center items-center gap-[0.625rem] lg:gap-[2.5rem] flex-shrink-0 rounded-[0.4375rem] border-[3px] border-[#797979] bg-[#D9D9D9] text-black text-center text-[1.5rem] font-bold leading-normal"
						onChange={e => {
							handleEquationChange(equation, "Result", e);
						}}
						value={studentActivityData[`eq${equation}Result`]}
					/>
				</div>
			</div>
		</div>
	);
}

export default CharacterContainer;
