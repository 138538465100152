import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import CreateAxiosInstance from "../../AxiosInstance/AxiosInstance";
import buaLogo from "../../assets/images/bua/bua-logo-multicolor.png";
import AppContext from "../../components/AppContext/AppContext";
import PopupModal from "../../components/PopupModal/PopupModal";
import PostModal from "../../components/PostModal/PostModal";
import ProjectFileTree from "../../components/ProjectsPage/ProjectFileTree/ProjectFileTree";
import useFetch from "../../hooks/useFetch";

const ProjectsPage = () => {

	const localContext = useContext(AppContext);
	const navigate = useNavigate();
	const [loadProjectTree, setLoadProjectTree] = useState(true);

	const { data, loading, error } = useFetch(loadProjectTree ? "/projects/getProjectsTree" : null);

	useEffect(() => {
		if (!loadProjectTree) return;

		if (data) {
			setFileTree(data);
			setLoadProjectTree(false);
		}

	}, [loadProjectTree, data]);

	const entityClick = (e, projectName, gameName, entityName) => {
		localContext.updateProjectName(projectName);
		localContext.updateGameName(gameName);
		localContext.updateEntityName(entityName);

		navigate('/design-tools/entity-builder');

		e.stopPropagation();
	};

	const [fileTree, setFileTree] = useState([]);
	const [isPopupModalOpen, setIsPopupModalOpen] = useState(false);

	const [popupTitle, setPopupTitle] = useState("");
	const [popupContent, setPopupContent] = useState("");
	const [modalType, setModalType] = useState("");
	const [content, setContent] = useState({});

	const [isPostModalOpen, setIsPostModalOpen] = useState(false);

	const [postModalTitle, setPostModalTitle] = useState("New Project");
	const [postModalType, setPostModalType] = useState("project");
	const [postModalData, setPostModalData] = useState({});


	const handleOpenModal = (type, content) => {
		setModalType(type);
		setContent(content);

		if (type === "renameDeleteProject") {
			setPopupTitle("Rename Project");
			setPopupContent(content.projectName);
			setIsPopupModalOpen(true);
		}

		if (type === "renameDeleteGame") {
			setPopupTitle("Rename Game");
			setPopupContent(content.gameName);
			setIsPopupModalOpen(true);
		}

		if (type === "renameDeleteEntity") {
			setPopupTitle("Rename Entity");
			setPopupContent(content.entityName);
			setIsPopupModalOpen(true);
		}
	}

	const handleCloseModal = () => setIsPopupModalOpen(false)

	const handleDelete = (name) => {
		const { projectName, gameName, entityName } = content;

		let deleteString;

		if (entityName) deleteString = `/entities/${projectName}/${gameName}/${entityName}`;
		else if (gameName) deleteString = `/games/${projectName}/${gameName}`;
		else deleteString = `/projects/${projectName}`;

		axios
			.delete(deleteString)
			.then(response => {
				if (response.status === 200) setLoadProjectTree(true);
				else new Error("Error:", response.data);
			})
	}

	const handleRename = (newName) => {

		const { projectName, gameName, entityName } = content;

		if (modalType === "renameDeleteProject") {
			axios
				.put(`/projects/${projectName}/${newName}`)
				.then(response => {
					if (response.status === 200) setLoadProjectTree(true);
					else new Error("Error:", response.data);
				})
		}

		if (modalType === "renameDeleteGame") {
			axios
				.put(`/games/${projectName}/${gameName}/${newName}`)
				.then(response => {
					if (response.status === 200) {
						setTimeout(() => {
							setLoadProjectTree(true);
						}, 1000);
					}
					else new Error("Error:", response.data);
				})
		}

		if (modalType === "renameDeleteEntity") {
			axios.put(`/entities/name/${projectName}/${gameName}/${entityName}/${newName}`)
				.then(response => {
					if (response.status === 200) {
						setTimeout(() => {
							setLoadProjectTree(true);
						}, 1000);
					}
					else new Error("Error:", response.data);
				})
		}


		setIsPopupModalOpen(false);
	}

	const handleAddProject = () => {
		setPostModalTitle("New Project");
		setPostModalData({});
		setPostModalType("project");
		setIsPostModalOpen(true);
	}

	const handleAddGame = (projectName) => {
		console.log(projectName);

		setPostModalTitle("New Game");
		setPostModalData({ projectName });
		setPostModalType("game");
		setIsPostModalOpen(true);
	}

	const handleAddEntity = (projectName, gameName) => {
		setPostModalTitle("New Entity");
		setPostModalData({ projectName, gameName });
		setPostModalType("entity");
		setIsPostModalOpen(true);
	}

	const handlePost = async (data) => {

		const { projectName, gameName } = postModalData;

		if (gameName) {
			// this is an entity call
			await axios.post("/entities/", {
				projectName,
				gameName,
				entityName: data,
			})
				.then(response => {
					if (response.status === 200) {
						console.log("Entity created successfully!");
						setLoadProjectTree(true);
					}
				});
		}
		else if (projectName) {
			// this is a game call
			await axios.post("/games/", {
				projectName,
				gameName: data,
			})
				.then(response => {
					if (response.status === 200) {
						console.log("Game created successfully!");
						setLoadProjectTree(true);
					}
				});


		}
		else {
			// this is a project call
			await axios.post("/projects/", { projectName: data })
				.then(response => {
					if (response.status === 200) {
						console.log("Project created successfully!");
						setLoadProjectTree(true);
					}
				});
		}

		setIsPostModalOpen(false);
	}

	const axios = CreateAxiosInstance();

	const projectTreeData = [];

	Object.keys(fileTree).map((projectName) => {

		projectTreeData.push({
			name: projectName,
			type: 'project',
			settingsCallback: () => {
				handleOpenModal("renameDeleteProject", { projectName: projectName });
			},
			children: fileTree[projectName].Games ? Object.keys(fileTree[projectName].Games).map((gameName) => {
				return {
					name: gameName,
					type: 'game',
					settingsCallback: () => {
						handleOpenModal("renameDeleteGame", { projectName, gameName });
					},
					children: fileTree[projectName].Games[gameName].Entities ? Object.keys(fileTree[projectName].Games[gameName].Entities).map((entityName) => {
						return {
							name: entityName,
							type: 'entity',
							settingsCallback: () => {
								handleOpenModal("renameDeleteEntity", { projectName, gameName, entityName });
							},
							onClickCallback: (e) => {
								entityClick(e, projectName, gameName, entityName);
							}
						}
					}) : []
				}
			}) : []
		})
	});

	return (
		<div className="flex flex-col items-center h-full">
			<button
				className="w-24 h-auto self-start m-6"
				type="button"
				onClick={() => { navigate("/") }}
			>
				<img src={buaLogo} alt="bua logo" />
			</button>
			<div className="w-10/12 max-w-[1100px] mb-2">
				<h1 className="text-color-bua-text-light text-4xl mb-4">
					Projects
				</h1>
				<ProjectFileTree
					data={projectTreeData}
					dataLoading={loading}
					addProjectCallback={handleAddProject}
					addGameCallback={handleAddGame}
					addEntityCallback={handleAddEntity}
				/>
			</div>
			<PopupModal
				isOpen={isPopupModalOpen}
				onClose={handleCloseModal}
				onConfirm={handleRename}
				onDelete={handleDelete}
				title={popupTitle}
				content={popupContent}
			/>
			<PostModal
				isOpen={isPostModalOpen}
				onClose={() => setIsPostModalOpen(false)}
				onConfirm={handlePost}
				title={postModalTitle}
				type={postModalType}
				data={postModalData}
			/>
		</div>
	);
};

export default ProjectsPage;
