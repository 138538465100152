import React, { useEffect } from "react";
import CreateAxiosInstance from "../../../AxiosInstance/AxiosInstance";
import CreateS3AxiosInstance from "../../../AxiosInstance/s3AxiosInstance";

import FilterSettings from "../FilterSettings/FilterSettings";
import HitboxSettings from "../HitboxSettings/HitboxSettings";
import MeshSettings from "../MeshSettings/MeshSettings";
import SystemsSettings from "../SystemsSettings/SystemsSettings";

import LabelInputButton from "../../UIBuilder/CustomDrawer/components/LabelInputButton";

function EntityBuilderSettings({ selectedEntity, projectName, gameName, saveEntityToDatabase }) {
	const axios = CreateAxiosInstance();
	const S3Axios = CreateS3AxiosInstance();
	const defaultValues = {
		name: "Entity",
		systems: ["pickUp"],
		components: {
			size: [1, 1, 1],
			rigidBodyShape: "cube",
			mass: 1,
			meshes: [
				{
					id: 0,
					meshScale: [1, 1, 1],
					meshPositionOffset: [0, 0, 0],
					meshRotationOffset: [0, 0, 0],
				},
			],
			filterGroup: -1,
			filterMask: [-1],
		},
	};

	const [entityBuilderSettings, setEntityBuilderSettings] = React.useState(defaultValues);

	useEffect(() => {
		async function fetchEntityData() {
			if (!selectedEntity) {
				setEntityBuilderSettings(defaultValues);
				return;
			}

			// get the secure s3 link from the API server (hardcoded project and game for now)
			const entityDataLink = await axios.get(`/entities/${projectName}/${gameName}/${selectedEntity}`);

			const selectedEntityData = await S3Axios.get(entityDataLink.data.data);
			const newEntityData = JSON.parse(selectedEntityData.data);

			if (newEntityData.components.meshes) {
				for (const mesh of newEntityData.components.meshes) {
					const modelLink = await axios.get(`/assets/models/${mesh.meshModelName}`);

					mesh.meshModelLink = modelLink.data;
				}
			}

			setEntityBuilderSettings(newEntityData);
		}

		fetchEntityData();
	}, [selectedEntity]);

	/* 
		call the updateEntity function inside the Bua engine's entity 
		builder content file whenever entityBuilderSettings is changed
		*/
	useEffect(() => {
		const intervalId = setInterval(() => {
			// eslint-disable-next-line no-undef
			if (typeof updateEntity === "function") {
				try {
					// eslint-disable-next-line no-undef
					updateEntity(entityBuilderSettings);
				} catch (error) {
					console.log(error);
				}
				clearInterval(intervalId);
			}
		}, 10); // Check every 10ms

		// Clean up on component unmount
		return () => clearInterval(intervalId);
	}, [entityBuilderSettings]);

	const handleEntityNameChange = newName => {
		setEntityBuilderSettings(prevEntityBuilderSettings => ({
			...prevEntityBuilderSettings,
			name: newName,
		}));
	};

	const handleSaveEntity = () => {
		saveEntityToDatabase(entityBuilderSettings);
	};

	const handleHitboxShapeChange = newShape => {
		setEntityBuilderSettings(prevEntityBuilderSettings => ({
			...prevEntityBuilderSettings,
			components: {
				...prevEntityBuilderSettings.components,
				rigidBodyShape: newShape,
			},
		}));
	};

	const handleHitboxSizeChange = newSize => {
		setEntityBuilderSettings(prevEntityBuilderSettings => ({
			...prevEntityBuilderSettings,
			components: {
				...prevEntityBuilderSettings.components,
				size: newSize,
			},
		}));
	};

	const handleHitboxMassChange = newMass => {
		setEntityBuilderSettings(prevEntityBuilderSettings => ({
			...prevEntityBuilderSettings,
			components: {
				...prevEntityBuilderSettings.components,
				mass: newMass,
			},
		}));
	};

	const handleAddingNewMesh = () => {
		setEntityBuilderSettings(prevEntityBuilderSettings => ({
			...prevEntityBuilderSettings,
			components: {
				...prevEntityBuilderSettings.components,
				meshes: [
					...prevEntityBuilderSettings.components.meshes,
					{
						id: prevEntityBuilderSettings.components.meshes.length,
						meshScale: [1, 1, 1],
						meshPositionOffset: [0, 0, 0],
						meshRotationOffset: [0, 0, 0],
					},
				],
			},
		}));
	};

	const handleMeshModelChange = (id, newName, newMeshModelLink) => {
		const prevMeshes = [...entityBuilderSettings.components.meshes];

		const index = prevMeshes.findIndex(x => x.id === id);

		prevMeshes[index].meshModelName = newName;
		prevMeshes[index].meshModelLink = newMeshModelLink;

		setEntityBuilderSettings(prevEntityBuilderSettings => ({
			...prevEntityBuilderSettings,
			components: {
				...prevEntityBuilderSettings.components,
				meshes: [...prevMeshes],
			},
		}));
	};

	const handleMeshScaleChange = (id, newMeshScale) => {
		const prevMeshes = [...entityBuilderSettings.components.meshes];

		const index = prevMeshes.findIndex(x => x.id === id);

		prevMeshes[index].meshScale = newMeshScale;

		setEntityBuilderSettings(prevEntityBuilderSettings => ({
			...prevEntityBuilderSettings,
			components: {
				...prevEntityBuilderSettings.components,
				meshes: [...prevMeshes],
			},
		}));
	};

	const handleMeshPositionOffsetChange = (id, newMeshPositionOffset) => {
		const prevMeshes = [...entityBuilderSettings.components.meshes];

		const index = prevMeshes.findIndex(x => x.id === id);

		prevMeshes[index].meshPositionOffset = newMeshPositionOffset;

		setEntityBuilderSettings(prevEntityBuilderSettings => ({
			...prevEntityBuilderSettings,
			components: {
				...prevEntityBuilderSettings.components,
				meshes: [...prevMeshes],
			},
		}));
	};

	const handleMeshRotationOffsetChange = (id, newMeshRotationOffset) => {
		const prevMeshes = [...entityBuilderSettings.components.meshes];

		const index = prevMeshes.findIndex(x => x.id === id);

		prevMeshes[index].meshRotationOffset = newMeshRotationOffset;

		setEntityBuilderSettings(prevEntityBuilderSettings => ({
			...prevEntityBuilderSettings,
			components: {
				...prevEntityBuilderSettings.components,
				meshes: [...prevMeshes],
			},
		}));
	};

	const handleSystemsSettingsChange = (system, checked) => {
		if (checked) {
			setEntityBuilderSettings(prevEntityBuilderSettings => ({
				...prevEntityBuilderSettings,
				systems: [...prevEntityBuilderSettings.systems, system],
			}));
		} else {
			setEntityBuilderSettings(prevEntityBuilderSettings => ({
				...prevEntityBuilderSettings,
				systems: [...prevEntityBuilderSettings.systems.filter(name => name !== system)],
			}));
		}
	};

	const handleFilterGroupChange = newFilterGroup => {
		setEntityBuilderSettings(prevEntityBuilderSettings => ({
			...prevEntityBuilderSettings,
			components: {
				...prevEntityBuilderSettings.components,
				filterGroup: newFilterGroup,
			},
		}));
	};

	const handleFilterMaskChange = newFilterMask => {
		setEntityBuilderSettings(prevEntityBuilderSettings => ({
			...prevEntityBuilderSettings,
			components: {
				...prevEntityBuilderSettings.components,
				filterMask: newFilterMask,
			},
		}));
	};

	return (
		<div className="entity-builder-settings-container">
			<LabelInputButton
				labelName={"Name: "}
				inputDefault={entityBuilderSettings.name}
				inputCallback={handleEntityNameChange}
				buttonName={"Save"}
				buttonCallback={handleSaveEntity}
			/>
			<HitboxSettings
				hitboxShapeCallback={handleHitboxShapeChange}
				hitboxShape={entityBuilderSettings.components.rigidBodyShape}
				hitboxSizeCallback={handleHitboxSizeChange}
				hitboxSizeX={entityBuilderSettings.components.size[0]}
				hitboxSizeY={entityBuilderSettings.components.size[1]}
				hitboxSizeZ={entityBuilderSettings.components.size[2]}
				hitboxMassCallback={handleHitboxMassChange}
				hitboxMass={entityBuilderSettings.components.mass}
			/>
			<SystemsSettings systems={entityBuilderSettings.systems} systemsSettingsCallback={handleSystemsSettingsChange} />
			<MeshSettings
				meshes={entityBuilderSettings.components.meshes}
				meshScaleCallback={handleMeshScaleChange}
				meshModelCallback={handleMeshModelChange}
				meshPositionOffsetCallback={handleMeshPositionOffsetChange}
				meshRotationOffsetCallback={handleMeshRotationOffsetChange}
				newMeshCallback={handleAddingNewMesh}
			/>
			<FilterSettings
				filterGroup={entityBuilderSettings.components.filterGroup}
				filterGroupCallback={handleFilterGroupChange}
				filterMask={entityBuilderSettings.components.filterMask}
				filterMaskCallback={handleFilterMaskChange}
			/>
		</div>
	);
}

export default EntityBuilderSettings;
