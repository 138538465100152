import React, { useContext, useState, useEffect } from "react";
import AppContext from "../../components/AppContext/AppContext";

import CreateAxiosInstance from "../../AxiosInstance/AxiosInstance";
import CreateS3AxiosInstance from "../../AxiosInstance/s3AxiosInstance";
import DesignToolsHeader from "../../components/DesignToolsHeader/DesignToolsHeader";
import CodeMirrorContainer from "../../components/ScriptEditor/CodeMirrorContainer/CodeMirrorContainer";
import FileTree from "../../components/ScriptEditor/FileTree/FileTree";

// Dummy files are needed to prevent issue with too many re-renders
const dummyFiles = [
	{
		id: 8,
		name: "",
		type: "file",
		code: "",
		directory: 7,
		modified: "false",
	},
];

const ScriptEditor = () => {
	const axios = CreateAxiosInstance();
	const s3Axios = CreateS3AxiosInstance();
	const localContext = useContext(AppContext);

	const [files, setFiles] = React.useState(dummyFiles);
	const [selectedFile, setSelectedFile] = React.useState(null);
	const [fileIdCount, setFileIdCount] = React.useState(0);

	useEffect(() => {
		const fetchFiles = async () => {
			try {
				const entitiesResponse = await axios.get(
					`/scripts/all?projectName=${localContext.projectName}&gameName=${localContext.gameName}`,
				);
				const entitiesList = entitiesResponse.data.data;

				// console.log(entitiesList);
				let tempIdCounter = 0;

				const files = await Promise.all(
					entitiesList.map(async (script, index) => {
						const fileResponse = await s3Axios.get(script.url);
						const fileData = fileResponse.data;
						const code = typeof fileData === "string" ? fileData : " ";

						if (entitiesList[index].path.includes("script")) {
							const number = Number(entitiesList[index].path.split("script")[1].split(".")[0]);
							if (number >= tempIdCounter) {
								tempIdCounter = number + 1;
							}
						}

						return {
							id: entitiesList[index].path,
							name: `${entitiesList[index].path.split("/Scripts/")[1]}`,
							code: code,
							type: "file",
							modified: "false",
						};
					}),
				);

				setFileIdCount(tempIdCounter);
				setFiles(files);
			} catch (error) {
				console.error("Error fetching scripts:", error);
			}
		};

		fetchFiles();
	}, [localContext.projectName, localContext.gameName]);

	if (selectedFile == null) {
		setSelectedFile(files.find(file => file.name === ""));
	}

	const onSelect = file => {
		// console.log("onSelect", file);
		localContext.updateEntityName(file.id); // set current entity context to selected file
		setSelectedFile(file);
	};

	const onUpdate = (id, code, fileSaved) => {
		const elementsIndex = files.findIndex(element => element.id === id);
		const newArray = [...files];

		let modified = true;
		if (fileSaved) modified = false;
		localContext.updateModifiedEntities(id, modified); // update modifiedEntities array stating if file has been modified

		newArray[elementsIndex] = { ...newArray[elementsIndex], code: code, modified: modified.toString() };

		setFiles(newArray);
	};

	const handleAddScript = () => {
		const newFiles = [
			...files,
			{
				id: `script${fileIdCount}`,
				name: `script${fileIdCount}.js`,
				code: "",
				type: "file",
				modified: "false",
			},
		];
		setFiles(newFiles);
		setFileIdCount(fileIdCount + 1);
	};
	const [leftWidth, setLeftWidth] = useState(20); // initial 50% width for left div

	const handleMouseDown = e => {
		document.addEventListener("mousemove", handleMouseMove);
		document.addEventListener("mouseup", handleMouseUp);
	};

	const handleMouseMove = e => {
		// Calculate new width based on mouse position
		const newLeftWidth = (e.clientX / window.innerWidth) * 100;
		if (newLeftWidth < 10 || newLeftWidth > 90) return;
		setLeftWidth(newLeftWidth);
	};

	const handleMouseUp = () => {
		document.removeEventListener("mousemove", handleMouseMove);
		document.removeEventListener("mouseup", handleMouseUp);
	};

	return (
		<div className="h-screen flex flex-col">
			{/* DesignToolsHeader at the top */}
			<DesignToolsHeader tab={"script-editor"} />

			{/* Main content: FileTree, Resizer, and CodeMirrorContainer */}
			<main className="flex flex-grow w-full h-0">
				{/* File Tree on the left */}
				<div className="overflow-y-scroll" style={{ width: `${leftWidth}%` }}>
					<FileTree files={files} selectedFile={selectedFile} onSelect={onSelect} />
				</div>

				{/* Resizer in the middle */}
				<div className="w-1 bg-gray-300 cursor-col-resize" onMouseDown={handleMouseDown} />

				{/* CodeMirrorContainer on the right */}
				{selectedFile && (
					<div className="flex-grow h-full overflow-hidden">
						<CodeMirrorContainer
							file={selectedFile}
							show={true}
							onUpdate={onUpdate}
							handleAddScript={handleAddScript}
						/>
					</div>
				)}
			</main>
		</div>
	);
};

export default ScriptEditor;
