// ActivityLayoutStore.js

import { makeAutoObservable } from "mobx";
const dlTest = {
	id: 1,
	position: [20, 20, 20],
	color: 0x0000ff,
	shadow: {
		castShadow: true,
		mapSizeWidth: 1024,
		mapSizeHeight: 1024,
		cameraLeft: -20,
		cameraRight: 20,
		cameraTop: 20,
		cameraBottom: -20,
		cameraFar: 60,
		cameraNear: 20,
	},
};
const dlTest2 = {
	id: 2,
	position: [20, 20, 20],
	color: 0x0000ff,
	shadow: {
		castShadow: true,
		mapSizeWidth: 1024,
		mapSizeHeight: 1024,
		cameraLeft: -20,
		cameraRight: 20,
		cameraTop: 20,
		cameraBottom: -20,
		cameraFar: 60,
		cameraNear: 20,
	},
};

class ActivityLayoutStore {
	// The Initialised State
	json = {
		1: {
			entities: {
				sampleEntity: {
					components: {},
					systems: {},
				},
			},
			grid: { height: 0, width: 0, color: "", showGrid: false, opacity: 0 },
			environment: {
				cannon: {},
				three: {
					camera: { position: [0, 0, 0] },
					floor: {
						planeGeometry: {
							width: 4000,
							height: 4000,
							rotation: -1.5707963267948966, // -Math.PI / 2
						},
						planeMaterial: {
							color: 0xffffff,
							roughness: 0.1,
							metalness: 0.1,
							opacity: 0.5,
						},
						receiveShadow: true,
						castShadow: false,
					},
				},
			},
			uiTemplate: "",
		},
	};

	lightsTest = { directionalLight: [dlTest, dlTest2] };
	jsonUnsavedChanges = false;
	currentActivityContext = 1;
	currentGameContext = "";
	currentProjectContext = "";
	selectedEntity;
	floorMesh = null;

	// This is the selected enity from the entity library
	entityToBeAdded = {};
	// This is the json's of all the entities in the entity library
	entityLibrary = {};
	gridSnap = true;
	currentEntityContext = "";
	ambientLight = null;
	constructor() {
		makeAutoObservable(this);
		this.addDirectionalLight = this.addDirectionalLight.bind(this);
		this.deleteDirectionalLight = this.deleteDirectionalLight.bind(this);
	}
	/**
	 * This function sets the floor mesh in the current activity context
	 */
	setFloorMesh() {
		// eslint-disable-next-line no-undef
		if (typeof engine === "undefined") return;
		// eslint-disable-next-line no-undef
		const scene = engine.getScene();
		// eslint-disable-next-line no-undef
		scene.traverse(child => {
			if (child.name === "floor") {
				this.floorMesh = child;
			}
		});
	}
	/**
	 * This function sets the floor width in the current activity context
	 * @param {Number} width - The new width of the floor
	 */
	setFloorWidth(width) {
		// Get the json
		const json = this.json;
		// Get the activity number
		const activityNumber = this.currentActivityContext;
		// Update the json with the new width
		json[activityNumber].environment.three.floor.planeGeometry.width = width;
		// eslint-disable-next-line no-undef
		if (typeof engine !== "undefined") {
			// eslint-disable-next-line no-undef
			enabledInterpreter.handleFloorSetup(this.json[this.currentActivityContext].environment.three.floor);
		}
	}
	/**
	 * This function sets the floor height in the current activity context
	 * @param {Number} height  - The new height of the floor
	 */
	setFloorHeight(height) {
		// Get the json
		const json = this.json;
		// Get the activity number
		const activityNumber = this.currentActivityContext;
		// Update the json with the new width
		json[activityNumber].environment.three.floor.planeGeometry.height = height;
		// eslint-disable-next-line no-undef
		if (typeof engine !== "undefined") {
			// eslint-disable-next-line no-undef
			enabledInterpreter.handleFloorSetup(this.json[this.currentActivityContext].environment.three.floor);
		}
	}
	/**
	 * This function sets the floor color in the current activity context
	 * @param {Number} color - #xxxxxx - The new color of the floor
	 */
	setFloorColor(color) {
		// Get the json
		const json = this.json;
		// Get the activity number
		const activityNumber = this.currentActivityContext;
		// Update the json with the new color
		json[activityNumber].environment.three.floor.planeMaterial.color = color;
		// eslint-disable-next-line no-undef
		if (typeof engine !== "undefined") {
			// eslint-disable-next-line no-undef
			enabledInterpreter.handleFloorSetup(this.json[this.currentActivityContext].environment.three.floor);
		}
	}
	/**
	 * This function adds a directional light to the current activity
	 **/
	addDirectionalLight() {
		//Add an id depending on how many lights are in the array
		const light = {};
		// Get the maximum id in the array
		let maxId;
		if (this.lightsTest.directionalLight.length === 0) {
			maxId = 0;
		} else {
			maxId = Math.max(...this.lightsTest.directionalLight.map(light => light.id));
		}

		light.id = maxId + 1;
		light.position = [0, 0, 0];
		light.color = 0x0000ff;
		light.shadow = {
			castShadow: true,
			mapSizeWidth: 1024,
			mapSizeHeight: 1024,
			cameraLeft: -20,
			cameraRight: 20,
			cameraTop: 20,
			cameraBottom: -20,
			cameraFar: 60,
			cameraNear: 20,
		};
		this.lightsTest.directionalLight.push(light);
	}
	/**
	 * This function updates the color of a directional light in the current activity context
	 * @param {Number} id - The id of the directional light to be updated
	 * @param {Number} color  - The new color of the directional light
	 */
	setDirectionLightColor(id, color) {
		const light = this.lightsTest.directionalLight.find(light => light.id === id);
		light.color = color;
	}
	/**
	 * This function updates the position of a directional light in the current activity context
	 * @param {Number} id  - The id of the directional light to be updated
	 * @param {*} position  - The new X position of the directional light
	 */
	setDirectionLightXPosition(id, position) {
		const light = this.lightsTest.directionalLight.find(light => light.id === id);
		light.position.x = position;
	}
	/**
	 * This function updates the Y position of a directional light in the current activity context
	 * @param {Number} id - The id of the directional light to be updated
	 * @param {Number} position - The new Y position of the directional light
	 */
	setDirectionLightYPosition(id, position) {
		const light = this.lightsTest.directionalLight.find(light => light.id === id);
		light.position.y = position;
	}
	/**
	 * This function updates the Z position of a directional light in the current activity context
	 * @param {Number} id - The id of the directional light to be updated
	 * @param {Number} position  - The new Z position of the directional light
	 */
	setDirectionLightZPosition(id, position) {
		const light = this.lightsTest.directionalLight.find(light => light.id === id);
		light.position.z = position;
	}
	/**
	 * This function deletes a directional light from the current activity context
	 * @param {Number} id - The id of the directional light to be deleted
	 */
	deleteDirectionalLight(id) {
		const index = this.lightsTest.directionalLight.findIndex(light => light.id === id);
		if (index === -1) return;
		this.lightsTest.directionalLight.splice(index, 1);
	}
	setJson(json) {
		// Action
		this.json = json;
	}
	setActivityContext(activityNumber) {
		// Action
		this.currentActivityContext = activityNumber;
	}
	setGameContext(gameName) {
		// Action
		this.currentGameContext = gameName;
	}
	setProjectContext(projectName) {
		// Action
		this.currentProjectContext = projectName;
	}
	setGridSnap(snap) {
		this.gridSnap = snap;
	}
	/**
	 * This sets the json of the entity to be added to the simulation
	 * @param {Object} entity  - the json object of the entity to be added
	 */
	setEntityToBeAdded(entity) {
		// Get the json from the entitylibrary
		let entityJSON = this.entityLibrary[entity];
		if (entity === undefined) entityJSON = {};
		this.entityToBeAdded = entityJSON;
	}
	// Sets the position of the entity to be added (AKA the selected entity in the entity library)
	setEntityToBeAddedPosition(position) {
		// Check if the entityToBeAdded has a components and position object
		if (!this.entityToBeAdded.components) {
			console.log("The selected entity does not have a position component");
			return;
		}
		this.entityToBeAdded.components.position = position;
	}
	/**
	 * This function sets the x position of the camera in the current activity context
	 * @param {Number} position - The new x position of the camera
	 * @returns
	 */
	setCameraPositionX(position) {
		if (isNaN(position)) {
			console.log("The position parameter is not a number");
			return;
		} // Get the json
		const json = this.json;
		// Get the activity number
		const activityNumber = this.currentActivityContext;
		// Update the json with the new position
		json[activityNumber].environment.three.camera.position[0] = position;
		const updatedPosition = this.json[this.currentActivityContext].environment.three.camera.position;
		// Check if engine is defined
		// eslint-disable-next-line no-undef
		if (typeof engine !== "undefined") {
			// eslint-disable-next-line no-undef
			engine.getCamera().position.set(updatedPosition[0], updatedPosition[1], updatedPosition[2]);
		}
	}
	/**
	 * This function sets the y position of the camera in the current activity context
	 * @param {Number} position - The new y position of the camera
	 */
	setCameraPositionY(position) {
		if (isNaN(position)) {
			console.log("The position parameter is not a number");
			return;
		}
		// Get the json
		const json = this.json;
		// Get the activity number
		const activityNumber = this.currentActivityContext;
		// Update the json with the new position
		json[activityNumber].environment.three.camera.position[1] = position;
		const updatedPosition = this.json[this.currentActivityContext].environment.three.camera.position;
		// Check if engine is defined
		// eslint-disable-next-line no-undef
		if (typeof engine !== "undefined") {
			// eslint-disable-next-line no-undef
			engine.getCamera().position.set(updatedPosition[0], updatedPosition[1], updatedPosition[2]);
		}
	}
	/**
	 * This function sets the z position of the camera in the current activity context
	 * @param {Number} position - The z position of the camera
	 */
	setCameraPositionZ(position) {
		if (isNaN(position)) {
			console.log("The position parameter is not a number");
			return;
		}
		// Get the json
		const json = this.json;
		// Get the activity number
		const activityNumber = this.currentActivityContext;
		// Update the json with the new position
		json[activityNumber].environment.three.camera.position[2] = position;
		const updatedPosition = this.json[this.currentActivityContext].environment.three.camera.position;
		// Check if engine is defined
		// eslint-disable-next-line no-undef
		if (typeof engine !== "undefined") {
			// eslint-disable-next-line no-undef
			engine.getCamera().position.set(updatedPosition[0], updatedPosition[1], updatedPosition[2]);
		}
	}
	setCameraDirectionX(direction) {
		if (isNaN(direction)) {
			console.log("The direction parameter is not a number");
			return;
		}
		// Convert direction string to an integer
		direction = Number(direction);
		// Get the activity number
		const activityNumber = this.currentActivityContext;
		// Update the json with the new direction
		this.json[activityNumber].environment.three.camera.target[0] = direction;
		const updatedDirection = this.json[this.currentActivityContext].environment.three.camera.target;
		if (typeof engine !== "undefined") {
			// eslint-disable-next-line no-undef
			engine.getController().target = new THREE.Vector3(updatedDirection[0], updatedDirection[1], updatedDirection[2]);
		}
	}

	setCameraDirectionY(direction) {
		if (isNaN(direction)) {
			console.log("The direction parameter is not a number");
			return;
		}
		// Convert direction string to an integer
		direction = Number(direction);
		// Get the activity number
		const activityNumber = this.currentActivityContext;
		// Update the json with the new direction
		this.json[activityNumber].environment.three.camera.target[1] = direction;
		const updatedDirection = this.json[this.currentActivityContext].environment.three.camera.target;
		if (typeof engine !== "undefined") {
			// eslint-disable-next-line no-undef
			engine.getController().target = new THREE.Vector3(updatedDirection[0], updatedDirection[1], updatedDirection[2]);
		}
	}
	setCameraDirectionZ(direction) {
		if (isNaN(direction)) {
			console.log("The direction parameter is not a number");
			return;
		}
		// Convert direction string to an integer
		direction = Number(direction);
		// Get the activity number
		const activityNumber = this.currentActivityContext;
		// Update the json with the new direction
		this.json[activityNumber].environment.three.camera.target[2] = direction;
		const updatedDirection = this.json[this.currentActivityContext].environment.three.camera.target;
		if (typeof engine !== "undefined") {
			// eslint-disable-next-line no-undef
			engine.getController().target = new THREE.Vector3(updatedDirection[0], updatedDirection[1], updatedDirection[2]);
		}
	}
	/**
	 * This function set the minimum distance the camera can zoom to in the current activity context
	 * @param {Number} distance - the minimum distance of the camera
	 * @returns
	 */
	setMinimumCameraDistance(distance) {
		// Check if the parameter is a number
		if (isNaN(distance)) {
			console.log("The distance parameter is not a number");
			return;
		}
		// Get the json
		const json = this.json;
		// Get the activity number
		const activityNumber = this.currentActivityContext;
		// Update the json with the new distance
		json[activityNumber].environment.three.camera.minDistance = distance;
		// eslint-disable-next-line no-undef
		if (typeof engine !== "undefined") {
			// eslint-disable-next-line no-undef
			engine.getController().minDistance = distance;
		}
	}
	/**
	 * This function sets the maximum distance the camera can zoom away in the current activity context
	 * @param {Number} distance - The maximum distance of the camera
	 */
	setMaximumCameraDistance(distance) {
		// Get the json
		const json = this.json;
		// Get the activity number
		const activityNumber = this.currentActivityContext;
		// Update the json with the new distance
		json[activityNumber].environment.three.camera.maxDistance = distance;
		// eslint-disable-next-line no-undef
		if (typeof engine !== "undefined") {
			// eslint-disable-next-line no-undef
			engine.getController().maxDistance = distance;
		}
	}
	/**
	 * This function sets the maximum polar angle the camera can rotate to in the current activity context
	 * @param {Number} angle - The maximum angle the camera can rotate to in degrees
	 */
	setMaximumPolarAngle(angle) {
		// Convert the angle from degrees to radians
		angle = (angle * Math.PI) / 180;
		// Get the json
		const json = this.json;
		// Get the activity number
		const activityNumber = this.currentActivityContext;
		// Update the json with the new angle
		json[activityNumber].environment.three.camera.maxPolarAngle = angle;
		// eslint-disable-next-line no-undef
		if (typeof engine !== "undefined") {
			// eslint-disable-next-line no-undef
			engine.getController().maxPolarAngle = angle;
		}
	}
	/**
	 * This function sets the color of the ambient light in the current activity context
	 * @param {Number} color - The color of the ambient light in the form #xxxxxx
	 * @returns
	 */
	setAmbientLightColor(color) {
		// Need to convert the color into format 0x000000
		if (this.ambientLight == null) return;
		color = color.replace("#", "0x");
		// Get the json
		const json = this.json;
		// Get the activity number
		const activityNumber = this.currentActivityContext;
		// Update the json with the new color
		json[activityNumber].environment.three.lights.ambientLight.color = color;
		if (this.ambientLight.color) this.ambientLight.color.setHex(color);
	}

	setAmbientLightIntensity(intensity) {
		if (this.ambientLight == null) return;
		// Get the json
		const json = this.json;
		// Get the activity number
		const activityNumber = this.currentActivityContext;
		// Update the json with the new color
		json[activityNumber].environment.three.lights.ambientLight.intensity = intensity;
		if (this.ambientLight.color) this.ambientLight.intensity = intensity;
	}

	// This function finds the ambient light in the current activity context
	findAmbientLight() {
		// eslint-disable-next-line no-undef
		if (typeof engine !== "undefined") {
			// eslint-disable-next-line no-undef
			this.ambientLight = engine.getAmbientLight();
		}
	}
	// This functions adds entity to the entity library
	addEntityToEntityLibrary(entity) {
		// If this entity has a name
		let entityName;
		if (!entity.name) entityName = entity.name;
		else entityName = entity.name;
		this.entityLibrary[entityName] = entity;
	}

	/**
	 * This function sets
	 * @param {String} entityName - The name of the entity to be selected
	 * @returns
	 */
	setSelectedEntity(entityName) {
		// If engine isnt defined, return
		// eslint-disable-next-line no-undef
		if (!engine) return;
		// Get the entity
		let entity;
		// eslint-disable-next-line no-undef
		for (const value of engine.entities.values()) {
			if (value.name === entityName) {
				entity = value;
				break;
			}
		}

		// Get the entity's mesh from the meshhelper
		// eslint-disable-next-line no-undef
		let mesh = engine.meshHelper.map.get(entity.meshID);
		if (!mesh && typeof buaDebugger !== "undefined") {
			// Use the debugger to get its body mesh
			// eslint-disable-next-line no-undef
			mesh = buaDebugger.helpers.get(entity.bodyID);
		}
		// Set the mesh from the meshhelper to be the selected entity
		this.selectedEntity = mesh;
	}
	/**
	 * This function updates an entities position in the current activity context
	 * @param {String} entityName - The name of the entity to update
	 * @param {Object} newPos - The new position of the entity in the form {x: number, y: number, z: number}
	 */
	setEntityPosition(entityName, newPos) {
		// Action
		// Get the entity from the entityHolder
		let entity;
		// Cycle through map and see if entity with name exists
		// eslint-disable-next-line no-undef
		for (const value of engine.entities.values()) {
			if (value.name === entityName) {
				entity = value;
				break;
			}
		}
		// If engine isnt defined, return
		// eslint-disable-next-line no-undef
		if (!engine) return;
		// Set the position of the entity using the relevant functions from the engine
		// eslint-disable-next-line no-undef
		engine.positionHelper.setPosition(entity.id, { x: newPos[0], y: newPos[1], z: newPos[2] });
		// Update the json with the new position
		this.json[this.currentActivityContext].entities[entityName].components.position = newPos;
	}

	/**
	 * This function sets the rotation of an entity in the simulation and updates the json with the new rotation
	 * @param {String} entityName - The name of the entity to be rotated
	 * @param {Array} newRot - The new rotation of the entity [x,y,z]
	 */
	setEntityRotation(entityName, newRot) {
		// Get the entity from the entityHolder
		let entity;
		// Cycle through map and see if entity with name exists
		// eslint-disable-next-line no-undef
		for (const value of engine.entities.values()) {
			if (value.name === entityName) {
				entity = value;
				break;
			}
		}
		// Convert the angle to radians
		const newRotRadians = {
			isEuler: true,
			_x: (newRot[0] * Math.PI) / 180,
			_y: (newRot[1] * Math.PI) / 180,
			_z: (newRot[2] * Math.PI) / 180,
			_order: "XYZ",
		};
		// Check if the engine is defined
		// eslint-disable-next-line no-undef
		if (!engine) return;
		// Set the rotation of the entity using the relevant functions from the engine
		// eslint-disable-next-line no-undef
		engine.rotationHelper.setRotation(entity.id, newRotRadians);
		// Update the json with the new rotation
		this.json[this.currentActivityContext].entities[entityName].components.rotation = newRot;
	}
	/**
	 * This function sets the zoom controls for the simulation for an activity
	 * @param {Boolean} zoom - Whether zoom is enabled or not
	 */
	setInputZoom(zoom) {
		// Get the json
		const json = this.json;
		// Get the activity number
		const activityNumber = this.currentActivityContext;
		// Update the json with the bool zoom value
		json[activityNumber].environment.three.zoom = zoom;
	}
	/**
	 * This function sets the pan controls for the simulation for an activity
	 * @param {Boolean} pan - Whether pan is enabled or not
	 */
	setInputPan(pan) {
		// Get the json
		const json = this.json;
		// Get the activity number
		const activityNumber = this.currentActivityContext;
		// Update the json with the bool zoom value
		json[activityNumber].environment.three.controls.enablePan = pan;
		// eslint-disable-next-line no-undef
		if (typeof engine !== "undefined") {
			// eslint-disable-next-line no-undef
			engine.getController().enablePan = pan;
		}
	}
	/**
	 * This function sets the rotate controls for the simulation for an activity
	 * @param {Boolean} rotate - Whether rotate is enabled or not
	 */
	setInputRotate(rotate) {
		// Get the json
		const json = this.json;
		// Get the activity number
		const activityNumber = this.currentActivityContext;
		// Update the json with the bool zoom value
		json[activityNumber].environment.three.controls.enableRotate = rotate;
	}
	/**
	 * This function sets the scale of an entity's mesh in the simulation and updates the json with the new scale
	 * @param {String} entityName -	The name of the entity to be scaled
	 * @param {Array} newScale - The new scale of the entity [x,y,z]
	 */
	setEntityMeshScale(entityName, newScale) {
		let entity;
		// Cycle through map and see if entity with name exists
		// eslint-disable-next-line no-undef
		for (const value of engine.entities.values()) {
			if (value.name === entityName) {
				entity = value;
				break;
			}
		}
		// Check if the engine is defined
		// eslint-disable-next-line no-undef
		if (!engine) return;
		// Set the scale of the entity using the relevant functions from the engine
		// eslint-disable-next-line no-undef
		engine.meshHelper.map.get(entity.id).scale.set(newScale[0], newScale[1], newScale[2]);
		// Update the json with the new scale
		this.json[this.currentActivityContext].entities[entityName].components.mesh.scale = newScale;
	}
	/**
	 * This function sets the size of an entity's rigid body in the simulation and updates the json with the new size
	 * @param {String} entityName - The name of the entity to be scaled
	 * @param {Array} newSize - The new size of the entity [x,y,z]
	 */
	setEntityRigidBodySize(entityName, newSize) {
		let entity;
		// Cycle through map and see if entity with name exists
		// eslint-disable-next-line no-undef
		for (const value of engine.entities.values()) {
			if (value.name === entityName) {
				entity = value;
				break;
			}
		}
		// Check if the engine is defined
		// eslint-disable-next-line no-undef
		if (!engine) return;
		// Set the size of the entity using the relevant functions from the engine
		// eslint-disable-next-line no-undef
		const cube = engine.rigidBodyHelper.map.get(entity.id).body.shapes[0];
		cube.halfExtents.set(...newSize.map(e => e / 2));
		// Update the json with the new size
		this.json[this.currentActivityContext].entities[entityName].components.size = newSize;
	}
	/**
	 * This function deletes an entity from the current activity context and from the simulation
	 * @param {String} entityName - The name of the entity to be deleted
	 */
	deleteEntity(entityName) {
		let entity;
		// eslint-disable-next-line no-undef
		for (const value of engine.entities.values()) {
			if (value.name === entityName) {
				entity = value;
				break;
			}
		}

		// Check if the enabledInterprer and the engine is defined
		// eslint-disable-next-line no-undef
		if (!enabledInterpreter || !engine) return;
		// Remove the entity from the scene using relevant functions from the interpreter
		// eslint-disable-next-line no-undef
		enabledInterpreter.removeRays(entity);
		// eslint-disable-next-line no-undef
		engine.entities.delete(entity.id);
		// eslint-disable-next-line no-undef
		enabledInterpreter.removeEntityComponents(entity);
		delete this.json[this.currentActivityContext].entities[entityName];
	}
	/**
	 * This adds an entity to the current activity context and add it to the simulation
	 * @param {String} entityName - The name of the entity to be added
	 * @param {Object} entityData - The entity json data to be added
	 * @returns
	 */
	async addEntity(entityName, entityData) {
		// Add the entityName as a component to the entityData
		entityData.components.name = entityName;
		// Check if the enabledInterpreter is defined
		// eslint-disable-next-line no-undef
		if (!enabledInterpreter) return;
		// Add the entity to the scene using relevant functions from the interpreter
		// eslint-disable-next-line no-undef
		const entityCreated = await enabledInterpreter.handleEntitySetup({ entityName: entityData });

		// Get new name of entity as it may have been changed if it already existed
		const newEntityName = entityCreated.entityNames[entityCreated.entityNames.length - 1];
		this.selectEntity(newEntityName); // Set the current entity context to the new entity name

		// Add the entity to the json
		this.json[this.currentActivityContext].entities[newEntityName] = entityData;
	}
	/**
	 * This function reloads the existing activity
	 */
	/**
	 * This function reloads the existing activity
	 */
	async reloadActivity() {
		// set current activity context in activityLayoutStore
		// eslint-disable-next-line no-undef
		await enabledInterpreter.interpretSplitJSON(this.json, this.currentActivityContext); // Load the new activity and set current activity context
	}
	/**
	 * This function switches the activity to the activity number specified, updating the scene and the activity context
	 * @param {Number} activityNumber - The activity number to switch to
	 */
	async switchActivity(activityNumber) {
		// set current activity context in activityLayoutStore
		this.setActivityContext(activityNumber);
		// eslint-disable-next-line no-undef
		await enabledInterpreter.interpretSplitJSON(this.json, activityNumber); // Load the new activity and set current activity context
	}
	/*** This function updates the grid based on the parameters passed in, also updating the grid object in the data store
	 * @param  {Object} gridObject - The object containing the grid attributes to be updated
	 */
	async updateGrid(gridObject) {
		// Action
		// Check if the grid object is defined and update the grid object in the data store
		if (this.json[this.currentAcivityContext].grid === undefined) {
			this.json[this.currentActivityContext].grid = gridObject;
		}
		this.json[this.currentActivityContext].grid.height =
			gridObject.height !== undefined ? gridObject.height : this.json[this.currentActivityContext].grid.height;
		this.json[this.currentActivityContext].grid.width =
			gridObject.width !== undefined ? gridObject.width : this.json[this.currentActivityContext].grid.width;
		this.json[this.currentActivityContext].grid.color =
			gridObject.color !== undefined ? gridObject.color : this.json[this.currentActivityContext].grid.color;
		this.json[this.currentActivityContext].grid.showGrid =
			gridObject.showGrid !== undefined ? gridObject.showGrid : this.json[this.currentActivityContext].grid.showGrid;
		this.json[this.currentActivityContext].grid.opacity =
			gridObject.opacity !== undefined ? gridObject.opacity : this.json[this.currentActivityContext].grid.opacity;

		// Create the grid based on the updated data store
		// eslint-disable-next-line no-undef
		await enabledInterpreter.createRectangleGrid(
			this.json[activityLayoutStore.currentActivityContext].grid.height,
			this.json[activityLayoutStore.currentActivityContext].grid.width,
			this.json[activityLayoutStore.currentActivityContext].grid.color,
			this.json[activityLayoutStore.currentActivityContext].grid.showGrid,
			this.json[activityLayoutStore.currentActivityContext].grid.opacity,
		);
	}
	/**
	 * * @param  {Object} environmentSettings - The object containing the environment settings to be updated
	 * @param  {Boolean} overWriteAllExisting - A boolean value to determine if all existing environment settings should be overwritten or not
	 */
	async updateEnvironmentSettings(environmentSettings, overWriteAllExisting = false) {
		// Action
		// Check if the environment object is defined, if not, set the environment object in the data store. Or if overWriteAllExisting is true, overwrite all existing environment settings
		if (this.json[this.currentActivityContext].environment === undefined || overWriteAllExisting) {
			this.json[this.currentActivityContext].environment = environmentSettings;
			return;
		}
		// Else, update the environment settings in the data store based on the environment settings passed in

		// Update the cannon environment settings if defined (wants to be updated)
		if (environmentSettings.cannon !== undefined)
			Object.assign(this.json[this.currentActivityContext].environment.cannon, environmentSettings.cannon);
		// Update the three environment settings if defined (wants to be updated)
		if (environmentSettings.three !== undefined)
			Object.assign(this.json[this.currentActivityContext].environment.three, environmentSettings.three);

		// Reload the activity with the updated environment settings
		// await this.reloadActivity();
		// eslint-disable-next-line no-undef
		await enabledInterpreter.handleEnvironmentSetup(this.json[this.currentActivityContext].environment);
	}
	/**
	 * This function sets the current entity context to the entity name specified
	 * @param  {String} entityName - The name of the entity to set as the current entity context*/
	selectEntity(entityName) {
		this.currentEntityContext = entityName;
	}
	/**
	 * This function sets the ui template for the current activity context
	 * @param {String} newUiTemplate
	 */
	setUiTemplate(newUiTemplate) {
		this.json[this.currentActivityContext].uiTemplate = newUiTemplate;
	}
	/**
	 * This function adds a new activity to the json
	 */
	addActivity() {
		// Action
		// const activityNumber = Object.keys(this.json).length + 1;
		const activityNumber = Number(Object.keys(this.json)[Object.keys(this.json).length - 1]) + 1;

		this.json[activityNumber] = {
			entities: {},
			grid: this.json[this.currentActivityContext ? this.currentActivityContext : activityNumber - 1].grid, // copy grid settings from current activity, or if not selected, from previous activity
			environment:
				this.json[this.currentActivityContext ? this.currentActivityContext : activityNumber - 1].environment, // copy grid settings from current activity, or if not selected, from previous activity
			uiTemplate: "",
		};
	}
	/**
	 * This function deletes an activity from the json, given the activity number
	 * @param {Number} activityNumber
	 */
	deleteActivity(activityNumber) {
		// Action
		delete this.json[activityNumber];
	}
	/**
	 * This function sets the jsonUnsavedChanges to the unsavedChanges object passed in
	 * @param {Object} unsavedChanges
	 */
	setJsonUnsavedChanges(unsavedChanges) {
		// Action
		this.jsonUnsavedChanges = unsavedChanges;
	}
}

export const activityLayoutStore = new ActivityLayoutStore();
