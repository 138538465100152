import React from 'react';

/*
    Label Input Button

    Custom component for the custom drawer, is a label component next to an input component netx to a button component. This can be 
    used to give an input but only apply the changes on button click. Example name change of entity in the Entity Builder component.
*/

function LabelInputButton({ labelName, inputDefault, inputCallback, buttonName, buttonCallback }) {
    return (
        <>
            <label htmlFor="inputField">{labelName}</label>
            <input id="inputField" type="text" value={inputDefault} onChange={e => inputCallback(e.target.value)} />
            <button type="button" onClick={buttonCallback}>{buttonName}</button>
        </>
    );
}

export default LabelInputButton;