import { useState } from "react";
import Modal from "react-modal";
import CreateAxiosInstance from "../../../AxiosInstance/AxiosInstance";
import AssetLibrary from "../../AssetLibrary/AssetLibrary";

import LabelInput from "../../UIBuilder/CustomDrawer/components/LabelInput/LabelInput";

function MeshSetup(props) {
	const id = props.id;
	const axios = CreateAxiosInstance();
	const [meshScaleX, meshScaleY, meshScaleZ] = props.meshScale;

	const [meshPositionOffsetX, meshPositionOffsetY, meshPositionOffsetZ] = props.meshPositionOffset;

	const [meshRotationOffsetX, meshRotationOffsetY, meshRotationOffsetZ] = props.meshRotationOffset;

	const meshModelCallback = props.meshModelCallback;
	const meshScaleCallback = props.meshScaleCallback;
	const meshPositionOffsetCallback = props.meshPositionOffsetCallback;
	const meshRotationOffsetCallback = props.meshRotationOffsetCallback;

	const handleMeshModelChange = (name, meshModelLink) => {
		meshModelCallback(id, name, meshModelLink);
	};

	const handleMeshScaleChange = (index, value) => {
		const scale = [meshScaleX, meshScaleY, meshScaleZ];

		scale[index] = value;

		meshScaleCallback(id, scale);
	};

	const handleMeshPositionOffsetChange = (index, value) => {
		const positionOffset = [meshPositionOffsetX, meshPositionOffsetY, meshPositionOffsetZ];

		positionOffset[index] = value;

		meshPositionOffsetCallback(id, positionOffset);
	};

	const handleMeshRotationOffsetChange = (index, value) => {
		const rotationOffset = [meshRotationOffsetX, meshRotationOffsetY, meshRotationOffsetZ];

		rotationOffset[index] = value;

		meshRotationOffsetCallback(id, rotationOffset);
	};

	const [assetLibraryModalIsOpen, setAssetLibraryModalIsOpen] = useState(props.meshModel);
	const [meshModel, setMeshModel] = useState(false);

	const handleModelSelection = async meshModel => {
		console.log("handle model selection");

		// set the mesh model in the state
		setMeshModel(meshModel);

		// get the secure s3 link from the API server
		const modelLink = await axios.get(`/assets/models/${meshModel}`);

		// pass new model link for entity (name, newModelLink)
		handleMeshModelChange(meshModel, modelLink.data);
	};

	// React Component Init
	const meshScaleInputInfo = [
		{
			inputDefault: "1",
			inputValue: meshScaleX.toString(),
			inputType: "number",
			inputStep: 0.1,
			inputMinimum: 0.01,
			inputMaximum: 100,
			inputCallback: (newValue) => handleMeshScaleChange(0, Number(newValue)),
		},
		{
			inputDefault: "1",
			inputValue: meshScaleY.toString(),
			inputType: "number",
			inputStep: 0.1,
			inputMinimum: 0.01,
			inputMaximum: 100,
			inputCallback: (newValue) => handleMeshScaleChange(1, Number(newValue)),
		},
		{
			inputDefault: "1",
			inputValue: meshScaleZ.toString(),
			inputType: "number",
			inputStep: 0.1,
			inputMinimum: 0.01,
			inputMaximum: 100,
			inputCallback: (newValue) => handleMeshScaleChange(2, Number(newValue)),
		}
	];
	const meshScaleInput = <LabelInput labelName={"Mesh Scale"} inputInfo={meshScaleInputInfo} />

	const meshPositionOffsetInputInfo = [
		{
			inputDefault: "0",
			inputValue: meshPositionOffsetX.toString(),
			inputType: "number",
			inputStep: 0.1,
			inputMinimum: 0,
			inputMaximum: 100,
			inputCallback: (newValue) => handleMeshPositionOffsetChange(0, Number(newValue)),
		},
		{
			inputDefault: "0",
			inputValue: meshPositionOffsetY.toString(),
			inputType: "number",
			inputStep: 0.1,
			inputMinimum: 0,
			inputMaximum: 100,
			inputCallback: (newValue) => handleMeshPositionOffsetChange(1, Number(newValue)),
		},
		{
			inputDefault: "0",
			inputValue: meshPositionOffsetZ.toString(),
			inputType: "number",
			inputStep: 0.1,
			inputMinimum: 0,
			inputMaximum: 100,
			inputCallback: (newValue) => handleMeshPositionOffsetChange(2, Number(newValue)),
		}
	];
	const meshPositionOffsetInput = <LabelInput labelName={"Mesh Position Offset"} inputInfo={meshPositionOffsetInputInfo} />

	const meshRotationOffsetInputInfo = [
		{
			inputDefault: "0",
			inputValue: meshRotationOffsetX.toString(),
			inputType: "number",
			inputStep: 0.1,
			inputMinimum: 0,
			inputMaximum: 359,
			inputCallback: (newValue) => handleMeshRotationOffsetChange(0, Number(newValue)),
		},
		{
			inputDefault: "0",
			inputValue: meshRotationOffsetY.toString(),
			inputType: "number",
			inputStep: 0.1,
			inputMinimum: 0,
			inputMaximum: 359,
			inputCallback: (newValue) => handleMeshRotationOffsetChange(0, Number(newValue)),
		},
		{
			inputDefault: "0",
			inputValue: meshRotationOffsetZ.toString(),
			inputType: "number",
			inputStep: 0.1,
			inputMinimum: 0,
			inputMaximum: 359,
			inputCallback: (newValue) => handleMeshRotationOffsetChange(0, Number(newValue)),
		}
	];
	const meshRotationOffsetInput = <LabelInput labelName={"Mesh Rotation Offset"} inputInfo={meshRotationOffsetInputInfo} />


	return (
		<div className="individual-mesh-settings__container">
			<Modal
				className="asset-library__modal"
				isOpen={assetLibraryModalIsOpen}
				contentLabel="Asset Library"
				ariaHideApp={false} // To supress warning
			>
				<AssetLibrary
					setAssetLibraryModalIsOpen={setAssetLibraryModalIsOpen}
					assetLibraryCallback={handleModelSelection}
				/>
			</Modal>

			<div className="mesh-upload__container">
				Choose Model:
				{meshModel ? (
					` ${meshModel}`
				) : (
					<button
						type="button"
						onClick={async () => {
							setAssetLibraryModalIsOpen(true);
						}}
					>
						+
					</button>
				)}
			</div>
			<div className="mesh-scale__container">
				{meshScaleInput}
			</div>
			<div className="mesh-position-offset__container">
				{meshPositionOffsetInput}
			</div>
			<div className="mesh-rotation-offset__container">
				{meshRotationOffsetInput}
			</div>
			<br />
		</div>
	);
}

export default MeshSetup;
