import React from "react";

import addActivityPng from '../../../assets/images/bua/add-activity.png';
import deleteActivityPng from '../../../assets/images/bua/delete-activity.png';

// import "./ActivitySelector.css";

function ActivitySelector({ buttons, selectedActivity, setSelectedActivity, addActivity, deleteActivity }) {
    return (
        <div className="flex flex-col h-full justify-between items-center" id="activity-selector__container">
            <div className="flex flex-col w-full items-center overflow-x-hidden pt-4 box-border" id="activity-selector__container__top">
                <div className="flex flex-col w-full items-center" id="activity-selector__container__activites">
                    {buttons.map((button, index) => (
                        <button
                            key={button}
                            type="button"
                            className={`w-10 h-10
                            rounded-full
                            ${selectedActivity === button ? "bg-blue-500" : "bg-white"}
                            text-black
                            cursor-pointer
                            mx-0 my-3
                            border-none
                            text-lg
                            font-bold
                            hover:bg-gray-500`}
                            onClick={() => setSelectedActivity(button)}
                        >
                            {button}
                        </button>
                    ))}
                </div>
            </div>
            <div className="flex flex-col w-full items-center justify-end" id="activity-selector__container__bottom">
                <hr className="w-full border-t-1 my-1"/>    
                <button
                    className="flex justify-center items-center w-10 h-10 rounded-full bg-white text-black cursor-pointer mx-0 my-3 border-none text-lg font-bold"
                    onClick={() => addActivity()}
                    type="button"
                >
                    <img className="w-6 h-6" id="activity-selector__add-activity" src={addActivityPng} alt="Add activity to game" /> 
                </button>
                <button
                    className="flex justify-center items-center w-10 h-10 rounded-full bg-white text-black cursor-pointer mx-0 my-3 border-none text-lg font-bold"
                    onClick={() => deleteActivity()}
                    type="button"
                >
                    <img className="w-6 h-6" id="activity-selector__delete-activity" src={deleteActivityPng} alt="Delete activity from game" /> 
                </button>
            </div>
        </div>
    );
}
export default ActivitySelector;


