import React from "react";
import { useState } from "react";

interface KeyboardShortcutInputProps {
	label: string,
	targetKey: string,
	defaultKey: string,
	setAccessibilityAttribute: (attribute: string, key: string, value: string) => void,
	checkIfKeyBeingUsed: (key: string) => boolean,
}

export default function KeyboardShortcutInput({
	label,
	targetKey,
	defaultKey,
	setAccessibilityAttribute,
	checkIfKeyBeingUsed,
}: KeyboardShortcutInputProps) {
	const [remappedKey, setRemappedKey] = useState(defaultKey);

	function handleKeyInput(key: string) {

		const restricted = [
			" ",
			"Tab",
			"Shift",
			"Control",
			"Alt",
			"Meta",
		]

		if (restricted.includes(key)) return;

		const newKey = key.length === 1 ? key.toUpperCase() : key;

		if (checkIfKeyBeingUsed(newKey)) {
			window.alert(`Key ${newKey} is already in use`);
			return;
		}

		// If enter, tab or an arrow button are entered, I want to update the value in the checkbox
		setRemappedKey(newKey);

		// Update the datastore with the new value
		setAccessibilityAttribute("controls", targetKey, newKey);
	}

	return (
		<div className="flex w-full items-center justify-between text-sm sm:text-base md:text-lg">
			<div
				id={`label${label}`}
				className=""
			>
				Remap of {label} key:
			</div>
			<input
				className="
					w-[40%] h-8 max-w-72 p-2
					rounded-md shadow-sm hover:cursor-pointer
					focus:ring-offset-2 focus:ring-[#1FC3FF] focus:ring focus:outline-none
				"
				type="text"
				value={remappedKey}
				onKeyDown={(e) => { handleKeyInput(e.key) }}
				onChange={() => { }} // necessary to avoid getting the no onChange warning
				id={`keyboardControlInput${label}`}
				aria-labelledby={`label${label}`}
			/>
		</div>
	);
};
