import React, { useState } from 'react'

interface DropdownProps {
    label: string;
    description?: string;
    defaultValue: string;
    options: { key: string, value: string }[];
    onChange: (value: string) => void;
    colorMode?: string;
}

export default function Dropdown({
    label,
    description,
    defaultValue,
    options = [],
    onChange,
    colorMode = "dark"
}: DropdownProps) {
    const [selectedValue, setSelectedValue] = useState(defaultValue);

    const optionElements = options.map((option) => {
        return (
            <option key={option.key} value={option.value}>
                {option.value}
            </option>
        )
    });

    const handleSelect = (value: string) => {
        setSelectedValue(value)
        if (onChange) {
            onChange(value)
        }
    }

    return (
        <div className="w-full flex items-center justify-between">
            <div className="flex-grow justify-center items-center">
                <div className={` text-sm sm:text-base
                    ${colorMode === "dark" ? "text-color-bua-text-light" : "text-color-bua-text-dark"}
                `}>
                    {label}
                </div>
            </div>
            <div className="flex-shrink-0">
                <label className="flex items-center">
                    <div className="relative">
                        <select
                            id="dropdown"
                            onChange={(e) => handleSelect(e.target.value)}
                            value={selectedValue}
                            className="
                                text-color-bua-text-dark flex-shrink-0 w-32 max-w-48 px-3 py-2 text-sm bg-white rounded-md shadow-sm hover:cursor-pointer
                                focus:ring-offset-2 focus:ring-[#1FC3FF] focus:ring focus:outline-none
                            "
                        >
                            {description && <option value="" disabled>{description}</option>}
                            {optionElements}
                        </select>
                    </div>
                </label>
            </div>
        </div>
    )
}