import React from "react";

import { Button, Collapse, Box, ActionIcon } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import collapseDivMax from '../../../assets/images/bua/collapse-div-maximise.png';
import collapseDivMin from '../../../assets/images/bua/collapse-div-minimise.png';

import './CollapseDiv.css';


const CollapseDiv = ({fillComponents, title, conditionToShowFillComponents = true, alternativeFillComponentsIfConditionNotMet = undefined}) => {

    const [opened, { toggle }] = useDisclosure(false);

    return(
        <>
            <Box style={{ zIndex: 10 }}>
                <div style={{ display: 'flex', alignItems: 'center', height: '50px', width: "100%", boxSizing: 'border-box', padding: "8px 16px", backgroundColor: '#252526', gap: "15px"}}>
                    <ActionIcon id="collapse-div__header__button__drop-down" variant="filled" aria-label="Settings" style={{ height: '100%' }} onClick={toggle}>
                    <img src={opened ? collapseDivMin : collapseDivMax} alt="Dropdown indicator" style={{ width: '100%', height: '100%' }} />
                    </ActionIcon>
                    <Button id="collapse-div__header__button__title" onClick={toggle} 
                        style={{ width: 'auto', height: '100%', textAlign: 'left', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{title}</Button>
                </div>

                <Collapse in={opened} style={{ padding: '15px 20px', backgroundColor: '#4A4A4A' }}>
                    {conditionToShowFillComponents ? fillComponents : <div style={{ color: 'white' }}>{alternativeFillComponentsIfConditionNotMet}</div>}
                </Collapse>
            </Box>
        </>
    );
};

export default CollapseDiv;
