import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import CreateAxiosInstance from "../../AxiosInstance/AxiosInstance";
import buaLogo from "../../assets/images/bua/bua-logo-multicolor.png";
import AppContext from "../../components/AppContext/AppContext";
import FolderItem from "../../components/FolderItem/FolderItem.jsx";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import useFetch from "../../hooks/useFetch";

export default function GamesPage() {

    const localContext = useContext(AppContext);
    const navigate = useNavigate();
    const [selectedProject, setSelectedProject] = useState(null);
    const [loadProjects, setLoadProjects] = useState(true);
    const [titleText, setTitleText] = useState("Projects");

    const [projectList, setProjectList] = useState([]);

    const handleSearch = (searchText) => {
        console.log(searchText);
    }

    const { data, loading, error } = useFetch(loadProjects ? "/projects/getProjectsTree" : null);

    if (error) {
        new Error(error);
    }

    useEffect(() => {
        if (data) {
            setProjectList(data);
        }
    }, [data]);

    let folders = [];

    if (selectedProject) {
        // show this project's games as folders
        folders = Object.keys(projectList[selectedProject].Games).map((game) => {
            return (
                <div key={game} className="w-10/12 h-10/12">
                    <FolderItem
                        folderName={`${game}`}
                        onClickCallback={() => {
                            localContext.setProject(selectedProject);
                            localContext.setGame(game);
                            navigate("/editor");
                        }}
                        gradientStartColor="#A387FF"
                        gradientEndColor="#7750F9"
                    />
                </div>
            )
        });
    }
    else {
        // show all project folders
        folders = Object.keys(projectList).map((project) => {
            return (
                <div key={project} className="w-10/12 h-10/12">
                    <FolderItem
                        folderName={`${project}`}
                        onClickCallback={() => {
                            setSelectedProject(project);
                            setTitleText(`${project} - Games`);
                        }}
                        gradientStartColor="#A387FF"
                        gradientEndColor="#7750F9"
                    />
                </div>
            )
        });
    }


    return (
        <div className="flex flex-col items-center w-full h-full">
            <button
                className="w-24 h-auto self-start m-6"
                type="button"
                onClick={() => { navigate("/") }}
            >
                <img src={buaLogo} alt="bua logo" />
            </button>

            <div className="w-3/4 h-3/4">
                <h1 className="text-color-bua-text-light text-4xl mb-4">
                    {titleText}
                </h1>
                <div className="border-2 rounded-xl w-full h-full flex flex-col items-center justify-center">
                    {loading ? (
                        <div className="w-16 h-16">
                            <LoadingSpinner />
                        </div>
                    ) :
                        (
                            <>
                                <input
                                    placeholder='Search...'
                                    aria-label="Search"
                                    className='w-full bg-color-bua-background border-b-2 px-2 py-1 rounded-t-xl text-color-bua-text-light text-lg md:text-xl lg:text-2xl'
                                    onChange={(e => { handleSearch(e.target.value) })}
                                />
                                <div className="w-full h-full p-4 grid grid-cols-1 place-items-center gap-x-2 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-y-8 sm:gap-y-4 overflow-y-auto">
                                    {folders}
                                </div>
                            </>

                        )
                    }
                </div>
            </div>

        </div>
    )
}