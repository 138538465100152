import React from "react";
import DirectionalLightInput from "./DirectionalLightInput";

const DirectionalLightList = ({ labelInfoContents }) => {
	return (
		<div>
			{Array.isArray(labelInfoContents) &&
				labelInfoContents.map((inputItem, index) => (
					<DirectionalLightInput
						key={index}
						labelName={inputItem.labelName}
						positionInputInfo={inputItem.positionInputInfo}
						colorInputInfo={inputItem.colorInputInfo}
						id={inputItem.id}
					/>
				))}
		</div>
	);
};

export default DirectionalLightList;
