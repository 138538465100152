import { observer } from "mobx-react-lite";
import React from "react";
import InputColourList from "../../UIBuilder/CustomDrawer/components/InputColorList";
import LabelInputList from "../../UIBuilder/CustomDrawer/components/LabelInputList";
import DirectionalLightList from "./DirectionalLightList";

import { activityLayoutStore } from "../../../pages/ActivityLayoutTab/ActivityLayoutStore";
import CheckboxLabel from "../../UIBuilder/CustomDrawer/components/CheckboxLabel";
import "./ActivitySettings.css";
const ActivitySettings = (selectedActivity, setSelectedActivity) => {
	const labelInfoContents = [
		{
			labelName: "Camera Position",
			inputInfo: [
				{
					inputDefault:
						activityLayoutStore.json[activityLayoutStore.currentActivityContext]?.environment?.three?.camera
							?.position?.[0] ?? 0,
					inputCallback: e => {
						activityLayoutStore.setCameraPositionX(e);
					},
				},
				{
					inputDefault:
						activityLayoutStore.json[activityLayoutStore.currentContext]?.environment?.three?.camera?.position?.[1] ??
						0,
					inputCallback: e => {
						activityLayoutStore.setCameraPositionY(e);
					},
				},
				{
					inputDefault:
						activityLayoutStore.json[activityLayoutStore.currentActivityContext]?.environment?.three?.camera
							?.position?.[2] ?? 0,
					inputCallback: e => {
						activityLayoutStore.setCameraPositionZ(e);
					},
				},
			],
		},
	];

	const cameraDirectionContents = [
		{
			labelName: "Camera Direction",
			inputInfo: [
				{
					inputDefault:
						activityLayoutStore.json[activityLayoutStore.currentActivityContext]?.environment?.three?.camera
							?.target?.[0] ?? 0,
					inputCallback: e => {
						activityLayoutStore.setCameraDirectionX(e);
					},
				},
				{
					inputDefault:
						activityLayoutStore.json[activityLayoutStore.currentActivityContext]?.environment?.three?.camera
							?.target?.[1] ?? 0,
					inputCallback: e => {
						activityLayoutStore.setCameraDirectionY(e);
					},
				},
				{
					inputDefault:
						activityLayoutStore.json[activityLayoutStore.currentActivityContext]?.environment?.three?.camera
							?.target?.[2] ?? 0,
					inputCallback: e => {
						activityLayoutStore.setCameraDirectionZ(e);
					},
				},
			],
		},
	];

	const lights = activityLayoutStore.lightsTest.directionalLight;
	const lightInfoContents = [];
	for (let i = 0; i < lights.length; i++) {
		lightInfoContents.push(
			{
				labelName: `Directional Light ${lights[i].id} Position`,
				inputInfo: [
					{
						inputDefault: lights[i].position[0],
						inputCallback: e => {
							activityLayoutStore.setDirectionLightPositionX(lights[i].id, e);
						},
					},
					{
						inputDefault: lights[i].position[1],
						inputCallback: e => {
							activityLayoutStore.setDirectionLightYPosition(lights[i].id, e);
						},
					},
					{
						inputDefault: lights[i].position[2],
						inputCallback: e => {
							activityLayoutStore.setDirectionLightZPosition(lights[i].id, e);
						},
					},
				],
			},
			{
				labelName: `Directional Light ${lights[i].id} Color`,
				inputInfo: [
					{
						inputDefault: lights[i].position[0],
						type: "color",
						inputCallback: e => {
							activityLayoutStore.setDirectionLightColor(lights[i].id, e);
						},
					},
				],
			},
		);
	}

	const directionalLightTest = [];
	for (let i = 0; i < lights.length; i++) {
		directionalLightTest.push({
			labelName: `Directional Light ${lights[i].id}`,
			id: lights[i].id,
			positionInputInfo: [
				{
					inputDefault: lights[i].position[0],
					inputCallback: e => {},
				},
				{
					inputDefault: lights[i].position[1],
					inputCallback: e => {
						console.log(e);
					},
				},
				{
					inputDefault: lights[i].position[2],
					inputCallback: e => {
						console.log(e);
					},
				},
			],
			colorInputInfo: [
				{
					inputDefault: lights[i].color,
					type: "color",
				},
			],
		});
	}

	const minCameraDistance = [
		{
			labelName: "Minimum Camera Distance",
			inputInfo: [
				{
					inputDefault:
						activityLayoutStore.json[activityLayoutStore.currentActivityContext]?.environment?.three?.controls
							?.minDistance || "0",
					inputCallback: e => {
						activityLayoutStore.setMinimumCameraDistance(e);
					},
				},
			],
		},
	];

	const maxCameraDistance = [
		{
			labelName: "Maximum Camera Distance",
			inputInfo: [
				{
					inputDefault:
						activityLayoutStore.json[activityLayoutStore.currentActivityContext]?.environment?.three?.controls
							?.maxDistance || "",
					inputCallback: e => {
						activityLayoutStore.setMaximumCameraDistance(e);
					},
				},
			],
		},
	];

	const maxPolarAngle = [
		{
			labelName: "Maximum Polar Angle",
			inputInfo: [
				{
					inputDefault:
						(180 *
							activityLayoutStore.json[activityLayoutStore.currentActivityContext]?.environment?.three?.controls
								?.maxPolarAngle) /
							Math.PI || "",
					inputCallback: e => {
						activityLayoutStore.setMaximumPolarAngle(e);
					},
				},
			],
		},
	];
	const ambientLightColor = [
		{
			labelName: "Ambient Light Color",
			inputInfo: [
				{
					inputDefault: 255,
					inputCallback: e => {
						activityLayoutStore.findAmbientLight();
						activityLayoutStore.setAmbientLightColor(e);
					},
				},
			],
		},
	];
	const ambientLightIntensity = [
		{
			labelName: "Ambient Light Intensity",
			inputInfo: [
				{
					inputDefault:
						activityLayoutStore.json[activityLayoutStore.currentActivityContext]?.environment?.three?.lights
							?.ambientLight?.intensity || "",
					inputCallback: e => {
						activityLayoutStore.findAmbientLight();
						activityLayoutStore.setAmbientLightIntensity(e);
					},
				},
			],
		},
	];

	const floorSettings = [
		{
			labelName: "Floor Width",
			inputInfo: [
				{
					inputDefault:
						activityLayoutStore.json[activityLayoutStore.currentActivityContext]?.environment?.three?.floor
							.planeGeometry.width || "",
					inputCallback: e => {
						activityLayoutStore.setFloorWidth(e);
					},
				},
			],
		},
		{
			labelName: "Floor Height",
			inputInfo: [
				{
					inputDefault:
						activityLayoutStore.json[activityLayoutStore.currentActivityContext]?.environment?.three?.floor
							.planeGeometry.height || "",
					inputCallback: e => {
						activityLayoutStore.setFloorHeight(e);
					},
				},
			],
		},
	];
	const floorColorSettings = [
		{
			labelName: "Floor Color",
			inputInfo: [
				{
					inputDefault:
						activityLayoutStore.json[activityLayoutStore.currentActivityContext]?.environment?.three?.floor?.material
							?.color || "",
					inputCallback: e => {
						activityLayoutStore.setFloorColor(e);
					},
				},
			],
		},
	];

	return (
		<div className="text-2xl h-full text-color-bua-text-light" id="activity-settings__container">
			<h2>Camera Settings</h2>
			<LabelInputList labelInfoContents={labelInfoContents} />
			<LabelInputList labelInfoContents={minCameraDistance} />
			<LabelInputList labelInfoContents={maxCameraDistance} />
			<LabelInputList labelInfoContents={maxPolarAngle} />
			<LabelInputList labelInfoContents={cameraDirectionContents} />
			<h2>Controller Settings</h2>
			<CheckboxLabel
				labelName={"Pan"}
				checkboxDefault={
					activityLayoutStore.json[activityLayoutStore.currentActivityContext]?.environment?.three?.controls
						?.enablePan || false
				}
				checkboxCallback={e => {
					// Set the value of the checkbox input in the activity store
					activityLayoutStore.setInputPan(e);
				}}
			/>
			<CheckboxLabel
				labelName={"Rotate"}
				checkboxDefault={
					activityLayoutStore.json[activityLayoutStore.currentActivityContext]?.environment?.three?.controls
						?.enableRotate || false
				}
				checkboxCallback={e => {
					// Set the value of the checkbox input in the activity store
					activityLayoutStore.setInputRotate(e);
				}}
			/>
			<CheckboxLabel
				labelName={"Zoom"}
				checkboxDefault={
					activityLayoutStore.json[activityLayoutStore.currentActivityContext]?.environment?.three?.controls
						?.enabledZoom || false
				}
				checkboxCallback={e => {
					// Set the value of the checkbox input in the activity store
					activityLayoutStore.setInputZoom(e);
				}}
			/>
			<h2>Ambient Light Settings</h2>
			<InputColourList labelInfoContents={ambientLightColor} />
			<LabelInputList labelInfoContents={ambientLightIntensity} />
			<div className="flex" id="activity-settings__directional-light">
				<h2>Directional Light Settings</h2>
				<button className="bg-color-bua-logo-green rounded-lg w-10" onClick={activityLayoutStore.addDirectionalLight} type="button">
					+
				</button>
			</div>
			<DirectionalLightList labelInfoContents={directionalLightTest} />

			<h2> Floor Settings</h2>
			<LabelInputList labelInfoContents={floorSettings} />
			<InputColourList labelInfoContents={floorColorSettings} />
		</div>
	);
};

export default observer(ActivitySettings);
