import React from 'react';
import LabelInputList from '../../UIBuilder/CustomDrawer/components/LabelInputList';
import CheckboxLabel from '../../UIBuilder/CustomDrawer/components/CheckboxLabel';
import { observer } from "mobx-react-lite";
import { activityLayoutStore } from '../../../pages/ActivityLayoutTab/ActivityLayoutStore';

const GridSettings = observer((selectedActivity, setSelectedActivity) => {
    const labelInfoContents = [
        {
            labelName: "Width",
            inputInfo: [
                {
                    inputDefault: activityLayoutStore.json[activityLayoutStore.currentActivityContext].grid.width,
                    inputCallback: (input) => { 
                        if(input.length > 4) {console.warn("Exceeded maximum length of 4 characters for grid width. No longer updating the grid object."); return; } 
                        activityLayoutStore.updateGrid({width:input}) 
                    },
                },
            ]
        },
        {
            labelName: "Height",
            inputInfo: [
                {
                    inputDefault: activityLayoutStore.json[activityLayoutStore.currentActivityContext].grid.height,
                    inputCallback: (input) => { 
                        if(input.length > 4) {console.warn("Exceeded maximum length of 4 characters for grid height. No longer updating the grid object."); return; }
                        activityLayoutStore.updateGrid({height:input}) 
                    },
                },
            ]
        },
        {
            labelName: "Colour (Hexadecimal)",
            inputInfo: [
                {
                    inputDefault: activityLayoutStore.json[activityLayoutStore.currentActivityContext].grid.color.toString(16),
                    inputCallback: (input) => { 
                        if(input.length > 6) {console.warn("Exceeded maximum length of 6 characters for hexadecimal colour code. No longer updating the grid object."); return; }
                        activityLayoutStore.updateGrid({color: `#${input}`}) 
                    },
                },
            ]
        },        
        {
            labelName: "Opacity",
            inputInfo: [
                {
                    inputDefault: activityLayoutStore.json[activityLayoutStore.currentActivityContext].grid.opacity,
                    inputCallback: (input) => { 
                        if(input.length > 4) {console.warn("Exceeded maximum length of 4 characters for opacity. No longer updating the grid object."); return; }
                        activityLayoutStore.updateGrid({opacity:input}) 
                    },
                },
            ]
        },
        
    ]
    return (
      <div>
        <LabelInputList labelInfoContents={labelInfoContents}/>
        <CheckboxLabel labelName={"Toggle Grid"} checkboxDefault={activityLayoutStore.json[activityLayoutStore.currentActivityContext].grid.showGrid} checkboxCallback={(input) => { activityLayoutStore.updateGrid({showGrid:input}); }}/>
      </div>
    );
  });
  
export default GridSettings;