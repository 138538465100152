import React from "react";
import { useEffect, useRef } from "react";
import characterFrancisco from "../../assets/images/ProducePacker/characterFrancisco.webp"
import fruitCrate from "../../assets/images/ProducePacker/fruitCrate.png"
import halfFilledStar from "../../assets/images/ProducePacker/halfFilledStar.svg";
import truck from "../../assets/images/ProducePacker/truck.png"

import {
    EquationsIcon,
    FriendShip,
    GraphIcon,
    NotesIcon,
    OrdersIcon,
} from "../Phone/SVGIcons";

interface TutorialsMenuProps {
    displayTutorialsMenu: boolean;
    toggleDisplayTutorialsMenu: () => void;
    replayTutorial: (tutorialTitle: string) => void;
}

export default function TutorialsMenu({
    displayTutorialsMenu,
    toggleDisplayTutorialsMenu,
    replayTutorial,
}: TutorialsMenuProps) {

    const tutorials = [
        {
            image: truck,
            title: "Introduction and Goals",
            description: "This is a description of the tutorial."
        },
        {
            image: <OrdersIcon />,
            title: "Orders App",
            description: "This is a description of the tutorial."
        },
        {
            image: <GraphIcon />,
            title: "Graphing App",
            description: "This is a description of the tutorial."
        },
        {
            image: <EquationsIcon />,
            title: "Equations App",
            description: "This is a description of the tutorial."
        },
        {
            image: <NotesIcon />,
            title: "Notes App",
            description: "This is a long long long long long long long long long long long long long long long long long long long long long long long long long long long long long long long long description of the tutorial."
        },
        {
            image: fruitCrate,
            title: "Packing Orders",
            description: "This is a description of the tutorial."
        },
        {
            image: <FriendShip score={5} style={{}} />,
            title: "Scoring and Feedback",
            description: "This is a description of the tutorial."
        },
        {
            image: characterFrancisco,
            title: "Recurring Customers",
            description: "This is a description of the tutorial."
        },
        {
            image: halfFilledStar,
            title: "First Failure",
            description: "This is a description of the tutorial."
        }
    ];

    const tutorialCards = tutorials.map((tutorial) => {
        return (
            <div key={tutorial.title} className="w-[95%] max-h-[120px] pl-2 py-2 mt-2 last:mb-2 bg-slate-200 odd:bg-blue-100 rounded-lg">
                <div className="flex flex-row w-full h-full items-center p-2">
                    {typeof tutorial.image === 'string' ? (
                        <img
                            className="min-w-16 w-16 xs:min-w-20 sm:min-w-24 xs:w-20 sm:w-24 h-16 min-h-16 xs:min-h-20 sm:min-h-24 object-cover rounded-lg"
                            src={tutorial.image}
                            alt={tutorial.title}
                        />
                    ) : (
                        <div className="min-w-16 w-16 xs:min-w-20 sm:min-w-24 xs:w-20 sm:w-24 h-auto object-cover rounded-lg">
                            {tutorial.image}
                        </div>
                    )}
                    <div className="flex grow h-full flex-col items-start justify-start px-4">
                        <h1 className="font-bold text-sm xs:text-base sm:text-lg md:text-xl">
                            {tutorial.title}
                        </h1>
                        <h2 className="text-sm sm:text-base line-clamp-2">
                            {tutorial.description}
                        </h2>
                    </div>
                    <button
                        className={` 
                            
                            h-1/2 py-2 mr-2
                            bg-color-bua-button-primary text-white text-sm sm:text-base px-2 sm:px-6 rounded-md
                            active:opacity-50 hover:opacity-75 
                            focus:ring-offset-2 focus:ring-[#1FC3FF] focus:ring focus:outline-none
                            shadow-md shadow-slate-300
                        `}
                        type="button"
                        onClick={() => {
                            replayTutorial(tutorial.title);
                            toggleDisplayTutorialsMenu();
                            focusOnTutorialsButton();
                        }}
                    >
                        Replay
                    </button>
                </div>
            </div>
        )
    });

    const menuRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                toggleDisplayTutorialsMenu();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [displayTutorialsMenu, toggleDisplayTutorialsMenu]);

    /**
     * Function to handle focusing the settings cog button
     */
    function focusOnTutorialsButton() {
        const tutorialsButton = document.getElementById("tutorials-button");
        if (tutorialsButton) {
            tutorialsButton.focus();
        } else {
            console.warn("Tutorials button not found.");
        }
    }

    return (
        displayTutorialsMenu && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-25 px-2">
                <div
                    ref={menuRef}
                    id="settings-pop-up__container"
                    className="w-11/12 max-w-[700px] h-3/4 absolute bg-slate-100 rounded-lg shadow-2xl z-100"
                >
                    <div className="flex flex-col w-full h-full">
                        <h1 className="flex items-end justify-center text-xl sm:text-3xl md:text-4xl mt-2">
                            Tutorials
                        </h1>
                        <div className="grow rounded-md border overflow-y-auto border-slate-500 flex flex-col items-center mx-4 my-4">
                            {tutorialCards}
                        </div>
                    </div>
                </div>
            </div>
        )
    );
}